import React, { useEffect, useRef, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Radio } from "@mui/material";
import { useHistory, useLocation } from "react-router";
import { ReactComponent as TestLogo } from "../../../images/svg/swiitcher_icon_blau.svg";
import { CssTextField2 } from "../../CssTextField2";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Box from "@mui/material/Box";
import { CssTextField3 } from "../../CssTextField3";
import useFetch from "../../UseFetch";
import { toast } from "react-toastify";
import { ReactSession } from "react-client-session";
import { Select2 } from "../../Select2";
import { CustomButton } from "../../CustomButton";
import { ReactComponent as TrashIcon } from "../../../images/svg/mulleimer.svg";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import { CssTextField4 } from "../../CssTextField4";
import { confirmAlert } from "react-confirm-alert";
import ReplyIcon from "@mui/icons-material/Reply";
import { isMobile } from "react-device-detect";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const steps = ['Matchkorb', 'Einloggen', 'Bezahlen'];

const EditAddress = React.forwardRef((props, ref) => {
    const {
        type,
        index,
        id,
        createInvoiceAddress,
        updateInvoiceAddress,
        deleteInvoiceAddress,
        isDefault,
        setIsDefault,
        companyName,
        setCompanyName,
        companyNameError,
        setCompanyNameError,
        street,
        setStreet,
        streetError,
        setStreetError,
        nr,
        setNr,
        nrError,
        setNrError,
        zipcode,
        setZipcode,
        zipcodeError,
        setZipcodeError,
        city,
        setCity,
        cityError,
        setCityError,
        email,
        setEmail,
        emailError,
        setEmailError,
        ustId,
        setUstId,
        ustIdError,
        setUstIdError,
        newAddress,
        setNewAddress,
        setEditAddress
    } = props;

    const validateEmail = email => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            );
    };

    const validateInvoiceAddress = () => {
        let error = false;
        if (!companyName || companyName == '') {
            setCompanyNameError('Firmenname darf nicht leer sein');
            error = true;
        } else {
            setCompanyNameError(false);
        }
        if (!street || street == '') {
            setStreetError('Straße darf nicht leer sein');
            error = true;
        } else {
            setStreetError(false);
        }

        if (!nr || nr == '') {
            setNrError('Nr darf nicht leer sein');
            error = true;
        } else {
            setNrError(false);
        }
        if (!zipcode || zipcode == '') {
            setZipcodeError('PLZ darf nicht leer sein');
            error = true;
        } else {
            setZipcodeError(false);
        }
        if (!city || city == '') {
            setCityError('Ort darf nicht leer sein');
            error = true;
        } else {
            setCityError(false);
        }
        if (!ustId || ustId == '') {
            setUstIdError('UST-ID-Nummer darf nicht leer sein');
            error = true;
        } else {
            setUstIdError(false);
        }


        if (!email || email == '') {
            setEmailError('E-Mail darf nicht leer sein');
            error = true;
        } else {
            setEmailError(false);
        }

        if (email && !validateEmail(email)) {
            setEmailError('Keine gültige E-Mail');
            error = true;
        }
        return !error;
    }

    return (
        <div ref={ref} key={id} style={{
            border: '2px solid #C7D300',
            borderRadius: 25,
            marginTop: (index === 0 || id === -1) ? 0 : 15,
            marginBottom: 15,
            marginLeft: 25,
            marginRight: 10,
            padding: '10px 5px 20px 15px',
            textAlign: 'left'
        }}>
            <Grid container>
                <Grid item xs={10} style={{ paddingTop: 5 }}>
                    <p style={{
                        margin: 0,
                        marginBottom: 5,
                        padding: 0,
                        textAlign: 'left',
                        fontFamily: 'VagRoundedBold',
                        fontSize: 14
                    }}
                    >Adresse {type === 'new' ? <>anlegen</> : <>bearbeiten</>}</p>
                    <FormGroup style={{ padding: '0 10px' }}>
                        <FormControlLabel
                            onClick={(e) => {
                                e.preventDefault()
                                console.log('click')
                                setIsDefault(!isDefault)
                            }}
                            className={'defaultRadio'}
                            control={
                                <Radio
                                    checked={isDefault}
                                    sx={{
                                        p: '4px 9px',
                                        color: '#C7D300',
                                        '&.Mui-checked': {
                                            color: '#C7D300',
                                        },
                                    }}
                                />
                            }
                            label={'Als Standardadresse festlegen'}
                            sx={{ '& .MuiSvgIcon-root': { fontSize: 12 } }}
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={2} style={{ textAlign: 'right', paddingRight: 4 }}>
                    {(id && id !== -1) && <TrashIcon className={'not-yellow-white'}
                                                     onClick={() => {
                                                         deleteInvoiceAddress(id);
                                                     }}
                                                     style={{
                                                         height: 18,
                                                         width: 18,
                                                         marginRight: 2,
                                                         transition: 'all 0.2s ease-in-out',
                                                         textAlign: 'center',
                                                         cursor: 'pointer',
                                                         borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                                     }}
                    />}
                    <ClearIcon
                        className={'not-yellow-white'}
                        onClick={() => {
                            setIsDefault(false);
                            setCompanyName('');
                            setCompanyNameError(false);
                            setStreet('');
                            setStreetError(false);
                            setNr('');
                            setNrError(false);
                            setZipcode('');
                            setZipcodeError(false);
                            setCity('');
                            setCityError(false);
                            setEmail('');
                            setEmailError(false);
                            setUstId('');
                            setUstIdError(false);
                            if (type === 'new') {
                                setNewAddress(false);
                                window.scrollTo(0, 0);
                            } else if (type === 'edit') {
                                setEditAddress(-1);
                            }
                        }}
                        style={{
                            height: 18,
                            width: 18,
                            marginRight: 2,
                            transition: 'all 0.2s ease-in-out',
                            textAlign: 'center',
                            cursor: 'pointer',
                            borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CssTextField4
                        label="Firmenname*"
                        fieldtype={'GrayTransparent'}
                        // InputLabelProps={{
                        //     shrink: true
                        // }}
                        // className={'text-input-label-blue'}
                        style={{
                            transition: 'all 0.2s ease',
                            minHeight: 29,

                            width: 'calc(100% - 10px)'
                        }}
                        autoComplete={'off'}
                        onChange={e => {
                            if (e.target.value.trim().length === 0) {
                                setCompanyNameError(true);
                            } else {
                                setCompanyNameError(false);
                            }
                            setCompanyName(e.target.value);
                        }}
                        value={companyName}
                        error={!!companyNameError}
                        helperText={companyNameError}
                    />
                </Grid>
                <Grid item xs={10}>
                    <CssTextField4
                        label="Straße*"
                        fieldtype={'GrayTransparent'}
                        style={{
                            transition: 'all 0.2s ease',
                            minHeight: 29,

                            width: 'calc(100% - 10px)'
                        }}
                        autoComplete={'off'}
                        onChange={e => {
                            if (e.target.value.trim().length === 0) {
                                setStreetError(true);
                            } else {
                                setStreetError(false);
                            }
                            setStreet(e.target.value);
                        }}
                        value={street}
                        error={!!streetError}
                        helperText={streetError}
                    />
                </Grid>
                <Grid item xs={2}>
                    <CssTextField4
                        label="Nr*"
                        fieldtype={'GrayTransparent'}
                        style={{
                            transition: 'all 0.2s ease',
                            minHeight: 29,

                            width: 'calc(100% - 10px)'
                        }}
                        autoComplete={'off'}
                        onChange={e => {
                            if (e.target.value.trim().length === 0) {
                                setNrError(true);
                            } else {
                                setNrError(false);
                            }
                            setNr(e.target.value);
                        }}
                        value={nr}
                        error={!!nrError}
                        helperText={nrError}
                    />
                </Grid>
                <Grid item xs={6}>
                    <CssTextField4
                        label="PLZ*"
                        fieldtype={'GrayTransparent'}
                        style={{
                            transition: 'all 0.2s ease',
                            minHeight: 29,

                            width: 'calc(100% - 10px)'
                        }}
                        autoComplete={'off'}
                        onChange={e => {
                            if (e.target.value.trim().length === 0) {
                                setZipcodeError(true);
                            } else {
                                setZipcodeError(false);
                            }
                            setZipcode(e.target.value);
                        }}
                        value={zipcode}
                        error={!!zipcodeError}
                        helperText={zipcodeError}
                    />
                </Grid>
                <Grid item xs={6}>
                    <CssTextField4
                        label="Ort / Sitz*"
                        fieldtype={'GrayTransparent'}
                        style={{
                            transition: 'all 0.2s ease',
                            minHeight: 29,

                            width: 'calc(100% - 10px)'
                        }}
                        autoComplete={'off'}
                        onChange={e => {
                            if (e.target.value.trim().length === 0) {
                                setCityError(true);
                            } else {
                                setCityError(false);
                            }
                            setCity(e.target.value);
                        }}
                        value={city}
                        error={!!cityError}
                        helperText={cityError}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CssTextField4
                        label="UST-ID-Nummer*"
                        fieldtype={'GrayTransparent'}
                        style={{
                            transition: 'all 0.2s ease',
                            minHeight: 29,

                            width: 'calc(100% - 10px)'
                        }}
                        autoComplete={'off'}
                        onChange={e => {
                            if (e.target.value.trim().length === 0) {
                                setUstIdError(true);
                            } else {
                                setUstIdError(false);
                            }
                            setUstId(e.target.value);
                        }}
                        value={ustId}
                        error={!!ustIdError}
                        helperText={ustIdError}
                    />
                </Grid>
                <Grid item xs={6}>
                    <CssTextField4
                        label="E-Mail*"
                        fieldtype={'GrayTransparent'}
                        style={{
                            transition: 'all 0.2s ease',
                            minHeight: 29,

                            width: 'calc(100% - 10px)'
                        }}
                        autoComplete={'off'}
                        onChange={e => {
                            if (e.target.value.trim().length === 0) {
                                setEmailError(true);
                            } else {
                                setEmailError(false);
                            }
                            setEmail(e.target.value);
                        }}
                        value={email}
                        error={!!emailError}
                        helperText={emailError}
                    />
                </Grid>
                <Grid item xs={6} style={{ textAlign: 'right', marginTop: 7 }}>
                    <CustomButton
                        buttontype={'yellowBlue'}
                        style={{ padding: '1.7px 15px', marginRight: 10 }}
                        onClick={() => {
                            if (type === 'new' && validateInvoiceAddress()) createInvoiceAddress();
                            else if (type === 'edit' && validateInvoiceAddress()) updateInvoiceAddress(id);
                        }}
                    >Speichern</CustomButton>
                </Grid>
            </Grid>
        </div>
    );
});

export default function Arbeitgeber(props) {

    const _isMounted = useRef(false);

    const { loginRef, theme, locationChange, menuAppBarRef } = props;

    const location = useLocation();

    const history = useHistory();
    const loadingSpinnerRef = useRef(null)
    const unverbindlichesAngebotRef = useRef(null)
    const wrapperRef = useRef(null);
    const inputRef = React.useRef();
    const produkteUPreise = React.useRef(null);
    const api = useFetch();
    const [invoiceAddresses, setInvoiceAddresses] = React.useState([]);
    const newAddressRef = React.createRef();

    const [buttonHover, setButtonHover] = useState('');

    const [onLogin, setOnLogin] = useState(true);
    const [mitarbeiterzahl, setMitarbeiterzahl] = useState(false);
    const [flatOrSingle, setFlatOrSingle] = useState(false);
    const [mail, setMail] = React.useState('');
    const [mailError, setMailError] = React.useState(false);
    const [accountType, setAccountType] = React.useState('Arbeitgeber');
    const [accountTypeError, setAccountTypeError] = React.useState(false);
    const [password, setPassword] = React.useState('');
    const [passwordVerify, setPasswordVerify] = React.useState('');
    const [passwordError, setPasswordError] = React.useState(false);
    const [passwordVerifyError, setPasswordVerifyError] = React.useState(false);
    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const handleClickShowPassword = () => {
        setShowPassword1(!showPassword1);
        setShowPassword2(!showPassword2);
    }
    const [price, setPrice] = useState(false);
    const [straße, setStraße] = useState(false);
    const [hausnummer, setHausnummer] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isDefault, setIsDefault] = useState(false);
    const [isSelected, setIsSelected] = useState(false);
    const [companyName, setCompanyName] = React.useState('');
    const [companyNameError, setCompanyNameError] = React.useState(false);
    const [street, setStreet] = React.useState('');
    const [streetError, setStreetError] = React.useState(false);
    const [nr, setNr] = React.useState('');
    const [nrError, setNrError] = React.useState(false);
    const [zipcode, setZipcode] = React.useState('');
    const [zipcodeError, setZipcodeError] = React.useState(false);
    const [city, setCity] = React.useState('');
    const [cityError, setCityError] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [emailError, setEmailError] = React.useState(false);
    const [ustId, setUstId] = React.useState('');
    const [ustIdError, setUstIdError] = React.useState(false);
    const [company, setCompany] = React.useState('');
    const [companyError, setCompanyError] = React.useState(false);

    const [editAddress, setEditAddress] = React.useState(-1);
    const [newAddress, setNewAddress] = React.useState(false);
    const [getRef, setGetRef] = React.useState(false);

    const leftUl = useRef(null);


    const [error, setError] = useState(false);

    const [gogo, setGogo] = useState(false);
    const [gogo2, setGogo2] = useState(false);
    const [gogo3, setGogo3] = useState(false);
    const [gogo4, setGogo4] = useState(false);
    const [gogo5, setGogo5] = useState(false);
    const [gogo6, setGogo6] = useState(false);
    const [gogo7, setGogo7] = useState(false);
    const [gogo8, setGogo8] = useState(false);
    const [gogo9, setGogo9] = useState(false);


    const [radioValue, setRadioValue] = useState(false);

    const [showUpDown, setShowUpDown] = useState(false);
    const [showUpDownClass, setShowUpDownClass] = useState(false);


    function currencyFormat(num) {
        if (num) {
            return num.toFixed(2).toString().replace(".", ",").replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.') + ' €'
        }
        return false;
    }

    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());
    const [onRegistration, setOnRegistration] = React.useState(false);
    const [isShrink, setIsShrink] = useState([]);

    const notify = (message, error) => {
        console.log('NOTIFY')
        if (error) {
            toast.error(message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                style: { marginTop: 30 }
            });
        } else {
            toast.success(message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                style: { marginTop: 30 }
            });
        }
    }

    const getInvoiceAddresses = async () => {

        let rows = [];
        let moreData = true;
        let pageNumber = 1;

        for (let i = 1; i < pageNumber + 1; i++) {
            let url = ENV.REACT_APP_API_URL + '/invoice-addresses?page%5Bsize%5D=20&page%5Bnumber%5D=' + i;


            let { response, data } = await api(url)


            if (!response || response.status == 401) {
                ReactSession.set('token', undefined);
                ReactSession.set('refreshToken', undefined);
                return;
            }
            console.log(data);
            if (data && data.data) {
                data.data.map((item, index) => {
                    item.attributes.isDefault = (item.attributes.isDefault == 1) ? true : false;
                    if (rows.filter(i => i.id === item.id).length === 0) {
                        if (item.attributes.isDefault == 1) setIsSelected(item.id);
                        rows.push(item);
                    } else {
                        rows[rows.indexOf(rows.filter(i => i.id === item.id)[0])] = item;
                    }
                })

                if (data.meta.pagination.total === rows.length) {
                    moreData = false;
                }

            } else if (response.error) {
                notify('API Fehler: ' + response.status, true);
            }
        }


        rows.sort((a, b) => (a.attributes.isDefault == 1 ? -1 : 1));
        setInvoiceAddresses(rows);
    }

    const createInvoiceAddress = async () => {
        let updatedAttributes = {
            data: {
                attributes: {
                    isDefault: isDefault === true ? 1 : 0,
                    companyName: companyName,
                    street: street,
                    houseNumber: nr,
                    zipcode: zipcode,
                    city: city,
                    email,
                    ustId
                },
                relationships: {}
            }
        }

        let url = ENV.REACT_APP_API_URL + "/invoice-addresses";
        const { response, data } = await api(url, 'POST', JSON.stringify(updatedAttributes));

        if (response && response.status != '404' && data) {
            setIsDefault(false);
            setCompanyName('');
            setCompanyNameError(false);
            setStreet('');
            setStreetError(false);
            setNr('');
            setNrError(false);
            setZipcode('');
            setZipcodeError(false);
            setCity('');
            setCityError(false);
            setEmail('');
            setEmailError(false);
            setUstId('');
            setUstIdError(false);
            setNewAddress(false);
            setEditAddress(-1);
            getInvoiceAddresses();
        } else if (response.status == '404') {

        }
    }

    const updateInvoiceAddress = async (id) => {
        let updatedAttributes = {
            data: {
                attributes: {
                    isDefault: isDefault === true ? 1 : 0,
                    companyName: companyName,
                    street: street,
                    houseNumber: nr,
                    zipcode: zipcode,
                    city: city,
                    email,
                    ustId
                },
                relationships: {}
            }
        }

        let url = ENV.REACT_APP_API_URL + "/invoice-address/" + id;
        const { response, data } = await api(url, 'PATCH', JSON.stringify(updatedAttributes));

        if (response && response.status != '404' && data) {
            setIsDefault(false);
            setCompanyName('');
            setCompanyNameError(false);
            setStreet('');
            setStreetError(false);
            setNr('');
            setNrError(false);
            setZipcode('');
            setZipcodeError(false);
            setCity('');
            setCityError(false);
            setEmail('');
            setEmailError(false);
            setUstId('');
            setUstIdError(false);
            setNewAddress(false);
            setEditAddress(-1);
            getInvoiceAddresses();
            notify('Deine Adresse wurde erfolgreich aktualisiert');
        } else if (response.status == '404') {

        }
    }

    const deleteInvoiceAddress = (id) => {
        console.log('DELETE')
        confirmAlert({
            title: 'Bestätigung',
            message: 'Möchtest Du diese Adresse wirklich löschen?',
            buttons: [
                {
                    label: 'Ja',
                    onClick: async () => {
                        console.log(id);
                        let url = ENV.REACT_APP_API_URL + '/invoice-address/' + id;
                        const { response, data } = await api(url, 'DELETE');
                        if (response && response.status == 204) {
                            setIsDefault(false);
                            setCompanyName('');
                            setCompanyNameError(false);
                            setStreet('');
                            setStreetError(false);
                            setNr('');
                            setNrError(false);
                            setZipcode('');
                            setZipcodeError(false);
                            setCity('');
                            setCityError(false);
                            setEmail('');
                            setEmailError(false);
                            setUstId('');
                            setUstIdError(false);
                            setNewAddress(false);
                            setEditAddress(-1);
                            getInvoiceAddresses();

                            notify('Deine Adresse wurde erfolgreich gelöscht');
                        } else if (response && response.status == 404) {

                        }
                    }
                },
                {
                    label: 'Nein',
                }
            ],
            overlayClassName: "overlay-custom-class-name"
        });
    }

    const isStepOptional = (step) => {
        return step === 1;
    };

    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        if (activeStep == 0 && ReactSession.get('user')) {
            setActiveStep((prevActiveStep) => prevActiveStep + 2);
        } else {
            if (activeStep == 2) {
                setLoading(true);
                // setTimeout(() => {
                //     setLoading(false);
                // }, 2000)
                completeOrder();
            }
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }

        setSkipped(newSkipped);
    };

    const completeOrder = async () => {
        let attributes = {
            data: {
                attributes: {
                    mitarbeiterzahl,
                    type: flatOrSingle,
                    selectedAddress: isSelected
                }
            }
        }

        let url = ENV.REACT_APP_API_URL + "/user/complete-order";
        const { response, data } = await api(url, 'POST', JSON.stringify(attributes));

        if (response && response.status != '404' && data && data.data && data.data.success) {
            setLoading(false);
        } else if (response.status == '404') {

        }
    }

    useEffect(() => {
        if (activeStep == 2) {
            getInvoiceAddresses();
        }
    }, [activeStep])

    const handleBack = () => {
        if (activeStep == 1 || (activeStep == 2 && ReactSession.get('user'))) {
            setGogo7(false);
            setGogo6(true);
            setTimeout(() => {
                setGogo5(true);
                setGogo8(false);
            }, 200)
        }

        if (activeStep == 2 && ReactSession.get('user')) {
            setActiveStep((prevActiveStep) => prevActiveStep - 2);
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }


    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    useEffect(() => {
        setMailError(false)
        setPasswordError(false)
        setPasswordVerifyError(false)
    }, [onLogin])

    useEffect(() => {
        _isMounted.current = true;
        document.title = 'swiitcher - Arbeitgeber';
        console.log(location);
        if (location.hash == '#produkte-und-preise') {
            window.history.replaceState("", document.title, window.location.pathname);
            const yOffset = 60;
            const element = produkteUPreise.current;
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

            window.scrollTo({ top: y, behavior: 'smooth' });
        }
        locationChange(location);

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
            _isMounted.current = false;
        };
    }, []);

    const handleClickOutside = (event) => {
        if (_isMounted.current && wrapperRef.current && !wrapperRef.current.contains(event.target)) {
            //alert("You clicked outside of me!");
            if (setShowUpDown) {
                setShowUpDownClass(false);
                setTimeout(() => {
                    if (_isMounted.current) setShowUpDown(false)
                }, 200);
            }
        }
    }

    const handleKeypress = e => {
        //it triggers by pressing the enter key
        if (e.charCode === 13) {
            // login();
        }
    };

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        disableScrollLock: true,
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                minHeight: 'unset',
            },
        },
    };

    useEffect(() => {
        if (newAddressRef.current && getRef && invoiceAddresses && invoiceAddresses.length > 1) {
            setGetRef(false);
            const yOffset = 0;
            const y = (newAddressRef.current.getBoundingClientRect().bottom - newAddressRef.current.getBoundingClientRect().y) + yOffset;

            newAddressRef.current.scrollIntoView({ top: y, block: 'nearest', behavior: 'smooth' });
        } else {
        }
    }, [newAddressRef])

    return (<div>
        <Grid ref={produkteUPreise} container style={{
            padding: 0,
            //background: 'linear-gradient(90deg, rgba(21,42,68,1) 0%, rgba(0,55,78,1) 100%)',
            background: '#00374e',
            overflow: 'hidden',
            height: '100vh',
        }}>
            <Grid item xs={12}
                  style={{
                      display: 'flex',
                      justifyContent: 'center',
                      // alignItems: 'center',
                      alignItems: 'normal',
                      // height: 'calc(100vh - 20px)',
                      // minHeight: 'calc(100vh - 3    00px)',
                      minHeight: '800px',
                      //background: '#00374e', //boxShadow: 'inset 0px 2px 4px -1px rgb(0 0 0 / 20%), inset 0px 4px 5px 0px rgb(0 0 0 / 14%), inset 0px 1px 10px 0px rgb(0 0 0 / 12%)'
                  }}>
                {!gogo2 && <div className={'fadeInOut ' + (gogo ? 'fadeLeft' : '')} style={{
                    paddingTop: 200, paddingLeft: isMobile ? 20 : 100, paddingRight: isMobile ? 20 : 100, paddingBottom: 100,

                }}>
                    <div style={{ textAlign: 'center', color: 'white' }}>
                        <p style={{
                            fontSize: isMobile ? 38 : 60,
                            fontFamily: 'VagRoundedBold',
                            fontWeight: 'bold',
                            margin: '0 0 15px 0',
                            lineHeight: 1,
                            color: '#C7D300'
                        }}>
                            Produkte & Preise
                        </p>
                        <p style={{
                            fontSize: 16,
                            fontFamily: 'VagRoundedBold',
                            fontWeight: 'bold',
                            margin: 0,
                            lineHeight: 1,
                            color: '#C7D300'
                        }}>
                            Eine Frage beantworten & Angebot erhalten
                        </p>
                        <p style={{
                            display: 'flex',
                            justifyContent: 'center',
                            margin: '70px 0 30px 0',
                            lineHeight: 2
                        }}>
                            Wie viele Mitarbeiter sind derzeit in Deinem Unternehmen beschäftigt?
                        </p>

                        <div ref={wrapperRef} style={{ position: 'relative' }}>
                            <div

                                className={'fadeInOut'}
                                style={{
                                    position: 'absolute',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    left: 0,
                                    right: 0,
                                    textAlign: 'center',
                                    width: 220
                                }}
                            >

                                {showUpDown && <div
                                    className={showUpDownClass ? 'fadeUp' : 'wegUp'}
                                    style={{
                                        position: 'absolute',
                                        top: 8,
                                        left: 0,
                                        height: '100%',
                                        margin: 0,
                                        padding: 0,
                                        display: 'flex',
                                        //alignItems: 'center',
                                        justifyContent: 'center',
                                        borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                        transition: 'all 0.2s ease-in-out',
                                    }}
                                >
                                    <IconButton
                                        style={{
                                            height: 20,
                                            width: 20,
                                            border: '2px solid',
                                            borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                            background: 'transparent',
                                            fontSize: 25,
                                            padding: 0,
                                            color: theme.palette.secondary.main,
                                            paddingBottom: 2
                                        }}
                                        className={'squareButtonLeft'}
                                        onClick={() => {
                                            let temp = 0;
                                            if (!isNaN(parseInt(mitarbeiterzahl))) {
                                                temp = mitarbeiterzahl;
                                            }
                                            if (temp > 0) {
                                                temp = parseInt(temp) - 1;
                                                setMitarbeiterzahl(temp);
                                                setError(false);
                                            }
                                        }}
                                    >
                                        -
                                    </IconButton>
                                </div>}


                                <CssTextField2
                                    sx={{ input: { color: theme.palette.secondary.main } }}
                                    showUpDown={showUpDown}
                                    label="Mitarbeiterzahl"
                                    type={'number'}
                                    value={mitarbeiterzahl}
                                    helperText={error ? 'Keine gültige Mitarbeiterzahl' : ''}
                                    onChange={e => {
                                        if (e.target.value.length >= 0) {
                                            setMitarbeiterzahl(e.target.value);
                                            setError(false);
                                        }

                                        if (e.target.value.length < 1 || e.target.value == '0') setError(true);
                                    }}
                                    id={'ASDFASDF'}
                                    // //onKeyPress={handleKeypress}
                                    // value={username}
                                    style={{ width: 150 }}
                                    onFocus={() => {
                                        //setShowUpDown(true);
                                        //setShowUpDownClass(true);
                                        //setShowUpDownClass(true)
                                        setShowUpDown(true);
                                        setTimeout(() => {
                                            setShowUpDownClass(true)
                                        }, 200);
                                    }}
                                    InputLabelProps={{
                                        shrink: true //(mitarbeiterzahl || showUpDown)
                                    }}
                                    inputRef={inputRef}
                                    //inputRef={input => input && input.focus()}

                                />


                                {showUpDown && <div
                                    className={showUpDownClass ? 'fadeUp' : 'wegUp'}
                                    style={{
                                        position: 'absolute',
                                        top: 8,
                                        right: 0,
                                        height: '100%',
                                        margin: 0,
                                        padding: 0,
                                        display: 'flex',
                                        //alignItems: 'center',
                                        justifyContent: 'center',
                                        borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                        transition: 'all 0.2s ease-in-out',
                                    }}
                                >
                                    <IconButton
                                        style={{
                                            height: 20,
                                            width: 20,
                                            border: '2px solid',
                                            borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                            background: 'transparent',
                                            fontSize: 20,
                                            padding: 0,
                                            color: theme.palette.secondary.main,
                                            paddingBottom: 0,
                                        }}
                                        className={'squareButtonRight'}
                                        onClick={() => {
                                            let temp = 0;
                                            if (!isNaN(parseInt(mitarbeiterzahl))) {
                                                temp = mitarbeiterzahl;
                                            }
                                            temp = parseInt(temp) + 1;

                                            setMitarbeiterzahl(temp)
                                            if (temp > 0) setError(false);
                                        }}
                                    >
                                        +
                                    </IconButton>
                                </div>}
                            </div>
                            <div style={{
                                position: 'absolute',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                left: 0,
                                right: 0,
                                top: 100,
                                textAlign: 'center',
                                width: 350
                            }}>
                                <button className={'fadeInOut ' + (showUpDownClass ? '' : '')}
                                        onMouseOver={() => setButtonHover('mitarbeiterzahlButton')}
                                        onMouseOut={() => setButtonHover('')} style={{
                                    fontSize: 12,
                                    fontFamily: 'VagRoundedBold',
                                    background: buttonHover === 'mitarbeiterzahlButton' ? '#00374e' : '#C7D300',
                                    cursor: 'pointer',
                                    width: 260,
                                    margin: 'auto',
                                    textAlign: 'center',
                                    padding: '10px 10px 10px 10px',
                                    borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                    color: buttonHover === 'mitarbeiterzahlButton' ? '#C7D300' : '#00374e',
                                    border: buttonHover === 'mitarbeiterzahlButton' ? '2px solid #C7D300' : '2px solid #00374e',

                                }} onClick={() => {
                                    if (mitarbeiterzahl && typeof mitarbeiterzahl == 'String' && mitarbeiterzahl.startsWith('0')) setMitarbeiterzahl(parseInt(mitarbeiterzahl).toString());

                                    if (mitarbeiterzahl && mitarbeiterzahl > 0) {
                                        //alert('Mitarbeiterzahl: ' + parseInt(mitarbeiterzahl).toString());
                                        setError(false);
                                        setGogo(true);
                                        //setGogo2(true);
                                        setTimeout(() => {
                                            setGogo2(true);
                                            setTimeout(async () => {
                                                //loadingSpinnerRef.current.scrollIntoView();

                                                const yOffset = 0;
                                                const element = loadingSpinnerRef.current;
                                                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

                                                window.scrollTo({ top: y, behavior: 'smooth' });


                                                setGogo3(true);

                                                let url = ENV.REACT_APP_API_URL + '/price-scale';

                                                let postData = {
                                                    data: {
                                                        attributes: {
                                                            mitarbeiterzahl
                                                        }
                                                    }
                                                }
                                                const {
                                                    response,
                                                    data
                                                } = await api(url, 'POST', JSON.stringify(postData));

                                                if (response && response.status == 200) {
                                                    console.log(data);
                                                    console.log(data.data.price);
                                                    setPrice(data.data.price);
                                                    setTimeout(() => {
                                                        setGogo4(true);
                                                        setTimeout(() => {
                                                            setGogo5(true);
                                                            //unverbindlichesAngebotRef.current.scrollIntoView()
                                                            setTimeout(() => {
                                                                const yOffset = -160;
                                                                const element = unverbindlichesAngebotRef.current;
                                                                const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

                                                                //window.scrollTo({top: y, behavior: 'smooth'});
                                                                setTimeout(() => {
                                                                    setGogo6(true);
                                                                }, 100);
                                                            }, 200)
                                                        }, 200);
                                                    }, 1000);

                                                } else {
                                                    //notify('API Fehler: ' + response.status, true);
                                                }


                                            }, 200);
                                        }, 200);

                                    } else {
                                        setError(true);
                                    }
                                }}>unverbindliches Angebot berechnen
                                </button>
                            </div>
                        </div>

                    </div>
                </div>}

                {(gogo2 && !gogo5 && !gogo9) &&
                    <div ref={loadingSpinnerRef}
                         className={'fadeInOut ' + (gogo3 ? gogo4 ? 'fadeLeft2' : '' : 'wegLeft2')}
                         style={{
                             //display: 'flex',
                             //justifyContent: 'center',
                             //alignItems: 'center',
                             color: 'white',
                             paddingTop: 100,
                             paddingLeft: 100,
                             paddingRight: 100,
                             paddingBottom: 100,
                             width: '100%',
                             display: 'flex',
                             justifyContent: 'center',
                             flexDirection: 'column'
                         }}>
                        <p>Angebot wird geladen ...</p>
                        <div id={'testLogo'} style={{
                            borderRadius: 50,
                            width: 60,
                            height: 60,
                            minWidth: 60,
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                            border: '4px solid transparent',
                            // cursor: 'pointer',
                            transitionDuration: '0.2s',
                            transitionProperty: 'transform',
                            margin: '0 auto'
                        }}
                        >
                            <div style={{
                                padding: 7.5,
                                background: 'rgba(0, 55, 78, 1)',
                                borderRadius: 50,
                                width: 57,
                                minWidth: 27,
                                height: 35,
                                minHeight: 35,
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'flex',
                                border: '5px solid rgba(199, 211, 0, 1)'
                            }}>
                                <TestLogo
                                    style={{
                                        width: 30,
                                    }}
                                />
                            </div>
                        </div>
                    </div>}


                {gogo5 && <Box id={'stepper2'}
                               sx={{ width: '95%' }}
                               style={{
                                   padding: 50, overflow: 'hidden',
                                   transition: 'all 0.3s ease-in-out',
                                   paddingTop: 0
                               }}
                               className={'fadeInOut ' + (gogo6 ? '' : 'wegLeft2')}
                >
                    <p style={{
                        marginBottom: 20,
                        textAlign: 'left',
                        fontFamily: 'VagRoundedBold',
                        color: 'white',
                        fontSize: 24
                    }}>Kaufübersicht</p>
                    <Stepper id={'stepper'} activeStep={activeStep} style={{ maxWidth: 600, margin: 'auto' }}>
                        {steps.map((label, index) => {
                            const stepProps = {};
                            const labelProps = {};
                            // if (isStepOptional(index)) {
                            //     labelProps.optional = (
                            //         <Typography variant="caption">Optional</Typography>
                            //     );
                            // }
                            // if (isStepSkipped(index)) {
                            //     stepProps.completed = false;
                            // }
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps}>{label}</StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>

                    {loading ? <div ref={loadingSpinnerRef}

                                    style={{
                                        //display: 'flex',
                                        //justifyContent: 'center',
                                        //alignItems: 'center',
                                        color: 'white',
                                        paddingTop: 100,
                                        paddingRight: 100,
                                        paddingBottom: 100,
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        flexDirection: 'column'
                                    }}>
                            <p>Kauf wird abgeschlossen ...</p>
                            <div id={'testLogo'} style={{
                                borderRadius: 50,
                                width: 60,
                                height: 60,
                                minWidth: 60,
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'flex',
                                border: '4px solid transparent',
                                // cursor: 'pointer',
                                transitionDuration: '0.2s',
                                transitionProperty: 'transform',
                                margin: '0 auto'
                            }}
                            >
                                <div style={{
                                    padding: 7.5,
                                    background: 'rgba(0, 55, 78, 1)',
                                    borderRadius: 50,
                                    width: 57,
                                    minWidth: 27,
                                    height: 35,
                                    minHeight: 35,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    display: 'flex',
                                    border: '5px solid rgba(199, 211, 0, 1)'
                                }}>
                                    <TestLogo
                                        style={{
                                            width: 30,
                                        }}
                                    />
                                </div>
                            </div>
                        </div> :
                        <div style={{
                            display: activeStep === steps.length ? 'block' : 'none',
                            justifycontent: 'center',
                            alignitems: 'flex-start',
                            // transition: 'all 0.3s ease-in-out 0s',
                            opacity: 1,
                            marginTop: '75px',
                            color: 'white',
                        }}>
                            <h3 style={{ fontFamily: 'VagRoundedBold', fontSize: 24 }}>Vielen Dank!</h3>
                            <p style={{ fontSize: 12 }}>Wir senden Dir unverzüglich eine Rechnung via Mail zu.</p>
                            <p style={{ fontSize: 12 }}>Diese kannst Du auch in deinem Arbeitgeberprofil unter
                                "Rechnungen" einsehen.</p>
                            <CustomButton
                                buttontype={'yellowWhite2'}
                                onClick={() => {
                                    // ReactSession.set('menuState', true)
                                    history.push('/neue-stellenanzeige')
                                }}
                                style={{
                                    color: '#00374e',
                                    borderRadius: 20,
                                    marginBottom: 5
                                }}
                            >STELLENANZEIGE ERSTELLEN</CustomButton>
                            <br/>
                            <CustomButton
                                buttontype={'gray'}
                                onClick={() => {
                                    // ReactSession.set('menuState', true)
                                    history.push('/übersicht')
                                }}
                                style={{
                                    color: '#00374e',
                                    fontSize: 10,
                                    borderRadius: 20
                                }}
                            >ZU MEINEM PROFIL</CustomButton>
                        </div>}
                    {activeStep === steps.length ? (
                        <React.Fragment>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>

                            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                                {steps.map((item, index) => {

                                    return (
                                        <React.Fragment>


                                            <div style={{
                                                display: 'block',
                                                justifyContent: 'center',
                                                alignItems: 'flex-start',
                                                // overflow: 'hidden',
                                                transition: 'all 0.3s ease-in-out',
                                                opacity: (activeStep === index) ? 1 : 0,
                                                width: (activeStep === index) ? 'calc(100vw)' : 0,
                                                // height: 'calc(100vh - 400px)',
                                                marginTop: 20,
                                                color: 'white'
                                            }}>
                                                {/*{index != 0 && item}*/}
                                                {index != 0 && <div
                                                    style={{
                                                        height: '80vh',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        justifyContent: 'space-between',
                                                    }}
                                                >
                                                    {/*    <div style={{height: '60vh'}}>{item}</div>*/}

                                                    {(index == 1 && onLogin) && <div className={'loginTest'} style={{
                                                        // height: 295,
                                                        margin: 'auto',
                                                        display: activeStep == 1 ? 'block' : 'none',
                                                        width: 300,
                                                        color: 'white',
                                                        // background: 'white',
                                                        // borderRadius: 25,
                                                        // padding: '5px 20px 20px 20px',
                                                        // boxShadow: '0px 0px 15px 6px rgb(46 61 73 / 15%)',
                                                        padding: '25px 40px 70px',
                                                        textAlign: 'center',
                                                        border: '3px solid ' + theme.palette.secondary.main,
                                                        borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                                    }}>

                                                        <React.Fragment>
                                                            <p style={{
                                                                fontFamily: 'VagRoundedBold',
                                                                paddingBottom: 20
                                                            }}>Login</p>
                                                            <CssTextField3
                                                                fieldtype={'ASDF'}
                                                                label="E-Mail"
                                                                // InputLabelProps={{
                                                                //     shrink: true
                                                                // }}
                                                                // className={'text-input-label-blue'}
                                                                style={{
                                                                    transition: 'all 0.2s ease',
                                                                    // margin: '0 0 8px 0',
                                                                    // minHeight: 60,
                                                                    marginTop: (isShrink && isShrink.includes('E-Mail') || mail) ? 20 : 5,
                                                                    width: '100%'
                                                                }}
                                                                onFocus={() => {
                                                                    let t = [...isShrink];
                                                                    t.push('E-Mail');
                                                                    setIsShrink(t);
                                                                }}
                                                                onBlur={() => {
                                                                    setIsShrink([]);
                                                                }}
                                                                autoComplete={'off'}
                                                                onChange={e => {
                                                                    if (e.target.value.trim().length === 0) {
                                                                        setMailError(true);
                                                                    } else {
                                                                        setMailError(false);
                                                                    }
                                                                    setMail(e.target.value);
                                                                }}
                                                                onKeyPress={handleKeypress}
                                                                value={mail}
                                                                error={!!mailError}
                                                                helperText={mailError === true ? 'Benutzername darf nicht leer sein!' : mailError}
                                                            />
                                                            <CssTextField3
                                                                label="Passwort"
                                                                // InputLabelProps={{
                                                                //     shrink: true
                                                                // }}
                                                                type={showPassword1 ? "text" : "password"}
                                                                InputProps={{ // <-- This is where the toggle button is added.
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <IconButton
                                                                                aria-label="toggle password visibility"
                                                                                onClick={handleClickShowPassword}
                                                                            >
                                                                                {showPassword1 ? <Visibility style={{ color: '#C7D300' }}/> :
                                                                                    <VisibilityOff style={{ color: '#C7D300' }}/>}
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                    ),
                                                                    autocomplete: 'new-password',
                                                                    form: {
                                                                        autocomplete: 'off',
                                                                    },
                                                                }}
                                                                // className={'text-input-label-blue'}
                                                                style={{
                                                                    transition: 'all 0.2s ease',
                                                                    marginTop: (isShrink && isShrink.includes('Passwort') || password) ? 25 : 5,
                                                                    marginBottom: 10,
                                                                    width: '100%'
                                                                }}
                                                                onFocus={() => {
                                                                    setIsShrink(['Passwort']);
                                                                }}
                                                                onBlur={() => {
                                                                    setIsShrink([]);
                                                                }}
                                                                onChange={e => {
                                                                    if (e.target.value.trim().length === 0) {
                                                                        setPasswordError(true);
                                                                    } else {
                                                                        setPasswordError(false);
                                                                    }
                                                                    setPassword(e.target.value);
                                                                }}
                                                                onKeyPress={handleKeypress}
                                                                value={password}
                                                                error={!!passwordError}
                                                                helperText={passwordError === true ? 'Passwort darf nicht leer sein!' : passwordError}
                                                            />

                                                            <button className={'fadeInOut'}
                                                                    onMouseOver={() => setButtonHover('loginButton')}
                                                                    onMouseOut={() => setButtonHover('')} style={{
                                                                fontFamily: 'VagRoundedBold',
                                                                background: buttonHover === 'loginButton' ? '#00374e' : '#C7D300',
                                                                cursor: 'pointer',
                                                                margin: 'auto',
                                                                textAlign: 'center',
                                                                padding: '10px 20px',
                                                                borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                                                color: buttonHover === 'loginButton' ? '#C7D300' : '#00374e',
                                                                border: buttonHover === 'loginButton' ? '2px solid #C7D300' : '2px solid #00374e',
                                                                fontSize: 12,
                                                                width: '100%'
                                                            }} onClick={() => {
                                                                // login();
                                                                loginRef.current.logIn(mail, password).then((e) => {
                                                                    // ReactSession.set('menuState', false)
                                                                    console.log(e);
                                                                    setMailError(e.mError);
                                                                    if (e.user && e.user.userType === 'Arbeitgeber') setMailError('Dieser Bereich ist nur für Arbeitgeber');
                                                                    setPasswordError(e.pError);
                                                                    console.log('ALARM')

                                                                    setCompanyName(e.user?.companyName);
                                                                    setStraße(e.user?.straße);
                                                                    setHausnummer(e.user?.hausnummer);
                                                                    setZipcode(e.user?.postleitzahl);
                                                                    setCity(e.user?.ort);
                                                                    if (e && e.success) {
                                                                        setActiveStep(2);
                                                                    }
                                                                });
                                                            }}>LOGIN
                                                            </button>
                                                            <div style={{
                                                                marginTop: 15,
                                                                lineHeight: 0.8,
                                                                fontFamily: 'VagRoundedRegular',
                                                                marginLeft: 0,
                                                                textAlign: 'left',
                                                                float: 'left'
                                                            }}>
                                                                <a onClick={() => {
                                                                    loginRef.current.passForget(mail);
                                                                }}
                                                                   style={{
                                                                       color: 'white',
                                                                       cursor: 'pointer',
                                                                       fontSize: 10,
                                                                       textAlign: 'left',
                                                                       textDecoration: 'none'
                                                                   }}>Passwort
                                                                    vergessen?</a>
                                                            </div>
                                                            <div style={{
                                                                textAlign: 'right',
                                                                float: 'right',
                                                                marginTop: 10
                                                            }}>
                                                                <CustomButton
                                                                    buttontype={'gray'}
                                                                    onClick={() => {
                                                                        setOnLogin(false)
                                                                    }}
                                                                    style={{
                                                                        fontSize: 10,
                                                                        color: 'black',
                                                                    }}
                                                                >Registrieren</CustomButton>
                                                            </div>
                                                        </React.Fragment>


                                                    </div>}


                                                    {(index == 1 && !onLogin) &&
                                                        <div className={'registerTest'} style={{
                                                            // height: 415,
                                                            margin: '50px auto 30px',
                                                            display: 'block',
                                                            maxWidth: 600,
                                                            color: 'white',
                                                            width: 300,
                                                            // height: 440,
                                                            // background: 'white',
                                                            // borderRadius: 25,
                                                            padding: '25px 40px 70px',
                                                            // boxShadow: '0px 0px 15px 6px rgb(46 61 73 / 15%)',
                                                            border: '3px solid ' + theme.palette.secondary.main,
                                                            borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                                        }}>

                                                            <React.Fragment>
                                                                <p style={{
                                                                    fontFamily: 'VagRoundedBold',
                                                                    color: 'white',
                                                                    paddingBottom: 20
                                                                }}>Registrierung</p>


                                                                <CssTextField3
                                                                    label="E-Mail"
                                                                    // InputLabelProps={{
                                                                    //     shrink: true
                                                                    // }}
                                                                    // className={'text-input-label-blue'}
                                                                    style={{
                                                                        transition: 'all 0.2s ease',
                                                                        // margin: '0 0 8px 0',
                                                                        // minHeight: 60,
                                                                        marginTop: (isShrink && isShrink.includes('E-Mail') || mail) ? 20 : 0,
                                                                        width: '100%'
                                                                    }}
                                                                    onFocus={() => {
                                                                        let t = [...isShrink];
                                                                        t.push('E-Mail');
                                                                        setIsShrink(t);
                                                                    }}
                                                                    onBlur={() => {
                                                                        setIsShrink([]);
                                                                    }}
                                                                    autoComplete={'off'}
                                                                    onChange={e => {
                                                                        if (e.target.value.trim().length === 0) {
                                                                            setMailError(true);
                                                                        } else {
                                                                            setMailError(false);
                                                                        }
                                                                        setMail(e.target.value);
                                                                    }}
                                                                    onKeyPress={handleKeypress}
                                                                    value={mail}
                                                                    error={!!mailError}
                                                                    helperText={mailError === true ? 'Benutzername darf nicht leer sein!' : mailError}
                                                                />
                                                                <FormControl sx={{
                                                                    width: '100%',
                                                                    // marginTop: 20,
                                                                    transition: 'all 0.2s ease',
                                                                    marginTop: (isShrink && isShrink.includes('Typ') || accountType) ? '30px !important' : '10px !important',

                                                                }}
                                                                             className={'testClassSelect'}>
                                                                    <InputLabel style={{ transition: 'all 0.2s ease' }}
                                                                                shrink={(isShrink && isShrink.includes('Typ') || accountType)}
                                                                                className={(isShrink && isShrink.includes('Typ') || accountType) ? 'whiteLabel' : ''}>Typ*</InputLabel>
                                                                    <Select2
                                                                        value={accountType}
                                                                        onChange={(e) => setAccountType(e.target.value)}
                                                                        fullWidth
                                                                        MenuProps={MenuProps}
                                                                        input={<Select2/>}
                                                                        style={{
                                                                            transition: 'all 0.2s ease',
                                                                            // marginTop: (isShrink && isShrink.includes('Typ') || accountType) ? 0 : 0,
                                                                            marginBottom: 10,
                                                                            width: '100%'
                                                                        }}
                                                                        onOpen={() => {
                                                                            if (accountType === null) setIsShrink(['Typ']);
                                                                        }}
                                                                        onClose={() => {
                                                                            setIsShrink([]);
                                                                        }}
                                                                        // onFocus={() => {
                                                                        //     if (accountType === null) setIsShrink(['Typ']);
                                                                        // }}
                                                                        // onBlur={() => {
                                                                        //     setIsShrink([]);
                                                                        // }}
                                                                    >
                                                                        <MenuItem
                                                                            value={'Arbeitgeber'}>Arbeitgeber</MenuItem>
                                                                        <MenuItem
                                                                            value={'Arbeitnehmer'}>Arbeitnehmer</MenuItem>
                                                                    </Select2>
                                                                </FormControl>
                                                                {accountType == 'Arbeitgeber' ?
                                                                    <CssTextField3
                                                                        label="Firmenname*"
                                                                        style={{
                                                                            transition: 'all 0.2s ease',
                                                                            marginTop: (isShrink && isShrink.includes('Firmenname') || company) ? 20 : 0,
                                                                            marginBottom: 10,
                                                                            width: '100%'
                                                                        }}
                                                                        onFocus={() => {
                                                                            setIsShrink(['Firmenname']);
                                                                        }}
                                                                        onBlur={() => {
                                                                            setIsShrink([]);
                                                                        }}
                                                                        autoComplete={'off'}
                                                                        onChange={e => {
                                                                            if (e.target.value.trim().length === 0) {
                                                                                setCompanyError(true);
                                                                            } else {
                                                                                setCompanyError(false);
                                                                            }
                                                                            setCompany(e.target.value);
                                                                        }}
                                                                        onKeyPress={(e) => handleKeypress(e, 'register')}
                                                                        value={company}
                                                                        error={!!companyError}
                                                                        helperText={companyError === true ? 'Firmenname darf nicht leer sein!' : companyError}
                                                                    />
                                                                    : null}
                                                                <CssTextField3
                                                                    label="Passwort*"
                                                                    // InputLabelProps={{
                                                                    //     shrink: true
                                                                    // }}
                                                                    type={showPassword1 ? "text" : "password"}
                                                                    InputProps={{ // <-- This is where the toggle button is added.
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                <IconButton
                                                                                    aria-label="toggle password visibility"
                                                                                    onClick={handleClickShowPassword}
                                                                                >
                                                                                    {showPassword1 ? <Visibility style={{ color: '#C7D300' }}/> :
                                                                                        <VisibilityOff style={{ color: '#C7D300' }}/>}
                                                                                </IconButton>
                                                                            </InputAdornment>
                                                                        ),
                                                                        autocomplete: 'new-password',
                                                                        form: {
                                                                            autocomplete: 'off',
                                                                        },
                                                                    }}
                                                                    // className={'text-input-label-blue'}
                                                                    style={{
                                                                        transition: 'all 0.2s ease',
                                                                        marginTop: (isShrink && isShrink.includes('Passwort') || password) ? 20 : 0,
                                                                        marginBottom: 10,
                                                                        width: '100%'
                                                                    }}
                                                                    onFocus={() => {
                                                                        setIsShrink(['Passwort']);
                                                                    }}
                                                                    onBlur={() => {
                                                                        setIsShrink([]);
                                                                    }}
                                                                    onChange={e => {
                                                                        if (e.target.value.trim().length === 0) {
                                                                            setPasswordError(true);
                                                                        } else {
                                                                            setPasswordError(false);
                                                                        }
                                                                        setPassword(e.target.value);
                                                                    }}
                                                                    onKeyPress={handleKeypress}
                                                                    value={password}
                                                                    error={!!passwordError}
                                                                    helperText={passwordError === true ? 'Passwort darf nicht leer sein!' : passwordError}
                                                                />
                                                                <CssTextField3
                                                                    label="Passwort wiederholen*"
                                                                    // InputLabelProps={{
                                                                    //     shrink: true
                                                                    // }}
                                                                    type={showPassword1 ? "text" : "password"}
                                                                    InputProps={{ // <-- This is where the toggle button is added.
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                <IconButton
                                                                                    aria-label="toggle password visibility"
                                                                                    onClick={handleClickShowPassword}
                                                                                >
                                                                                    {showPassword1 ? <Visibility style={{ color: '#C7D300' }}/> :
                                                                                        <VisibilityOff style={{ color: '#C7D300' }}/>}
                                                                                </IconButton>
                                                                            </InputAdornment>
                                                                        ),
                                                                        autocomplete: 'new-password',
                                                                        form: {
                                                                            autocomplete: 'off',
                                                                        },
                                                                    }}
                                                                    // className={'text-input-label-blue'}
                                                                    style={{
                                                                        transition: 'all 0.2s ease',
                                                                        marginTop: (isShrink && isShrink.includes('Passwort wiederholen') || passwordVerify) ? 20 : 0,
                                                                        marginBottom: 10,
                                                                        width: '100%'
                                                                    }}
                                                                    onFocus={() => {
                                                                        setIsShrink(['Passwort wiederholen']);
                                                                    }}
                                                                    onBlur={() => {
                                                                        setIsShrink([]);
                                                                    }}
                                                                    onChange={e => {
                                                                        if (e.target.value.trim().length === 0) {
                                                                            setPasswordVerifyError(true);
                                                                        } else {
                                                                            setPasswordVerifyError(false);
                                                                        }
                                                                        setPasswordVerify(e.target.value);
                                                                    }}
                                                                    onKeyPress={handleKeypress}
                                                                    value={passwordVerify}
                                                                    error={!!passwordVerifyError}
                                                                    helperText={passwordVerifyError === true ? 'Passwort darf nicht leer sein!' : passwordVerifyError}
                                                                />


                                                                <button className={'fadeInOut'}
                                                                        onMouseOver={() => setButtonHover('loginButton')}
                                                                        onMouseOut={() => setButtonHover('')} style={{
                                                                    fontFamily: 'VagRoundedBold',
                                                                    background: buttonHover === 'loginButton' ? '#00374e' : '#C7D300',
                                                                    cursor: 'pointer',
                                                                    margin: 'auto',
                                                                    textAlign: 'center',
                                                                    padding: '10px 20px',
                                                                    borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                                                    color: buttonHover === 'loginButton' ? '#C7D300' : '#00374e',
                                                                    border: buttonHover === 'loginButton' ? '2px solid #C7D300' : '2px solid #00374e',
                                                                    fontSize: 12,
                                                                    width: '100%'
                                                                }} onClick={() => {
                                                                    loginRef.current.reg(mail, accountType, accountType !== 'Arbeitgeber' ? null : company, password, passwordVerify).then((e) => {
                                                                        console.log(e);
                                                                        if (e && e.success) {
                                                                            // if (accountType) history.push('/login');
                                                                            // else history.push('/');
                                                                            setOnLogin(true);
                                                                        } else if (e) {
                                                                            setMailError(e.mError);
                                                                            setCompanyError(e.coError);
                                                                            setPasswordError(e.pError);
                                                                            setPasswordVerifyError(e.pVError);
                                                                        }
                                                                    })
                                                                }}>REGISTRIEREN
                                                                </button>
                                                                <div style={{
                                                                    color: 'white',
                                                                    fontSize: 14,
                                                                    float: 'left',
                                                                    marginTop: 15
                                                                }}>* Pflichtfelder
                                                                </div>
                                                                <div style={{
                                                                    textAlign: 'right',
                                                                    float: 'right',
                                                                    marginTop: 10
                                                                }}>
                                                                    <CustomButton
                                                                        buttontype={'gray'}
                                                                        onClick={() => {
                                                                            setOnLogin(true)
                                                                        }}
                                                                        style={{
                                                                            fontSize: 10,
                                                                            color: 'black',
                                                                        }}
                                                                    >Login</CustomButton>
                                                                </div>
                                                            </React.Fragment>


                                                        </div>}


                                                    {index == 2 && <div id={'step3'} style={{
                                                        color: 'white',
                                                        textAlign: 'left',
                                                        // margin: '50px auto 30px',
                                                        margin: '30px 0 0 0',
                                                        display: 'block',
                                                        // width: '60%',
                                                        minWidth: 200,
                                                        // background: 'white',
                                                        padding: '5px 20px 20px 40px',
                                                        // boxShadow: '0px 0px 15px 6px rgb(46 61 73 / 15%)',
                                                        // border: '3px solid ' + theme.palette.secondary.main,
                                                        borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                                    }}>
                                                        <Grid container
                                                              style={{
                                                                  maxWidth: '80%',
                                                                  textAlign: 'left',
                                                                  margin: 'auto'
                                                              }}
                                                              className={'newAddressDiv'}>
                                                            {console.log(ReactSession.get('user'))}
                                                            {ReactSession.get('user') == 'Arbeitgeber' ? <>
                                                                    <Grid item xs={4} style={{ marginBottom: 20 }}>Rechnung
                                                                        per</Grid>
                                                                    <Grid item xs={6}>
                                                                        <FormGroup style={{ padding: '0 10px' }}>
                                                                            <FormControlLabel
                                                                                className={'emailRadio'}
                                                                                control={
                                                                                    <Radio
                                                                                        sx={{
                                                                                            p: '4px 9px',
                                                                                            color: '#C7D300',
                                                                                            '&.Mui-checked': {
                                                                                                color: '#C7D300',
                                                                                            },
                                                                                        }}
                                                                                        checked={true}
                                                                                    />
                                                                                }
                                                                                label={'E-Mail'}
                                                                                sx={{ '& .MuiSvgIcon-root': { fontSize: 12 } }}
                                                                            />
                                                                        </FormGroup>
                                                                    </Grid>
                                                                    <Grid item xs={2}></Grid>

                                                                    <Grid item xs={4}>Rechnung an</Grid>
                                                                    <Grid item xs={6} className={'overscroll-none'}
                                                                          style={{
                                                                              // maxHeight: 300,
                                                                              // overflowY: 'auto',
                                                                              paddingRight: 10,
                                                                          }}>
                                                                        {invoiceAddresses && invoiceAddresses.map((item, index) => {
                                                                            if (editAddress === index) {
                                                                                return (
                                                                                    <EditAddress
                                                                                        type={'edit'}
                                                                                        index={index}
                                                                                        id={item.id}
                                                                                        isDefault={isDefault}
                                                                                        setIsDefault={setIsDefault}
                                                                                        createInvoiceAddress={createInvoiceAddress}
                                                                                        updateInvoiceAddress={updateInvoiceAddress}
                                                                                        deleteInvoiceAddress={deleteInvoiceAddress}
                                                                                        companyName={companyName}
                                                                                        setCompanyName={setCompanyName}
                                                                                        companyNameError={companyNameError}
                                                                                        setCompanyNameError={setCompanyNameError}
                                                                                        street={street}
                                                                                        setStreet={setStreet}
                                                                                        streetError={streetError}
                                                                                        setStreetError={setStreetError}
                                                                                        nr={nr}
                                                                                        setNr={setNr}
                                                                                        nrError={nrError}
                                                                                        setNrError={setNrError}
                                                                                        zipcode={zipcode}
                                                                                        setZipcode={setZipcode}
                                                                                        zipcodeError={zipcodeError}
                                                                                        setZipcodeError={setZipcodeError}
                                                                                        city={city}
                                                                                        setCity={setCity}
                                                                                        cityError={cityError}
                                                                                        setCityError={setCityError}
                                                                                        email={email}
                                                                                        setEmail={setEmail}
                                                                                        emailError={emailError}
                                                                                        setEmailError={setEmailError}
                                                                                        ustId={ustId}
                                                                                        setUstId={setUstId}
                                                                                        ustIdError={ustIdError}
                                                                                        setUstIdError={setUstIdError}
                                                                                        newAddress={newAddress}
                                                                                        setNewAddress={setNewAddress}
                                                                                        setEditAddress={setEditAddress}
                                                                                    />
                                                                                );
                                                                            }


                                                                            if (editAddress !== index) {
                                                                                return (
                                                                                    <>
                                                                                        <FormGroup className={'testC'}
                                                                                                   style={{ padding: '0 10px' }}>
                                                                                            <FormControlLabel
                                                                                                control={
                                                                                                    <Radio
                                                                                                        sx={{
                                                                                                            p: '4px 9px',
                                                                                                            color: '#C7D300',
                                                                                                            '&.Mui-checked': {
                                                                                                                color: '#C7D300',
                                                                                                            },
                                                                                                        }}
                                                                                                        checked={isSelected === item.id}
                                                                                                        // onClick={() => {
                                                                                                        //     if (selectedReason === 1) {
                                                                                                        //         setSelectedReason(false);
                                                                                                        //     } else {
                                                                                                        //         setSelectedReason(1);
                                                                                                        //     }
                                                                                                        // }}
                                                                                                        // checked={selectedReason === 1}
                                                                                                    />
                                                                                                }
                                                                                                onClick={() => {
                                                                                                    console.log('clicked')
                                                                                                    setIsSelected(item.id);
                                                                                                }}
                                                                                                label={<div key={index}
                                                                                                            style={{
                                                                                                                background: 'rgba(238,238,238,0.2)',
                                                                                                                border: '2px solid transparent',
                                                                                                                borderRadius: 25,
                                                                                                                // margin: index === 0 ? '35px 0 0' : '15px 0 0',
                                                                                                                margin: '0 0 0 10px',
                                                                                                                padding: '10px 15px 20px 25px',
                                                                                                                textAlign: 'left',
                                                                                                                fontSize: 12,
                                                                                                            }}>
                                                                                                    <Grid container>
                                                                                                        <Grid item xs={8}>

                                                                                                            <Grid container>
                                                                                                                <Grid item
                                                                                                                      xs={12}
                                                                                                                      style={{
                                                                                                                          paddingTop: 10,
                                                                                                                          lineBreak: 'anywhere'
                                                                                                                      }}>
                                                                                                                    <p style={{ margin: 0 }}>{item.attributes.companyName}</p>
                                                                                                                    <p style={{ margin: 0 }}>{item.attributes.street}&nbsp;{item.attributes.houseNumber}</p>
                                                                                                                    <p style={{ margin: 0 }}>{item.attributes.zipcode}&nbsp;{item.attributes.city}</p>
                                                                                                                    <p style={{ margin: 0 }}>{item.attributes.ustId}</p>
                                                                                                                    <p style={{ margin: 0 }}>{item.attributes.email}</p>
                                                                                                                </Grid>
                                                                                                            </Grid>


                                                                                                        </Grid>
                                                                                                        <Grid item xs={4}
                                                                                                              style={{ textAlign: 'right' }}>
                                                                                                            <TrashIcon
                                                                                                                className={'not-yellow-white'}
                                                                                                                onClick={() => {
                                                                                                                    deleteInvoiceAddress(item.id);
                                                                                                                }}
                                                                                                                style={{
                                                                                                                    height: 18,
                                                                                                                    width: 18,
                                                                                                                    marginRight: 2,
                                                                                                                    transition: 'all 0.2s ease-in-out',
                                                                                                                    textAlign: 'center',
                                                                                                                    cursor: 'pointer',
                                                                                                                    borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                                                                                                }}
                                                                                                            />
                                                                                                            <EditIcon
                                                                                                                className={'not-yellow-white'}
                                                                                                                style={{
                                                                                                                    height: 18,
                                                                                                                    width: 18,
                                                                                                                    // marginRight: 2,
                                                                                                                    transition: 'all 0.2s ease-in-out',
                                                                                                                    textAlign: 'center',
                                                                                                                    cursor: 'pointer',
                                                                                                                    borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                                                                                                }}
                                                                                                                onClick={() => {
                                                                                                                    console.log('EDIT')
                                                                                                                    setIsDefault(item.attributes.isDefault);
                                                                                                                    setCompanyName(item.attributes.companyName);
                                                                                                                    setCompanyNameError(false);
                                                                                                                    setStreet(item.attributes.street);
                                                                                                                    setStreetError(false);
                                                                                                                    setNr(item.attributes.houseNumber);
                                                                                                                    setNrError(false);
                                                                                                                    setZipcode(item.attributes.zipcode);
                                                                                                                    setZipcodeError(false);
                                                                                                                    setCity(item.attributes.city);
                                                                                                                    setCityError(false);
                                                                                                                    setEmail(item.attributes.email);
                                                                                                                    setEmailError(false);
                                                                                                                    setUstId(item.attributes.ustId);
                                                                                                                    setUstIdError(false);
                                                                                                                    setNewAddress(false);
                                                                                                                    setEditAddress(index);
                                                                                                                }}
                                                                                                            />
                                                                                                        </Grid>
                                                                                                    </Grid>
                                                                                                </div>}
                                                                                                sx={{ '& .MuiSvgIcon-root': { fontSize: 12 } }}
                                                                                            />
                                                                                        </FormGroup>

                                                                                    </>
                                                                                );
                                                                            }
                                                                        })}
                                                                        <div style={{
                                                                            margin: '10px 10px 20px 25px',
                                                                            textAlign: 'left',
                                                                            fontFamily: 'VagRoundedBold',
                                                                            fontSize: 14,
                                                                            position: 'relative',
                                                                            paddingTop: 5,
                                                                            paddingBottom: 5,
                                                                            paddingLeft: 20,
                                                                            width: 'auto',
                                                                            background: 'rgba(238,238,238,0.2)',
                                                                            // border: '2px solid #C7D300',
                                                                            borderRadius: 100,
                                                                            color: 'white',
                                                                        }}
                                                                             className={'color-blue'}>
                                                                            Neue Adresse anlegen
                                                                            <div style={{
                                                                                display: 'flex',
                                                                                position: 'absolute',
                                                                                top: 6,
                                                                                right: 0,
                                                                                paddingRight: 15,
                                                                                zIndex: 1
                                                                            }}>
                                                                                <ClearIcon
                                                                                    className={'not-yellow-white'}
                                                                                    onClick={() => {
                                                                                        setGetRef(true);
                                                                                        setNewAddress(true);
                                                                                    }}
                                                                                    style={{
                                                                                        height: 18,
                                                                                        width: 18,
                                                                                        marginRight: 2,
                                                                                        transition: 'all 0.2s ease-in-out',
                                                                                        textAlign: 'center',
                                                                                        fill: 'white',
                                                                                        cursor: 'pointer',
                                                                                        transform: 'rotate(45deg)',
                                                                                        borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        {newAddress && <EditAddress
                                                                            ref={newAddressRef}
                                                                            type={'new'}
                                                                            id={-1}
                                                                            isDefault={isDefault}
                                                                            setIsDefault={setIsDefault}
                                                                            createInvoiceAddress={createInvoiceAddress}
                                                                            companyName={companyName}
                                                                            setCompanyName={setCompanyName}
                                                                            companyNameError={companyNameError}
                                                                            setCompanyNameError={setCompanyNameError}
                                                                            street={street}
                                                                            setStreet={setStreet}
                                                                            streetError={streetError}
                                                                            setStreetError={setStreetError}
                                                                            nr={nr}
                                                                            setNr={setNr}
                                                                            nrError={nrError}
                                                                            setNrError={setNrError}
                                                                            zipcode={zipcode}
                                                                            setZipcode={setZipcode}
                                                                            zipcodeError={zipcodeError}
                                                                            setZipcodeError={setZipcodeError}
                                                                            city={city}
                                                                            setCity={setCity}
                                                                            cityError={cityError}
                                                                            setCityError={setCityError}
                                                                            email={email}
                                                                            setEmail={setEmail}
                                                                            emailError={emailError}
                                                                            setEmailError={setEmailError}
                                                                            ustId={ustId}
                                                                            setUstId={setUstId}
                                                                            ustIdError={ustIdError}
                                                                            setUstIdError={setUstIdError}
                                                                            newAddress={newAddress}
                                                                            setNewAddress={setNewAddress}
                                                                        />}
                                                                    </Grid></>
                                                                : <><p
                                                                    style={{ textAlign: 'center', width: '100%' }}>Dieser
                                                                    Bereich ist nur für Arbeitgeber</p></>}
                                                        </Grid>

                                                        <div style={{ display: 'block', marginBottom: 10 }}></div>
                                                    </div>}

                                                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}
                                                         style={{ padding: '0 50px 50px 50px' }}>
                                                        <CustomButton
                                                            buttontype={'yellowWhiteDisabled2'}
                                                            disabled={activeStep === 0}
                                                            onClick={handleBack}
                                                            sx={{ mr: 1 }}
                                                            style={{
                                                                borderRadius: 20
                                                            }}
                                                        >
                                                            <ReplyIcon
                                                                style={{
                                                                    marginBottom: 3,
                                                                    width: 30,
                                                                    height: 30,
                                                                    paddingRight: 3
                                                                }}
                                                            />
                                                            Zurück
                                                        </CustomButton>
                                                        <Box sx={{ flex: '1 1 auto' }}/>
                                                        <CustomButton
                                                            buttontype={'yellowWhiteDisabled2'}
                                                            disabled={!isSelected || !ReactSession.get('user') || ReactSession.get('user') !== 'Arbeitgeber'}
                                                            onClick={handleNext}
                                                            sx={{ mr: 1 }}
                                                            style={{
                                                                borderRadius: 20
                                                            }}
                                                        >
                                                            {/*{activeStep < 1 ? 'Nächster Schritt' : 'Abschließen'}*/}
                                                            Weiter
                                                            <ReplyIcon
                                                                style={{
                                                                    transform: 'scaleX(-1)',
                                                                    marginBottom: 3,
                                                                    width: 30,
                                                                    height: 30,
                                                                    paddingRight: 3
                                                                }}
                                                            />
                                                        </CustomButton>
                                                    </Box>
                                                </div>}
                                                {index == 0 && <div ref={unverbindlichesAngebotRef}
                                                    // className={'fadeInOut ' + (gogo6 ? '' : gogo7 ? '' : 'wegLeft')}
                                                                    style={{
                                                                        // opacity: (gogo7) ? 0 : 1,
                                                                        display: (gogo7) ? 'none' : 'block',
                                                                        // width: (index === 0 && activeStep == 0) ? 'calc(100vw)' : 0,
                                                                    }}>
                                                    <div style={{
                                                        color: theme.palette.secondary.main,
                                                        textAlign: 'center',
                                                        marginTop: 30
                                                    }}>
                                                        <p style={{
                                                            fontSize: 40,
                                                            margin: 0,
                                                            lineHeight: 1,
                                                        }}>
                                                            <span>Unsere</span>
                                                            <span style={{
                                                                fontFamily: 'VagRoundedBold',
                                                                fontWeight: 'bold'
                                                            }}> Empfehlung</span>
                                                        </p>
                                                        <p style={{
                                                            margin: '10px auto 0 auto',
                                                        }}>
                                                            Dein swiitcher Paket
                                                        </p>
                                                    </div>
                                                    <Grid container style={{
                                                        // paddingTop: 100, paddingLeft: 100, paddingRight: 100, paddingBottom: 100,
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}>
                                                        <Grid item md={1} lg={1} style={{
                                                            display: 'flex',
                                                            textAlign: 'center',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            height: 'calc(100vh - 60px)',
                                                            // minHeight: '800px',
                                                        }}>
                                                            <div style={{
                                                                paddingTop: 60,
                                                                paddingLeft: 10,
                                                                paddingRight: 10,
                                                                paddingBottom: 100,
                                                            }}>
                                                                <div style={{
                                                                    textAlign: 'left',
                                                                }}
                                                                     onMouseOver={() => {
                                                                         setButtonHover('ArrowBackIosIcon')
                                                                     }}
                                                                     onMouseOut={() => {
                                                                         setButtonHover('')
                                                                     }}
                                                                >
                                                                    <ArrowBackIosIcon
                                                                        style={{
                                                                            transition: 'all 0.2s ease-in-out',
                                                                            textAlign: 'center',
                                                                            color: theme.palette.secondary.main,
                                                                            cursor: 'pointer',
                                                                            padding: '5px 0px 5px 14px',
                                                                            border: buttonHover === 'ArrowBackIosIcon' ? ('2px solid ' + theme.palette.secondary.main) : ('2px solid ' + theme.palette.primary.main),
                                                                            borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                                                        }}
                                                                        onClick={() => {

                                                                            setGogo6(false);


                                                                            setGogo3(false);
                                                                            setGogo4(false);


                                                                            setTimeout(() => {
                                                                                setGogo2(false);
                                                                                setGogo5(false);
                                                                                setTimeout(() => {
                                                                                    setGogo(false);
                                                                                }, 200);
                                                                            }, 200);
                                                                        }}
                                                                    />
                                                                </div>

                                                            </div>

                                                        </Grid>


                                                        <Grid item md={5} lg={5} style={{
                                                            //display: 'flex',
                                                            textAlign: 'center',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            height: 'calc(100vh - 20px)',
                                                            // minHeight: '800px',
                                                            paddingTop: 40
                                                        }}>
                                                            <div style={{
                                                                width: '70%',
                                                                padding: '25px 40px 10px',
                                                                textAlign: 'center',
                                                                margin: 'auto',
                                                                border: '3px solid ' + theme.palette.secondary.main,
                                                                borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                                                position: 'relative'
                                                            }}>
                                                                <div style={{
                                                                    color: theme.palette.secondary.main,
                                                                    textAlign: 'left'
                                                                }}>
                                                                    <p style={{
                                                                        fontSize: 32,
                                                                        margin: 0,
                                                                        lineHeight: 1,
                                                                        color: 'white'
                                                                    }}>
                                        <span style={{
                                            fontFamily: 'VagRoundedBold', fontWeight: 'bold'
                                        }}>swiitcher<br/>Flat</span>
                                                                    </p>
                                                                    <div style={{ marginTop: '1em' }}>
                                                                        <p style={{
                                                                            margin: 0,
                                                                            minHeight: 22.39
                                                                        }}>{!price ? '' : 'Unsere swiitcher Empfehlung'}</p>
                                                                    </div>

                                                                    <ul ref={leftUl} className={'customBullet'} style={{
                                                                        color: 'white',
                                                                        listStylePosition: 'inside',
                                                                        padding: 0,
                                                                        minHeight: 230
                                                                    }}>
                                                                        {price !== false &&
                                                                            <>
                                                                                <li>12 Monate Laufzeit</li>
                                                                                <li>Automatischer Vertragsauslauf</li>
                                                                                <li>Unbegrenzte Schaltung von
                                                                                    Stellenanzeigen
                                                                                </li>
                                                                                <li>Individuelle Gestaltung</li>
                                                                                <li>Automatisiertes Matching</li>
                                                                                <li>Veröffentlichung der Stellenanzeigen
                                                                                    innerhalb 24h (Mo-Fr)
                                                                                </li>
                                                                                <li>Zahlung per Rechnung</li>
                                                                                <li>Starke Performance</li>
                                                                            </>}
                                                                    </ul>

                                                                    <p style={{
                                                                        color: theme.palette.secondary.main,
                                                                        fontSize: 20,
                                                                        marginTop: 30,
                                                                        marginBottom: 25,
                                                                        lineHeight: 1,
                                                                        textAlign: 'center'
                                                                    }}>
                                        <span><span style={{
                                            fontSize: 50
                                        }}>
                                            {currencyFormat(price)}<br/>
                                        </span></span>
                                                                    </p>
                                                                    <p style={{ textAlign: 'left' }}>
                                                                        <button
                                                                            className={'fadeInOut ' + (showUpDownClass ? '' : '')}
                                                                            onMouseOver={() => setButtonHover('jetztBuchenButton')}
                                                                            onMouseOut={() => setButtonHover('')}
                                                                            style={{
                                                                                fontSize: 25,
                                                                                fontFamily: 'VagRoundedBold',
                                                                                background: buttonHover === 'jetztBuchenButton' ? '#00374e' : '#C7D300',
                                                                                cursor: 'pointer',
                                                                                width: '100%',
                                                                                margin: 'auto',
                                                                                textAlign: 'center',
                                                                                padding: '10px 10px 10px 10px',
                                                                                borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                                                                color: buttonHover === 'jetztBuchenButton' ? '#C7D300' : '#00374e',
                                                                                border: buttonHover === 'jetztBuchenButton' ? '2px solid #C7D300' : '2px solid #00374e',

                                                                            }} onClick={() => {
                                                                            setFlatOrSingle('Flat')
                                                                            const yOffset = -230;
                                                                            const element = unverbindlichesAngebotRef.current;
                                                                            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

                                                                            window.scrollTo({
                                                                                top: y,
                                                                                behavior: 'smooth'
                                                                            });
                                                                            setGogo7(true);
                                                                            //
                                                                            if (ReactSession.get('user')) {
                                                                                setActiveStep(2);
                                                                            } else {
                                                                                setActiveStep(1);
                                                                            }
                                                                        }}>JETZT BUCHEN
                                                                        </button>
                                                                    </p>
                                                                    <p style={{
                                                                        fontSize: 12,
                                                                        textAlign: 'left',
                                                                        margin: '15px auto 0 auto',
                                                                        color: 'white',
                                                                        marginBottom: 10
                                                                    }}>
                                                                        Gesamtbetrag exkl. MwSt.<br/>
                                                                        Eine Auftragsbestätigung wird per Mail
                                                                        zugesandt.
                                                                    </p>
                                                                    {price === false ?
                                                                        <div style={{
                                                                            position: 'absolute',
                                                                            left: 0,
                                                                            top: 0,
                                                                            borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                                                            height: '100%',
                                                                            width: '100%',
                                                                            backgroundColor: 'rgba(0,0,0,0.4)',
                                                                            display: 'flex',
                                                                            justifyContent: 'center',
                                                                            alignItems: 'center',
                                                                        }}>
                                                                            <div style={{
                                                                                display: 'block',
                                                                                padding: '0 20px',
                                                                                textAlign: 'center'
                                                                            }}>
                                                                                <p style={{ fontFamily: 'VagRoundedBold' }}>Du
                                                                                    bist an der<br/>Flat interessiert?
                                                                                </p>
                                                                                <p style={{
                                                                                    fontFamily: 'VagRoundedBold',
                                                                                    color: 'white'
                                                                                }}>Dann erhalte von uns<br/>Dein
                                                                                    individuelles Angebot</p>
                                                                                <p style={{
                                                                                    color: 'white',
                                                                                    margin: 0
                                                                                }}>info@swiitcher.de</p>
                                                                                <p style={{
                                                                                    color: 'white',
                                                                                    margin: 0
                                                                                }}>T. 0831 960151 12</p>
                                                                            </div>
                                                                        </div> : null}
                                                                </div>
                                                            </div>
                                                        </Grid>
                                                        <Grid item md={5} lg={5} style={{
                                                            //display: 'flex',
                                                            textAlign: 'center',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            height: 'calc(100vh - 20px)',
                                                            // minHeight: '800px',
                                                            paddingTop: 40,
                                                        }}>
                                                            <div style={{
                                                                width: '70%',
                                                                padding: '25px 40px 10px',
                                                                textAlign: 'center',
                                                                margin: 'auto',
                                                                border: '3px solid ' + theme.palette.secondary.main,
                                                                borderRadius: ENV.REACT_APP_BORDER_RADIUS,

                                                            }}>
                                                                <div style={{
                                                                    color: theme.palette.secondary.main,
                                                                    textAlign: 'left'
                                                                }}>
                                                                    <p style={{
                                                                        fontSize: 32,
                                                                        margin: 0,
                                                                        lineHeight: 1,
                                                                        color: 'white'
                                                                    }}>
                                        <span style={{
                                            fontFamily: 'VagRoundedBold', fontWeight: 'bold'
                                        }}>swiitcher<br/>Einzelanzeige</span>
                                                                    </p>
                                                                    <div style={{ marginTop: '1em' }}>
                                                                        <p style={{ margin: 0 }}>Unsere swiitcher
                                                                            Empfehlung</p>
                                                                    </div>
                                                                    <ul className={'customBullet'} style={{
                                                                        color: 'white',
                                                                        listStylePosition: 'inside',
                                                                        padding: 0,
                                                                        minHeight: leftUl.current ? leftUl.current.offsetHeight : 'unset'
                                                                    }}>
                                                                        <li>30 Tage Laufzeit</li>
                                                                        <li>30 Tage Zugriff</li>
                                                                        <li>Individuelle Gestaltung</li>
                                                                        <li>Veröffentlichung der Stellenanzeigen
                                                                            innerhalb 24h (Mo-Fr)
                                                                        </li>
                                                                        <li>Automatisiertes Matching</li>
                                                                        <li>Zahlung per Rechnung</li>
                                                                        <li className={'customClearIcon'}>Keine
                                                                            Änderungen möglich
                                                                        </li>
                                                                    </ul>

                                                                    <p style={{
                                                                        color: theme.palette.secondary.main,
                                                                        fontSize: 20,
                                                                        marginTop: 30,
                                                                        marginBottom: 25,
                                                                        lineHeight: 1,
                                                                        textAlign: 'center'
                                                                    }}>
                                        <span><span style={{
                                            fontSize: 50
                                        }}>
                                            {currencyFormat(399)}
                                        </span>&nbsp;</span>
                                                                    </p>
                                                                    <p style={{ textAlign: 'left' }}>
                                                                        <button
                                                                            className={'fadeInOut ' + (showUpDownClass ? '' : '')}
                                                                            onMouseOver={() => setButtonHover('jetztBuchen2Button')}
                                                                            onMouseOut={() => setButtonHover('')}
                                                                            style={{
                                                                                fontSize: 25,
                                                                                fontFamily: 'VagRoundedBold',
                                                                                background: buttonHover === 'jetztBuchen2Button' ? '#00374e' : '#C7D300',
                                                                                cursor: 'pointer',
                                                                                width: '100%',
                                                                                margin: 'auto',
                                                                                textAlign: 'center',
                                                                                padding: '10px 10px 10px 10px',
                                                                                borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                                                                color: buttonHover === 'jetztBuchen2Button' ? '#C7D300' : '#00374e',
                                                                                border: buttonHover === 'jetztBuchen2Button' ? '2px solid #C7D300' : '2px solid #00374e',

                                                                            }} onClick={() => {
                                                                            setFlatOrSingle('Single')
                                                                            const yOffset = -230;
                                                                            const element = unverbindlichesAngebotRef.current;
                                                                            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

                                                                            window.scrollTo({
                                                                                top: y,
                                                                                behavior: 'smooth'
                                                                            });
                                                                            setGogo7(true);
                                                                            //
                                                                            if (ReactSession.get('user')) {
                                                                                setActiveStep(2);
                                                                            } else {
                                                                                setActiveStep(1);
                                                                            }
                                                                        }}>JETZT BUCHEN
                                                                        </button>
                                                                    </p>
                                                                    <p style={{
                                                                        fontSize: 12,
                                                                        textAlign: 'left',
                                                                        margin: '15px auto 0 auto',
                                                                        color: 'white',
                                                                        marginBottom: 10
                                                                    }}>
                                                                        Gesamtbetrag exkl. MwSt.<br/>
                                                                        Eine Auftragsbestätigung wird per E-Mail
                                                                        zugesandt.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </div>}
                                            </div>
                                        </React.Fragment>
                                    );
                                })}
                            </div>


                        </React.Fragment>
                    )}
                </Box>}
            </Grid>
        </Grid>
    </div>);
}

