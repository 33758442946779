import {styled, TextField} from "@mui/material";
import {makeStyles} from "@mui/styles";
import { TextFieldProps } from '@material-ui/core';


let fieldtype = undefined;



export const CssTextField4 = (props) => {
    const { showUpDown, ...textFieldProps } = props;
    const classes = useStyles();
    fieldtype = props.fieldtype;


    return (
        <form autoComplete={"off"}>
        <CustomTextfield className={classes.input} {...textFieldProps} />
        </form>
    );
}

const useStyles = makeStyles({
    input: {
        '& input[type=number]': {
            // '-moz-appearance': 'textfield'
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            // '-webkit-appearance': 'none',
            // margin: 0
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
        }
    },
});



// Do whatever you want with `customProp`
const CustomTextfield = styled(TextField)(({theme}) => ({
    margin: '8px 0 0 0',
    '& label:not(.MuiInputLabel-shrink)': {
        top: -11,
        marginLeft: 0,
        fontSize: '12px',
        color: 'black',

        // transition: 'none'
    },
    '& label.MuiInputLabel-shrink': {
        marginLeft: 0,
        top: -8,
        fontSize: '12px !important',
        opacity: 0,
    },
    '& label': {
        margin: 0,
        padding: 0,
        marginLeft: 0,
        top: -8,
        // top: -8,
        fontSize: '12px !important',
        color: 'black',
        transition: theme.transitions.create([
            'color',
        ]),
    },
    '& label.MuiFormLabel-filled': {
        top: -8
    },
    // '& label.MuiFormLabel-filled:not(.focused)': {
    //     margin: 0,
    //     padding: 0,
    //     marginLeft: 0,
    //     top: -8,
    //     fontSize: '12px !important',
    //     color: 'white',
    //     transition: theme.transitions.create([
    //         'color',
    //         'transform'
    //     ]),
    // },
    '&:hover label': {
        color: 'black',
        fontSize: '14px',
        transition: theme.transitions.create([
            'color',
        ]),
    },
    '& label.Mui-focused': {
        marginLeft: 0,
        top: -8,
        fontSize: '12px !important',
        // display: 'none',
        opacity: 0,
        color: 'white',
        transition: theme.transitions.create([
            'color',
        ]),
    },






    '.MuiFormHelperText-filled': {
        color: 'red',
        transition: theme.transitions.create([
            'color',
        ]),
    },
    '.MuiFormHelperText-contained': {
        color: 'red',
        transition: theme.transitions.create([
            'color',
        ]),
    },


    // '& .MuiInput-underline:after': {
    //     borderColor: theme.palette.primary.main,
    // },
    '& .MuiOutlinedInput-root': {
        borderRadius: 25,
        height: 25,
        // '& ::placeholder': {
        //     color: theme.palette.secondary.main,
        //     // color: 'red',
        //     opacity: 1,
        //     fontSize: 12,
        //     marginTop: 1,
        // },
        '& .MuiOutlinedInput-input': {
            background: 'white',
            padding: '3px',
            fontSize: 12,
            borderRadius: 25,
            paddingLeft: 10,
            transition: theme.transitions.create([
                'border-color',
                'background',
            ]),
            '&:not([value=""])': {
                background: '#eee'
            },
        },
        '& fieldset': {
            border: '2px solid',
            borderColor: '#eee',
            borderRadius: 25,
            transition: theme.transitions.create([
                'border-color',
            ]),
            '& legend': {
                width: 0
            }
        },
        '&:hover fieldset': {
            border: '2px solid',
            borderColor: '#eee',

        },
        '&.Mui-focused fieldset': {
            border: '2px solid',
            borderColor: '#eee'
        },
    },
}));