import React, {useEffect} from 'react';
import Container from "@material-ui/core/Container";
import {useLocation} from "react-router-dom";

export default function Impressum() {

    const location = useLocation();

    useEffect(() => {
        document.title = 'Impressum';
    }, []);

    return (
        <Container maxWidth="lg" style={{marginTop: 100, background: 'white'}}>

            <div style={{textAlign: 'left'}}>
                {/*<h1>Impressum</h1>*/}

                {/*<h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>*/}
                {/*<p>TT Verlag GmbH<br />*/}
                {/*    St.-Mang-Platz 1<br />*/}
                {/*    87435 Kempten</p>*/}

                {/*<p>Handelsregister: HRB 10132<br />*/}
                {/*    Registergericht: Kempten</p>*/}

                {/*<p><strong>Vertreten durch:</strong><br />*/}
                {/*    Thomas T&auml;nzel</p>*/}

                {/*<h2>Kontakt</h2>*/}
                {/*<p>Telefon: +49 831 960151-12<br />*/}
                {/*    Telefax: +49 831 960151-29<br />*/}
                {/*    E-Mail: info@swiitcher.de</p>*/}

                {/*<h2>Umsatzsteuer-ID</h2>*/}
                {/*<p>Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:<br />*/}
                {/*    DE 263 904 869</p>*/}

                {/*<h2>Redaktionell verantwortlich</h2>*/}
                {/*<p>Meike Winter</p>*/}

                {/*<h2>EU-Streitschlichtung</h2>*/}
                {/*<p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>*/}

                {/*<h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>*/}
                {/*<p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>*/}

                {/*<p>Quelle: <a href="https://www.e-recht24.de/impressum-generator.html">https://www.e-recht24.de/impressum-generator.html</a></p>*/}
                <h1>Impressum</h1>

                <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
                <p>TT-Verlag GmbH<br />
                    St.-Mang-Platz 1<br />
                    87435 Kempten</p>

                <p>Handelsregister: HRB10132<br />
                    Registergericht: Kempten</p>

                <p><strong>Vertreten durch:</strong><br />
                    Thomas T&auml;nzel</p>

                <h2>Kontakt</h2>
                <p>Telefon: +49831960151-12<br />
                    E-Mail: info@swiitcher.de</p>

                <h2>Umsatzsteuer-ID</h2>
                <p>Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:<br />
                    DE263904869</p>

                <h2>Redaktionell verantwortlich</h2>
                <p>Thomas T&auml;nzel</p>

                <h2>EU-Streitschlichtung</h2>
                <p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a href="https://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.</p>

                <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
                <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</p>
            </div>
        </Container>
    );
}
