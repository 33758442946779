import React, {useEffect, useRef} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {Checkbox, FormControl, FormControlLabel, Grid, IconButton, Radio, RadioGroup} from "@mui/material";
import {useState} from "react";
import {useHistory, useLocation} from "react-router";
import {ReactSession} from "react-client-session";
import useFetch from "./UseFetch";
import {toast} from "react-toastify";
import {DataGrid} from '@mui/x-data-grid';
import ImageUploading from "react-images-uploading";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {ReactComponent as LogoSvgHeartOutline} from "../images/svg/heart_outline.svg";
import {ReactComponent as LogoSvgHeart} from "../images/svg/heart.svg";
import {ReactComponent as LogoUser} from "../images/svg/Login.svg";
import AddIcon from "@mui/icons-material/Add";
import {ReactComponent as TrashIcon} from "../images/svg/mulleimer.svg";
import ClearIcon from "@mui/icons-material/Clear";
import {CssTextField3} from "./CssTextField3";
import {CssTextField4} from "./CssTextField4";
import {CustomButton} from "./CustomButton";
import {confirmAlert} from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import validator from 'validator'

const EditAddress = React.forwardRef((props, ref) => {
    const {
        type,
        index,
        id,
        createCompanyAddress,
        updateCompanyAddress,
        deleteCompanyAddress,
        isDefault,
        setIsDefault,
        companyName,
        setCompanyName,
        companyNameError,
        setCompanyNameError,
        street,
        setStreet,
        streetError,
        setStreetError,
        nr,
        setNr,
        nrError,
        setNrError,
        zipcode,
        setZipcode,
        zipcodeError,
        setZipcodeError,
        city,
        setCity,
        cityError,
        setCityError,
        phonenumber,
        setPhonenumber,
        phonenumberError,
        setPhonenumberError,
        email,
        setEmail,
        emailError,
        setEmailError,
        website,
        setWebsite,
        websiteError,
        setWebsiteError,
        newAddress,
        setNewAddress,
        setEditAddress
    } = props;

    const validateEmail = email => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            );
    };

    const validateCompanyAddress = () => {
        let error = false;
        if (!companyName || companyName == '') {
            setCompanyNameError('Firmenname darf nicht leer sein');
            error = true;
        } else {
            setCompanyNameError(false);
        }
        if (!street || street == '') {
            setStreetError('Straße darf nicht leer sein');
            error = true;
        } else {
            setStreetError(false);
        }
        if (!zipcode || zipcode == '') {
            setZipcodeError('PLZ darf nicht leer sein');
            error = true;
        } else {
            setZipcodeError(false);
        }
        if (!city || city == '') {
            setCityError('Ort darf nicht leer sein');
            error = true;
        } else {
            setCityError(false);
        }
        if ((!phonenumber || phonenumber == '') && !email) {
            setPhonenumberError('Telefon oder E-Mail darf nicht leer sein');
            error = true;
        } else {
            setPhonenumberError(false);
        }
        if (website && !validator.isURL(website)) {
            setWebsiteError('Keine gültige URL');
            error = true;
        } else {
            setWebsiteError(false);
        }


        if ((!email || email == '') && !phonenumber) {
            setEmailError('E-Mail oder Telefon darf nicht leer sein');
            error = true;
        } else {
            setEmailError(false);
        }
        if (phonenumber && !/[0-9]/.test(phonenumber)) {
            setPhonenumberError('Keine gültige Telefonnummer');
            error = true;
        }
        if (email && !validateEmail(email)) {
            setEmailError('Keine gültige E-Mail');
            error = true;
        }
        return !error;
    }


    return (
        <div ref={ref} key={id} style={{
            border: '2px solid #C7D300',
            borderRadius: 25,
            // height: 200,
            marginTop: (index === 0 || id === -1) ? 35 : 15,
            // padding: '10px 15px 15px 15px',
            padding: '10px 15px 20px 15px',
            textAlign: 'left'
        }}>
            <Grid container>
                <Grid item xs={8} style={{paddingTop: 5}}>
                    <p style={{
                        margin: 0,
                        padding: 0,
                        textAlign: 'left',
                        fontFamily: 'VagRoundedBold',
                        fontSize: 14
                    }}
                       className={'color-blue'}>Adresse {type === 'new' ? <>anlegen</> : <>bearbeiten</>}</p>
                    <FormControl className={'testC'} style={{color: '#C7D300'}}>
                        <RadioGroup row>
                            <FormControlLabel onClick={(e) => {
                                e.preventDefault()
                                console.log('click')
                                setIsDefault(!isDefault)
                            }} control={<Radio checked={isDefault}/>}
                                              label="Als Standardadresse festlegen"/>
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={4} style={{textAlign: 'right'}}>
                    {(id && id !== -1) && <TrashIcon
                        onClick={() => {
                            deleteCompanyAddress(id);
                        }}
                        style={{
                            height: 30,
                            width: 30,
                            marginRight: 2,
                            transition: 'all 0.2s ease-in-out',
                            textAlign: 'center',
                            fill: '#C7D300',
                            cursor: 'pointer',
                            borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                        }}
                    />}
                    <ClearIcon
                        className={'not-yellow'}
                        onClick={() => {
                            setIsDefault(false);
                            setCompanyName('');
                            setCompanyNameError(false);
                            setStreet('');
                            setStreetError(false);
                            setNr('');
                            setNrError(false);
                            setZipcode('');
                            setZipcodeError(false);
                            setCity('');
                            setCityError(false);
                            setPhonenumber('');
                            setPhonenumberError(false);
                            setEmail('');
                            setEmailError(false);
                            setWebsite('');
                            setWebsiteError(false);
                            if (type === 'new') {
                                setNewAddress(false);
                                window.scrollTo(0, 0);
                            } else if (type === 'edit') {
                                setEditAddress(-1);
                            }
                        }}
                        style={{
                            height: 30,
                            width: 30,
                            marginRight: 2,
                            transition: 'all 0.2s ease-in-out',
                            textAlign: 'center',
                            fill: '#00374e',
                            cursor: 'pointer',
                            borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <CssTextField4
                        label="Firmenname*"
                        fieldtype={'GrayTransparent'}
                        // InputLabelProps={{
                        //     shrink: true
                        // }}
                        // className={'text-input-label-blue'}
                        style={{
                            transition: 'all 0.2s ease',
                            minHeight: 29,
                            width: 'calc(100% - 10px)'
                        }}
                        autoComplete={'off'}
                        onChange={e => {
                            if (e.target.value.trim().length === 0) {
                                setCompanyNameError(true);
                            } else {
                                setCompanyNameError(false);
                            }
                            setCompanyName(e.target.value);
                        }}
                        // onKeyPress={handleKeypress}
                        value={companyName}
                        error={!!companyNameError}
                        helperText={companyNameError}
                    />
                </Grid>
                <Grid item xs={10}>
                    <CssTextField4
                        label="Straße*"
                        fieldtype={'GrayTransparent'}
                        // InputLabelProps={{
                        //     shrink: true
                        // }}
                        // className={'text-input-label-blue'}
                        style={{
                            transition: 'all 0.2s ease',
                            minHeight: 29,
                            width: 'calc(100% - 10px)'
                        }}
                        autoComplete={'off'}
                        onChange={e => {
                            if (e.target.value.trim().length === 0) {
                                setStreetError(true);
                            } else {
                                setStreetError(false);
                            }
                            setStreet(e.target.value);
                        }}
                        // onKeyPress={handleKeypress}
                        value={street}
                        error={!!streetError}
                        helperText={streetError}
                    />
                </Grid>
                <Grid item xs={2}>
                    <CssTextField4
                        label="Nr"
                        fieldtype={'GrayTransparent'}
                        // InputLabelProps={{
                        //     shrink: true
                        // }}
                        // className={'text-input-label-blue'}
                        style={{
                            transition: 'all 0.2s ease',
                            minHeight: 29,
                            width: 'calc(100% - 10px)'
                        }}
                        autoComplete={'off'}
                        onChange={e => {
                            if (e.target.value.trim().length === 0) {
                            } else {
                                setNrError(false);
                            }
                            setNr(e.target.value);
                        }}
                        // onKeyPress={handleKeypress}
                        value={nr}
                        error={!!nrError}
                        helperText={nrError}
                    />
                </Grid>
                <Grid item xs={6}>
                    <CssTextField4
                        label="PLZ*"
                        fieldtype={'GrayTransparent'}
                        // InputLabelProps={{
                        //     shrink: true
                        // }}
                        // className={'text-input-label-blue'}
                        style={{
                            transition: 'all 0.2s ease',
                            minHeight: 29,
                            width: 'calc(100% - 10px)'
                        }}
                        autoComplete={'off'}
                        onChange={e => {
                            if (e.target.value.trim().length === 0) {
                                setZipcodeError(true);
                            } else {
                                setZipcodeError(false);
                            }
                            setZipcode(e.target.value);
                        }}
                        // onKeyPress={handleKeypress}
                        value={zipcode}
                        error={!!zipcodeError}
                        helperText={zipcodeError}
                    />
                </Grid>
                <Grid item xs={6}>
                    <CssTextField4
                        label="City / Sitz*"
                        fieldtype={'GrayTransparent'}
                        // InputLabelProps={{
                        //     shrink: true
                        // }}
                        // className={'text-input-label-blue'}
                        style={{
                            transition: 'all 0.2s ease',
                            minHeight: 29,
                            width: 'calc(100% - 10px)'
                        }}
                        autoComplete={'off'}
                        onChange={e => {
                            if (e.target.value.trim().length === 0) {
                                setCityError(true);
                            } else {
                                setCityError(false);
                            }
                            setCity(e.target.value);
                        }}
                        // onKeyPress={handleKeypress}
                        value={city}
                        error={!!cityError}
                        helperText={cityError}
                    />
                </Grid>
                <Grid item xs={6}>
                    <CssTextField4
                        label="Telefonnummer"
                        fieldtype={'GrayTransparent'}
                        // InputLabelProps={{
                        //     shrink: true
                        // }}
                        // className={'text-input-label-blue'}
                        style={{
                            transition: 'all 0.2s ease',
                            minHeight: 29,
                            width: 'calc(100% - 10px)'
                        }}
                        autoComplete={'off'}
                        onChange={e => {
                            if (e.target.value.trim().length === 0) {
                            } else {
                                setPhonenumberError(false);
                            }
                            setPhonenumber(e.target.value);
                        }}
                        // onKeyPress={handleKeypress}
                        value={phonenumber}
                        error={!!phonenumberError}
                        helperText={phonenumberError}
                    />
                </Grid>
                <Grid item xs={6}>
                    <CssTextField4
                        label="E-Mail"
                        fieldtype={'GrayTransparent'}
                        // InputLabelProps={{
                        //     shrink: true
                        // }}
                        // className={'text-input-label-blue'}
                        style={{
                            transition: 'all 0.2s ease',
                            minHeight: 29,
                            width: 'calc(100% - 10px)'
                        }}
                        autoComplete={'off'}
                        onChange={e => {
                            if (e.target.value.trim().length === 0) {
                            } else {
                                setEmailError(false);
                            }
                            setEmail(e.target.value);
                        }}
                        // onKeyPress={handleKeypress}
                        value={email}
                        error={!!emailError}
                        helperText={emailError}
                    />
                </Grid>
                <Grid item xs={6}>
                    <CssTextField4
                        label="Website"
                        fieldtype={'GrayTransparent'}
                        // InputLabelProps={{
                        //     shrink: true
                        // }}
                        // className={'text-input-label-blue'}
                        style={{
                            transition: 'all 0.2s ease',
                            minHeight: 29,
                            width: 'calc(100% - 10px)'
                        }}
                        autoComplete={'off'}
                        onChange={e => {
                            if (e.target.value.trim().length === 0) {
                            } else {
                                setWebsiteError(false);
                            }
                            setWebsite(e.target.value);
                        }}
                        // onKeyPress={handleKeypress}
                        value={website}
                        error={!!websiteError}
                        helperText={websiteError}
                    />
                </Grid>
                <Grid item xs={6} style={{textAlign: 'right', marginTop: 7}}>
                    <CustomButton
                        buttontype={'yellowBlue'}
                        style={{padding: '1.7px 15px', marginRight: 10}}
                        onClick={() => {
                            if (type === 'new' && validateCompanyAddress()) createCompanyAddress();
                            else if (type === 'edit' && validateCompanyAddress()) updateCompanyAddress(id);
                        }}
                    >Speichern</CustomButton>
                </Grid>
            </Grid>
        </div>
    );
});

const EditContact = React.forwardRef((props, ref) => {
    const {
        type,
        index,
        id,
        isDefaultContact,
        setIsDefaultContact,
        anrede,
        setAnrede,
        anredeError,
        setAnredeError,
        vorname,
        setVorname,
        vornameError,
        setVornameError,
        nachname,
        setNachname,
        nachnameError,
        setNachnameError,
        telefon,
        setTelefon,
        telefonError,
        setTelefonError,
        emailContact,
        setEmailContact,
        emailContactError,
        setEmailContactError,
        contactImage,
        setContactImage,
        contactImageId,
        setContactImageId,
        contactImageError,
        setContactImageError,
        createContact,
        updateContact,
        deleteContact,
        newContact,
        setNewContact,
        setEditContact
    } = props;

    const validateContact = () => {
        let error = false;
        if (!anrede || anrede == '') {
            setAnredeError('Anrede darf nicht leer sein');
            error = true;
        } else {
            setAnredeError(false);
        }
        if (!nachname || nachname == '') {
            setNachnameError('Nachname darf nicht leer sein');
            error = true;
        } else {
            setNachnameError(false);
        }
        // if ((!telefon || telefon == '') && !emailContact) {
        //     setTelefonError('Telefon oder E-Mail darf nicht leer sein');
        //     error = true;
        // } else {
        //     setTelefonError(false);
        // }
        // if ((!emailContact || emailContact == '') && !telefon) {
        //     setEmailContactError('E-Mail oder Telefon darf nicht leer sein');
        //     error = true;
        // } else {
        //     setEmailContactError(false);
        // }
        if (emailContact && !validateEmail(emailContact)) {
            setEmailContactError('Keine gültige E-Mail');
            error = true;
        }
        if (telefon && !/[0-9]/.test(telefon)) {
            setTelefonError('Keine gültige Telefonnummer');
            error = true;
        }
        return !error;
    }

    const onChange = (imageList, addUpdateIndex) => {
        // data for submit
        console.log(imageList, addUpdateIndex);
        setContactImage(imageList);
    };
    const validateEmail = email => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            );
    };

    return (
        <div ref={ref} key={id} style={{
            border: '2px solid #C7D300',
            borderRadius: 25,
            // height: 200,
            marginTop: (index === 0 || id === -1) ? 35 : 15,
            // padding: '10px 15px 15px 15px',
            padding: '10px 15px 20px 15px',
            textAlign: 'left'
        }}>
            <Grid container>
                <Grid item xs={8} style={{paddingTop: 5}}>
                    <p style={{
                        margin: 0,
                        padding: 0,
                        textAlign: 'left',
                        fontFamily: 'VagRoundedBold',
                        fontSize: 14
                    }}
                       className={'color-blue'}>Ansprechpartner {type === 'new' ? <>anlegen</> : <>bearbeiten</>}</p>
                    <FormControl className={'testC'} style={{color: '#C7D300'}}>
                        <RadioGroup row>
                            <FormControlLabel onClick={(e) => {
                                e.preventDefault()
                                console.log('click')
                                setIsDefaultContact(!isDefaultContact)
                            }} control={<Radio checked={isDefaultContact}/>}
                                              label="Als Standardansprechpartner festlegen"/>
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item xs={4} style={{textAlign: 'right'}}>
                    {(id && id !== -1) && <TrashIcon
                        onClick={() => {
                            deleteContact(id);
                        }}
                        style={{
                            height: 30,
                            width: 30,
                            marginRight: 2,
                            transition: 'all 0.2s ease-in-out',
                            textAlign: 'center',
                            fill: '#C7D300',
                            cursor: 'pointer',
                            borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                        }}
                    />}
                    <ClearIcon
                        className={'not-yellow'}
                        onClick={() => {
                            setIsDefaultContact(false);
                            setAnrede('');
                            setAnredeError(false);
                            setVorname('');
                            setVornameError(false);
                            setNachname('');
                            setNachnameError(false);
                            setTelefon('');
                            setTelefonError(false);
                            setEmailContact('');
                            setEmailContactError(false);
                            setContactImage([]);
                            setContactImageError(false);
                            if (type === 'new') {
                                setNewContact(false);
                                window.scrollTo(0, 0);
                            } else if (type === 'edit') {
                                setEditContact(-1);
                            }
                        }}
                        style={{
                            height: 30,
                            width: 30,
                            marginRight: 2,
                            transition: 'all 0.2s ease-in-out',
                            textAlign: 'center',
                            fill: '#00374e',
                            cursor: 'pointer',
                            borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                        }}
                    />
                </Grid>

                <Grid item xs={6}>
                    <CssTextField4
                        label="Anrede*"
                        fieldtype={'GrayTransparent'}
                        // InputLabelProps={{
                        //     shrink: true
                        // }}
                        // className={'text-input-label-blue'}
                        style={{
                            transition: 'all 0.2s ease',
                            // minHeight: '44.59px',
                            minHeight: 29,
                            width: 'calc(50% - 10px)'
                        }}
                        autoComplete={'off'}
                        onChange={e => {
                            if (e.target.value.trim().length === 0) {
                                setAnredeError(true);
                            } else {
                                setAnredeError(false);
                            }
                            setAnrede(e.target.value);
                        }}
                        // onKeyPress={handleKeypress}
                        value={anrede}
                        error={anredeError != false}
                        helperText={anredeError}
                    />
                    <CssTextField4
                        label="Vorname"
                        fieldtype={'GrayTransparent'}
                        // InputLabelProps={{
                        //     shrink: true
                        // }}
                        // className={'text-input-label-blue'}
                        style={{
                            transition: 'all 0.2s ease',
                            minHeight: 29,
                            width: 'calc(100% - 10px)'
                        }}
                        autoComplete={'off'}
                        onChange={e => {
                            if (e.target.value.trim().length === 0) {
                            } else {
                                setVornameError(false);
                            }
                            setVorname(e.target.value);
                        }}
                        // onKeyPress={handleKeypress}
                        value={vorname}
                        error={vornameError != false}
                        helperText={vornameError}
                    />
                    <CssTextField4
                        label="Nachname*"
                        fieldtype={'GrayTransparent'}
                        // InputLabelProps={{
                        //     shrink: true
                        // }}
                        // className={'text-input-label-blue'}
                        style={{
                            transition: 'all 0.2s ease',
                            minHeight: 29,
                            width: 'calc(100% - 10px)'
                        }}
                        autoComplete={'off'}
                        onChange={e => {
                            if (e.target.value.trim().length === 0) {
                                setNachnameError(true);
                            } else {
                                setNachnameError(false);
                            }
                            setNachname(e.target.value);
                        }}
                        // onKeyPress={handleKeypress}
                        value={nachname}
                        error={nachnameError != false}
                        helperText={nachnameError}
                    />
                    <CssTextField4
                        label="Telefon"
                        fieldtype={'GrayTransparent'}
                        // InputLabelProps={{
                        //     shrink: true
                        // }}
                        // className={'text-input-label-blue'}
                        style={{
                            transition: 'all 0.2s ease',
                            minHeight: 29,
                            width: 'calc(100% - 10px)'
                        }}
                        autoComplete={'off'}
                        onChange={e => {
                            if (e.target.value.trim().length === 0) {
                                setTelefonError(true);
                            } else {
                                setTelefonError(false);
                            }
                            setTelefon(e.target.value);
                        }}
                        // onKeyPress={handleKeypress}
                        value={telefon}
                        error={telefonError != false}
                        helperText={telefonError}
                    />
                </Grid>
                <Grid item xs={6}>
                    <div style={{minHeight: 111, height: 111}}>
                        <ImageUploading
                            multiple
                            value={contactImage}
                            onChange={onChange}
                            maxNumber={1}
                            dataURLKey="data_url"
                            acceptType={["jpg", "png"]}
                        >
                            {({
                                  imageList,
                                  onImageUpload,
                                  onImageRemoveAll,
                                  onImageUpdate,
                                  onImageRemove,
                                  isDragging,
                                  dragProps
                              }) => (
                                <div className="upload__image-wrapper" style={{
                                    height: '100%',
                                    position: 'relative',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                    {(!contactImage || contactImage.length == 0) && <LogoUser
                                        style={{
                                            height: 111,
                                            width: '100%',
                                            cursor: 'pointer',
                                            fill: '#00374e'
                                        }}
                                        onClick={() => onImageUpload()}
                                    />}
                                    {(!contactImage || contactImage.length == 0) &&
                                        <p onClick={() => onImageUpload()} style={{
                                            position: 'absolute',
                                            cursor: 'pointer',
                                            color: 'white',
                                            fontSize: 10,
                                            top: '65%',
                                        }}>Bild hochladen</p>}
                                    {imageList.map((image, index) => (
                                        <div key={index} className="image-item" style={{
                                            height: '100%',
                                            position: 'relative',
                                            textAlign: 'center'
                                        }}>

                                            <img
                                                src={(image && image.data_url) ? image.data_url : ENV.REACT_APP_API_URL + '/file/' + image + '/unlogged/content'}
                                                style={{
                                                    height: 111,
                                                    width: 'auto',
                                                    cursor: 'pointer'
                                                }} onClick={() => onImageUpdate(0)} alt="" width="100"/>
                                        </div>
                                    ))}
                                    {(contactImage && contactImage.length > 0) && <>
                                        <div style={{
                                            position: 'absolute',
                                            bottom: '-5%',
                                        }}>
                                            <span onClick={() => onImageUpdate(0)} style={{
                                                color: 'white',
                                                cursor: 'pointer',
                                                background: '#00374e',
                                                padding: '2px 5px',
                                                borderRadius: 4,
                                                fontSize: 10,
                                            }}>Bild ändern
                                            </span>
                                            <span onClick={() => {
                                                onImageRemove(0)
                                                setContactImage([])
                                                setContactImageId(null)
                                            }} style={{
                                                color: 'red',
                                                cursor: 'pointer',
                                                background: '#00374e',
                                                padding: '2px 5px',
                                                borderRadius: 4,
                                                fontSize: 10,
                                            }}>X</span>
                                        </div>
                                    </>}
                                </div>
                            )}
                        </ImageUploading>
                    </div>
                    <CssTextField4
                        label="E-Mail"
                        fieldtype={'GrayTransparent'}
                        // InputLabelProps={{
                        //     shrink: true
                        // }}
                        // className={'text-input-label-blue'}
                        style={{
                            transition: 'all 0.2s ease',
                            minHeight: 29,
                            width: 'calc(100% - 10px)'
                        }}
                        autoComplete={'off'}
                        onChange={e => {
                            if (e.target.value.trim().length === 0) {
                                setEmailContactError(true);
                            } else {
                                setEmailContactError(false);
                            }
                            setEmailContact(e.target.value);
                        }}
                        // onKeyPress={handleKeypress}
                        value={emailContact}
                        error={emailContactError != false}
                        helperText={emailContactError}
                    />
                </Grid>


                <Grid item xs={12} style={{textAlign: 'right', marginTop: 7}}>
                    <CustomButton
                        buttontype={'yellowBlue'}
                        style={{padding: '1.7px 15px', marginRight: 10}}
                        onClick={() => {
                            if (type === 'new' && validateContact()) createContact();
                            else if (type === 'edit' && validateContact()) updateContact(id);
                        }}
                    >Speichern</CustomButton>
                </Grid>
            </Grid>
        </div>
    );
});

export default function Unternehmensprofil(props) {

    const {locationChange, menuAppBarRef} = props;
    const location = useLocation();
    const history = useHistory();
    const api = useFetch();

    useEffect(() => {
        if (ReactSession.get('user') != 'Arbeitgeber') {
            menuAppBarRef.current.logNeeded('Arbeitgeber');
            history.push('/');
        } else {
            document.title = 'Unternehmensprofil';
            locationChange(location);
            getCompanyAddresses();
            getContacts();
        }
    }, [])

    const [buttonHover, setButtonHover] = useState(null);
    const [activeMatchItem, setActiveMatchItem] = useState(null);
    const [favorite, setFavorite] = useState(null);
    const [companyAddresses, setCompanyAddresses] = React.useState([]);
    const [contacts, setContacts] = React.useState([]);


    const [isDefault, setIsDefault] = useState(false);
    const [companyName, setCompanyName] = React.useState('');
    const [companyNameError, setCompanyNameError] = React.useState(false);
    const [street, setStreet] = React.useState('');
    const [streetError, setStreetError] = React.useState(false);
    const [nr, setNr] = React.useState('');
    const [nrError, setNrError] = React.useState(false);
    const [zipcode, setZipcode] = React.useState('');
    const [zipcodeError, setZipcodeError] = React.useState(false);
    const [city, setCity] = React.useState('');
    const [cityError, setCityError] = React.useState(false);
    const [phonenumber, setPhonenumber] = React.useState('');
    const [phonenumberError, setPhonenumberError] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [emailError, setEmailError] = React.useState(false);
    const [website, setWebsite] = React.useState('');
    const [websiteError, setWebsiteError] = React.useState(false);


    const [editAddress, setEditAddress] = React.useState(-1);
    const [newAddress, setNewAddress] = React.useState(false);
    const [getRef, setGetRef] = React.useState(false);
    const newAddressRef = React.createRef();


    const [isDefaultContact, setIsDefaultContact] = useState(false);
    const [anrede, setAnrede] = useState('');
    const [anredeError, setAnredeError] = useState(false);
    const [vorname, setVorname] = useState('');
    const [vornameError, setVornameError] = useState(false);
    const [nachname, setNachname] = useState('');
    const [nachnameError, setNachnameError] = useState(false);
    const [telefon, setTelefon] = useState('');
    const [telefonError, setTelefonError] = useState(false);
    const [emailContact, setEmailContact] = useState('');
    const [emailContactError, setEmailContactError] = useState(false);
    const [contactImage, setContactImage] = useState([]);
    const [contactImageError, setContactImageError] = useState(false);
    const [contactImageId, setContactImageId] = useState(false);

    const [editContact, setEditContact] = React.useState(-1);
    const [newContact, setNewContact] = React.useState(false);
    const [getContactRef, setGetContactRef] = React.useState(false);
    const newContactRef = React.createRef();


    const [images, setImages] = React.useState([]);
    const maxNumber = 1;

    const getCompanyAddresses = async () => {

        let rows = [];
        let moreData = true;
        let pageNumber = 1;

        for (let i = 1; i < pageNumber + 1; i++) {
            let url = ENV.REACT_APP_API_URL + '/company-addresses?page%5Bsize%5D=20&page%5Bnumber%5D=' + i;

            //if (this.state.scity) url += '&scity=' + this.state.scity;

            let {response, data} = await api(url)


            if (!response || response.status == 401) {
                ReactSession.set('token', undefined);
                ReactSession.set('refreshToken', undefined);
                history.push('/login');
                return;
            }
            if (data && data.data) {
                data.data.map((item, index) => {
                    item.attributes.isDefault = (item.attributes.isDefault == 1) ? true : false;
                    if (rows.filter(i => i.id === item.id).length === 0) {
                        rows.push(item);
                    } else {
                        rows[rows.indexOf(rows.filter(i => i.id === item.id)[0])] = item;
                    }
                })

                if (data.meta.pagination.total === rows.length) {
                    moreData = false;
                }

            } else if (response.error) {
                notify('API Fehler: ' + response.status, true);
            }
        }

        rows.sort((a, b) => (a.attributes.isDefault == 1 ? -1 : 1));
        setCompanyAddresses(rows);
    }

    const getContacts = async () => {

        let rows = [];
        let moreData = true;
        let pageNumber = 1;

        for (let i = 1; i < pageNumber + 1; i++) {
            let url = ENV.REACT_APP_API_URL + '/contacts?page%5Bsize%5D=20&page%5Bnumber%5D=' + i;

            let {response, data} = await api(url)


            if (!response || response.status == 401) {
                ReactSession.set('token', undefined);
                ReactSession.set('refreshToken', undefined);
                history.push('/login');
                return;
            }
            if (data && data.data) {
                data.data.map((item, index) => {
                    if (rows.filter(i => i.id === item.id).length === 0) {
                        rows.push(item);
                    } else {
                        rows[rows.indexOf(rows.filter(i => i.id === item.id)[0])] = item;
                    }
                })

                if (data.meta.pagination.total === rows.length) {
                    moreData = false;
                }

            } else if (response.error) {
                notify('API Fehler: ' + response.status, true);
            }
        }

        setContacts(rows);
    }

    useEffect(() => {
        if (newAddressRef.current && getRef && companyAddresses && companyAddresses.length > 1) {
            setGetRef(false);
            const yOffset = 0;
            const y = (newAddressRef.current.getBoundingClientRect().bottom - newAddressRef.current.getBoundingClientRect().y) + yOffset;

            window.scrollTo({top: y, behavior: 'smooth'});
        }
    }, [newAddressRef])

    useEffect(() => {
        if (newContactRef.current && getContactRef && contacts && contacts.length > 1) {
            setGetContactRef(false);
            const yOffset = 0;
            const y = (newContactRef.current.getBoundingClientRect().bottom - newContactRef.current.getBoundingClientRect().y) + yOffset;

            window.scrollTo({top: y, behavior: 'smooth'});
        }
    }, [newContactRef])

    const notify = (message, error) => {
        console.log('NOTIFY')
        if (error) {
            toast.error(message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                style: {marginTop: 30}
            });
        } else {
            toast.success(message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                style: {marginTop: 30}
            });
        }
    }

    const handleMouseIn = (type) => {
        setButtonHover(type)
    };

    const handleMouseOut = () => {
        setButtonHover(null)
    };

    const createCompanyAddress = async () => {
        let updatedAttributes = {
            data: {
                attributes: {
                    isDefault: isDefault === true ? 1 : 0,
                    companyName: companyName,
                    street: street,
                    houseNumber: nr,
                    zipcode: zipcode,
                    city: city,
                    telephoneNumber: phonenumber,
                    email,
                    website: (website && !website.includes('https://') && !website.includes('http://')) ? 'https://' + website : website ? website : null
                },
                relationships: {}
            }
        }

        let url = ENV.REACT_APP_API_URL + "/company-addresses";
        const {response, data} = await api(url, 'POST', JSON.stringify(updatedAttributes));

        if (response && response.status != '404' && data) {
            setIsDefault(false);
            setCompanyName('');
            setCompanyNameError(false);
            setStreet('');
            setStreetError(false);
            setNr('');
            setNrError(false);
            setZipcode('');
            setZipcodeError(false);
            setCity('');
            setCityError(false);
            setPhonenumber('');
            setPhonenumberError(false);
            setEmail('');
            setEmailError(false);
            setWebsite('');
            setWebsiteError(false);
            setNewAddress(false);
            setEditAddress(-1);
            getCompanyAddresses();
            notify('Deine Adresse wurde erfolgreich angelegt');
        } else if (response.status == '404') {

        }
    }

    const updateCompanyAddress = async (id) => {
        let updatedAttributes = {
            data: {
                attributes: {
                    isDefault: isDefault === true ? 1 : 0,
                    companyName: companyName,
                    street: street,
                    houseNumber: nr,
                    zipcode: zipcode,
                    city: city,
                    telephoneNumber: phonenumber,
                    email,
                    website: (website && !website.includes('https://') && !website.includes('http://')) ? 'https://' + website : website ? website : null
                },
                relationships: {}
            }
        }

        let url = ENV.REACT_APP_API_URL + "/company-address/" + id;
        const {response, data} = await api(url, 'PATCH', JSON.stringify(updatedAttributes));

        if (response && response.status != '404' && data) {
            setIsDefault(false);
            setCompanyName('');
            setCompanyNameError(false);
            setStreet('');
            setStreetError(false);
            setNr('');
            setNrError(false);
            setZipcode('');
            setZipcodeError(false);
            setCity('');
            setCityError(false);
            setPhonenumber('');
            setPhonenumberError(false);
            setEmail('');
            setEmailError(false);
            setWebsite('');
            setWebsiteError(false);
            setNewAddress(false);
            setEditAddress(-1);
            getCompanyAddresses();
            notify('Deine Adresse wurde erfolgreich aktualisiert');
        } else if (response.status == '404') {

        }
    }

    const deleteCompanyAddress = (id) => {
        console.log(id);
        console.log('DELETE')
        confirmAlert({
            title: 'Bestätigung',
            message: 'Möchtest Du diese Adresse wirklich löschen?',
            buttons: [
                {
                    label: 'Ja',
                    onClick: async () => {
                        console.log(id);
                        let url = ENV.REACT_APP_API_URL + '/company-address/' + id;
                        const {response, data} = await api(url, 'DELETE');
                        if (response && response.status == 204) {
                            setIsDefault(false);
                            setCompanyName('');
                            setCompanyNameError(false);
                            setStreet('');
                            setStreetError(false);
                            setNr('');
                            setNrError(false);
                            setZipcode('');
                            setZipcodeError(false);
                            setCity('');
                            setCityError(false);
                            setPhonenumber('');
                            setPhonenumberError(false);
                            setEmail('');
                            setEmailError(false);
                            setWebsite('');
                            setWebsiteError(false);
                            setNewAddress(false);
                            setEditAddress(-1);
                            getCompanyAddresses();

                            notify('Deine Adresse wurde erfolgreich gelöscht');
                        } else if (response && response.status == 500) {
                            notify('Löschen der Adresse nicht möglich. Diese Adresse wird aktuell in mindestens einer Stellenanzeige verwendet', true)
                        }
                    }
                },
                {
                    label: 'Nein',
                }
            ],
            overlayClassName: "overlay-custom-class-name"
        });
    }

    const createContact = async () => {
        const formData = new FormData();
        formData.append(
            'data',
            JSON.stringify({
                data: {
                    attributes: {
                        anrede: anrede,
                        vorname: vorname,
                        nachname: nachname,
                        telefon: telefon,
                        email: emailContact,
                    },
                },
            }),
        );

        if (contactImage && contactImage[0]) formData.append('file', contactImage[0].file);

        let url = ENV.REACT_APP_API_URL + "/contacts";
        const {response, data} = await api(url, 'POST', formData, true);

        if (response && response.status != '404' && data) {
            setIsDefaultContact(false);
            setAnrede('');
            setAnredeError(false);
            setVorname('');
            setVornameError(false);
            setNachname('');
            setNachnameError(false);
            setTelefon('');
            setTelefonError(false);
            setEmailContact('');
            setEmailContactError(false);
            setContactImage([]);
            setContactImageError(false);
            setNewContact(false);
            setEditContact(-1);
            setContactImage(false);
            getContacts();
            notify('Dein Ansprechpartner wurde erfolgreich angelegt');
        } else if (response.status == '404') {

        }
    }

    const updateContact = async (id) => {
        const formData = new FormData();
        formData.append(
            'data',
            JSON.stringify({
                data: {
                    attributes: {
                        anrede: anrede,
                        vorname: vorname,
                        nachname: nachname,
                        telefon: telefon,
                        email: emailContact,
                    },
                    file: contactImageId
                },
            }),
        );
        if (contactImage && contactImage[0] && !contactImageId) {
            console.log('TRUE');

            formData.append('file', contactImage[0].file);
        }
        let url = ENV.REACT_APP_API_URL + "/contacts/" + id + "/upload/file";
        const {response, data} = await api(url, 'POST', formData, true);

        if (response && response.status != '404' && data) {
            setIsDefaultContact(false);
            setAnrede('');
            setAnredeError(false);
            setVorname('');
            setVornameError(false);
            setNachname('');
            setNachnameError(false);
            setTelefon('');
            setTelefonError(false);
            setEmailContact('');
            setEmailContactError(false);
            setContactImage([]);
            setContactImageError(false);
            setNewContact(false);
            setEditContact(-1);
            setContactImage(false);
            getContacts();
            notify('Dein Ansprechpartner wurde erfolgreich aktualisiert');
        } else if (response.status == '404') {

        }
    }

    const deleteContact = (id) => {
        console.log(id);
        console.log('DELETE')
        confirmAlert({
            title: 'Bestätigung',
            message: 'Möchtest Du diesen Ansprechpartner wirklich löschen?',
            buttons: [
                {
                    label: 'Ja',
                    onClick: async () => {
                        console.log(id);
                        let url = ENV.REACT_APP_API_URL + '/contact/' + id;
                        const {response, data} = await api(url, 'DELETE');
                        if (response && response.status == 204) {
                            setIsDefaultContact(false);
                            setAnrede('');
                            setAnredeError(false);
                            setVorname('');
                            setVornameError(false);
                            setNachname('');
                            setNachnameError(false);
                            setTelefon('');
                            setTelefonError(false);
                            setEmailContact('');
                            setEmailContactError(false);
                            setContactImage([]);
                            setContactImageError(false);
                            setNewContact(false);
                            setEditContact(-1);
                            setContactImage(false);
                            getContacts();

                            notify('Dein Ansprechpartner wurde erfolgreich gelöscht');
                        } else if (response && response.status == 404) {
                        }else if (response && response.status === 500){
                            notify('Löschen des Ansprechpartners nicht möglich. Dieser Ansprechpartner wird aktuell in mindestens einer Stellenanzeige verwendet', true)
                        }
                    }
                },
                {
                    label: 'Nein',
                }
            ],
            overlayClassName: "overlay-custom-class-name"
        });
    }

    return (
        <Grid className="mainApp" container style={{
            padding: 0,
        }}>
            <Grid id={'unternehmensprofil'} item xs={12} style={{
                display: 'block',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 64,
                marginBottom: activeMatchItem !== null ? 50 : 0,
                padding: '30px 50px 50px',
                width: '100%',
                minHeight: '800px',
            }}>
                <Grid id={'step4'} container style={{padding: 15}}>
                    <Grid item xs={12} style={{padding: '0'}}>
                        <p style={{margin: '20px 0', textAlign: 'left', fontFamily: 'VagRoundedBold', fontSize: 32}}
                           className={'color-blue'}>Unternehmensdaten</p>
                    </Grid>
                    <Grid item xs={6} style={{padding: 5}}>
                        <div style={{
                            margin: '20px 0',
                            textAlign: 'left',
                            fontFamily: 'VagRoundedBold',
                            fontSize: 32,
                            position: 'relative',
                            marginTop: 2,
                            paddingTop: 5,
                            paddingBottom: 5,
                            paddingLeft: 20,
                            width: 'calc(100% - 20px)',
                            background: '#C7D300',
                            border: '2px solid #C7D300',
                            borderRadius: 100,
                            color: 'white',
                        }}
                             className={'color-blue'}>
                            Firmenadressen
                            <div style={{
                                display: 'flex',
                                position: 'absolute',
                                top: 9,
                                right: 0,
                                paddingRight: 15,
                                zIndex: 1
                            }}>
                                <ClearIcon
                                    className={'not-yellow'}
                                    onClick={() => {
                                        setGetRef(true);
                                        setNewAddress(true);
                                    }}
                                    style={{
                                        height: 35,
                                        width: 35,
                                        marginRight: 2,
                                        transition: 'all 0.2s ease-in-out',
                                        textAlign: 'center',
                                        fill: '#00374e',
                                        cursor: 'pointer',
                                        transform: 'rotate(45deg)',
                                        borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                    }}
                                />
                            </div>
                        </div>
                        {companyAddresses && companyAddresses.map((item, index) => {
                            if (editAddress === index) {
                                return (
                                    <EditAddress
                                        type={'edit'}
                                        index={index}
                                        id={item.id}
                                        isDefault={isDefault}
                                        setIsDefault={setIsDefault}
                                        createCompanyAddress={createCompanyAddress}
                                        updateCompanyAddress={updateCompanyAddress}
                                        deleteCompanyAddress={deleteCompanyAddress}
                                        companyName={companyName}
                                        setCompanyName={setCompanyName}
                                        companyNameError={companyNameError}
                                        setCompanyNameError={setCompanyNameError}
                                        street={street}
                                        setStreet={setStreet}
                                        streetError={streetError}
                                        setStreetError={setStreetError}
                                        nr={nr}
                                        setNr={setNr}
                                        nrError={nrError}
                                        setNrError={setNrError}
                                        zipcode={zipcode}
                                        setZipcode={setZipcode}
                                        zipcodeError={zipcodeError}
                                        setZipcodeError={setZipcodeError}
                                        city={city}
                                        setCity={setCity}
                                        cityError={cityError}
                                        setCityError={setCityError}
                                        phonenumber={phonenumber}
                                        setPhonenumber={setPhonenumber}
                                        phonenumberError={phonenumberError}
                                        setPhonenumberError={setPhonenumberError}
                                        email={email}
                                        setEmail={setEmail}
                                        emailError={emailError}
                                        setEmailError={setEmailError}
                                        website={website}
                                        setWebsite={setWebsite}
                                        websiteError={websiteError}
                                        setWebsiteError={setWebsiteError}
                                        newAddress={newAddress}
                                        setNewAddress={setNewAddress}
                                        setEditAddress={setEditAddress}
                                    />
                                );
                            }

                            if (editAddress !== index) {
                                return (

                                    <div key={index} style={{
                                        background: '#eee',
                                        border: '2px solid #eee',
                                        borderRadius: 25,
                                        margin: index === 0 ? '35px 0 0' : '15px 0 0',
                                        padding: '10px 15px 20px 25px',
                                        textAlign: 'left'
                                    }}>
                                        <Grid container>
                                            <Grid item xs={8}>

                                                <Grid container>
                                                    <Grid item xs={12} style={{paddingTop: 10}}>
                                                        <p style={{margin: 0}}>{item.attributes.companyName}</p>
                                                        <p style={{margin: 0}}>{item.attributes.street}&nbsp;{item.attributes.houseNumber}</p>
                                                        <p style={{margin: 0}}>{item.attributes.zipcode}&nbsp;{item.attributes.city}</p>
                                                        <p style={{margin: 0}}>{item.attributes.telephoneNumber}</p>
                                                        <p style={{margin: 0}}>{item.attributes.email}</p>
                                                        <p style={{margin: 0}}>{item.attributes.website}</p>
                                                    </Grid>
                                                </Grid>


                                            </Grid>
                                            <Grid item xs={4} style={{textAlign: 'right'}}>
                                                <TrashIcon
                                                    className={'not-yellow'}
                                                    onClick={() => {
                                                        deleteCompanyAddress(item.id);
                                                    }}
                                                    style={{
                                                        height: 30,
                                                        width: 30,
                                                        marginRight: 2,
                                                        transition: 'all 0.2s ease-in-out',
                                                        textAlign: 'center',
                                                        fill: '#00374e',
                                                        cursor: 'pointer',
                                                        borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                                    }}
                                                />
                                                <EditIcon
                                                    className={'not-yellow'}
                                                    style={{
                                                        height: 30,
                                                        width: 30,
                                                        marginRight: 2,
                                                        transition: 'all 0.2s ease-in-out',
                                                        textAlign: 'center',
                                                        fill: '#00374e',
                                                        cursor: 'pointer',
                                                        borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                                    }}
                                                    onClick={() => {
                                                        console.log('EDIT')
                                                        setIsDefault(item.attributes.isDefault);
                                                        setCompanyName(item.attributes.companyName);
                                                        setCompanyNameError(false);
                                                        setStreet(item.attributes.street);
                                                        setStreetError(false);
                                                        setNr(item.attributes.houseNumber);
                                                        setNrError(false);
                                                        setZipcode(item.attributes.zipcode);
                                                        setZipcodeError(false);
                                                        setCity(item.attributes.city);
                                                        setCityError(false);
                                                        setPhonenumber(item.attributes.telephoneNumber);
                                                        setPhonenumberError(false);
                                                        setEmail(item.attributes.email);
                                                        setEmailError(false);
                                                        setWebsite(item.attributes.website);
                                                        setWebsiteError(false);
                                                        setNewAddress(false);
                                                        setEditAddress(index);
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>
                                );
                            }
                        })}

                        {newAddress && <EditAddress
                            ref={newAddressRef}
                            type={'new'}
                            id={-1}
                            isDefault={isDefault}
                            setIsDefault={setIsDefault}
                            createCompanyAddress={createCompanyAddress}
                            companyName={companyName}
                            setCompanyName={setCompanyName}
                            companyNameError={companyNameError}
                            setCompanyNameError={setCompanyNameError}
                            street={street}
                            setStreet={setStreet}
                            streetError={streetError}
                            setStreetError={setStreetError}
                            nr={nr}
                            setNr={setNr}
                            nrError={nrError}
                            setNrError={setNrError}
                            zipcode={zipcode}
                            setZipcode={setZipcode}
                            zipcodeError={zipcodeError}
                            setZipcodeError={setZipcodeError}
                            city={city}
                            setCity={setCity}
                            cityError={cityError}
                            setCityError={setCityError}
                            phonenumber={phonenumber}
                            setPhonenumber={setPhonenumber}
                            phonenumberError={phonenumberError}
                            setPhonenumberError={setPhonenumberError}
                            email={email}
                            setEmail={setEmail}
                            emailError={emailError}
                            setEmailError={setEmailError}
                            website={website}
                            setWebsite={setWebsite}
                            websiteError={websiteError}
                            setWebsiteError={setWebsiteError}
                            newAddress={newAddress}
                            setNewAddress={setNewAddress}
                        />}

                        {(!newAddress && companyAddresses && companyAddresses.length > 2) && <div style={{
                            margin: '20px 0',
                            textAlign: 'left',
                            fontFamily: 'VagRoundedBold',
                            fontSize: 16,
                            position: 'relative',
                            marginTop: 35,
                            paddingTop: 15,
                            paddingBottom: 15,
                            paddingLeft: 20,
                            width: 'calc(100% - 20px)',
                            background: '#eee',
                            // border: '2px solid #C7D300',
                            borderRadius: 100,
                            // color: 'white',
                            // height: 48.8,
                        }}
                                                                                                  className={'color-blue'}>
                            Neue Adresse anlegen
                            <div style={{
                                display: 'flex',
                                position: 'absolute',
                                top: 9,
                                right: 0,
                                paddingRight: 15,
                                zIndex: 1
                            }}>
                                <ClearIcon
                                    className={'not-yellow'}
                                    onClick={() => {
                                        setNewAddress(true);
                                    }}
                                    style={{
                                        height: 35,
                                        width: 35,
                                        marginRight: 2,
                                        transition: 'all 0.2s ease-in-out',
                                        textAlign: 'center',
                                        fill: '#00374e',
                                        cursor: 'pointer',
                                        transform: 'rotate(45deg)',
                                        borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                    }}
                                />
                            </div>
                        </div>}
                    </Grid>
                    <Grid item xs={6} style={{padding: 5}}>
                        <div style={{
                            margin: '20px 0 0 0',
                            textAlign: 'left',
                            fontFamily: 'VagRoundedBold',
                            fontSize: 32,
                            position: 'relative',
                            marginTop: 2,
                            paddingTop: 5,
                            paddingBottom: 5,
                            paddingLeft: 20,
                            width: 'calc(100% - 20px)',
                            background: '#C7D300',
                            border: '2px solid #C7D300',
                            borderRadius: 100,
                            color: 'white',
                        }}
                             className={'color-blue'}>
                            Ansprechpartner
                            <div style={{
                                display: 'flex',
                                position: 'absolute',
                                top: 9,
                                right: 0,
                                paddingRight: 15,
                                zIndex: 1
                            }}>
                                <ClearIcon
                                    className={'not-yellow'}
                                    onClick={() => {
                                        setGetContactRef(true);
                                        setNewContact(true);
                                    }}
                                    style={{
                                        height: 35,
                                        width: 35,
                                        marginRight: 2,
                                        transition: 'all 0.2s ease-in-out',
                                        textAlign: 'center',
                                        fill: '#00374e',
                                        cursor: 'pointer',
                                        transform: 'rotate(45deg)',
                                        borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                    }}
                                />
                            </div>
                        </div>

                        {contacts && contacts.map((item, index) => {

                            if (editContact === index) {
                                return (
                                    <EditContact
                                        type={'edit'}
                                        index={index}
                                        id={item.id}
                                        isDefaultContact={isDefaultContact}
                                        setIsDefaultContact={setIsDefaultContact}
                                        anrede={anrede}
                                        setAnrede={setAnrede}
                                        anredeError={anredeError}
                                        setAnredeError={setAnredeError}
                                        vorname={vorname}
                                        setVorname={setVorname}
                                        vornameError={vornameError}
                                        setVornameError={setVornameError}
                                        nachname={nachname}
                                        setNachname={setNachname}
                                        nachnameError={nachnameError}
                                        setNachnameError={setNachnameError}
                                        telefon={telefon}
                                        setTelefon={setTelefon}
                                        telefonError={telefonError}
                                        setTelefonError={setTelefonError}
                                        emailContact={emailContact}
                                        setEmailContact={setEmailContact}
                                        emailContactError={emailContactError}
                                        setEmailContactError={setEmailContactError}
                                        contactImage={contactImage}
                                        setContactImage={setContactImage}
                                        contactImageId={contactImageId}
                                        setContactImageId={setContactImageId}
                                        contactImageError={contactImageError}
                                        setContactImageError={setContactImageError}
                                        createContact={createContact}
                                        updateContact={updateContact}
                                        deleteContact={deleteContact}
                                        newContact={newContact}
                                        setNewContact={setNewContact}
                                        setEditContact={setEditContact}
                                    />
                                );
                            }

                            if (editContact !== index) {
                                return (

                                    <div key={index} style={{
                                        background: '#eee',
                                        border: '2px solid #eee',
                                        borderRadius: 25,
                                        margin: index === 0 ? '35px 0 0' : '15px 0 0',
                                        padding: '10px 15px 20px 25px',
                                        textAlign: 'left'
                                    }}>
                                        <Grid container>
                                            <Grid item xs={10}>

                                                <div style={{
                                                    paddingTop: 10,
                                                    display: 'flex',
                                                    justifyContent: 'flex-start',
                                                    alignItems: 'center',
                                                    flexDirection: 'row',
                                                    maxWidth: 90
                                                }}>
                                                    <img style={{height: 'auto', width: '100%'}}
                                                         src={ENV.REACT_APP_API_URL + '/file/' + item.attributes.file_id + '/unlogged/content'}/>
                                                    <div style={{paddingLeft: 30}}>
                                                        <p style={{margin: 0}}>{item.attributes.anrede}&nbsp;{item.attributes.vorname}&nbsp;{item.attributes.nachname}</p>
                                                        <p style={{margin: 0}}>{item.attributes.telefon}</p>
                                                        <p style={{margin: 0}}>{item.attributes.email}</p>
                                                    </div>
                                                </div>


                                            </Grid>
                                            <Grid item xs={2} style={{textAlign: 'right'}}>
                                                <TrashIcon
                                                    className={'not-yellow'}
                                                    onClick={() => {
                                                        deleteContact(item.id);
                                                    }}
                                                    style={{
                                                        height: 30,
                                                        width: 30,
                                                        marginRight: 2,
                                                        transition: 'all 0.2s ease-in-out',
                                                        textAlign: 'center',
                                                        fill: '#00374e',
                                                        cursor: 'pointer',
                                                        borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                                    }}
                                                />
                                                <EditIcon
                                                    className={'not-yellow'}
                                                    style={{
                                                        height: 30,
                                                        width: 30,
                                                        marginRight: 2,
                                                        transition: 'all 0.2s ease-in-out',
                                                        textAlign: 'center',
                                                        fill: '#00374e',
                                                        cursor: 'pointer',
                                                        borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                                    }}
                                                    onClick={async () => {
                                                        console.log('EDIT')
                                                        setIsDefaultContact(item.attributes.isDefault);
                                                        setAnrede(item.attributes.anrede);
                                                        setAnredeError(false);
                                                        setVorname(item.attributes.vorname);
                                                        setVornameError(false);
                                                        setNachname(item.attributes.nachname);
                                                        setNachnameError(false);
                                                        setTelefon(item.attributes.telefon);
                                                        setTelefonError(false);
                                                        setEmailContact(item.attributes.email);
                                                        setEmailContactError(false);
                                                        setNewContact(false);
                                                        setContactImage(item.attributes.file_id ? [item.attributes.file_id] : [])
                                                        setContactImageId(item.attributes.file_id)
                                                        setNewContact(false);
                                                        setEditContact(index);
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </div>
                                );
                            }
                        })}

                        {newContact && <EditContact
                            ref={newContactRef}
                            type={'new'}
                            id={-1}
                            isDefaultContact={isDefaultContact}
                            setIsDefaultContact={setIsDefaultContact}
                            anrede={anrede}
                            setAnrede={setAnrede}
                            anredeError={anredeError}
                            setAnredeError={setAnredeError}
                            vorname={vorname}
                            setVorname={setVorname}
                            vornameError={vornameError}
                            setVornameError={setVornameError}
                            nachname={nachname}
                            setNachname={setNachname}
                            nachnameError={nachnameError}
                            setNachnameError={setNachnameError}
                            telefon={telefon}
                            setTelefon={setTelefon}
                            telefonError={telefonError}
                            setTelefonError={setTelefonError}
                            emailContact={emailContact}
                            setEmailContact={setEmailContact}
                            emailContactError={emailContactError}
                            setEmailContactError={setEmailContactError}
                            contactImage={contactImage}
                            setContactImage={setContactImage}
                            contactImageError={contactImageError}
                            setContactImageError={setContactImageError}
                            createContact={createContact}
                            updateContact={updateContact}
                            deleteContact={deleteContact}
                            newContact={newContact}
                            setNewContact={setNewContact}
                            setEditContact={setEditContact}
                        />}

                        {(!newContact && contacts && contacts.length > 2) && <div style={{
                            margin: '20px 0',
                            textAlign: 'left',
                            fontFamily: 'VagRoundedBold',
                            fontSize: 16,
                            position: 'relative',
                            marginTop: 35,
                            paddingTop: 15,
                            paddingBottom: 15,
                            paddingLeft: 20,
                            width: 'calc(100% - 20px)',
                            background: '#eee',
                            // border: '2px solid #C7D300',
                            borderRadius: 100,
                            // color: 'white',
                            // height: 48.8,
                        }}
                                                                                  className={'color-blue'}>
                            Neuen Ansprechpartner anlegen
                            <div style={{
                                display: 'flex',
                                position: 'absolute',
                                top: 9,
                                right: 0,
                                paddingRight: 15,
                                zIndex: 1
                            }}>
                                <ClearIcon
                                    className={'not-yellow'}
                                    onClick={() => {
                                        setNewContact(true);
                                    }}
                                    style={{
                                        height: 35,
                                        width: 35,
                                        marginRight: 2,
                                        transition: 'all 0.2s ease-in-out',
                                        textAlign: 'center',
                                        fill: '#00374e',
                                        cursor: 'pointer',
                                        transform: 'rotate(45deg)',
                                        borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                    }}
                                />
                            </div>
                        </div>}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
