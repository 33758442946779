import React, {Component} from 'react';

const CODE_LENGTH = new Array(6).fill('-');
class CodeInput extends Component {
    state = {
        value: this.props.value,
        focused: false,
    };

    input = React.createRef();

    handleClick = () => {
        this.input.current.focus();
    };
    handleFocus = () => {
        this.setState({ focused: true });
    };
    handleBlur = () => {
        this.setState({
            focused: false,
        });
    };

    handleChange = (e) => {
        const value = e.target.value;

        console.log(value);

        this.setState((state) => {
            if (state.value.length >= CODE_LENGTH.length) return null;
            return {
                value: (state.value + value).slice(0, CODE_LENGTH.length),
            };
        }, () => {
            this.props.setValue(this.state.value);
        });
    };

    handleKeyUp = (e) => {
        if (e.key === "Backspace") {
            this.setState((state) => {
                return {
                    value: state.value.slice(0, state.value.length - 1),
                };
            });
        }
    };


    render() {
        const { value, focused } = this.state;
        const values = value.split("");
        const selectedIndex =
            values.length < CODE_LENGTH.length ? values.length : CODE_LENGTH.length - 1;
        const hideInput = !(values.length < CODE_LENGTH.length);


        console.log(selectedIndex);

        return (
                <div className="wrap" onClick={this.handleClick}>
                    {
                        CODE_LENGTH.map((v, index) => {
                            const selected = values.length === index;
                            const filled =
                                values.length === CODE_LENGTH.length && index === CODE_LENGTH.length - 1;

                            return (
                                <div className="display">
                                    {values[index] || (selected && focused) ? values[index] : v}
                                </div>
                            );
                        })
                    }
                    <input
                        type={"number"}
                        value=""
                        ref={this.input}
                        onChange={this.handleChange}
                        onKeyUp={this.handleKeyUp}
                        onFocus={this.handleFocus}
                        onBlur={this.handleBlur}
                        className="code-input"
                        style={{
                            width: "34px",
                            top: "0px",
                            bottom: "0px",
                            left: `${selectedIndex * 40}px`,
                            opacity: hideInput ? 0 : 1,
                        }}
                    />
                </div>
        );
    }
}

export default CodeInput;