import React, { useEffect, useRef, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory, useLocation } from "react-router";
import './Chat.css';
import { ReactSession } from "react-client-session";
import useFetch from "./UseFetch";

const useQuery = () => new URLSearchParams(useLocation().search);


export default function Chat(props) {

    const { theme, locationChange, unreadChats, login, menuAppBarRef } = props;
    const location = useLocation();
    const history = useHistory();
    const query = useQuery();

    const [message, setMessage] = useState('');
    const pageNumber = useRef(1);
    const mode = useRef(false);
    const [hasMoreData, setHasMoreData] = useState(false);
    const [expandState, setExpandState] = useState(false);
    const selectedMatching = useRef(false);
    const [chatMatching, setChatMatching] = useState(false);
    const [iframeLoaded, setIframeLoaded] = useState(false)
    const [matchings, setMatchings] = useState([]);
    const [urlGenerated, setUrlGenerated] = useState(null);
    const intervalRef = useRef();


    const bottomRef = useRef(null);
    const api = useFetch();
    useEffect(() => {
        console.log(query.get('id'))
        if (query.get('matchId') && query.get('jobAdId')) {
            setUrlGenerated(`${ENV.REACT_APP_AN_FRONTEND_URL}/chatsAG/${query.get('matchId')}/${query.get('jobAdId')}`)
        } else {
            setUrlGenerated(`${ENV.REACT_APP_AN_FRONTEND_URL}/chatsAG`)
        }
        if (!ReactSession.get('user')) {
            menuAppBarRef.current.logNeeded();
            history.push('/');
        } else {
            document.title = 'swiitcher';
            locationChange(location);

            return () => {
                console.log('callback')
                if (intervalRef && intervalRef.current) {
                    console.log('clear interval')
                    clearInterval(intervalRef.current);
                    intervalRef.current = null;
                    setChatMatching(null);
                }
            }
        }


    }, [])

    window.onmessage = async function (e) {
        if (typeof e.data == 'string' && e.data.includes('"matchId":"')) {
            const data = JSON.parse(e.data)
            history.push('/matches-arbeitgeber?matchId=' + data.matchId + '&jobAdId=' + data.jobAdId);
        } else if (typeof e.data == 'string' && e.data.includes('fileId:')) {
            const fileId = e.data.split(':')[1]
            let url = ENV.REACT_APP_API_URL + `/file/${fileId}/chat`;
            console.log(url);
            let headers = new Headers();
            let token = ReactSession.get('token');

            // headers.append('Content-Type', 'application/vnd.api+json');

            headers.append('Authorization', `Bearer ${token}`);
            //if (this.state.sort) url += '&sort=' + this.state.sort;
            const response = await fetch(url, {
                headers: headers,
                method: 'GET',
            })
            const data = await response.blob()

            // Extract filename from Content-Disposition header
            let filename = 'default-download'; // A default filename if none is found
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore Todo: response header types is not working
            const contentDisposition = (response.headers) ? response.headers['Content-Disposition'] || response.headers.get('content-disposition') : null;
            if (contentDisposition) {
                const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
                if (matches != null && matches[1]) {
                    filename = matches[1].replace(/['"]/g, ''); // Remove any surrounding quotes
                }
            }

            // Check the data type and create object URL
            if (data?.type.includes('image') || data?.type.includes('pdf') || data?.type.includes('word') || data?.type.includes('opendocument')) {
                const objUrl = window.URL.createObjectURL(new File([data], filename, { type: data.type }))

                let a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                a.href = objUrl;
                a.download = filename;
                a.click();
                window.URL.revokeObjectURL(objUrl);

                // window.open(window.URL.createObjectURL(new File([data], filename, { type: data.type })), '_blank')
            }
        } else if (typeof e.data == 'string' && e.data === 'routeToNewAd') {
            history.push('/stellenanzeigen')
        }
    };

    return (
        <div style={{
            paddingTop: 64, height: '100%', width: '100%', background: 'white',
            border: 'none'
        }}>
            {urlGenerated && <iframe src={urlGenerated}
                                     style={{
                                         height: 'calc(100vh - 64px)', width: '100%',
                                         border: 'none'
                                     }}
                                     id={'idIframe'}
                                     sandbox={"allow-top-navigation allow-scripts allow-forms allow-same-origin allow-popups allow-modals allow-popups-to-escape-sandbox allow-downloads"}
            />}
        </div>
    );
}

