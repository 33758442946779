import {styled, TextField} from "@mui/material";
import {makeStyles} from "@mui/styles";
import { TextFieldProps } from '@material-ui/core';


let fieldtype = undefined;
let width = undefined;



export const CssTextField3 = (props) => {
    const { showUpDown, ...textFieldProps } = props;
    const classes = useStyles();
    fieldtype = props.fieldtype;
    width = props.width;


    return (
        <CustomTextfield className={classes.input} {...textFieldProps} />
    );
}

const useStyles = makeStyles({
    input: {
        '& input[type=number]': {
            // '-moz-appearance': 'textfield'
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            // '-webkit-appearance': 'none',
            // margin: 0
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
        }
    },
});



// Do whatever you want with `customProp`
const CustomTextfield = styled(TextField)(({theme}) => ({
    '& label:not(.MuiInputLabel-shrink)': {
        top: -4,
        marginLeft: 5,
        fontSize: '14px',
        color: 'black',

        // transition: 'none'
    },
    '& label.MuiInputLabel-shrink': {
        marginLeft: 5,
        top: -8,
        fontSize: '12px !important',
    },
    '& label': {
        margin: 0,
        padding: 0,
        marginLeft: 5,
        top: -8,
        // top: -8,
        fontSize: '12px !important',
        color: 'black',
        transition: theme.transitions.create([
            'color',
            'transform'
        ]),
    },
    '& label.MuiFormLabel-filled': {
        top: -8,
        color: 'white',
    },
    // '& label.MuiFormLabel-filled:not(.focused)': {
    //     margin: 0,
    //     padding: 0,
    //     marginLeft: 5,
    //     top: -8,
    //     fontSize: '12px !important',
    //     color: 'white',
    //     transition: theme.transitions.create([
    //         'color',
    //         'transform'
    //     ]),
    // },
    '&:hover label': {
        color: 'black',
        fontSize: '14px',
        transition: theme.transitions.create([
            'color',
        ]),
    },
    '& label.Mui-filled': {
        marginLeft: 5,
        top: -8,
        fontSize: '12px !important',
        color: 'white !important',
        transition: theme.transitions.create([
            'color',
            'transform'
        ]),
    },
    '& label.Mui-focused': {
        marginLeft: 5,
        top: -8,
        fontSize: '12px !important',
        color: 'white',
        transition: theme.transitions.create([
            'color',
            'transform'
        ]),
    },
    // '& .MuiFormHelperText-root': {
    //     // height: 0,
    //     paddingBottom: 5
    // },






    '.MuiFormHelperText-filled': {
        color: 'red',
        transition: theme.transitions.create([
            'color',
            'transform'
        ]),
    },
    '.MuiFormHelperText-contained': {
        color: 'red',
        transition: theme.transitions.create([
            'color',
            'transform'
        ]),
    },


    // '& .MuiInput-underline:after': {
    //     borderColor: theme.palette.primary.main,
    // },
    '& .MuiOutlinedInput-root': {
        background: 'white',
        borderRadius: 25,
        // '& ::placeholder': {
        //     color: theme.palette.secondary.main,
        //     // color: 'red',
        //     opacity: 1,
        //     fontSize: 12,
        //     marginTop: 1,
        // },
        '& .MuiOutlinedInput-input': {
            padding: '10px 10px 10px 10px',
            fontSize: 14,
            borderRadius: 25,
            paddingLeft: 20,
            transition: theme.transitions.create([
                'border-color',
            ]),
        },
        '& fieldset': {
            border: '2px solid',
            borderColor: theme.palette.secondary.main,
            borderRadius: 25,
            transition: theme.transitions.create([
                'border-color',
            ]),
            '& legend': {
                width: 0
            }
        },
        '&:hover fieldset': {
            border: '2px solid',
            borderColor: theme.palette.secondary.main,
            transition: theme.transitions.create([
                'border-color',
            ]),
        },
        '&.Mui-focused fieldset': {
            border: '2px solid',
            borderColor: theme.palette.secondary.main
        },
    },
}));
