import * as React from 'react';
import { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import { CssTextField3 } from '../../CssTextField3';
import './LoginAndRegistration.css'
import { Box, IconButton, InputAdornment, Link, Modal } from '@mui/material';
import { PriorityHigh, Visibility, VisibilityOff } from '@mui/icons-material';
import { CustomButton } from '../../CustomButton';
import { ReactComponent as SmallLogo } from "../../../images/svg/swiitcher_icon_blau.svg"
import { ReactComponent as BigLogo } from "../../../images/svg/swiitcher_logo_weiss.svg"
import { ReactSession } from 'react-client-session';
import useFetch from '../../UseFetch';
import { useHistory, useParams } from 'react-router-dom';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { isMobile } from 'react-device-detect';
import WestIcon from '@mui/icons-material/West';


export default function LoginAndRegistration(props) {
    // ? Variable's I have to use from old Code
    const { theme, notify, update, loginRef, getHistory, locationChange, location, goBackRoot } = props
    const history = useHistory()
    const [goBack, setGoBack] = React.useState(false);
    const [onLogin, setOnLogin] = React.useState(false);
    const [loginOverlay, setLoginOverlay] = React.useState(false);

    const { token } = useParams()

    const [userType, setUserType] = React.useState('Arbeitnehmer')
    const [isArbeitnehmer, setIsArbeitnehmer] = React.useState(ReactSession.get('login') ?? true);

    // ? variables for Login/Registration
    const [isLogin, setIsLogin] = React.useState(true)
    const [headingText, setHeadingText] = React.useState('Login')
    const [submitButtonText, setSubmitButtonText] = React.useState('LOGIN')
    const [switchButtonText, setSwitchButtonText] = React.useState('Registrieren')

    // ? Login & Registration Mail
    const [mailActive, setMailActive] = React.useState(false)
    const [mail, setMail] = React.useState('')
    const [mailError, setMailError] = React.useState(false)

    // ? Login & Registration Company-name
    const [companyNameActive, setCompanyNameActive] = React.useState(false)
    const [companyName, setCompanyName] = React.useState('')
    const [companyError, setCompanyError] = React.useState(false);

    // ? Login & registration password 1
    const [passwordActive, setPasswordActive] = React.useState(false)
    const [password, setPassword] = React.useState('')
    const [passwordError, setPasswordError] = React.useState('')
    const [showPasswordOne, setShowPasswordOne] = React.useState(false)
    const [onPasswordForget, setOnPasswordForget] = React.useState(false)

    // ? Login & Registration Password-repeat
    const [repeatPasswordActive, setRepeatPasswordActive] = React.useState(false)
    const [passwordRepeat, setPasswordRepeat] = React.useState('')
    const [passwordRepeatError, setPasswordRepeatError] = React.useState(false)
    const [showPasswordTwo, setShowPasswordTwo] = React.useState(false)
    const [passwordVerify, setPasswordVerify] = React.useState('');
    const [passwordVerifyError, setPasswordVerifyError] = React.useState(false);

    // ? Forgot-password
    const [isShrink, setIsShrink] = useState([]);
    const [isShrinkPasswordForget, setIsShrinkPasswordForget] = useState([]);
    const [isShrinkPassword1Reset, setIsShrinkPassword1Reset] = useState([]);
    const [isShrinkPassword2Reset, setIsShrinkPassword2Reset] = useState([]);
    const [mailPasswordForget, setMailPasswordForget] = React.useState('');
    const [mailPasswordForgetError, setMailPasswordForgetError] = React.useState(false);

    const [mailPassword1Reset, setMailPassword1Reset] = React.useState('');
    const [mailPassword1ResetError, setMailPassword1ResetError] = React.useState(false);

    const [mailPassword2Reset, setMailPassword2Reset] = React.useState('');
    const [mailPassword2ResetError, setMailPassword2ResetError] = React.useState(false);
    const [onPasswordReset, setOnPasswordReset] = React.useState(false);

    // ? Assign new Password


    const api = useFetch()

    useEffect(() => {
        console.log(goBackRoot)
        if (goBackRoot) setGoBack(true)
        if (history) {
            getHistory(history);
        }
    }, [history])

    useEffect(() => {
        if (location !== history.location.pathname) {
            if ((history.location.pathname != '/Arbeitgeber' && ReactSession.get('user') == 'Arbeitnehmer') ||
                (history.location.pathname == '/' && !ReactSession.get('user'))) {
                setIsArbeitnehmer(true);
            } else if ((ReactSession.get('user') == 'Arbeitgeber' || (history.location.pathname == '/Arbeitgeber' && !ReactSession.get('login')))) {
                setIsArbeitnehmer(false);
            }
        }
        if (location.token) {
            setOnPasswordReset(true);
        }
    }, [location]);

    const handleClickShowPasswordOne = () => {
        setShowPasswordOne(!showPasswordOne);
    }
    const handleClickShowPasswordTwo = () => {
        setShowPasswordTwo(!showPasswordTwo);
    }

    const handleSubmit = () => {
        isLogin ? login() : registerTest()
    }

    const handleSwitch = () => {
        switch (isLogin) {
            case true:
                setHeadingText('Registrierung')
                setSubmitButtonText('REGISTRIEREN')
                setSwitchButtonText('Log in')
                setIsLogin(false)
                break
            case false:
                setHeadingText('Login')
                setSubmitButtonText('LOGIN')
                setSwitchButtonText('Registrieren')
                setIsLogin(true)
        }
    }

    const handlePasswordForget = () => {
        setOnPasswordForget(!onPasswordForget)
    }

    const handlePasswordForgetModalClose = () => {
        setMailPasswordForget('');
        setMailPasswordForgetError(false);
        setOnPasswordForget(false);
    }


    const registerTest = () => {
        // Todo: validate
        // console.log('register')
        setMailError(false);
        setCompanyError(false);
        setPasswordError(false);
        setPasswordVerifyError(false);
        register(mail, userType, userType !== 'Arbeitgeber' ? null : companyName, password, passwordRepeat, true).then(async (e) => {

            // if (e.success) {
            //     await createQuestion(ReactSession.get('temp-auth-key'));
            // } else {
            //     setMailError(e.mError);
            //     setCompanyError(e.coError);
            //     setPasswordError(e.pError);
            //     setPasswordVerifyError(e.pVError);
            // }
        })
    }

    const register = async (mailT, accountTypeT, companyT, passwordT, passwordVerifyT, doNotify = true) => {
        // console.log('register');

        let ma = mailT ?? mail;
        let co = companyT ?? companyName;
        let at = accountTypeT ?? userType;
        let pa = passwordT ?? password;
        let paV = passwordVerifyT ?? passwordRepeat;
        let mailError = null;
        let companyError = null;
        let passwordError = null;
        let passwordVerifyError = null;
        let temp = false;
        let errorType = false;
        let error = false;


        if (pa == '') {
            error = true;
            setPasswordError('Passwort darf nicht leer sein!');
            passwordError = 'Passwort darf nicht leer sein!';
        } else {
            setPasswordError(undefined);
        }

        if (pa && pa.length < 8) {
            error = true;
            setPasswordError('Passwort muss mindestens 8 Zeichen lang sein');
            passwordError = 'Passwort muss mindestens 8 Zeichen lang sein';
        } else {
            setPasswordError(undefined);
        }

        if (accountTypeT === 'Arbeitgeber' && (!companyT && company.length === 0) || !accountTypeT) {
            error = true;
            setCompanyError('Firmenname darf nicht leer sein!')
            companyError = 'Firmenname darf nicht leer sein!';
        } else {
            setCompanyError(undefined);
        }

        if (ma == '' || !accountTypeT) {
            error = true;
            setMailError('E-Mail darf nicht leer sein!');
            mailError = 'E-Mail darf nicht leer sein!';
        } else if (ma && !validateEmail(ma) || !accountTypeT) {
            error = true;
            setMailError('Keine gültige E-Mail!');
            mailError = 'Keine gültige E-Mail!';
        } else {
            setMailError(undefined);
        }

        if (paV == '' || !accountTypeT) {
            error = true;
            setPasswordVerifyError('Passwort darf nicht leer sein!');
            passwordVerifyError = 'Passwort darf nicht leer sein!';
        } else {
            setPasswordVerifyError(undefined);
        }

        if (pa !== pa.trim() || !accountTypeT) {
            error = true;
            setPasswordError('Passwörter enthalten Leerzeichen!');
            setPasswordVerifyError('Passwörter enthalten Leerzeichen!');
            passwordError = 'Passwörter enthalten Leerzeichen!';
            passwordVerifyError = 'Passwörter enthalten Leerzeichen!';
        } else if (pa.trim() !== paV.trim() || !accountTypeT) {
            error = true;
            setPasswordError('Passwörter stimmen nicht überein!');
            setPasswordVerifyError('Passwörter stimmen nicht überein!');
            passwordError = 'Passwörter stimmen nicht überein!';
            passwordVerifyError = 'Passwörter stimmen nicht überein!';
        }

        if (!accountTypeT) {
            error = false;
            setMailError(false);
            setPasswordError(false);
        }

        if (
            !error
        ) {
            let url = ENV.REACT_APP_API_URL + '/user/register';

            let dataa = {
                data: {
                    attributes: {
                        email: ma.trim(),
                        companyName: (co && co.length > 0) ? co.trim() : null,
                        userType: at,
                        password: pa.trim(),
                    }
                }
            };
            if (!accountTypeT || accountTypeT == 'Arbeitnehmer') {
                dataa = {
                    data: {
                        attributes: {
                            email: ma.trim(),
                            password: pa.trim(),
                            userType: 'Arbeitnehmer'
                        }
                    }
                };
            }
            const { response, data } = await api(url, 'POST', JSON.stringify(dataa));

            if (data && data.errors && data.errors[0] && data.errors[0].status == '401') {
                // console.log('wrong credentials');
            } else if (data && data.errors && data.errors[0] && data.errors[0].meta && data.errors[0].meta.email) {
                error = data.errors[0].meta.email;
                errorType = 'mail';
                setMailError(data.errors[0].meta.email);
                mailError = data.errors[0].meta.email;
                // setMailError('E-Mail darf nicht leer sein!');
            } else if (data && data.errors && data.errors[0] && data.errors[0].meta && data.errors[0].meta.password) {
                error = data.errors[0].meta.password;
                errorType = 'password';
                setPasswordError(data.errors[0].meta.password);
                passwordError = data.errors[0].meta.password;
            } else if (data && data.errors && data.errors[0] && data.errors[0].meta && data.errors[0].meta.username) {
                error = data.errors[0].meta.username;
                errorType = 'mail';
                setMailError(data.errors[0].meta.username);
                mailError = data.errors[0].meta.username;
            } else if (data && data.errors && data.errors[0] && data.errors[0].meta && data.errors[0].meta.companyName) {
                error = data.errors[0].meta.companyName;
                errorType = 'companyName';
                setCompanyError(data.errors[0].meta.companyName);
                companyError = data.errors[0].meta.companyName;
            } else if (response.error) {
                notify('API Fehler: ' + response.status, true);
            } else if (data &&
                data.errors &&
                data.errors[0] &&
                data.errors[0].status == '400'
            ) {
                // console.log('bad');
            } else if ((data.errors && data.errors[0]) || response.status == '500') {
                notify('API Fehler: ' + response.status, true);
            } else if (data.data && data.data.id) {
                // console.log('right credentials');
                setMailError('');
                setPasswordError('');
                temp = true;
                ReactSession.set('temp-auth-key', data.data.attributes.auth_key ?? data.data.auth_key)
                if (doNotify) notify('Registrierung erfolgreich - Dir wurde eine E-Mail zugesendet. Bitte klicke auf den Link in der E-Mail um die Registrierung abzuschließen');
                handleSwitch()
            }
        } else {
            // console.log('KAPUTT');
        }

        if (mailT !== undefined && passwordT !== undefined && passwordVerifyT !== undefined) {
            return {
                mError: mailError,
                coError: companyError,
                pError: passwordError,
                pVError: passwordVerifyError,
                success: temp,
                error,
                errorType
            }
        }
    };

    const login = async (mailT = null, passwordT = null, redirect = true) => {
        // console.log('login');
        setMailError(false);
        setPasswordError(false);
        if (validate(mailT, passwordT)) {
            // setOnLogin(true); ? variable is not use ?

            let ma = mailT ?? mail;
            let pass = passwordT ?? password;

            let url = ENV.REACT_APP_API_URL + '/user/login';
            let { response, data } = await api(url, 'POST',
                JSON.stringify({
                    email: ma.trim(),
                    password: pass.trim()
                })).catch((error) => {
                // console.log(error);
                return {
                    response: {
                        status: error.message,
                        error: true
                    },
                    data: null
                }
            });
            let temp = false;

            if (response && data) {

                if (data.errors && data.errors[0] && data.errors[0].status == '403' && data.errors[0].detail == "User does not exist") {
                    setMailError('Benutzer existiert nicht');
                } else if (data.errors && data.errors[0] && data.errors[0].status == '403' && data.errors[0].detail == "User password is wrong") {
                    setPasswordError('Passwort ist fehlerhaft');
                } else if (data.errors && data.errors[0] && data.errors[0].status == '403' && data.errors[0].detail == "User not confirmed") {
                    setMailError((
                        <span style={{ marginLeft: '18px' }}>Benutzer ist nicht bestätigt - <Link style={{ cursor: 'pointer', color: '#C7D300' }}
                                                                                                  onClick={() => {
                                                                                                      resendRegistration();
                                                                                                  }}>erneut versenden</Link></span>));
                } else if (data.errors && data.errors[0]) {
                    notify('API Fehler: ' + response.status, true);
                } else if (data.data && data.data.token) {
                    // ? Arbeitnehmer test with old AG part -> comment out if userType and uncomment if (false)
                    if (data.data.user.userType == 'Arbeitnehmer') {
                    // if (false){
                        localStorage.setItem('authKey', data.data.token);
                        localStorage.setItem('userId', data.data.user.id);
                        localStorage.setItem('refreshToken', data.data.refreshToken);
                        window.location.replace('/arbeitnehmer');
                    }else {
                        // console.log('right credentials');
                        ReactSession.set('login', mail);
                        ReactSession.set('description', data.data.user.description);
                        ReactSession.set('token', data.data.token);
                        ReactSession.set('refreshToken', data.data.refreshToken);
                        ReactSession.set('userId', data.data.user.id);
                        // console.log(data.data.user.userType);
                        ReactSession.set('user', data.data.user.userType);
                        ReactSession.set('userCreatedAt', data.data.user.createdAt);
                        if (data.data.jobAdvertisementContingent) ReactSession.set('jobAdvertisementContingent', data.data.jobAdvertisementContingent);
                        ReactSession.set('file_id', data.data.user.file_id);
                        ReactSession.set('username', data.data.user.username);
                        temp = true;

                        if (data.data.user.userType == 'Arbeitgeber') {
                            setIsArbeitnehmer(false);
                            //setUser('Arbeitgeber');
                            update('Arbeitgeber');
                        } else {
                            setIsArbeitnehmer(true);
                            //setUser('Arbeitnehmer');
                            update('Arbeitnehmer');
                        }


                        notify('Du wurdest erfolgreich eingeloggt');
                        setLoginOverlay(false);
                        setPassword('');
                        setPasswordError(false);
                        if (goBack) {
                            setGoBack(false);
                            history.goBack();
                        } else if (redirect) {

                            history.push('/übersicht')
                        }
                    }

                }

                if (!mailT && !passwordT && false) {
                } else {
                    return { mError: mailError, pError: passwordError, user: data.data?.user, success: temp }
                }

            } else if (response.error) {
                notify('API Fehler: ' + response.status, true);
            }
            setOnLogin(false);


        } else {
            let mError = '';
            let pError = '';
            if (mailT === '') {
                mError = 'E-Mail darf nicht leer sein!';
            }
            if (!passwordT || passwordT !== passwordT.trim()) {
                pError = 'Passwort enthält Leerzeichen!';
            }
            if (passwordT === '') {
                pError = 'Passwort darf nicht leer sein!';
            }

            return { mError, pError, success: false }
        }

    };

    const validateEmail = email => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            );
    };

    const validate = (mailT, passwordT) => {

        let ma = mailT ?? mail;
        let pass = passwordT ?? password;

        let error = false;
        if (ma === '') {
            error = true;
            setMailError('E-Mail darf nicht leer sein!');
        } else {
            setMailError(undefined);
        }
        if (pass === '') {
            error = true;
            setPasswordError('Passwort darf nicht leer sein!');
        } else {
            setPasswordError(undefined);
        }
        if (!pass || pass !== pass.trim()) {
            error = true;
            setPasswordError('Passwort enthält Leerzeichen!');
        }
        return !error;
    };

    const resendRegistration = async () => {
        // console.log('resendRegistration');

        let ma = mail;
        let pa = password;

        let mailError = null;
        let companyError = null;
        let passwordError = null;
        let passwordVerifyError = null;
        let temp = false;
        let errorType = false;
        let error = false;


        if (pa === '') {
            // error = true;
            setPasswordError('Passwort darf nicht leer sein!');
            // passwordError = 'Passwort darf nicht leer sein!';
        } else {
            setPasswordError(undefined);
        }

        if (pa && pa.length < 8) {
            // error = true;
            setPasswordError('Passwort muss mindestens 8 Zeichen lang sein');
            // passwordError = 'Passwort muss mindestens 8 Zeichen lang sein';
        } else {
            setPasswordError(undefined);
        }

        if (ma == '') {
            error = true;
            setMailError('E-Mail darf nicht leer sein!');
            mailError = 'E-Mail darf nicht leer sein!';
        } else if (ma && !validateEmail(ma)) {
            error = true;
            setMailError('Keine gültige E-Mail!');
            mailError = 'Keine gültige E-Mail!';
        } else {
            setMailError(undefined);
        }

        if (pa !== pa.trim()) {
            error = true;
            setPasswordError('Passwörter enthalten Leerzeichen!');
            setPasswordRepeatError('Passwörter enthalten Leerzeichen!');
            passwordError = 'Passwörter enthalten Leerzeichen!';
            passwordVerifyError = 'Passwörter enthalten Leerzeichen!';
        }

        error = false;
        setMailError(false);
        setPasswordError(false);

        if (
            !error
        ) {
            let url = ENV.REACT_APP_API_URL + '/user/register';
            let dataa = {
                data: {
                    attributes: {
                        email: ma.trim(),
                        password: pa.trim()
                    }
                }
            };
            const { response, data } = await api(url, 'POST', JSON.stringify(dataa));

            if (data && data.errors && data.errors[0] && data.errors[0].status == '401') {
                // console.log('wrong credentials');
            } else if (data && data.errors && data.errors[0] && data.errors[0].meta && data.errors[0].meta.email) {
                error = data.errors[0].meta.email;
                errorType = 'mail';
                setMailError(data.errors[0].meta.email);
                mailError = data.errors[0].meta.email;
                // setMailError('E-Mail darf nicht leer sein!');
            } else if (data && data.errors && data.errors[0] && data.errors[0].meta && data.errors[0].meta.password) {
                error = data.errors[0].meta.password;
                errorType = 'password';
                setPasswordError(data.errors[0].meta.password);
                passwordError = data.errors[0].meta.password;
            } else if (data && data.errors && data.errors[0] && data.errors[0].meta && data.errors[0].meta.username) {
                error = data.errors[0].meta.username;
                errorType = 'mail';
                setMailError(data.errors[0].meta.username);
                mailError = data.errors[0].meta.username;
            } else if (data && data.errors && data.errors[0] && data.errors[0].meta && data.errors[0].meta.companyName) {
                error = data.errors[0].meta.companyName;
                errorType = 'companyName';
                setCompanyError(data.errors[0].meta.companyName);
                companyError = data.errors[0].meta.companyName;
            } else if (response.error) {
                notify('API Fehler: ' + response.status, true);
            } else if (data &&
                data.errors &&
                data.errors[0] &&
                data.errors[0].status == '400'
            ) {
                // console.log('bad');
            } else if ((data.errors && data.errors[0]) || response.status == '500') {
                notify('API Fehler: ' + response.status, true);
            } else if (data.data && data.data.id) {
                // console.log('right credentials');
                setMailError('');
                setPasswordError('');
                temp = true;
                ReactSession.set('temp-auth-key', data.data.attributes.auth_key ?? data.data.auth_key)
                notify('Registrierung erfolgreich - Dir wurde eine E-Mail zugesendet. Bitte klicke auf den Link in der E-Mail um die Registrierung abzuschließen');
            }
        } else {
            // console.log('KAPUTT');
        }


    };

    const requestNewPassword = async () => {

        if (validatePasswortForget()) {
            let updatedAttributes = {
                email: mailPasswordForget.trim()
            }

            let url = ENV.REACT_APP_API_URL + '/user/password-forgotten';
            const { response, data } = await api(url, 'POST', JSON.stringify(updatedAttributes));

            if (response && !data.errors) {
                ReactSession.set('userId', undefined);
                ReactSession.set('token', undefined);
                ReactSession.set('refreshToken', undefined);
                //history.push('/');
                setOnPasswordForget(false);
                notify('Dir wurde eine E-Mail zugesendet. Bitte klicke auf den Link in der E-Mail um dein Passwort zu ändern');
            } else if (data && data.errors && data.errors[0] && data.errors[0].status == 403) {
                setMailPasswordForgetError("Unbekannte E-Mail-Adresse");
            } else if (data && data.errors && data.errors[0] && data.errors[0].status == 400) {
                setMailPasswordForgetError("Fehlerhafte E-Mail-Adresse");
            } else if ((data && data.errors && data.errors[0]) || response.error) {
                notify('API Fehler: ' + response.status, true);
            }
        }

    };

    const updatePassword = async () => {

        if (validateNewPassword() && token) {

            let updatedAttributes = {
                password: mailPassword1Reset,
                token: token
            };

            let url = ENV.REACT_APP_API_URL + '/user/new-password';
            const { response, data } = await api(url, 'POST', JSON.stringify(updatedAttributes));

            if (response && response.status != '401' && data) {
                ReactSession.set('newPassword', undefined);
                ReactSession.set('token', undefined);
                ReactSession.set('refreshToken', undefined);
                setOnPasswordReset(false);
                notify('Dein Passwort wurde erfolgreich aktualisiert');
            } else if ((data.errors && data.errors[0]) && response.status == '401') {
                notify('Dieser Token ist nicht mehr gültig', true);
            } else if ((data.errors && data.errors[0]) || response.status == '500') {
                notify('API Fehler: ' + response.status, true);
            }
        }
    };

    const validateNewPassword = () => {
        let error = false;


        let lowerCaseLetters = /[a-z]/g;
        if (!mailPassword1Reset.match(lowerCaseLetters)) {
            error = 'Passwort enthält keinen Kleinbuchstaben';
        }

        // Validate capital letters
        let upperCaseLetters = /[A-Z]/g;
        if (!mailPassword1Reset.match(upperCaseLetters)) {
            error = 'Passwort enthält keinen Großbuchstaben';
        }

        // Validate numbers
        let numbers = /[0-9]/g;
        if (!mailPassword1Reset.match(numbers)) {
            error = 'Passwort enthält keine Zahl';
        }

        // Validate special chars
        let chars = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (!mailPassword1Reset.match(chars)) {
            error = 'Passwort enthält kein Sonderzeichen';
        }

        // Validate length
        if (mailPassword1Reset.length < 8) {
            // console.log('to short')
            error = 'Passwort muss mindestens 8 Zeichen lang sein'
        }

        // Validate length
        if (mailPassword1Reset.length === '') {
            error = 'Passwort darf nicht leer sein!'
        }

        if (mailPassword1Reset.trim() !== mailPassword2Reset.trim()) {
            error = true;
            setMailPassword1ResetError('Passwörter stimmen nicht überein!');
            setMailPassword2ResetError('Passwörter stimmen nicht überein!');
        } else {
            setMailPassword1ResetError(error)
            setMailPassword2ResetError(undefined)
        }

        if (!error) {
            setMailPassword1ResetError(undefined);
            setMailPassword2ResetError(undefined);
        }

        return !error;

    };


    const validatePasswortForget = (send = false) => {
        let error = false;
        if (mailPasswordForget === '' || (send && !mailPasswordForget)) {
            setMailPasswordForgetError('E-Mail darf nicht leer sein!');
            error = true;
        } else if (!validateEmail(mailPasswordForget)) {
            setMailPasswordForgetError('Fehlerhafte E-Mail-Adresse');
            error = true;
        } else {
            setMailPasswordForgetError(undefined);
        }

        return !error;

    };

    useEffect(() => {

        if (token) {
            setOnPasswordReset(true);
        }
    }, [location]);

    const handleKeyPress = (key) => {
        if (key.keyCode === 13){
            isLogin? login() : registerTest()
        }
    }

    return (
        <div className={'login-parent'}>
            {isMobile && <WestIcon
                className={'arrow-back-button-login-mobile'}
                onClick={() => window.location.href = 'https://www.swiitcher.de/'}
            />}
            {!isMobile && <WestIcon
                className={'arrow-back-button-login-desktop'}
                onClick={() => window.location.href = 'https://www.swiitcher.de/'}

            />}
            <div style={{
                marginTop: 50
            }}>
                {/*{isMobile && <SmallLogo*/}
                {/*    className={'questions-logo-small'}*/}
                {/*    onClick={() => window.location.href = 'https://www.swiitcher.de/'}*/}
                {/*/>}*/}

                {!isMobile && <BigLogo
                    className={'login-and-registration-logo-big'}
                    id={'logoHeaderWhite'}
                    onClick={() => window.location.href = 'https://www.swiitcher.de/'}
                />}
            </div>

            {/* ? Registration & Login-form*/}
            <Container maxWidth="sm"
                       className="login-registration-container"
            >

                <div
                    style={{
                        border: !isMobile ? '3px solid white' : 'none',
                        borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                        padding: '15px 40px 50px',
                    }}
                >
                    <p className="login-heading">{headingText}</p>

                    <div className="input-container"
                         onKeyUp={(e) => handleKeyPress(e) }
                    >
                        {!isLogin &&
                            <div className={'select-type-container'}>
                                <label className={'select-type-label'}>Typ*</label>
                                <select defaultValue={userType} className={'select-type-input'}
                                        onChange={(e) => setUserType(e.target.value)}
                                >
                                    <option value={'Arbeitnehmer'}>Arbeitnehmer</option>
                                    <option value={'Arbeitgeber'}>Arbeitgeber</option>
                                </select>
                            </div>
                        }

                        {!isLogin && (userType === 'Arbeitgeber') &&
                            <CssTextField3
                                label="Firmenname *"
                                className="input"
                                style={{ marginTop: companyNameActive || (companyName !== '') ? 20 : 5 }}
                                onFocus={() => setCompanyNameActive(true)}
                                onBlur={() => setCompanyNameActive(false)}
                                type="text"
                                value={companyName}
                                onChange={(e) => setCompanyName(e.target.value)}
                            />
                        }

                        <CssTextField3
                            label="E-Mail *"
                            className="input"
                            style={{ marginTop: mailActive || (mail !== '') ? 20 : 5 }}
                            onFocus={() => setMailActive(true)}
                            onBlur={() => setMailActive(false)}
                            type="text"
                            value={mail}
                            onChange={(e) => setMail(e.target.value.replace(/ /g, ''))}
                            helperText={mailError === true ? 'E-Mail darf nicht leer sein!' : mailError}
                        />

                        <CssTextField3
                            label="Passwort *"
                            className="input"
                            style={{ marginTop: passwordActive || (password !== '') ? 20 : 5 }}
                            onFocus={() => setPasswordActive(true)}
                            onBlur={() => setPasswordActive(false)}
                            type={showPasswordOne ? 'text' : 'password'}
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            helperText={passwordError === true ? 'Passwort darf nicht leer sein!' : passwordError}
                            InputProps={{ // <-- ? This is where the toggle button is added.
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPasswordOne}
                                            tabIndex={-1}
                                        >
                                            {showPasswordOne ? <Visibility style={{ color: '#C7D300' }}/> :
                                                <VisibilityOff style={{ color: '#C7D300' }}/>}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />

                        {!isLogin &&
                            <CssTextField3
                                label="Passwort wiederholen *"
                                className="input"
                                style={{ marginTop: repeatPasswordActive || (passwordRepeat !== '') ? 20 : 5 }}
                                onFocus={() => setRepeatPasswordActive(true)}
                                onBlur={() => setRepeatPasswordActive(false)}
                                type={showPasswordTwo ? 'text' : 'password'}
                                value={passwordRepeat}
                                helperText={passwordRepeatError === true ? 'Passwort darf nicht leer sein!' : passwordRepeatError}
                                onChange={(e) => setPasswordRepeat(e.target.value)}
                                InputProps={{ // <-- ? This is where the toggle button is added.
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPasswordTwo}
                                                tabIndex={-1}
                                            >
                                                {showPasswordTwo ? <Visibility style={{ color: '#C7D300' }}/> :
                                                    <VisibilityOff style={{ color: '#C7D300' }}/>}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        }

                        <button className={'fadeInOut submit-button'}
                                style={{ borderRadius: ENV.REACT_APP_BORDER_RADIUS }}
                                onClick={handleSubmit}
                        >
                            {submitButtonText}
                        </button>
                        <div className="submit-info">
                            * Pflichtfelder
                        </div>
                        <div className="info-container">
                            {/*Todo: forgot-password*/}
                            <a className={'forgot-password'}
                               onClick={handlePasswordForget}
                            >
                                Passwort vergessen?
                            </a>
                            <div className="requiredInfo">
                                <CustomButton
                                    className="switch-login-registration-button"
                                    buttontype={'gray'}
                                    onClick={handleSwitch}
                                >
                                    {switchButtonText}
                                </CustomButton>
                            </div>
                        </div>

                    </div>
                </div>


            </Container>


            {/* ? Forgot-password Modal*/}
            <Modal
                open={onPasswordForget}
                onClose={() => handlePasswordForgetModalClose()}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className={'forgot-password-modal'}>
                    <h3 style={{ marginTop: 0, marginBottom: 30, fontFamily: 'VagRoundedBold' }}>Passwort
                        vergessen<QuestionMarkIcon
                            style={{
                                color: '#92D050',
                                marginBottom: -5,
                                width: 40,
                                height: 40,
                            }}
                        /></h3>

                    <p style={{ fontSize: 12 }}>Gib hier deine E-Mail-Adresse ein. Du erhälst von uns umgehend eine Mail,
                        mit der du dein Passwort zurücksetzen kannst.</p>

                    <CssTextField3
                        label="E-Mail-Adresse"
                        style={{
                            transition: 'all 0.2s ease',
                            marginTop: (isShrinkPasswordForget && isShrinkPasswordForget.includes('E-Mail') || mailPasswordForget) ? 20 : 5,
                            width: '100%'
                        }}
                        onFocus={() => {
                            let t = [...isShrinkPasswordForget];
                            t.push('E-Mail');
                            setIsShrinkPasswordForget(t);
                        }}
                        onBlur={() => {
                            setIsShrinkPasswordForget([]);
                        }}
                        autoComplete={'off'}
                        onChange={e => {
                            if (e.target.value.trim().length === 0) {
                                setMailPasswordForgetError(true);
                            } else {
                                setMailPasswordForgetError(false);
                            }
                            setMailPasswordForget(e.target.value);
                        }}
                        className={'label-black'}
                        value={mailPasswordForget}
                        error={!!mailPasswordForgetError}
                        helperText={mailPasswordForgetError}
                    />
                    <CustomButton
                        buttontype={'yellowBlue'}
                        onClick={() => {
                            requestNewPassword();
                        }}
                        style={{
                            marginTop: 20,
                            width: '100%'
                        }}
                    >Senden</CustomButton>
                    <p style={{ fontSize: 10 }}>E-Mail-Adresse vergessen? Dann schreib uns gerne eine Nachricht an <a
                        href={'mailto:info@swiitcher.de'}>info@swiitcher.de</a></p>
                </Box>
            </Modal>


            {/* ? Assign new Password-modal*/}
            <Modal
                open={onPasswordReset}
                onClose={() => {
                    setMailPassword1Reset('');
                    setMailPassword1ResetError(false);
                    setMailPassword2Reset('');
                    setMailPassword2ResetError(false);
                    setOnPasswordReset(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className={'forgot-password-modal'}>
                    <h3 style={{ marginTop: 0, marginBottom: 30, fontFamily: 'VagRoundedBold' }}>Neues Passwort
                        vergeben<PriorityHigh
                            style={{
                                color: '#92D050',
                                marginBottom: -5,
                                width: 40,
                                height: 40,
                            }}
                        /></h3>

                    <p style={{ fontSize: 12 }}>Dein Passwort muss min. 8 Zeichen lang sein, einen Groß- und einen
                        Kleinbuchstaben sowie ein Sonderzeichen bzw. eine Zahl enthalten. Es muss sich von deinem alten
                        Passwort unterscheiden.</p>

                    <CssTextField3
                        label="Passwort"
                        // InputLabelProps={{
                        //     shrink: true
                        // }}
                        // className={'text-input-label-blue'}
                        style={{
                            transition: 'all 0.2s ease',
                            // margin: '0 0 8px 0',
                            // minHeight: 60,
                            marginTop: (isShrinkPassword1Reset && isShrinkPassword1Reset.includes('E-Mail') || mailPassword1Reset) ? 20 : 5,
                            width: '100%'
                        }}
                        autoFocus
                        type={showPasswordOne ? "text" : "password"}
                        InputProps={{ // <-- This is where the toggle button is added.
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPasswordOne}
                                    >
                                        {showPasswordOne ? <Visibility style={{ color: '#C7D300' }}/> :
                                            <VisibilityOff style={{ color: '#C7D300' }}/>}
                                    </IconButton>
                                </InputAdornment>
                            ),
                            autocomplete: 'new-password',
                            form: {
                                autocomplete: 'off',
                            },
                        }}
                        onFocus={() => {
                            let t = [...isShrinkPassword1Reset];
                            t.push('E-Mail');
                            setIsShrinkPassword1Reset(t);
                        }}
                        onBlur={() => {
                            setIsShrinkPassword1Reset([]);
                        }}
                        autoComplete="new-password"
                        onChange={e => {
                            if (e.target.value.trim().length === 0) {
                                setMailPassword1ResetError(true);
                            } else {
                                setMailPassword1ResetError(false);
                            }
                            setMailPassword1Reset(e.target.value);
                        }}
                        className={'label-black'}
                        // onKeyPress={handleKeypress}
                        value={mailPassword1Reset}
                        error={!!mailPassword1ResetError}
                        helperText={mailPassword1ResetError}
                    />
                    <CssTextField3
                        label="Passwort wiederholen"
                        // InputLabelProps={{
                        //     shrink: true
                        // }}
                        // className={'text-input-label-blue'}
                        style={{
                            transition: 'all 0.2s ease',
                            // margin: '0 0 8px 0',
                            // minHeight: 60,
                            marginTop: (isShrinkPassword2Reset && isShrinkPassword2Reset.includes('E-Mail') || mailPassword2Reset) ? 20 : 5,
                            width: '100%'
                        }}
                        type={showPasswordTwo ? "text" : "password"}
                        InputProps={{ // <-- This is where the toggle button is added.
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPasswordTwo}
                                    >
                                        {showPasswordTwo ? <Visibility style={{ color: '#C7D300' }}/> :
                                            <VisibilityOff style={{ color: '#C7D300' }}/>}
                                    </IconButton>
                                </InputAdornment>
                            ),
                            autocomplete: 'new-password',
                            form: {
                                autocomplete: 'off',
                            },
                        }}
                        onFocus={() => {
                            let t = [...isShrinkPassword2Reset];
                            t.push('E-Mail');
                            setIsShrinkPassword2Reset(t);
                        }}
                        onBlur={() => {
                            setIsShrinkPassword2Reset([]);
                        }}
                        autoComplete="new-password"
                        onChange={e => {
                            if (e.target.value.trim().length === 0) {
                                setMailPassword2ResetError(true);
                            } else {
                                setMailPassword2ResetError(false);
                            }
                            setMailPassword2Reset(e.target.value);
                        }}
                        className={'label-black'}
                        // onKeyPress={handleKeypress}
                        value={mailPassword2Reset}
                        error={!!mailPassword2ResetError}
                        helperText={mailPassword2ResetError}
                    />
                    <CustomButton
                        buttontype={'yellowBlue'}
                        onClick={() => {
                            updatePassword();
                        }}
                        style={{
                            marginTop: 20,
                            width: '100%'
                        }}
                    >Speichern</CustomButton>
                </Box>
            </Modal>
        </div>

    )
}
