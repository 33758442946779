import React, {useEffect, useRef} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {Grid, Tooltip} from "@mui/material";
import {useState} from "react";
import {ReactComponent as LogoSvgGefunden} from "../images/svg/auge.svg";
import {ReactComponent as LogoSvgPuzzle} from "../images/svg/puzzle.svg";
import {ReactComponent as LogoSvgHeart} from "../images/svg/heart.svg";
import {ReactSession} from "react-client-session";
import useFetch from "./UseFetch";
import {useHistory} from "react-router";
import moment from "moment";
import {isMobile} from "react-device-detect";
import {ReactComponent as LogoSvgSwiitcherIcon} from "../images/svg/swiitcher_icon.svg";
import useWindowDimensions from "./useWindowDimensions";
import {CustomButton} from "./CustomButton";
import InfoIcon from "@mui/icons-material/Info";

export default function ÜbersichtArbeitnehmer(props) {

    const [activeMatchItem, setActiveMatchItem] = useState(null);
    const [matchesLoading, setMatchesLoading] = useState(true);
    const [chatLoading, setChatLoading] = useState(true);
    const [advertisementsLoading, setAdvertisementsLoading] = useState(true);
    const [userLoading, setUserLoading] = useState(true);
    const [matchesFound, setMatchesFound] = useState(0);
    const [chatModels, setChatModels] = useState([]);
    const [chatMessages, setChatMessages] = useState([]);
    const [advertisements, setAdvertisements] = useState([]);
    const [description, setDescription] = useState(false);
    const [matches, setMatches] = useState(0);
    const [progress, setProgress] = useState(90);
    const [anonymesProfil, setAnonymesProfil] = useState(null);
    const [questions, setQuestions] = useState([]);
    const [helperText, setHelperText] = useState(null);
    const [icon, setIcon] = useState(null);
    const api = useFetch();
    const history = useHistory();

    useEffect(() => {
        getMatchingOverview();
        getChat();
        getUser();
        getAnsweredQuestions();
    }, [])

    const getMatchingOverview = async () => {
        let url = ENV.REACT_APP_API_URL + '/matchings/count/matching-overview';

        //if (this.state.sort) url += '&sort=' + this.state.sort;

        let {response, data} = await api(url)


        if (!response || response.status == 401) {
            ReactSession.set('token', undefined);
            ReactSession.set('refreshToken', undefined);
            return;
        }
        console.log(data);
        if (data && data.data) {
            setMatchesFound(data.data.matching_count_found);
            setMatches(data.data.matching_count);
            setMatchesLoading(false);
        }
    }

    const getChat = async () => {
        let url = ENV.REACT_APP_API_URL + '/matchings/count/chat-overview';
        let {response, data} = await api(url)


        if (!response || response.status == 401) {
            ReactSession.set('token', undefined);
            ReactSession.set('refreshToken', undefined);
            return;
        }
        if (data && data.data) {
            setChatModels(data.data.new_chat_models)
            setChatMessages(data.data.new_chat_messages)
            setChatLoading(false);
        }
    }

    const getUser = async () => {
        let url = ENV.REACT_APP_API_URL + '/user/' + ReactSession.get('userId');

        let {response, data} = await api(url)


        if (!response || response.status == 401) {
            ReactSession.set('token', undefined);
            ReactSession.set('refreshToken', undefined);
            history.push('/');
            return;
        }
        if (data && data.data) {
            if (data.data.attributes.description && data.data.attributes.description.length > 0) {
                setProgress(100);
            } else {
                setProgress(90);
            }


        } else if (response.error) {
            //notify('API Fehler: ' + response.status, true);
        }
    }

    const {width} = useWindowDimensions();

    const truncate = (str, n) => {
        return (str.length > n) ? str.slice(0, n - 1) + '...' : str;
    };

    const getAnsweredQuestions = async () => {
        let answeredQuestionsTemp = [];
        let temp = '';
        let items = [];

        let url = ENV.REACT_APP_API_URL + '/answered-questions/for/matching';


        let postData = {
            userId: ReactSession.get('userId'),
        }


        let tempData = await api(url, 'POST', JSON.stringify(postData))
        let response = tempData.response;
        let data = tempData.data;

        if (!response || response.status == 401) {
            ReactSession.set('token', undefined);
            ReactSession.set('refreshToken', undefined);
        }

        if (data && data.data) {
            data.data.map((item, index) => {
                answeredQuestionsTemp.push(item);
            });
        } else if (response.error) {
            // notify('API Fehler: ' + response.status, true);
        }

        answeredQuestionsTemp.sort((a, b) => (a.question_id == 1) ? -1 : 1);

        let answersAN2 = [];
        let answersAN3 = [];
        answeredQuestionsTemp.filter(i => i.question_id == 2).map((item, index) => {
            item.answers.answerAN.map((item, index) => {
                if (item != 'Nein' && !answersAN2.includes(item) && !answersAN3.includes(item)) answersAN2.push(item);
            })

        })

        answeredQuestionsTemp.filter(i => i.question_id != 2 && (i.question_id == 3)).map((item, index) => {
            item.answers.answerAN.map((item, index) => {
                if (item != 'Nein' && !answersAN2.includes(item) && !answersAN3.includes(item)) answersAN3.push(item);
            })
        })

        // answersAN2.push(...answersAN3);
        // rightAnswers2.push(...rightAnswers3);
        let newItem = {
            text: 'Aktueller Status:',
            answersAN: answersAN2,
            answersAN3: answersAN3,
            question_id: 2
        };

        items.push(newItem);

        let answersAN = [];
        let rightAnswers = [];

        answeredQuestionsTemp.filter(i => i.question_id == 36 || i.question_id == 48).map((item, index) => {
            item.answers.answerAN.map((item, index) => {
                if (item != 'Nein' && !answersAN.includes(item) && !answersAN.includes(item)) answersAN.push(item);
            })
        })

        newItem = {};
        newItem = {
            text: 'Konkreter Berufswunsch:',
            answersAN,
            rightAnswers,
            question_id: 36
        };

        items.push(newItem);

        newItem = {};
        answersAN = [];
        rightAnswers = [];
        let helperText = null;
        let icon = null;


        answeredQuestionsTemp.filter(i => i.question_id != 2 && i.question_id != 3 && i.question_id != 36 && i.question_id != 48).map((item, index) => {
            if (index == 0) {
                newItem = {};
                newItem.text = item.question;
                newItem.question_id = item.question_id;
                newItem.helperText = item.helperText;
                answersAN = [];

                item.answers.answerAN.map((item, index) => {
                    answersAN.push(item);
                    switch (item) {
                        case 'Denker':
                            helperText = 'Er/Sie hat viel Fantasie und kombiniert seinen/ihren enormen Wissensdurst mit ausgefeilter strategischer Planung. Intellektuelle Herausforderungen lassen sein/ihr Licht heller strahlen und er/sie findet immer einen Weg zum Ziel.';
                            icon = item;
                            break;
                        case 'Entdecker':
                            helperText = 'Ausprobieren, neu kombinieren und in unbekannte Welten vordringen: Mit ihm/ihr an der Seite werden überraschende Entdeckungen möglich. Er/Sie hat innovative Ideen und spannende Visionen und begeistert die Menschen um sich mit Forscherdrang und seinem/ihrem verspieltem Charme.';
                            icon = item;
                            break;
                        case 'Helfer':
                            helperText = 'Auf ihn/sie ist einfach immer Verlass, denn er/sie kümmert sich jederzeit um andere und hilft, wo er/sie kann. Dabei ist er/sie praktisch veranlagt und organisiert problemlos das alltägliche Chaos um sich herum.';
                            icon = item;
                            break;
                        case 'Diplomat':
                            helperText = 'Er/Sie glaubt fest an das Gute und verbreitet Zuversicht auf Schritt und Tritt. Mit seinem/ihrem freien und kreativen Geist inspiriert er/sie die Menschen um sich herum zu kühnen Taten. Er/Sie steht zu seinen/ihren Idealen und sprüht vor positiver Energie.';
                            icon = item;
                            break;
                    }
                })

                if (helperText && icon) {
                    setHelperText(helperText);
                    setIcon(icon);
                }

                newItem.answersAN = answersAN;
                items.push(newItem);
            } else {

            }

        })
        console.log(items);
        setQuestions(items);

        setAnonymesProfil(items.length > 0)
    }

    return (
        <Grid item xs={12} style={{
            display: 'block',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 64,
            marginBottom: activeMatchItem !== null ? 50 : 0,
            padding: '10px 30px 30px',
            minHeight: 'calc(100vh - 20px)',
            width: '100%',
        }}>
            <Grid container id={'top-container'} spacing={2}>
                <Grid item xs={isMobile ? 12 : 6}>
                        <div style={{height: 'calc(100vh/2 - 100px)', position: 'relative', padding: isMobile ? '20px 0 0 0' : 20,}}>
                            <p className={'headerName'}
                               style={{position: 'relative', width: 'fit-content', marginBottom: 10}}>
                                Matches
                            </p>
                            {matchesLoading ? <></> :<Grid container style={{paddingTop: 0}}>
                                <Grid item xs={6}>
                                    <div style={{padding: 0, position: 'relative'}}>
                                        <div style={{margin: '30px auto 10px', maxWidth: '100%', height: isMobile ? width / 4 - 50 : 'auto', width: 100, padding: 0, display: 'flex', alignItems: 'center', justifyContent: 'center'}} >
                                            <LogoSvgSwiitcherIcon style={{height: 50}} />
                                        </div>
                                        <div style={{fontSize: 36, fontFamily: 'VagRoundedBold', color: '#00374e'}}>{matches}</div>

                                        <p style={{fontSize: 20, marginTop: 5, fontFamily: 'VagRoundedBold', color: '#00374e'}}>Anfragen</p>
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <div style={{padding: 0, position: 'relative'}}>
                                        <div style={{margin: '30px auto 10px', maxWidth: '100%', height: isMobile ? width / 4 - 50 : 'auto', width: 100, padding: 0, display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                            <LogoSvgGefunden style={{height: 50}} className={'eyeIcon'}/>
                                        </div>
                                        <div style={{fontSize: 36, fontFamily: 'VagRoundedBold', color: '#00374e'}}>{matchesFound}</div>
                                        <p style={{fontSize: 20, marginTop: 5, fontFamily: 'VagRoundedBold', color: '#00374e'}}>Treffer</p>
                                    </div>
                                </Grid>
                            </Grid>}
                            <p className={'buttonLinkYellow'} onClick={() => history.push('/matches')}
                               style={{
                                   position: 'absolute',
                                   textAlign: 'right',
                                   width: 'calc(100% - 20px)',
                                   bottom: 0,
                                   fontSize: 12,
                                   textDecoration: 'underline',
                                   color: '#C7D300',
                                   cursor: 'pointer',
                                   transition: 'all 0.1s ease-in-out'
                               }}>alle Matches</p>
                        </div>
                </Grid>
                <Grid item xs={isMobile ? 12 : 6}>
                    <div style={{height: 'calc(100vh/2 - 100px)', position: 'relative', padding: isMobile ? 0 : 20,}}>
                        <p className={'headerName'}
                           style={{position: 'relative', width: 'fit-content', marginBottom: 10}}>
                            Chats & Nachrichten
                            {/*<span className={'count'} style={{top: -12, right: -41, height: 16, width: 16}}>5</span>*/}
                        </p>
                        {chatLoading ? <></> : <>
                            {chatMessages && chatMessages.map((item, index) => {
                                return (
                                    <div key={index} onClick={() => {
                                        history.push('/chat');
                                    }} style={{
                                        position: 'relative',
                                        height: 25,
                                        margin: '5px 15px 5px 15px',
                                        paddingTop: 2,
                                        background: 'rgba(199, 211, 0, 0.2)',
                                        cursor: 'pointer',
                                        borderRadius: 4,
                                        border: '1px solid rgba(221, 221, 221, 0.5)',
                                        overflow: 'hidden',
                                        padding: 10,
                                        color: 'white',
                                    }}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <p className={'headerName'} style={{
                                                    lineHeight: '26px',
                                                    fontSize: 16,
                                                    position: 'relative',
                                                    width: '100%'
                                                }}>
                                                    {truncate(item.title, 30)}
                                                    <span style={{
                                                        position: 'absolute',
                                                        right: 10,
                                                        textAlign: 'right',
                                                        fontFamily: 'VagRoundedBold',
                                                        fontWeight: 'bold',
                                                        marginLeft: 10,
                                                        lineHeight: '28px',
                                                        color: '#C7D300'
                                                    }}>neue ungelesene Nachricht</span>
                                                </p>
                                            </Grid>
                                        </Grid>
                                    </div>
                                );
                            })}
                            {chatModels && chatModels.map((item, index) => {
                                return (
                                    <div key={index} onClick={() => {
                                        history.push('/chat');
                                    }} style={{
                                        position: 'relative',
                                        height: 25,
                                        margin: '5px 15px 5px 15px',
                                        paddingTop: 2,
                                        background: 'rgba(199, 211, 0, 0.2)',
                                        cursor: 'pointer',
                                        borderRadius: 4,
                                        border: '1px solid rgba(221, 221, 221, 0.5)',
                                        overflow: 'hidden',
                                        padding: 10,
                                        color: 'white',
                                    }}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <p className={'headerName'} style={{
                                                    lineHeight: '26px',
                                                    fontSize: 16,
                                                    position: 'relative',
                                                    width: '100%'
                                                }}>
                                                    {truncate(item.title, 30)}
                                                    <span style={{
                                                        position: 'absolute',
                                                        right: 10,
                                                        textAlign: 'right',
                                                        fontFamily: 'VagRoundedBold',
                                                        fontWeight: 'bold',
                                                        marginLeft: 10,
                                                        lineHeight: '28px',
                                                        color: '#C7D300'
                                                    }}>neuer Chat</span>
                                                </p>
                                            </Grid>
                                        </Grid>
                                    </div>
                                );
                            })}
                        </>}
                        <p className={'buttonLinkYellow'} onClick={() => history.push('/chat')} style={{
                            position: 'absolute',
                            textAlign: 'right',
                            width: 'calc(100% - 20px)',
                            bottom: 0,
                            fontSize: 12,
                            textDecoration: 'underline',
                            color: '#C7D300',
                            cursor: 'pointer',
                            transition: 'all 0.1s ease-in-out'
                        }}>alle Nachrichten</p>
                    </div>
                </Grid>
                {!isMobile && <Grid item xs={6}>

                </Grid>}
                {/*<Grid item xs={isMobile ? 12 : 6}>*/}
                {/*    <div style={{*/}
                {/*        height: 'calc(100vh/2 - 100px)',*/}
                {/*        position: 'relative',*/}
                {/*        background: 'rgba(221, 221, 221, 0.5)',*/}
                {/*        padding: 20,*/}
                {/*        borderRadius: 40,*/}
                {/*    }}>*/}
                {/*        <p className={'headerName'}*/}
                {/*           style={{position: 'relative', width: 'fit-content', marginBottom: 10}}>*/}
                {/*            Profildaten*/}
                {/*        </p>*/}
                {/*        {user && <>*/}
                {/*            <Grid container style={{paddingLeft: 15, marginTop: 10}}>*/}
                {/*                <Grid item xs={3}>*/}
                {/*                    <p style={{textAlign: 'left', lineHeight: '26px', margin: 0}}*/}
                {/*                       className={'color-blue'}>Name</p>*/}
                {/*                </Grid>*/}
                {/*                <Grid item xs={9}>*/}
                {/*                    <p style={{textAlign: 'left', margin: 0, fontFamily: 'VagRoundedBold'}}*/}
                {/*                       className={'color-blue'}>{user.attributes.firstname} {user.attributes.surname}</p>*/}
                {/*                </Grid>*/}
                {/*            </Grid>*/}
                {/*            <Grid container style={{paddingLeft: 15}}>*/}
                {/*                <Grid item xs={3}>*/}
                {/*                    <p style={{textAlign: 'left', lineHeight: '26px', margin: 0}}*/}
                {/*                       className={'color-blue'}>Anschrift</p>*/}
                {/*                </Grid>*/}
                {/*                <Grid item xs={9}>*/}
                {/*                    <p style={{textAlign: 'left', margin: 0, fontFamily: 'VagRoundedBold'}}*/}
                {/*                       className={'color-blue'}>{user.attributes.street} {user.attributes.houseNumber}</p>*/}
                {/*                </Grid>*/}
                {/*            </Grid>*/}
                {/*            <Grid container style={{paddingLeft: 15}}>*/}
                {/*                <Grid item xs={3}>*/}
                {/*                </Grid>*/}
                {/*                <Grid item xs={9}>*/}
                {/*                    <p style={{textAlign: 'left', margin: 0, fontFamily: 'VagRoundedBold'}}*/}
                {/*                       className={'color-blue'}>{user.attributes.zipcode} {user.attributes.city}</p>*/}
                {/*                </Grid>*/}
                {/*            </Grid>*/}
                {/*            <Grid container style={{paddingLeft: 15}}>*/}
                {/*                <Grid item xs={3}>*/}
                {/*                    <p style={{textAlign: 'left', lineHeight: '26px', margin: 0}}*/}
                {/*                       className={'color-blue'}>Telefon</p>*/}
                {/*                </Grid>*/}
                {/*                <Grid item xs={9}>*/}
                {/*                    <p style={{textAlign: 'left', margin: 0, fontFamily: 'VagRoundedBold'}}*/}
                {/*                       className={'color-blue'}>{user.attributes.telephoneNumber}</p>*/}
                {/*                </Grid>*/}
                {/*            </Grid>*/}
                {/*            <Grid container style={{paddingLeft: 15}}>*/}
                {/*                <Grid item xs={3}>*/}
                {/*                    <p style={{textAlign: 'left', lineHeight: '26px', margin: 0}}*/}
                {/*                       className={'color-blue'}>E-Mail</p>*/}
                {/*                </Grid>*/}
                {/*                <Grid item xs={9}>*/}
                {/*                    <p style={{textAlign: 'left', margin: 0, fontFamily: 'VagRoundedBold'}}*/}
                {/*                       className={'color-blue'}>{user.attributes.email}</p>*/}
                {/*                </Grid>*/}
                {/*            </Grid>*/}
                {/*            <p style={{*/}
                {/*                position: 'absolute',*/}
                {/*                textAlign: 'left',*/}
                {/*                bottom: isMobile && progress < 100 ? 50 : 25,*/}
                {/*                marginLeft: 15,*/}
                {/*                fontSize: 12,*/}
                {/*                color: '#C7D300'*/}
                {/*            }}>Dein Profil ist zu {progress}% fertiggestellt</p>*/}
                {/*            {progress < 100 && <p className={'buttonLinkYellow'} onClick={() => history.push('/einstellungen')} style={{*/}
                {/*                position: 'absolute',*/}
                {/*                textAlign: 'right',*/}
                {/*                bottom: 25,*/}
                {/*                right: isMobile ? 'unset' : 35,*/}
                {/*                left: isMobile ? 20 : 'unset',*/}
                {/*                marginLeft: 15,*/}
                {/*                fontSize: 12,*/}
                {/*                textDecoration: 'underline',*/}
                {/*                color: '#C7D300',*/}
                {/*                cursor: 'pointer',*/}
                {/*                transition: 'all 0.1s ease-in-out'*/}
                {/*            }}>Jetzt vervollständigen</p>}*/}
                {/*            <div style={{*/}
                {/*                position: 'absolute',*/}
                {/*                bottom: 15,*/}
                {/*                width: 'calc(100% - 70px)',*/}
                {/*                height: 10,*/}
                {/*                marginLeft: 15,*/}
                {/*                fontSize: 12,*/}
                {/*                textDecoration: 'underline',*/}
                {/*                border: '3px solid #C7D300',*/}
                {/*                borderRadius: 10,*/}
                {/*            }}>*/}
                {/*                <div style={{*/}
                {/*                    marginTop: -1,*/}
                {/*                    background: '#C7D300',*/}
                {/*                    width: (progress.toString() + '%'),*/}
                {/*                    height: 'calc(100% + 2px)',*/}
                {/*                    borderBottomLeftRadius: 10,*/}
                {/*                    borderTopLeftRadius: 10*/}
                {/*                }}></div>*/}
                {/*            </div>*/}
                {/*        </>}*/}

                {/*    </div>*/}
                {/*</Grid>*/}
                <Grid item xs={isMobile ? 12 : 6} style={{position: 'relative', zIndex: 1, height: isMobile ? 'auto' : 'calc(100vh/2 - 65px)', padding: '34px 10px ' + (isMobile ? '100' : '34') + 'px 34px'}}>
                    {anonymesProfil ?
                        <div className={icon} style={{height: '100%'}}>
                            {(questions && questions.length > 0) && questions.map((item, index3) => {
                                let items = [];

                                if (item.answersAN) {
                                    item.answersAN.map((item, index) => {
                                        if(item.title) {
                                            items.push(
                                                <span key={index + '-1'} style={{padding: (item.title == icon && helperText) ? '10px 20px' : '10px 20px 7px 20px'}}
                                                      className={'answer'}>
                                                                                {(item.title == icon && helperText) ? <>{item.title}<Tooltip enterTouchDelay={0}
                                                                                                                                                             title={helperText}>
                                                                                    <InfoIcon
                                                                                        style={{marginLeft: 4}}
                                                                                        fontSize={'14px'}
                                                                                        id={'infoIcon'}
                                                                                    />
                                                                                </Tooltip></> : item.title}
                                                                            </span>
                                            )
                                        } else {
                                            items.push(
                                                <span key={index + '-1'} style={{padding: (item == icon && helperText) ? '10px 20px' : '10px 20px 7px 20px'}}
                                                      className={'answer'}>
                                                                                {(item == icon && helperText) ? <>{item}<Tooltip enterTouchDelay={0}
                                                                                                                                                             title={helperText}>
                                                                                    <InfoIcon
                                                                                        style={{marginLeft: 4}}
                                                                                        fontSize={'14px'}
                                                                                        id={'infoIcon'}
                                                                                    />
                                                                                </Tooltip></> : item}
                                                                            </span>
                                            )
                                        }

                                    })
                                }

                                if (item.answersAN3) {
                                    item.answersAN3.map((item, index) => {
                                        if(item.title) {
                                            items.push(
                                                <span key={index + '-3'} style={{padding: (item.title == icon && helperText) ? '10px 20px' : '10px 20px 7px 20px'}}
                                                      className={'answer'}>
                                                                                {(item.title == icon && helperText) ? <>{item.title}<Tooltip enterTouchDelay={0}
                                                                                                                                                             title={helperText}>
                                                                                    <InfoIcon
                                                                                        style={{marginLeft: 4}}
                                                                                        fontSize={'14px'}
                                                                                        id={'infoIcon'}
                                                                                    />
                                                                                </Tooltip></> : item.title}
                                                                            </span>
                                            )
                                        } else {
                                            items.push(
                                                <span key={index + '-3'} style={{padding: (item == icon && helperText) ? '10px 20px' : '10px 20px 7px 20px'}}
                                                      className={'answer'}>
                                                                                {(item == icon && helperText) ? <>{item}<Tooltip enterTouchDelay={0}
                                                                                                                                                             title={helperText}>
                                                                                    <InfoIcon
                                                                                        style={{marginLeft: 4}}
                                                                                        fontSize={'14px'}
                                                                                        id={'infoIcon'}
                                                                                    />
                                                                                </Tooltip></> : item}
                                                                            </span>
                                            )
                                        }
                                    })
                                }
                                console.log(items)
                                if (item.text) {
                                    return <>
                                        {index3 == 0 ? <Grid item xs={12} style={{
                                            textAlign: 'left',
                                            fontFamily: 'VagRoundedBold',
                                            fontSize: 16,
                                            margin: 0,
                                            marginBottom: 10
                                        }} className={'color-blue'}>
                                            Übersicht
                                        </Grid> : null}
                                        {(items && items.length > 0) && <div
                                            style={{
                                                minWidth: (item.question_id == 1 || item.question_id == 2 || item.question_id == 12 || width < 1100) ? '100%' : '100%',
                                                maxWidth: '50%',
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                            }}
                                            key={index3}>
                                            <div style={{ minWidth: 'fit-content', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                                {item.text}
                                            </div>
                                            <div style={{
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                                marginBottom: 'auto',
                                                lineHeight: 18,
                                                paddingLeft: 5
                                            }}>
                                                {items}
                                            </div>
                                        </div>}
                                    </>
                                }

                            })}
                            {(questions && questions.length > 0) ? <>
                            <p style={{textAlign: 'left', fontSize: 30, margin: 0}}>...</p>


                            <p style={{
                                    position: 'absolute',
                                    textAlign: 'left',
                                    bottom: isMobile && progress < 100 ? 60 : 20,
                                    left: 34,
                                    fontSize: 12,
                                    color: '#C7D300'
                                }}>Dein Profil ist zu {progress}% fertiggestellt</p>
                                {progress < 100 && <CustomButton
                                    buttontype={'yellowBlue'}
                                    onClick={() => {
                                        history.push('/matches#anonymes-profil')
                                    }}
                                    style={{
                                        position: 'absolute',
                                        bottom: 32,
                                        right: isMobile ? 'unset' : 30,
                                        left: isMobile ? 34 : 'unset',
                                        zIndex: 5,
                                        height: 40.13
                                    }}
                                >JETZT VERVOLLSTÄNDIGEN</CustomButton>}
                                <div style={{
                                    position: 'absolute',
                                    bottom: 10,
                                    width: 'calc(100% - 70px)',
                                    height: 10,
                                    fontSize: 12,
                                    textDecoration: 'underline',
                                    border: '3px solid #C7D300',
                                    borderRadius: 10,
                                }}>
                                    <div style={{
                                        marginTop: -1,
                                        background: '#C7D300',
                                        width: (progress.toString() + '%'),
                                        height: 'calc(100% + 2px)',
                                        borderBottomLeftRadius: 10,
                                        borderTopLeftRadius: 10
                                    }}></div>
                                </div>


                            </> : null}

                    </div>
                        : anonymesProfil === false ? <div style={{zIndex: 3, display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%'}}>
                        <div>
                            <p style={{marginTop: 0, textAlign: 'center'}}>Du bist derzeit auf Jobsuche?</p>

                            <CustomButton
                                buttontype={'yellowBlue'}
                                onClick={() => {
                                    history.push('/anonym-swiitchen')
                                }}
                                style={{
                                    position: 'relative',
                                    zIndex: 5,
                                    height: 40.13
                                }}
                            >ANONYMES PROFIL ERSTELLEN</CustomButton>
                        </div>
                    </div> : <></>}
                    <fieldset style={{
                        position: 'absolute',
                        width: 'calc(100% - 40px)',
                        height: 'calc(100% - 35px)',
                        padding: 20,
                        borderRadius: 40,
                        borderWidth: 2,
                        borderColor: '#C7D300',
                    }}
                              className="MuiOutlinedInput-notchedOutline css-1d3z3hw-MuiOutlinedInput-notchedOutline">
                        <legend className="css-1in441m" style={{visibility: 'visible', height: 22}}><span style={{color: '#C7D300', opacity: 1, fontSize: 18, padding: '0 10px'}}>Dein anonymes Profil</span></legend>
                    </fieldset>
                    <p className={'buttonLinkYellow'} onClick={() => history.push('/matches#anonymes-profil')} style={{
                        position: 'absolute',
                        textAlign: 'right',
                        bottom: -40,
                        right: 0,
                        fontSize: 12,
                        textDecoration: 'underline',
                        color: '#C7D300',
                        cursor: 'pointer',
                        transition: 'all 0.1s ease-in-out'
                    }}>Zum anonymen Profil</p>
                    {/*<div style={{*/}
                    {/*    height: 'calc(100vh/2 - 100px)',*/}
                    {/*    position: 'relative',*/}
                    {/*    border: '2px solid black',*/}
                    {/*    padding: 20,*/}
                    {/*    borderRadius: 40,*/}
                    {/*}}>*/}
                    {/*    {anonymesProfil ? <></> : <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%'}}>*/}
                    {/*        <div>*/}
                    {/*            <p style={{marginTop: 0}}>Du bist derzeit auf Jobsuche?</p>*/}
                    {/*            <CustomButton*/}
                    {/*                buttontype={'yellowBlue'}*/}
                    {/*                onClick={() => {*/}
                    {/*                    history.push('/anonym-swiitchen')*/}
                    {/*                }}*/}
                    {/*                style={{*/}
                    {/*                    height: 40.13*/}
                    {/*                }}*/}
                    {/*            >ANONYMES PROFIL ERSTELLEN</CustomButton>*/}
                    {/*        </div>*/}
                    {/*    </div>}*/}
                    {/*</div>*/}
                </Grid>
            </Grid>
        </Grid>
    );
}
