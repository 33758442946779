import {styled, TextField} from "@mui/material";
import {makeStyles} from "@mui/styles";
import { TextFieldProps } from '@material-ui/core';


let test = undefined;

export const CssTextField2 = (props) => {
    const { showUpDown, ...textFieldProps } = props;
    const classes = useStyles();



    return (
        <CustomTextfield className={classes.input} {...textFieldProps} />
    );
}


const useStyles = makeStyles({
    input: {
        '& input[type=number]': {
            // '-moz-appearance': 'textfield'
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            // '-webkit-appearance': 'none',
            // margin: 0
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
        }
    },
});



// Do whatever you want with `customProp`
const CustomTextfield = styled(TextField)(({theme}) => ({
    '& label:not(.MuiInputLabel-shrink)': {
        top: -8,
        marginLeft: 5,
        fontSize: 14,
        color: 'BLACK',

        // transition: 'none'
    },
    '&:hover label': {
        color: theme.palette.secondary.main,
        transition: theme.transitions.create([
            'color',
        ]),
    },
    '& label.Mui-focused': {
        marginLeft: 5,
        top: -8,
        fontSize: 14,
        color: theme.palette.secondary.main,
        transition: theme.transitions.create([
            'color',
            'transform'
        ]),
    },
    '.MuiFormHelperText-filled': {
        color: 'red',
        transition: theme.transitions.create([
            'color',
            'transform'
        ]),
    },
    '.MuiFormHelperText-contained': {
        color: 'red',
        transition: theme.transitions.create([
            'color',
            'transform'
        ]),
    },
    '& label.MuiInputLabel-shrink': {
        marginLeft: 5,
        top: 3,
        fontSize: 14,
        color: theme.palette.secondary.main,
        transition: theme.transitions.create([
            'color',
            'transform'
        ]),
    },
    '& .MuiFormLabel-filled': {
        marginLeft: 5,
        top: 3,
        fontSize: 14,
        transition: theme.transitions.create([
            'color',
        ]),
    },
    // '& .MuiInput-underline:after': {
    //     borderColor: theme.palette.primary.main,
    // },
    '& .MuiOutlinedInput-root': {
        '& .MuiOutlinedInput-input': {
            padding: '10px 10px 7px 10px',
            fontSize: 14,
            borderRadius: 25,
            paddingLeft: 20,
            transition: theme.transitions.create([
                'border-color',
            ]),
        },
        '& fieldset': {
            border: '2px solid',
            borderColor: theme.palette.secondary.main,
            borderRadius: 25,
            transition: theme.transitions.create([
                'border-color',
            ]),
        },
        '&:hover fieldset': {
            border: '2px solid',
            borderColor: theme.palette.secondary.main,
            transition: theme.transitions.create([
                'border-color',
            ]),
        },
        '&.Mui-focused fieldset': {
            border: '2px solid',
            borderColor: theme.palette.secondary.main
        },
    },
}));