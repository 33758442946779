import React from "react";

const BouncingDotsLoader = (props) => {
    return (
        <>
            <div className="bouncing-loader">
                <div></div>
                <div></div>
                <div></div>
            </div>
        </>
    );
};

export default BouncingDotsLoader;