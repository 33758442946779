import React, {useEffect, useRef} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {Grid} from "@mui/material";
import {useHistory, useLocation} from "react-router";
import ÜbersichtArbeitnehmer from "./ÜbersichtArbeitnehmer";
import ÜbersichtArbeitgeber from "./ÜbersichtArbeitgeber";
import {ReactSession} from "react-client-session";

export default function Übersicht(props) {

    const {theme, unreadChats, locationChange, login, menuAppBarRef} = props;
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        if (!ReactSession.get('user'))  {
            menuAppBarRef.current.logNeeded();
            history.push('/');
        }
        else {
            document.title = 'Übersicht';
            locationChange(location);
        }
    }, [])

    return (
        <Grid container style={{
            padding: 0,
        }}>
            {ReactSession.get('user') === 'Arbeitnehmer' ? <ÜbersichtArbeitnehmer unreadChats={unreadChats} /> : <ÜbersichtArbeitgeber unreadChats={unreadChats} />}
        </Grid>
    );
}

