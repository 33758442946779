import React, {useEffect, useState} from 'react';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
function ScrollToTop() {

    const [classVisible, setClassVisible] = useState(false);
    const [visible, setVisible] = useState(false);
    const [hover, setHover] = useState(false);

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 100){
                setVisible(true)
                setTimeout(() => {
                    setClassVisible(true);
                }, 200);

        }
        else if (scrolled <= 100){
                setClassVisible(false)
                setTimeout(() => {
                    setVisible(false);
                }, 200);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', toggleVisible);

        return () => {
            window.removeEventListener('scroll', toggleVisible);
        };
    }, []);


    return (
        <React.Fragment>
            {visible && <div
                className="scrollToTop"
                style={{
                    background: hover ? '#00374e' : '#C7D300',
                    color: hover ? '#C7D300' : '#00374e',
                    opacity: classVisible ? 1 : 0,
                    cursor: 'pointer',
                    transition: 'all 0.2s ease-in-out',
                    fontSize: 28,
                    paddingLeft: 0
                }}
                onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}
                onMouseOver={() => {
                    setHover(true)
                }}
                onMouseOut={() => {
                    setHover(false)
                }}
            >
                <KeyboardDoubleArrowUpIcon />
            </div>}
        </React.Fragment>

    );
}

export default ScrollToTop;
