import React, { useEffect, useMemo, useRef, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {
    Alert, AlertTitle,
    CircularProgress, createMuiTheme,
    FormControl, FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem, Radio, RadioGroup, Slider,
    Stack,
    TextField,
    Tooltip
} from "@mui/material";
import { ReactComponent as LogoSvgDenkerFlaeche } from "../images/svg/Illustration_TTVerlag_Denker_Flaeche.svg";
import { ReactComponent as LogoSvgDenkerKontur } from "../images/svg/Illustration_TTVerlag_Denker_Kontur.svg";
import { ReactComponent as LogoSvgEntdeckerFlaeche } from "../images/svg/Illustration_TTVerlag_Entdecker_Flaeche.svg";
import { ReactComponent as LogoSvgEntdeckerKontur } from "../images/svg/Illustration_TTVerlag_Entdecker_Kontur.svg";
import { ReactComponent as LogoSvgHelferFlaeche } from "../images/svg/Illustration_TTVerlag_Helfer_Flaeche.svg";
import { ReactComponent as LogoSvgHelferKontur } from "../images/svg/Illustration_TTVerlag_Helfer_Kontur.svg";
import { ReactComponent as LogoSvgDiplomatFlaeche } from "../images/svg/Illustration_TTVerlag_Diplomat_Flaeche.svg";
import { ReactComponent as LogoSvgDiplomatKontur } from "../images/svg/Illustration_TTVerlag_Diplomat_Kontur.svg";
import { ReactComponent as AppStore } from "../images/svg/App_Store_Badge.svg";
import { ReactComponent as GooglePlay } from "../images/svg/google_play_badge_2.svg";
import { ReactComponent as LogoSvgB } from "../images/svg/B.svg";
import { ReactComponent as LogoSvgC } from "../images/svg/C.svg";
import { ReactComponent as LogoSvgD } from "../images/svg/D.svg";
import { ReactComponent as LogoSvgL } from "../images/svg/L.svg";
import { ReactComponent as LogoSvgFL } from "../images/svg/FL.svg";
import { ReactComponent as CheckIcon } from "../images/svg/haken.svg"
import { CssTextField2 } from "./CssTextField2";
import InfoIcon from "@mui/icons-material/Info";
import WestIcon from "@mui/icons-material/West";
import EastIcon from "@mui/icons-material/East";
import Clear from "@mui/icons-material/Clear";
import Add from "@mui/icons-material/AddCircleOutline";
import { makeStyles, styled, withStyles } from "@mui/styles";
import Checkbox from "@mui/material/Checkbox";
import { useHistory, useLocation } from "react-router";
import { ReactSession } from "react-client-session";
import useFetch from "./UseFetch";
import { Select2 } from "./Select2";
import { Autocomplete } from "@mui/material";
import "moment/locale/de";
import moment from "moment";
import de from 'dayjs/locale/de';
import DayJsUtils from '@date-io/dayjs';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import { toast } from "react-toastify";
import { CssTextField3 } from "./CssTextField3";
import { CustomButton } from "./CustomButton";
import htmlToDraft from "html-to-draftjs";
import ContentState from "draft-js/lib/ContentState";
import { EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { ReactComponent as TestLogo } from "../images/svg/swiitcher_icon_blau.svg";
import { ReactComponent as SmallLogo } from "../images/svg/swiitcher_icon_blau.svg"
import { ReactComponent as BigLogo } from "../images/svg/swiitcher_logo_weiss.svg"
import debounce from "lodash.debounce";
import useWindowDimensions from "./useWindowDimensions";
import { isMobile } from "react-device-detect";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { CssTextField4 } from "./CssTextField4";
import { CssTextField5 } from "./CssTextField5";
import { CssTextField6 } from "./CssTextField6";
import parsePhoneNumber, { validatePhoneNumberLength } from 'libphonenumber-js/max'
import CountDown from 'react-number-count-down';
import ReactInputVerificationCode from "react-input-verification-code";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import CodeInput from "./CodeInput";
import isNumeric from "validator/es/lib/isNumeric";
import GoogleMapReact from 'google-map-react';
import Circle from 'google-map-react';

const MapRadius = ({ km }) => <div style={{ width: '50%', height: '50%', background: 'blue' }}>{km}</div>;

const PrettoSlider = styled(Slider)({
    color: '#C7D300',
    height: 3,
    width: 'calc(100% - 30px)',
    padding: 0,
    marginTop: 5,
    marginBottom: 5,
    '& .MuiSlider-rail': {
        color: 'white',
        height: 4
    },
    '& .MuiSlider-track': {
        border: 'none',
        color: '#C7D300',
        height: 4
    },
    '& .MuiSlider-thumb': {
        height: 18,
        width: 18,
        color: '#C7D300',
        backgroundColor: '#C7D300',
        border: '2px solid #C7D300',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&:before': {
            display: 'none',
        },
        '&:after': {
            height: 16,
        },
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1.2,
        fontSize: 12,
        background: 'unset',
        padding: 0,
        width: 32,
        height: 32,
        borderRadius: '50% 50% 50% 0',
        backgroundColor: '#52af77',
        transformOrigin: 'bottom left',
        transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
        '&:before': { display: 'none' },
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
        },
        '& > *': {
            transform: 'rotate(45deg)',
        },
    },
});
const useStyles = makeStyles({
    select: {
        "& ul.MuiList-padding": {
            padding: 0
        },
        "& li": {
            color: '#00374e',
        },
    },
});
const BpIcon = styled('span')(({ theme }) => ({
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    borderRadius: 25,
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
    },
    'input:hover ~ &': {
        backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
    },
}));

const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#C7D300',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage:
            "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
            " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
            "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%2300374e'/%3E%3C/svg%3E\")",
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: '#C7D300',
    },
});

function BpCheckbox(props) {
    return (
        <Checkbox
            sx={{
                '&:hover': { bgcolor: 'transparent' },
            }}
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon/>}
            icon={<BpIcon/>}
            inputProps={{ 'aria-label': 'Checkbox demo' }}
            {...props}
        />
    );
}

const areaCodes = [
    {
        value: '+49',
        label: '+49',
    },
    {
        value: '+43',
        label: '+43',
    },
    {
        value: '+41',
        label: '+41',
    },
    {
        value: '+33',
        label: '+33',
    },
    {
        value: '+34',
        label: '+34',
    },
    {
        value: '+45',
        label: '+45',
    },
    {
        value: '+39',
        label: '+39',
    },
    {
        value: '+351',
        label: '+351',
    }
];

export default function AnonymSwiitchen(props) {

    const { login, loginRef, theme, locationChange, menuAppBarRef } = props;
    const { height, width } = useWindowDimensions();

    const location = useLocation();
    const api = useFetch();
    const classes = useStyles();
    const history = useHistory();
    const loadingSpinnerRef = useRef(null)

    const testRef = useRef([]);
    const autocompleteRef = useRef([]);

    const [autocompleteLoading, setAutocompleteLoading] = useState(false);

    const [vorwahl, setVorwahl] = useState('+49');
    const [validPhonenumber, setValidPhonenumber] = useState(false);
    const [mobilnummer, setMobilnummer] = useState('');
    const [mobilnummerError, setMobilnummerError] = useState('');
    const [showLoadingspinner, setShowLoadingspinner] = useState(true);
    const [mailOnly, setMailOnly] = useState(false);
    const [resendCode, setResendCode] = useState(false);
    const [useMailOnly, setUseMailOnly] = useState(false);
    const [requestSent, setRequestSent] = useState(false);

    const [finished, setFinished] = React.useState(false);
    const [counter, setCounter] = React.useState(0);
    const [counterTimer, setCounterTimer] = React.useState(false);
    const [code, setCode] = useState('');
    const [autocomplete, setAutocomplete] = useState([]);
    const [autocompleteInput, setAutocompleteInput] = useState('');
    const [selectedAutocomplete, setSelectedAutocomplete] = useState('');
    const [test, setTest] = useState(false);
    const [currentCircle, setCurrentCircle] = useState(null);
    const [shareLocation, setShareLocation] = useState(null);


    const steps = ['1', '2'];

    useEffect(() => {
        const timer =
            counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [counter])

    useEffect(() => {
        if (finished) {
            setTimeout(() => {
                setShowLoadingspinner(false);
            }, 1000)
        }
    }, [finished])

    useEffect(() => {
        if (code && code.length == 6) {
            sendConfirmcode(code);
        }
    }, [code])

    // const [getElementInterval, setGetElementInterval] = useState(false);
    const [buttonHover, setButtonHover] = useState(null);
    const [isFocused, setIsFocused] = useState(null);
    const [answeredQuestions, setAnsweredQuestions] = useState([]);

    const [activeQuestion, setActiveQuestion] = useState(1);
    const [questions, setQuestions] = useState(null);
    const [questionsTemp, setQuestionsTemp] = useState(null);
    const [onChangeQuestion, setOnChangeQuestion] = useState(false);
    const [showSubQuestion, setShowSubQuestion] = useState([]);
    const [category, setCategory] = useState([]);
    const [answers, setAnswers] = React.useState([]);
    const [onLogin, setOnLogin] = useState(false);
    const [mail, setMail] = React.useState('');
    const [mailError, setMailError] = React.useState(false);
    const [accountType, setAccountType] = React.useState('Arbeitnehmer');
    const [password, setPassword] = React.useState('');
    const [passwordVerify, setPasswordVerify] = React.useState('');
    const [passwordError, setPasswordError] = React.useState(false);
    const [passwordVerifyError, setPasswordVerifyError] = React.useState(false);

    const [companyName, setCompanyName] = React.useState('');
    const [straße, setStraße] = React.useState('');
    const [hausnummer, setHausnummer] = React.useState('');
    const [postleitzahl, setPostleitzahl] = React.useState('');
    const [ort, setOrt] = React.useState('');
    const [company, setCompany] = React.useState('');
    const [companyError, setCompanyError] = React.useState(false);
    const [activeStep, setActiveStep] = useState(2);
    const [isShrink, setIsShrink] = useState([]);
    const [errors, setErrors] = React.useState([]);
    const [month, setMonth] = useState(false);
    const [year, setYear] = useState(false);
    const [multiSelectOpen, setMultiSelectOpen] = useState(false);
    const [keyDown, setKeyDown] = useState(false);
    const [showPassword1, setShowPassword1] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [onMouseOver, setOnMouseOver] = useState(false);
    const [sectorData, setSectorData] = useState(null);
    const [sectors, setSectors] = useState({});
    const [sixtyOne, setSixtyOne] = useState({
        sectors: [],
        topCategories: {},
        categories: {}
    });

    const [topCategories, setTopCategories] = useState({});
    const [categories, setCategories] = useState({});
    const currentYear = new Date().getFullYear()

    const [currentRange, setCurrentRange] = useState(30);
    const [currentMap, setCurrentMap] = useState(null);
    const [currentMaps, setCurrentMaps] = useState(null);
    const [defaultProps, setDefaultProps] = useState({
        center: {
            lat: 51.13,
            lng: 9.21
        },
        zoom: 5
    });

    const handleClickShowPassword = () => {
        setShowPassword1(!showPassword1);
        setShowPassword2(!showPassword2);
    }
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        disableScrollLock: true,
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                minHeight: 'unset',
            },
        },
        classes: { paper: classes.select }
    };

    const notify = (message, error) => {
        // console.log('NOTIFY')
        if (error) {
            toast.error(message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                style: { marginTop: 30 }
            });
        } else {
            toast.success(message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                style: { marginTop: 30 }
            });
        }
    }

    const ignoreTypes = [
        '42', '43', '51', '52', '59', '60', '66', '67', '13', '26', '37', '49'
    ];

    useEffect(() => {
        if (!questions || !questions[activeQuestion - 1]) return
        const quest = questions[activeQuestion - 1]
        if (questions) console.log("ACCCTIVEQ", questions[activeQuestion - 1])

        let sector = undefined
        if (quest.id == 3 || quest.id == 6 || quest.id == 16) {
            if (quest.selected?.autocomplete[0]?.subAutocomplete) {
                sector = quest.selected?.autocomplete[0]?.subAutocomplete[0]?.answer
            }
            if (sector) {
                // if(topCategories[quest.id]?.length > 0) fetchCategoryData(sector, quest.id)
                fetchTopCategoryData(sector, quest.id)
                fetchCategoryData(sector, quest.id)
            } else {
                setTopCategories({})
                setCategories({})
            }
        } else if (quest.id == 61) {
            if (quest.selected.autocomplete.length == 0) {
                quest.selected.autocomplete[0] = {}

                // quest.selected.autocomplete[1] = {}
                // quest.selected.autocomplete[2] = {}
            } else {
                quest.selected.autocomplete.forEach((item, index) => {
                    fetchSOTopCategoryData(item.answer, index)
                    fetchSOCategoryData(item.answer, index)
                    // sector.push(item.answer)
                })

            }
        }


    }, [activeQuestion])

    useEffect(() => {
        if (ReactSession.get('user') && ReactSession.get('user') !== 'Arbeitnehmer') {
            menuAppBarRef.current.logNeeded('Arbeitnehmer');
            history.push('/');
        } else {
            document.title = 'Anonym swi!tchen';
            if (locationChange) locationChange(location);

            getStuff();
        }


        return () => {
            if (counterTimer) clearInterval(counterTimer);
        }
    }, [])

    const getStuff = async () => {
        setTimeout(() => {
            getData();
        }, 1000)
    }


    const getData = async () => {
        let answeredQuestionsTemp = [];
        let temp = [];
        let t = false;

        console.log("user is", ReactSession.get('user'));
        if (ReactSession.get('user') == 'Arbeitnehmer') {
            let url = ENV.REACT_APP_API_URL + '/answered-questions?page%5Bsize%5D=999';

            let tempData = await api(url)
            let response = tempData.response;
            let data = tempData.data;

            if (!response || response.status == 401) {
                ReactSession.set('token', undefined);
                ReactSession.set('refreshToken', undefined);
                // history('/arbeitgeber');
                //return;
            }
            console.log(data);
            let rows = [];
            if (data && data.data) {

                data.data.map((item, index) => {
                    if (item.attributes.answer_id == 401) {
                        let temp = item.attributes.answerText.split(' ');
                        setMonth(temp[0]);
                        setYear(temp[1]);
                    }
                    answeredQuestionsTemp.push(item);

                    if (item.attributes.question_id == 2 && !temp.includes(item.attributes.answerText)) {
                        temp.push(item.attributes.answerText);
                    }

                });
            } else if (response.error) {
                notify('API Fehler: ' + response.status, true);
            }
        }

        let rows = [];

        // let url = ENV.REACT_APP_API_URL + '/questions?page%5Bsize%5D=999&include=answers';
        let url = ENV.REACT_APP_API_URL + '/questions/for/arbeitnehmer?page%5Bsize%5D=999';

        let tempData = await api(url)
        let response = tempData.response;
        let data = tempData.data;

        if (!response || response.status == 401) {
            ReactSession.set('token', undefined);
            ReactSession.set('refreshToken', undefined);
            history.push('/login');
            return;
        }
        let z = false;

        if (data && data.data) {
            await data.data.map(async (quest, index) => {


                let id = quest.id;

                let question = quest.question;
                let helperText = quest.helperText;
                let selected = {};
                // let answers = item.answers;
                let answers = quest.answers.sort((a, b) => a.sort - b.sort);
                let type = quest.type;
                let categoryT = quest.category;
                let visibleFor = quest.visibleFor;
                let optional = quest.optional;

                let topAnswers = [];
                answers.map((item, index) => {
                    if (item.parent_id == null) {
                        item.sub = answers.filter(i => i.parent_id == item.id && quest.id != 9); //TODO REMOVE CHECK FOR ID 9 WHEN subCats are added

                        let an = [];
                        let au = [];
                        let subAu = [];
                        let dropdown = null;
                        if (answeredQuestionsTemp && answeredQuestionsTemp.length > 0) {
                            answeredQuestionsTemp.filter(i => i.attributes.question_id == id && i.attributes.answerText != '').map((item, index) => {
                                if (!an.includes(item.attributes.answerText)) {
                                    if (id != 3 && id != 62 && (id != 5 && id != 40) || (id == 5 && item.attributes.answerText && !item.attributes.autocompleteText) || (id == 40 && item.attributes.answerText && !item.attributes.autocompleteText)) {
                                        an.push(item.attributes.answerText);
                                    } else if (id == 62) {
                                        if (isNumeric(item.attributes.answerText)) setCurrentRange(Number(item.attributes.answerText))
                                        if (item.attributes.answerText === "Ich möchte meinen Standort teilen") setShareLocation(item.attributes.answerText)
                                        an.push(item.attributes.answerText);
                                    } else if (id != 3) {
                                        dropdown = item.attributes.answerText;
                                    }
                                }
                            })
                            console.log("answeredQuestionsTemp", answeredQuestionsTemp)
                            answeredQuestionsTemp.filter(i => i.attributes.question_id == id && i.attributes.autocompleteText != '' && i.attributes.autocompleteText != null).map((item, index) => {
                                if (!au.includes({
                                    id: item.id,
                                    answer: item.attributes.autocompleteText,
                                    title: item.attributes.autocompleteText,
                                    value: item.attributes.autocompleteText
                                })) {
                                    if (id == 62) {
                                        let url = ENV.REACT_APP_API_URL + '/zipcodes';
                                        getZipcodesById(url, item.attributes.autocompleteText)
                                        // apiIsLoaded()
                                        au.push({
                                            id: item.id,
                                            answer: item.attributes.autocompleteText,
                                            title: item.attributes.autocompleteText,
                                            value: item.attributes.autocompleteText,
                                            autocompleteType: 'Zipcode'
                                        });
                                    } else if (id == 61 || id == 3 || id == 6 || id == 16 || id == 48) {
                                        console.log("SECTOR ITEMM", item)
                                        if (!item.attributes.relatedAnsweredQuestionId) {
                                            const subs = []
                                            answeredQuestionsTemp.filter(i => i.attributes.question_id == id && i.attributes.autocompleteText != '').map(quest => {
                                                console.log("QUESTQUEST", quest)
                                                if (quest.attributes.relatedAnsweredQuestionId == item.id) {
                                                    subs.push({
                                                        id: quest.id,
                                                        answerId: quest.attributes.answer_id,
                                                        answer: quest.attributes.autocompleteText,
                                                        title: quest.attributes.autocompleteText,
                                                        value: quest.attributes.autocompleteText,
                                                    })
                                                }
                                            })
                                            au.push({
                                                id: item.id,
                                                answerId: item.attributes.answer_id,
                                                answer: item.attributes.autocompleteText,
                                                title: item.attributes.autocompleteText,
                                                value: item.attributes.autocompleteText,
                                                subAutocomplete: subs
                                            });
                                        }
                                        console.log("ANAU", an, au)
                                    } else if (item.attributes.answer_id == 9 || item.attributes.question_id != 3) au.push({
                                        id: item.id,
                                        answer: item.attributes.autocompleteText,
                                        title: item.attributes.autocompleteText,
                                        value: item.attributes.autocompleteText
                                    });
                                    if (item.attributes.answer_id == 508) subAu.push({
                                        id: item.id,
                                        answer: item.attributes.autocompleteText,
                                        title: item.attributes.autocompleteText,
                                        value: item.attributes.autocompleteText
                                    });
                                }
                            })
                        }

                        if (item.sub.length === 0) {
                            selected = {
                                id,
                                'answer': an,
                                autocomplete: au,
                                autocompleteInput: '',
                                subAutocomplete: subAu,
                                subAutocompleteInput: '',
                                dropdown
                            }
                        } else {
                            selected = {
                                id,
                                'answer': an,
                                subAnswer: [],
                                sub: true,
                                autocomplete: au,
                                autocompleteInput: '',
                                subAutocomplete: subAu,
                                subAutocompleteInput: '',
                                dropdown
                            }
                        }

                        topAnswers.push(item);
                    }
                })

                let logos = {
                    DenkerF: LogoSvgDenkerFlaeche,
                    DenkerK: LogoSvgDenkerKontur,
                    EntdeckerF: LogoSvgEntdeckerFlaeche,
                    EntdeckerK: LogoSvgEntdeckerKontur,
                    HelferF: LogoSvgHelferFlaeche,
                    HelferK: LogoSvgHelferKontur,
                    DiplomatF: LogoSvgDiplomatFlaeche,
                    DiplomatK: LogoSvgDiplomatKontur,
                };

                let führerscheinLogos = {
                    B: LogoSvgB,
                    C: LogoSvgC,
                    D: LogoSvgD,
                    L: LogoSvgL,
                    FL: LogoSvgFL
                };
                if (id == 7 && selected.autocomplete.length == 0) {
                    selected.autocomplete = [{ title: 'Deutsch', value: 'Deutsch' }, {
                        title: 'Englisch',
                        value: 'Englisch'
                    }];
                }
                if (!selected) {
                    selected = {
                        id,
                        'answer': [],
                        subAnswer: [],
                        autocomplete: [],
                        autocompleteInput: '',
                        subAutocomplete: [],
                        subAutocompleteInput: '',
                    }
                }

                let t = [];

                let temp = {
                    id,
                    subAutocomplete: [],
                    question,
                    helperText,
                    selected,
                    answers,
                    visibleFor,
                    category: categoryT,
                    logos,
                    führerscheinLogos,
                    optional,
                    type
                };


                if ((id == 13 || id == 26 || id == 37 || id == 49) && z == false) {
                    z = { id: '13', question: 'Antworten werden gespeichert' };
                } else if ((id != 13 && id != 26 && id != 37 && id != 49)) {

                    if (id == 61) {
                        let url = ENV.REACT_APP_API_URL + '/autocomplete/popular-jobs';
                        let { response, data } = await api(url);
                        if (data && data.data) {
                            temp.autocompleteFromDB = data.data.popularJobs;
                            rows.push(temp)
                        }
                    } else {
                        rows.push(temp)
                    }


                }
            })

        } else if (response.error) {
        }
        if (answeredQuestionsTemp) setActiveQuestion(1);
        setAnsweredQuestions(answeredQuestionsTemp);
        setCategory(temp);

        rows.sort((a, b) => (parseInt(a.id) > parseInt(b.id)) ? 1 : -1);

        if (z) rows.push(z);

        setTimeout(() => {
            setQuestionsTemp(rows);
        }, 1000)
    }

    useEffect(() => {
        let cat = category;
        let temp = [];
        console.log("cat", cat);
        if (cat && cat.length > 0) {
            if (questionsTemp) {
                let t = [];
                let azubi = [1, 2, 16, 17, 5, 7, 8, 9, 10, 11, 12, 61, 13];
                let schueler = [1, 2, 29, 30, 7, 8, 9, 10, 11, 36, 13];
                let student = [1, 2, 40, 30, 6, 7, 8, 9, 10, 11, 48, 13];

                if (cat.includes('Arbeitnehmer') || cat.includes('Arbeitnehmer*in') || cat.includes('Selbstständige*r') || cat.includes('Rentner*in')) {

                    t = t.concat(questionsTemp.filter(i => (i.category == '' || i.category == 'Arbeitnehmer' || i.id == 13 || i.id == 26 || i.id == 37 || i.id == 49)));
                    console.log(t);
                }
                t = t.concat(questionsTemp.filter(i => i.category == 'Alle'));
                console.log("ALLE", t)
                if (cat.includes('Auszubildende*r')) {
                    azubi.map((item, index) => {
                        if (questionsTemp.filter(i => (i.id == item)).length > 0 && !t.includes(questionsTemp.filter(i => (i.id == item))[0])) {
                            t.push(questionsTemp.filter(i => (i.id == item))[0]);
                        }
                    })
                }

                if (cat.includes('Schüler*in')) {
                    schueler.map((item, index) => {
                        if (questionsTemp.filter(i => (i.id == item)).length > 0 && !t.includes(questionsTemp.filter(i => (i.id == item))[0])) {
                            t.push(questionsTemp.filter(i => (i.id == item))[0]);
                        }
                    })
                }

                if (cat.includes('Student*in')) {
                    student.map((item, index) => {
                        if (questionsTemp.filter(i => (i.id == item)).length > 0 && !t.includes(questionsTemp.filter(i => (i.id == item))[0])) {
                            t.push(questionsTemp.filter(i => (i.id == item))[0]);
                        }
                    })
                }

                t.sort((a, b) => (parseInt(a.id) > parseInt(b.id) ? 1 : -1));

                let distanceItem = t.filter(i => i.id == 62)
                console.log("DISTANCE ITEM", distanceItem)
                let endItem = t.filter(i => (i.id == 13 || i.id == 26 || i.id == 37 || i.id == 49));
                t = t.filter(i => (i.id != 13 && i.id != 26 && i.id != 37 && i.id != 49 && i.id != 62));

                if (distanceItem && distanceItem.length == 1) t.push(distanceItem[0]);
                if (endItem && endItem.length == 1) t.push(endItem[0]);

                t.map((questionItem, questionIndex) => {
                    if (questionItem.answers) {
                        questionItem.answers.map((answerItem, answerIndex) => {
                            if (questionItem.selected && questionItem.selected.answer && questionItem.selected.answer.includes(answerItem.answer)) {
                                let index = answers.indexOf(answers.filter(i => i.question_id == questionItem.id && i.answer == answerItem.answer)[0]);
                                temp.push('answer-' + questionIndex + '-' + answerIndex);
                            }
                        })
                    }
                })

                setShowSubQuestion(temp);


                console.log("tquestions", t);
                setQuestions(t);
            }
        } else {
            if (questionsTemp) setQuestions(questionsTemp.filter(i => i.id == 1 || i.id == 2));
        }
    }, [questionsTemp])

    useEffect(() => {
        let temp = category;
        console.log("catTeamp", temp)
        if (temp && temp.length > 0 && questionsTemp) {
            let t = [];
            let azubi = [1, 2, 16, 30, 17, 5, 7, 8, 9, 10, 11, 12, 61, 13];
            let schueler = [1, 2, 29, 30, 7, 8, 9, 10, 11, 36, 13];
            let student = [1, 2, 40, 30, 6, 7, 8, 9, 10, 11, 48, 13];

            if (temp.includes('Arbeitnehmer*in') || temp.includes('Selbstständige*r') || temp.includes('Rentner*in')) {
                t = t.concat(questionsTemp.filter(i => (i.category == '' || i.category == 'Arbeitnehmer' || i.id == 13 || i.id == 26 || i.id == 37 || i.id == 49)));
                console.log("ARBEITNEHMER", t)
            }
            t = t.concat(questionsTemp.filter(i => i.category == 'Alle'));
            console.log("ALLE", t)
            if (temp.includes('Auszubildende*r')) {
                azubi.map((item, index) => {
                    if (questionsTemp.filter(i => (i.id == item)).length > 0 && !t.includes(questionsTemp.filter(i => (i.id == item))[0])) {
                        t.push(questionsTemp.filter(i => (i.id == item))[0]);
                    }
                })
            }

            if (temp.includes('Schüler*in')) {
                schueler.map((item, index) => {
                    if (questionsTemp.filter(i => (i.id == item)).length > 0 && !t.includes(questionsTemp.filter(i => (i.id == item))[0])) {
                        t.push(questionsTemp.filter(i => (i.id == item))[0]);
                    }
                })
            }
            console.log("Schueler", t[1])
            if (temp.includes('Student*in')) {
                student.map((item, index) => {
                    if (questionsTemp.filter(i => (i.id == item)).length > 0 && !t.includes(questionsTemp.filter(i => (i.id == item))[0])) {
                        t.push(questionsTemp.filter(i => (i.id == item))[0]);
                    }
                })
            }

            t.sort((a, b) => (parseInt(a.id) > parseInt(b.id) ? 1 : -1));

            let distanceItem = t.filter(i => i.id == 62)

            let endItem = t.filter(i => (i.id == 13 || i.id == 26 || i.id == 37 || i.id == 49));
            t = t.filter(i => (i.id != 13 && i.id != 26 && i.id != 37 && i.id != 49 && i.id != 62));

            if (distanceItem && distanceItem.length == 1) t.push(distanceItem[0]);

            if (endItem && endItem.length == 1) t.push(endItem[0]);
            setQuestions(t);
            setOnChangeQuestion(false);
        } else if (questionsTemp && questionsTemp.length > 0) {
            setQuestions(questionsTemp.filter(i => (i.id == 1 || i.id == 2)))
        }

    }, [category])

    const validateQuestions = () => {
        let temp = [];
        answers.map((item, index) => {
            console.log(item.answer, item)
            if (questions.filter(i => i.optional == 1 && item.id == i.id).length == 0 && ((item.answer && item.answer.length === 0 && (!item.autocomplete || item.autocomplete.length === 0)) || (item.answer && item.answer.length > 0 && item.subAnswer && item.subAnswer.length === 0) || (item.answer && item.answer.length > 0 && item.autocomplete && item.autocomplete.length === 0)) || (item.answer && item.answer[0] && item.answer[0].answer && item.answer[0].answer == 'Studium' && item.autocomplete && item.autocomplete[0] && !item.autocomplete[0].dropdown)) {
                temp.push(item.id);
            }
        })
        setErrors(temp);

        if (temp.length > 0) {
            notify('Ein oder mehrere Pflichtfelder sind nicht ausgefüllt', true);
            return false;
        } else {
            return true;
        }
    }

    useEffect(() => {
        if (questions && questions[activeQuestion - 1]) {
            // ? Matomo question-changed-event
            _paq.push(['trackEvent', 'question-changed', 'question-changed', `qId-${questions[activeQuestion - 1].id}-qIdx-${activeQuestion - 1}-${questions[activeQuestion - 1].question}`])
        }
        if (questions && questions[activeQuestion - 1] && questions[activeQuestion - 1].then && Object.keys(questions[activeQuestion - 1].then) > 0 && Object.keys(questions[activeQuestion - 1].then).filter(i => i.antwort !== '').length > 0) {
            let temp = [];
            Object.keys(questions[activeQuestion - 1].then).filter(i => i.antwort !== '').map((key, index) => {
                temp.push(('question-' + (activeQuestion - 1) + '-' + index));
            })
            setShowSubQuestion(temp);
        }

        setTimeout(() => {
            setOnChangeQuestion(false);
        }, 300)


        if (questions && questions[activeQuestion - 1] && questions[activeQuestion - 1].question == 'Antworten werden gespeichert' && ReactSession.get('token')) {
            createQuestion();
        }
    }, [activeQuestion])


    useEffect(() => {

        if (questions && questions.length > 0) {
            let answers = [];
            questions.map((item, index) => {
                if (errors.includes(item.id)) {
                    //validateQuestions();
                }
                if (item.selected?.autocomplete[0] && item.selected?.autocomplete[0]) {
                    console.log("ZIPCODE SELECTED AUTO ANSWER", JSON.stringify(item.selected.autocomplete[0][0]))
                } else {
                    console.log("ZIPCODE SELECTED AUTO ANSWER no extra array", JSON.stringify(item.selected))
                }
                let temp = {}
                let test = [];
                if (item.id) {
                    temp.id = item.id;
                    if (item.selected && !Array.isArray(item.selected.answer)) {
                        let answerId = item.answers.filter(i => i.answer == item.selected.answer);
                        console.log("ANSWERID NO ARRAY", answerId)
                        if (answerId && answerId.length == 1) answerId = answerId[0].id;
                        else {
                            answerId = item.answers.filter(i => i.question_id == item.selected.id);
                            if (answerId && answerId.length >= 1) answerId = answerId[0].id;
                        }
                        if (!test.includes({
                            id: item.id,
                            answer: item.selected.answer,
                            answerId
                        })) test.push({ id: item.id, answer: item.selected.answer, answerId })
                        temp.answer = test;
                    }
                    if (item.selected && Array.isArray(item.selected.answer)) {
                        item.selected.answer.map((answer, index) => {
                            let answerId = item.answers.filter(i => i.answer == answer || i.answer?.id == answer?.id || i.label == answer);
                            let ans = answer;
                            console.log("ANSWERID ARRAY", answerId)
                            if (
                                typeof ans === 'object' &&
                                !Array.isArray(ans) &&
                                ans !== null
                            ) {
                                answerId = item.answers.filter(i => i.answer == answer.answer || i.answer?.id == answer.id);
                                ans = answer.answer;
                            }
                            console.log(answerId)
                            if (answerId && answerId.length > 0) answerId = answerId[0].id;
                            else {
                                answerId = item.answers.filter(i => i.question_id == item.selected.id);
                                console.log('questionIdFilter', answerId)
                                if (answerId && answerId.length == 1) answerId = answerId[0].id;
                            }
                            console.log(answerId)
                            if (!test.includes({ id: item.id, answer: ans, answerId })) test.push({
                                id: item.id,
                                answer: ans,
                                answerId
                            })
                        })
                        temp.answer = test;
                    }

                    test = [];

                    if (temp.answer && temp.answer.length == 0 && item.selected.autocomplete && item.selected.autocomplete.length > 0) {
                        console.log("TEMP ANSWER LENGTH 0")
                        if (item.id == 3 || item.id == 16 || item.id == 61) {
                            item.selected.autocomplete.map((answer, index) => {
                                let answerId = item.answers.filter(i => i.type == 'autocomplete' || i.type == 'autocompleteDropdown' && i.autocompleteType != 'subAutocomplete');
                                console.log("ANSWERID AUTOCOMPLETE !SUB", answerId)
                                if (answerId) answerId = answerId[0].id;
                                console.log("ANSWERID AUTOCOMPLETE !SUB 222", answerId)
                                console.log("ANSWERID AUTOCOMPLETE !SUB 222", JSON.stringify(answer))
                                let t = {
                                    id: item.id,
                                    answer: answer.answer ?? answer.input ?? answer.title,
                                    answerId,
                                    dropdown: item.selected.dropdown,
                                    subAutocomplete: answer.subAutocomplete
                                };
                                if (!test.includes(t)) test.push(t)
                            })
                            temp.autocomplete = test;
                        } else {
                            item.selected.autocomplete.map((answer, index) => {
                                let answerId = item.answers.filter(i => i.type == 'autocomplete' || i.type == 'autocompleteDropdown' && i.autocompleteType != 'subAutocomplete');
                                console.log("ANSWERID AUTOCOMPLETE !SUB", answerId)
                                if (answerId) answerId = answerId[0].id;
                                console.log("ANSWERID AUTOCOMPLETE !SUB 222", answerId)
                                console.log("ANSWERID AUTOCOMPLETE !SUB 333", answer)
                                let t = {
                                    id: item.id,
                                    answer: answer.answer ?? answer.input ?? answer.title,
                                    answerId,
                                    dropdown: item.selected.dropdown
                                };
                                if (!test.includes(t)) test.push(t)
                            })
                            temp.autocomplete = test;
                        }

                    }
                    console.log("ZIPCODE SELECTED", JSON.stringify(temp), item.selected)
                    if (item.selected?.autocomplete[0]) console.log("ZIPCODE SELECTED AUTO ANSWER", temp, item.selected.autocomplete[0][0]?.answer)
                    if (temp.answer && temp.answer.length > 0 && item.selected.autocomplete && item.selected.autocomplete.length > 0) {
                        console.log("ZIPCODE SELECTED in if", JSON.stringify(item.selected?.autocomplete ?? {}))
                        if (item.id == 6 || item.id == 36 || item.id == 48) {
                            item.selected.autocomplete.map((answer, index) => {
                                let answerId = item.answers.filter(i => i.type == 'autocomplete' && i.autocompleteType != 'subAutocomplete');
                                console.log("ANSWERID AUTOCOMPLETE !SUB", answerId)
                                if (answerId) answerId = answerId[0].id;
                                console.log("ANSWERID AUTOCOMPLETE !SUB 222", answerId)
                                let t = {
                                    id: item.id,
                                    answer: answer.answer ?? answer.input ?? answer.title,
                                    answerId,
                                    dropdown: item.selected.dropdown,
                                    subAutocomplete: answer.subAutocomplete
                                };

                                console.log("T BRANCHEN JA", t)
                                if (!test.includes(t)) test.push(t)
                            })
                            temp.autocomplete = test;
                        } else if (item.selected.autocomplete?.find(selectedAutoComplete => selectedAutoComplete.autocompleteType == "Zipcode")) {
                            item.selected.autocomplete.map((answer, index) => {
                                let answerId = item.answers.filter(i => i.type == 'autocomplete' && i.autocompleteType != 'subAutocomplete');
                                console.log("ANSWERID AUTOCOMPLETE !SUB", answerId)
                                if (answerId) answerId = answerId[0].id;
                                console.log("ANSWERID AUTOCOMPLETE !SUB 222", answerId)
                                if (answer.answer || answer.input || answer.title) {
                                    let t = {
                                        id: item.id,
                                        answer: answer.answer ?? answer.input ?? answer.title,
                                        answerId,
                                        dropdown: item.selected.dropdown
                                    };
                                    if (!test.includes(t)) test.push(t)
                                }

                            })
                            temp.autocomplete = test;
                        }
                    }

                    test = [];

                    if (temp.answer && temp.answer.length == 0 && item.selected.subAutocomplete && item.selected.subAutocomplete.length > 0) {
                        item.selected.subAutocomplete.map((answer, index) => {
                            let answerId = item.answers.filter(i => i.type == 'autocomplete' && i.autocompleteType == 'subAutocomplete');
                            if (answerId && answerId.length > 0) answerId = answerId[0].id;
                            let t = {
                                id: item.id,
                                answer: answer.answer ?? answer.input ?? answer.title,
                                answerId: answerId && answerId.length > 0 ? answerId : null,
                                dropdown: item.selected.dropdown
                            };
                            if (!test.includes(t)) test.push(t)
                        })
                        temp.subAutocomplete = test;
                    }


                    test = [];
                    let t = null;
                    if (temp.answer && temp.answer.length == 1 && item.id != 6 && item.id != 36 && item.id != 48) {
                        if (item.answers.filter(i => temp.answer[0].answer == i.answer && i.sub && i.sub.length > 0).length > 0) {
                            if (!item.selected.autocomplete || item.selected.autocomplete.length == 0) {
                                temp.subAnswer = [];
                                t = { id: item.id, answer: '' }
                            }
                            if (item.selected.subAnswer && item.selected.subAnswer.length > 0) {
                                temp.subAnswer = item.selected.subAnswer;
                                item.selected.subAnswer.map((answer, index) => {
                                    let answerId = item.answers.filter(i => i.answer == answer);
                                    if (answerId) answerId = answerId[0].id;
                                    if (!test.includes({
                                        id: item.id,
                                        answer: answer,
                                        answerId
                                    })) test.push({ id: item.id, answer: answer, answerId })
                                    // t = {id: item.id, answer: answer.title}
                                    // test.push(t)
                                })
                                temp.subAnswer = test;
                            }

                            test = [];
                            if (item.selected.autocomplete && item.selected.autocomplete.length > 0) {
                                item.selected.autocomplete.map((answer, index) => {
                                    let answerId = item.answers.filter(i => i.type == 'autocomplete');
                                    if (answerId) answerId = answerId[0].id;
                                    if (!test.includes({
                                        id: item.id,
                                        answer: answer.answer ?? answer.input ?? answer.title ?? answer,
                                        answerId,
                                        dropdown: item.selected.dropdown ?? []
                                    })) test.push({
                                        id: item.id,
                                        answer: answer.answer ?? answer.input ?? answer.title ?? answer,
                                        answerId,
                                        dropdown: item.selected.dropdown ?? []
                                    })
                                })
                                temp.autocomplete = test;
                            }
                        }
                    }
                    answers.push(temp);
                    console.log("temü", temp.autocomplete?.length)
                }
            })
            let t = [];
            if (errors.length > 0) {
                answers.map((item, index) => {
                    if ((item.answer && item.answer.length === 0 && (!item.autocomplete || item.autocomplete.length === 0)) || (item.answer && item.answer.length > 0 && item.subAnswer && item.subAnswer.length === 0) || (item.answer && item.answer.length > 0 && item.autocomplete && item.autocomplete.length === 0)) {
                        t.push(item.id);
                    }
                })
            }
            setErrors(t);
            setAnswers(answers);
        }


        if (questions) {
            testRef.current = testRef.current.slice(0, questions.length);
        }
    }, [questions])


    useEffect(() => {
        console.log("ANSWERS END OF USEEEFFECT", JSON.stringify(answers[10]))
    }, [answers]);
    const handleMouseIn = (type) => {
        setButtonHover(type)
    };

    const handleMouseOut = () => {
        setButtonHover(null)
    };

    const createQuestion = async (authKey = null) => {
        const formData = new FormData();
        // ?  Matomo user-registered-event
        _paq.push(['trackEvent', 'user-registered', 'user-registered'])

        let tempData = {
            'answers': answers
        }
        let url = ENV.REACT_APP_API_URL + "/answered-questions";
        if (authKey) {
            tempData.authKey = authKey;
            url = ENV.REACT_APP_API_URL + "/answered-questions/for/new-user"
        }
        formData.append(
            'data',
            JSON.stringify(tempData),
        );

        const {
            response,
            data
        } = await api(url, 'POST', formData, true);
        if (response.status === 200 && isFromMobileApp && !ReactSession.get('temp-auth-key')) {
            window.parent.postMessage('allow-back', '*');
        }
        if (!response || response.status == 401) {
            ReactSession.set('token', undefined);
            ReactSession.set('refreshToken', undefined);
            // history.push('/login');
            return;
        }

        if (response && response.status != '404') {
            // notify('Deine Antworten wurde erfolgreich gespeichert');
            let t = activeQuestion;
            t++;
            setActiveQuestion(t)
            // ReactSession.set('temp-auth-key', undefined);
            //history.push('/stellenanzeigen');
        } else {
            notify('API Fehler: ' + response.status, true);
        }
    }
    const getResultsZip = async () => {
        // setOnSearch(true);
        // setSearchActive(true);
        console.log('loading')


        let url = '';


        if (selectedAutocomplete && selectedAutocomplete.id) {
            console.log('zipcode only');
            // url = ENV.REACT_APP_API_URL + '/job-advertisements?filter[id]=' + selectedAutocomplete.id + '&filter[range]=' + currentRange + '&include=creator';
        } else if (!selectedAutocomplete) {
            console.log('search only');
            // url = ENV.REACT_APP_API_URL + '/job-advertisements?filter[search][like]=' + search + '&include=creator';
        } else if (selectedAutocomplete && selectedAutocomplete.id) {
            console.log('zipcode and search');
            // url = ENV.REACT_APP_API_URL + '/job-advertisements?filter[id]=' + selectedAutocomplete.id + '&filter[range]=' + currentRange + '&filter[search][like]=' + search + '&include=creator';
        } else {
            // setSearchActive(false);
            // getJobs();
            return;
            // url = ENV.REACT_APP_API_URL + '/job-advertisements' + '?include=creator';
        }
        console.log(url);
        let { response, data } = await api(url);
        console.log(data);
        let t = [];
        // if (data && data.data) {
        //     setItemsOverall(data.meta.pagination.total);
        //     await Promise.all(data.data.map(async (item, index) => {
        //         item.attributes.badges = JSON.parse(item.attributes.badges);
        //         let url = ENV.REACT_APP_API_URL + '/job-advertisement/' + item.id + '?include=companyAddresses,contacts';
        //
        //         //if (this.state.sort) url += '&sort=' + this.state.sort;
        //
        //         let temp = await api(url)
        //
        //         let data2 = temp.data;
        //
        //         if (data2 && data2.included) item.attributes.companyAddress = data2.included.filter(i => i.type == 'company-addresses') ? data2.included.filter(i => i.type == 'company-addresses') : [];
        //         if (data2 && data2.included) item.attributes.contacts = data2.included.filter(i => i.type == 'contacts') ? data2.included.filter(i => i.type == 'contacts') : [];
        //
        //         item.attributes.creator = data.included.filter(i => i.type == 'users' && i.id == item.attributes.creator_id)[0] ?? null;
        //         t.push(item)
        //     }));
        //     t.sort((a, b) => (parseInt(a.id) > parseInt(b.id)) ? 1 : -1)
        //     console.log(t);
        //     setJobs(t);
        // }

        // setTimeout(() => {
        //     setOnSearch(false);
        // }, 300)

    }
    const getResults = async (url, type, temp, index, childIndex, subIndex) => {
        // console.log('GET RESULTS')
        let t = [];
        let { response, data } = await api(url);
        if (data && data.data) {
            data.data.map((item, index) => {
                if (t.filter(i => i.title === item.attributes.autocompleteData).length === 0) {
                    t.push({
                        input: item.attributes.autocompleteData,
                        title: item.attributes.autocompleteData
                    })
                }
            })
        }

        if (type == 1) {
            temp[index].autocomplete = t;
        } else if (type == 2) {
            temp[index].answers[childIndex].sub[subIndex].autocomplete = t;
        }
        setQuestions(temp);
        setAutocompleteLoading(false);
    }

    //map
    // const defaultProps = {
    //     center: {
    //         lat: 50.99835602,
    //         lng: 77.01502627
    //     },
    //     zoom: 11
    // };
// Return map bounds based on list of places
    const getMapBounds = (map, maps, places) => {
        const bounds = new maps.LatLngBounds();

        // places.forEach((place) => {
        bounds.extend(new maps.LatLng(
            59.955413,
            30.955413,
        ));
        // });
        return bounds;
    };

// Re-center map when resizing the window
    const bindResizeListener = (map, maps, bounds) => {
        maps.event.addDomListenerOnce(map, 'idle', () => {
            maps.event.addDomListener(window, 'resize', () => {
                map.fitBounds(bounds);
            });
        });
    };

    useEffect(() => {
        apiIsLoaded()
    }, [defaultProps.center.lat, defaultProps.center.lng, currentRange])
// Fit map to its bounds after the api is loaded
    const apiIsLoaded = (map = currentMap, maps = currentMaps) => {
        if (!map || !maps) return
        // // Get bounds by our places
        // const bounds = getMapBounds(map, maps);
        // // Fit map to bounds
        // map.fitBounds(bounds);
        // // setCurrentMap(map)
        // // maps.LatLng(30, 30)
        if (currentCircle) currentCircle.setMap(null)
        if (currentRange < 100 && selectedAutocomplete) {
            setCurrentCircle(new maps.Circle({
                strokeColor: '#C7D300',
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: '#C7D300',
                fillOpacity: 0.3,
                map,
                center: { lat: defaultProps.center.lat, lng: defaultProps.center.lng, },
                radius: currentRange * 1000,
            }))
        } else if (currentRange >= 100 && selectedAutocomplete) {
            setCurrentCircle(new maps.Circle({
                strokeColor: '#C7D300',
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: '#C7D300',
                fillOpacity: 0.3,
                map,
                center: { lat: defaultProps.center.lat, lng: defaultProps.center.lng, },
                radius: 800 * 1000,
            }))
        }

        map.disableDefaultUI = true
        const test = new maps.LatLng(
            defaultProps.center.lat,
            defaultProps.center.lng,
        )
        //
        console.log(test)
        // test.zoom = 8
        // map.setCircle(circle)
        map.setOptions({ disableDefaultUI: true })
        map.setCenter(test)
        if (selectedAutocomplete) {
            if (currentRange < 20) {
                map.setZoom((isMobile) ? 10 : 10)
            } else if (currentRange < 40) {
                map.setZoom((isMobile) ? 8 : 9)
            } else if (currentRange < 70) {
                map.setZoom((isMobile) ? 7 : 8)
            } else if (currentRange < 100) {
                map.setZoom((isMobile) ? 6 : 7)
            } else {
                map.setZoom((isMobile) ? 4 : 4)
            }
        }


        // Bind the resize listener
        // bindResizeListener(map, maps, bounds);
        setCurrentMap(map)
        setCurrentMaps(maps)
    };
    const handleKeypressEnter = e => {
        //it triggers by pressing the enter key
        if (e.charCode === 13) {
            getResultsZip();
        }
    };

    const getZipcodesById = async (url, newInputValue) => {
        // let t = [];
        url += '?filter%5Bid%5D%5Beq%5D=' + newInputValue;

        console.log(url);


        let { response, data } = await api(url);
        console.log("FILTER RESPONSE DATA", data);

        // if (data && data.data) {
        //     data.data.map((item, index) => {
        //         if (t.filter(i => i.attributes.zipcode == item.attributes.zipcode && i.attributes.name == item.attributes.name).length == 0) t.push(item);
        //     })
        //     console.log(t);
        //     setAutocomplete([]);
        //     setAutocomplete(t);
        //     setAutocompleteLoading(false);
        // }
        setSelectedAutocomplete(data.data[0])
        const props = {
            center: {
                lat: parseFloat(data.data[0].attributes.lat),
                lng: parseFloat(data.data[0].attributes.long)
            },
            zoom: 5
        }
        setDefaultProps(props)
        // return data.data
    }

    const getZipcodes = async (url, newInputValue) => {
        let t = [];
        if (isNumeric(newInputValue)) {
            url += '?filter%5Bzipcode%5D%5Beq%5D=' + newInputValue;
        } else {
            url += '?filter%5Bname%5D%5Blike%5D=' + encodeURIComponent(newInputValue);
        }

        console.log(url);


        let { response, data } = await api(url);
        console.log(data);

        if (data && data.data) {
            data.data.map((item, index) => {
                if (t.filter(i => i.attributes.zipcode == item.attributes.zipcode && i.attributes.name == item.attributes.name).length == 0) t.push(item);
            })
            console.log(t);
            setAutocomplete([]);
            setAutocomplete(t);
            setAutocompleteLoading(false);
        }
    }

    const debouncedChangeHandler = useMemo(
        () => debounce(getResults, 400)
        , []);

    const debouncedChangeHandlerZip = useMemo(
        () => debounce(getZipcodes, 400)
        , []);

    const handleKeypress = (e, type) => {
        //it triggers by pressing the enter key
        if (e.charCode === 13 && type == 'register') {
            loginRef.current.reg(mail, accountType, accountType !== 'Arbeitgeber' ? null : company, password, passwordVerify, false).then(async (e) => {
                if (e.success) {
                    await createQuestion(ReactSession.get('temp-auth-key'));
                    if (accountType === 'Arbeitgeber') history.push('/login');
                    // else history.push('/')
                } else {
                    setMailError(e.mError);
                    setCompanyError(e.coError);
                    setPasswordError(e.pError);
                    setPasswordVerifyError(e.pVError);
                }
            })
        } else if (e.charCode === 13 && type == 'login') {
            loginRef.current.logIn(mail, password).then((e) => {
                setMailError(e.mError);
                setPasswordError(e.pError);
                setActiveStep(2);

                setCompanyName(e.user?.companyName);
                setStraße(e.user?.straße);
                setHausnummer(e.user?.hausnummer);
                setPostleitzahl(e.user?.postleitzahl);
                setOrt(e.user?.ort);

                if (e.success) {
                    let t = activeQuestion;
                    t++;
                    createQuestion();
                    history.push('/übersicht')
                }
            });
        }//else history.push('/Login')
    };

    const doResize = () => {
        const doc = document.documentElement
        doc.style.setProperty('--vh', (window.innerHeight * .01) + 'px');
    }

    useEffect(() => {

        if (isMobile) {
            window.addEventListener('resize', doResize);
            doResize();
        }

        return () => {
            ReactSession.set('temp-auth-key', undefined)
            if (isMobile) {
                window.removeEventListener('resize', doResize);
                const doc = document.documentElement
                doc.style.removeProperty('--vh');
            }
        }
    }, [])


    async function fetchSectorData() {
        const url = ENV.REACT_APP_API_URL + '/jobTitles?target=sector'
        const sector = await api(url)
        const mappedNames = sector.data.data.filter(item => item.attributes.sector != '').map((item) => {
            return item.attributes.sector
        })
        setSectors(mappedNames)

        const temp = sixtyOne
        temp.sectors = mappedNames
        setSixtyOne(temp)
        console.log("SECTOR", mappedNames)
    }

    async function fetchTopCategoryData(sectorName, questionId, isChange = false) {
        let url = ENV.REACT_APP_API_URL + '/jobTitles?target=topCategory'
        if (sectorName) url += '&' + new URLSearchParams({
            'filter[sector][eq]': sectorName
        })
        const response = await api(url)

        // if(topCategories.data.data.length) {
        const mappedNamesTopCat = response.data.data.filter(item => item.attributes.topCategory != '').map((item) => {
            return item.attributes.topCategory
        })

        // const tempData = sectorData
        // if (tempData && !mappedNamesTopCat[0]) {
        //     tempData.topCategory = ''
        //
        //     setSectorData(tempData)
        // }

        // if (JSON.stringify(mappedNamesTopCat) !== JSON.stringify(topCategories[questionId])) {
        //     fetchCategoryData()
        // }

        // const questionIndex = questions.findIndex(quest => quest.id == questionId)
        // if(questionIndex > -1) {
        //     console.log("LOGG", questions[questionIndex]?.selected.autocomplete[0]?.subAutocomplete)
        // }

        const tempTopCat = { ...topCategories }
        tempTopCat[questionId] = mappedNamesTopCat
        setTopCategories(tempTopCat)
        console.log("topcat", tempTopCat)

        if (isChange) {
            console.log("17teste")
            if (questionId == 6) {
                let tq = [...questions];
                const index = tq.findIndex(quest => quest.id == questionId)
                let t = tq[index].selected.answer;

                t = 'Alle Oberkategorien';
                const answerOberId = tq[index].answers.find(item => item.label == 'Oberkategorie')?.id ?? null
                if (answerOberId) {
                    console.log("17test", answerOberId)
                    const indexUsed = tq[index].selected.autocomplete[0].subAutocomplete.findIndex(item => item.answerId == answerOberId)
                    console.log("17test", indexUsed)
                    if (indexUsed > -1) {
                        tq[index].selected.autocomplete[0].subAutocomplete[indexUsed].answer = t;
                    } else {
                        tq[index].selected.autocomplete[0].subAutocomplete.push({
                            id: questions[index]?.id,
                            answer: t,
                            answerId: answerOberId
                        })
                    }
                }
                t = 'Alle Kategorien'
                const answerId = tq[index].answers.find(item => item.label == 'Kategorie')?.id ?? null
                if (answerId) {
                    const indexDelete = tq[index].selected.autocomplete[0].subAutocomplete?.findIndex(item => item.answerId == answerId)
                    if (indexDelete > -1) {
                        tq[index].selected.autocomplete[0].subAutocomplete[indexDelete].answer = t;
                    } else if (tq[index].selected.autocomplete[0].subAutocomplete) {
                        tq[index].selected.autocomplete[0].subAutocomplete.push({
                            id: questions[index]?.id,
                            answer: t,
                            answerId: answerId
                        })
                    } else {
                        tq[index].selected.autocomplete[0].subAutocomplete = [{
                            id: questions[index]?.id,
                            answer: t,
                            answerId: answerId
                        }]
                    }
                }
                // if (indexDelete > -1) tq[index].selected.autocomplete[0].subAutocomplete.splice(indexDelete, 1)
                // tq[index].selected.answer = t;
                setQuestions(tq);
            } else if (questionId == 3 || questionId == 16) {
                let tq = [...questions];
                const index = tq.findIndex(quest => quest.id == questionId)
                let t = tq[index].selected.answer;

                t = 'Alle Oberkategorien';
                const answerOberId = tq[index].answers.find(item => item.label == 'Oberkategorie')?.id ?? null
                if (answerOberId) {
                    const indexUsed = tq[index].selected.autocomplete[0]?.subAutocomplete?.findIndex(item => item.answerId == answerOberId)
                    if (indexUsed > -1) {
                        tq[index].selected.autocomplete[0].subAutocomplete[indexUsed].answer = t;
                    } else if (tq[index].selected.autocomplete[0]?.subAutocomplete) {
                        tq[index].selected.autocomplete[0].subAutocomplete.push({
                            id: questions[index]?.id,
                            answer: t,
                            answerId: answerOberId
                        })
                    } else {
                        tq[index].selected.autocomplete[0].subAutocomplete = [{
                            id: questions[index]?.id,
                            answer: t,
                            answerId: answerOberId
                        }]
                    }
                }

                t = 'Alle Kategorien';
                const answerId = tq[index].answers.find(item => item.label == 'Kategorie')?.id ?? null
                if (answerId) {
                    const indexDelete = tq[index].selected.autocomplete[0].subAutocomplete?.findIndex(item => item.answerId == answerId)
                    if (indexDelete > -1) {
                        tq[index].selected.autocomplete[0].subAutocomplete[indexDelete].answer = t;
                    } else if (tq[index].selected.autocomplete[0].subAutocomplete) {
                        tq[index].selected.autocomplete[0].subAutocomplete.push({
                            id: questions[index]?.id,
                            answer: t,
                            answerId: answerId
                        })
                    } else {
                        tq[index].selected.autocomplete[0].subAutocomplete = [{
                            id: questions[index]?.id,
                            answer: t,
                            answerId: answerId
                        }]
                    }
                }
                // tq[index].selected.answer = t;
                setQuestions(tq);
            }
            console.log("17test", questions)
        }

    }

    async function fetchCategoryData(sectorName, questionId, topCatName) {
        let url = ENV.REACT_APP_API_URL + '/jobTitles?target=category'
        if (topCatName) {
            url += '&' + new URLSearchParams({
                'filter[topCategory][eq]': topCatName
            })
        } else if (sectorName) {
            url += '&' + new URLSearchParams({
                'filter[sector][eq]': sectorName
            })
        }
        const categories = await api(url)
        const mappedNamesCat = categories.data.data.filter(item => item.attributes.category != '').map((item) => {
            return item.attributes.category
        })

        const tempCat = { ...categories }
        tempCat[questionId] = mappedNamesCat
        setCategories(tempCat)
        console.log("cat", mappedNamesCat)
        console.log("17test")
        // if (questionId == 6) {
        //     let tq = [...questions];
        //     const index = tq.findIndex(quest => quest.id == questionId)
        //     let t = tq[index].selected.answer;
        //
        //     t = 'Alle Kategorien'
        //     const answerId = tq[index].answers.find(item => item.label == 'Kategorie')?.id ?? null
        //     if (answerId) {
        //         const indexDelete = tq[index].selected.autocomplete[0].subAutocomplete?.findIndex(item => item.answerId == answerId)
        //         if (indexDelete > -1) {
        //             tq[index].selected.autocomplete[0].subAutocomplete[indexDelete].answer = t;
        //         } else if (tq[index].selected.autocomplete[0].subAutocomplete) {
        //             tq[index].selected.autocomplete[0].subAutocomplete.push({
        //                 id: questions[index]?.id,
        //                 answer: t,
        //                 answerId: answerId
        //             })
        //         } else {
        //             tq[index].selected.autocomplete[0].subAutocomplete = [{
        //                 id: questions[index]?.id,
        //                 answer: t,
        //                 answerId: answerId
        //             }]
        //         }
        //     }
        //     // if (indexDelete > -1) tq[index].selected.autocomplete[0].subAutocomplete.splice(indexDelete, 1)
        //     // tq[index].selected.answer = t;
        //     setQuestions(tq);
        // } else if (questionId == 3 || questionId == 16) {
        //     let tq = [...questions];
        //     const index = tq.findIndex(quest => quest.id == questionId)
        //     let t = tq[index].selected.answer;
        //
        //     t = 'Alle Kategorien';
        //     const answerId = tq[index].answers.find(item => item.label == 'Kategorie')?.id ?? null
        //     if (answerId) {
        //         const indexDelete = tq[index].selected.autocomplete[0].subAutocomplete?.findIndex(item => item.answerId == answerId)
        //         if (indexDelete > -1) {
        //             tq[index].selected.autocomplete[0].subAutocomplete[indexDelete].answer = t;
        //         } else if (tq[index].selected.autocomplete[0].subAutocomplete) {
        //             tq[index].selected.autocomplete[0].subAutocomplete.push({
        //                 id: questions[index]?.id,
        //                 answer: t,
        //                 answerId: answerId
        //             })
        //         } else {
        //             tq[index].selected.autocomplete[0].subAutocomplete = [{
        //                 id: questions[index]?.id,
        //                 answer: t,
        //                 answerId: answerId
        //             }]
        //         }
        //     }
        //     // tq[index].selected.answer = t;
        //     setQuestions(tq);
        // }
    }

    async function fetchSOTopCategoryData(sectorName, soIndex, isChange = false) {
        let url = ENV.REACT_APP_API_URL + '/jobTitles?target=topCategory'
        if (sectorName) url += '&' + new URLSearchParams({
            'filter[sector][eq]': sectorName
        })
        const response = await api(url)

        // if(topCategories.data.data.length) {
        const mappedNamesTopCat = response.data.data.filter(item => item.attributes.topCategory != '').map((item) => {
            return item.attributes.topCategory
        })

        // const tempData = sectorData
        // if (tempData && !mappedNamesTopCat[0]) {
        //     tempData.topCategory = ''
        //
        //     setSectorData(tempData)
        // }

        // if (JSON.stringify(mappedNamesTopCat) !== JSON.stringify(topCategories[questionId])) {
        //     fetchCategoryData()
        // }

        // const questionIndex = questions.findIndex(quest => quest.id == questionId)
        // if(questionIndex > -1) {
        //     console.log("LOGG", questions[questionIndex]?.selected.autocomplete[0]?.subAutocomplete)
        // }

        const temp = sixtyOne
        temp.topCategories[soIndex] = mappedNamesTopCat
        setSixtyOne(temp)
        console.log("topcat", temp)

        if (isChange) {
            console.log("17test")

            let tq = [...questions];
            const index = tq.findIndex(quest => quest.id == 61)
            let t = tq[index].selected.answer;

            t = 'Alle Oberkategorien';
            const answerOberId = tq[index].answers.find(item => item.label == 'Oberkategorie')?.id ?? null
            if (answerOberId) {
                const indexUsed = tq[index].selected.autocomplete[soIndex]?.subAutocomplete?.findIndex(item => item.answerId == answerOberId)
                if (indexUsed > -1) {
                    tq[index].selected.autocomplete[soIndex].subAutocomplete[indexUsed].answer = t;
                } else if (tq[index].selected.autocomplete[soIndex].subAutocomplete) {
                    tq[index].selected.autocomplete[soIndex].subAutocomplete.push({
                        id: questions[index]?.id,
                        answer: t,
                        answerId: answerOberId
                    })
                } else {
                    tq[index].selected.autocomplete[soIndex].subAutocomplete = [{
                        id: questions[index]?.id,
                        answer: t,
                        answerId: answerOberId
                    }]
                }
            }

            t = 'Alle Kategorien'
            const answerId = tq[index].answers.find(item => item.label == 'Kategorie')?.id ?? null
            if (answerId) {
                const indexDelete = tq[index].selected.autocomplete[soIndex].subAutocomplete?.findIndex(item => item.answerId == answerId)
                if (indexDelete > -1) {
                    tq[index].selected.autocomplete[soIndex].subAutocomplete[indexDelete].answer = t;
                } else if (tq[index].selected.autocomplete[soIndex].subAutocomplete) {
                    tq[index].selected.autocomplete[soIndex].subAutocomplete.push({
                        id: questions[index]?.id,
                        answer: t,
                        answerId: answerId
                    })
                } else {
                    tq[index].selected.autocomplete[soIndex].subAutocomplete = [{
                        id: questions[index]?.id,
                        answer: t,
                        answerId: answerId
                    }]
                }
            }


            // tq[index].selected.answer = t;
            setQuestions(tq);

            console.log("17test", questions)
        }

    }

    async function fetchSOCategoryData(sectorName, soIndex, topCatName) {
        let url = ENV.REACT_APP_API_URL + '/jobTitles?target=category'
        if (topCatName) {
            url += '&' + new URLSearchParams({
                'filter[topCategory][eq]': topCatName
            })
        } else if (sectorName) {
            url += '&' + new URLSearchParams({
                'filter[sector][eq]': sectorName
            })
        }
        const categories = await api(url)
        const mappedNamesCat = categories.data.data.filter(item => item.attributes.category != '').map((item) => {
            return item.attributes.category
        })

        const temp = sixtyOne
        temp.categories[soIndex] = mappedNamesCat
        setSixtyOne(temp)
        console.log("cat", mappedNamesCat)
        console.log("17test")

        let tq = [...questions];
        const index = tq.findIndex(quest => quest.id == 61)
        let t = tq[index].selected.answer;

        t = 'Alle Kategorien'
        const answerId = tq[index].answers.find(item => item.label == 'Kategorie')?.id ?? null
        if (answerId) {
            const indexDelete = tq[index].selected.autocomplete[soIndex].subAutocomplete?.findIndex(item => item.answerId == answerId)
            if (indexDelete > -1) {
                tq[index].selected.autocomplete[soIndex].subAutocomplete[indexDelete].answer = t;
            } else if (tq[index].selected.autocomplete[soIndex].subAutocomplete) {
                tq[index].selected.autocomplete[soIndex].subAutocomplete.push({
                    id: questions[index]?.id,
                    answer: t,
                    answerId: answerId
                })
            } else {
                tq[index].selected.autocomplete[soIndex].subAutocomplete = [{
                    id: questions[index]?.id,
                    answer: t,
                    answerId: answerId
                }]
            }
        }


        // tq[index].selected.answer = t;
        setQuestions(tq);

    }


    useEffect(() => {

        fetchSectorData()
    }, [])

    // useEffect(() => {
    //     fetchTopCategoryData()
    // }, [sectorData?.sector])
    //
    // useEffect(() => {
    //     fetchCategoryData()
    // }, [sectorData?.topCategory])


    const validatePhonenumber = (vorwahl, mobilnummer) => {
        let number = vorwahl + mobilnummer;
        const phoneNumber = parsePhoneNumber(number);
        // if (phoneNumber && phoneNumber.isValid() === true && phoneNumber.getType() === 'MOBILE') {
        if (phoneNumber && phoneNumber.isValid() === true && (phoneNumber.getType() === 'FIXED_LINE' || phoneNumber.getType() === 'MOBILE')) {
            setMobilnummerError('');
            return phoneNumber.formatInternational();
        } else if (phoneNumber && phoneNumber.isValid() === true) {
            setMobilnummerError('Keine Mobilnummer');
            return phoneNumber.formatInternational();
        } else {
            setMobilnummerError('Keine Mobilnummer');
        }
        return false;
    }

    const sendPhonenumber = async () => {
        let number = validatePhonenumber(vorwahl, mobilnummer);
        if (number) {


            let attributes = {
                data: {
                    mobilnummer: number,
                    auth_key: ReactSession.get('temp-auth-key')
                }
            }

            let url = ENV.REACT_APP_API_URL + "/user/send-mobilnummer";
            const { response, data } = await api(url, 'POST', JSON.stringify(attributes));

            if (response && response.status === 200 && data) {

                if (data.data && data.data.next_try && data.data.next_try) {

                    let next_try = data.data.next_try;
                    let now = moment().unix();

                    let diff = next_try - now;
                    setCounter(diff);
                } else {
                    setRequestSent(true);
                    setCounter(5);
                }


            } else {
                notify(data.errors[0].detail ?? data.errors, true);
            }


        }
    }

    const sendConfirmcode = async (confirmCode) => {
        let attributes = {
            data: {
                confirm_code: confirmCode,
                auth_key: ReactSession.get('temp-auth-key')
            }
        }

        let url = ENV.REACT_APP_API_URL + "/user/confirm-mobilnummer";
        const { response, data } = await api(url, 'POST', JSON.stringify(attributes));

        if (response && response.status == 200) {
            setFinished(true);
        } else if (data && data.errors && data.errors[0] && data.errors[0].detail == 'Code ist abgelaufen') {
            notify(data.errors[0].detail, true);
        }
    }
    // ? Params for user coming from AN-Mobile app
    // ? required for not showing "back" button & redirecting back to App
    const urlParams = new URLSearchParams(window.location.search);
    const isFromMobileApp = urlParams.get('mobile') === 'true'
    const isNativeMobile = urlParams.get('isNative') === 'true'


    return (
        <>
            <Grid
                id={'anonymSwiitchenWrapper'}
                container
                style={{
                    display: 'block',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: '#00374e',
                    position: 'relative',
                    padding: isMobile ? ((height / 50) + 64) + 'px 0 70px' : '100px 0 50px',
                }}>
                {/*{isMobile && <SmallLogo*/}
                {/*    className={'questions-logo-small'}*/}
                {/*    onClick={() => (ReactSession.get('user') === 'Arbeitnehmer') ? history.push('/matches') : window.location.href = 'https://www.swiitcher.de/'}*/}
                {/*/>}*/}

                {!isMobile && <BigLogo
                    className={'question-logo-big'}
                    id={'logoHeaderWhite'}
                    onClick={() => (ReactSession.get('user') === 'Arbeitnehmer') ? history.push('/matches') : window.location.href = 'https://www.swiitcher.de/'}
                />
                }
                {!isMobile && !isFromMobileApp && <WestIcon
                    onClick={() => {
                        if(history.length > 1) return history.goBack()
                        window.location.href = 'https://www.swiitcher.de/'
                    }}
                    className={'arrow-back-button-anonym-swiitchen-desktop'}
                />}

                {isMobile && !isFromMobileApp && <WestIcon
                    className={'arrow-back-button-anonym-swiitchen-mobile'}
                    onClick={() => {
                        if(history.length > 1) return history.goBack()
                        window.location.href = 'https://www.swiitcher.de/'
                    }}
                />}


                {(!questions || questions.length == 0) ? <>
                    <div ref={loadingSpinnerRef}
                         style={{
                             color: 'white',
                             paddingTop: 100,
                             paddingLeft: 0,
                             paddingRight: 100,
                             paddingBottom: 100,
                             width: '100%',
                             display: 'flex',
                             justifyContent: 'center',
                             flexDirection: 'column'
                         }}>
                        <div id={'testLogo'} style={{
                            borderRadius: 50,
                            width: 60,
                            height: 60,
                            minWidth: 60,
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                            border: '4px solid transparent',
                            transitionDuration: '0.2s',
                            transitionProperty: 'transform',
                            margin: '0 auto'
                        }}
                        >
                            <div style={{
                                padding: 7.5,
                                background: 'rgba(0, 55, 78, 1)',
                                borderRadius: 50,
                                width: 57,
                                minWidth: 27,
                                height: 35,
                                minHeight: 35,
                                justifyContent: 'center',
                                alignItems: 'center',
                                display: 'flex',
                                border: '5px solid rgba(199, 211, 0, 1)'
                            }}>
                                <TestLogo
                                    style={{
                                        width: 30,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </> : <></>}
                <Grid
                    item
                    xs={12}
                    style={{ display: 'block' }}>
                    {(questions && questions.length >= activeQuestion) ? <p
                        id={'question-number'}
                        style={{
                            fontSize: 64,
                            fontFamily: 'VagRoundedBold',
                            marginTop: 0,
                            minHeight: 89.6,
                            marginBottom: 0,
                            color: 'rgba(146,146,146,0.6)'
                        }}>
                        {activeQuestion && activeQuestion}
                    </p> : null}
                    {(questions && questions.length > 0 && questions[activeQuestion - 1] && questions[activeQuestion - 1].question != 'Antworten werden gespeichert') ?
                        <p
                            style={{
                                fontSize: 20,
                                fontFamily: 'VagRoundedBold',
                                color: '#C7D300',
                                padding: isMobile ? '0 20px' : 0,
                                minHeight: 28,
                                marginBottom: 0,
                            }}>
                            {(questions && (questions.length) >= activeQuestion && questions[activeQuestion - 1]) ? (questions[activeQuestion - 1].question) : <></>}
                            {(questions && questions[activeQuestion - 1] && questions[activeQuestion - 1] && questions[activeQuestion - 1].helperText) &&
                                <span style={{ color: 'white' }}>
                                                                    <Tooltip
                                                                        enterTouchDelay={0}
                                                                        title={questions[activeQuestion - 1].helperText}>
                                                                        <InfoIcon
                                                                            style={{ marginLeft: 4 }}
                                                                            fontSize={'14px'}
                                                                            id={'infoIcon'}
                                                                        />
                                                                    </Tooltip>
                                                                </span>
                            }
                        </p> : null}
                    {questions && questions[activeQuestion - 1] && questions[activeQuestion - 1].answers && questions[activeQuestion - 1].answers.filter(i => (i.type == 'multiple' || i.type == 'multipleDropdown')).length > 1 &&
                        <p
                            style={{
                                minHeight: 25,
                                height: 25,
                                margin: 0,
                                padding: 0,
                                fontSize: 10,
                                color: 'white'
                            }}>
                            {(questions && questions[activeQuestion - 1] && (questions[activeQuestion - 1].answers.filter(i => i.type === 'multiple') || questions[activeQuestion - 1].type === 'multipleDropdown')) ? '(Mehrere Antworten möglich)' : ''}
                        </p>
                    }
                    {((questions && (questions.length) < activeQuestion && questions.length !== 0) ? <>
                        <div style={{
                            color: 'white',
                            textAlign: 'left',
                            marginLeft: isMobile ? '10%' : '20%',
                            width: isMobile ? 'calc(100% - 20%)' : 'calc(100% - 40%)',
                            display: isMobile ? 'flex' : 'block',
                            flexDirection: isMobile ? 'column' : 'column',
                            alignItems: isMobile ? 'center' : 'start'
                        }}>
                            <div style={{ zIndex: 1 }}>
                                {(requestSent === false && !finished && ReactSession.get('temp-auth-key')) ? <>
                                    <h2 style={{
                                        textAlign: 'center',
                                        color: '#C7D300',
                                        fontFamily: 'VagRoundedBold'
                                        ,
                                    }}>Perfekt! Gleich geschafft...</h2>

                                    <p style={{
                                        textAlign: 'center', color: '#C7D300', marginBottom: 60,
                                    }}>Wir
                                        benachrichtigen dich per E-Mail und SMS
                                        über Anfragen, Jobangebote und wichtige
                                        Informationen</p>

                                    <div style={{
                                        width: 'auto', margin: 'auto',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        gap: '30px'
                                    }}>
                                        <p style={{
                                            fontSize: 12,
                                            color: 'white',
                                            marginBottom: 0,
                                            textAlign: 'left'
                                        }}>Gib eine Mobilnummer ein</p>

                                        <div style={{
                                            flexGrow: 1
                                        }}>
                                            <div style={{ display: 'inline-block' }}
                                                 className={(vorwahl && vorwahl.length > 0) ? 'filled' : ''}>
                                                <CssTextField5
                                                    label="Vorwahl"
                                                    select
                                                    style={{
                                                        transition: 'all 0.2s ease',
                                                        marginRight: 5,
                                                    }}
                                                    value={vorwahl}
                                                    defaultValue={areaCodes[0].value}
                                                    onChange={(e) => {
                                                        setVorwahl(e.target.value)
                                                        validatePhonenumber(e.target.value, mobilnummer);
                                                    }}
                                                    // onFocus={() => {
                                                    //     setIsShrink(['Passwort']);
                                                    // }}
                                                    // onBlur={() => {
                                                    //     setIsShrink([]);
                                                    // }}
                                                    // onChange={e => {
                                                    //     if (e.target.value.trim().length === 0) {
                                                    //         setPasswordError(true);
                                                    //     } else {
                                                    //         setPasswordError(false);
                                                    //     }
                                                    //     setPassword(e.target.value);
                                                    // }}
                                                    // onKeyPress={(e) => handleKeypress(e, 'register')}
                                                    // value={password}
                                                    // error={!!passwordError}
                                                    // helperText={passwordError === true ? 'Passwort darf nicht leer sein!' : passwordError}
                                                >
                                                    {areaCodes.map((option) => (
                                                        <MenuItem style={{ cursor: 'pointer' }}
                                                                  className={'areaCodeOptions'} key={option.value}
                                                                  value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </CssTextField5>
                                            </div>


                                            <div style={{ display: 'inline-block', position: 'relative' }}
                                                 className={(mobilnummer && mobilnummer.length > 0) ? 'filled' : ''}>

                                                <CssTextField6
                                                    label="Mobilnummer"
                                                    style={{
                                                        transition: 'all 0.2s ease',
                                                    }}
                                                    type="number"
                                                    InputLabelProps={{
                                                        shrink: true
                                                    }}
                                                    value={mobilnummer}
                                                    // onFocus={() => {
                                                    //     setIsShrink(['Passwort']);
                                                    // }}
                                                    // onBlur={() => {
                                                    //     setIsShrink([]);
                                                    // }}
                                                    onChange={e => {
                                                        setMobilnummer(e.target.value);
                                                        validatePhonenumber(vorwahl, e.target.value);
                                                        if (counter > 0) setCounter(0);
                                                    }}
                                                    // onKeyPress={(e) => handleKeypress(e, 'register')}
                                                    // value={password}
                                                    error={!!mobilnummerError}
                                                    helperText={mobilnummerError === true ? 'Passwort darf nicht leer sein!' : mobilnummerError}
                                                />
                                            </div>
                                            {!mobilnummerError ? <p style={{
                                                fontFamily: 'VagRoundedBold',

                                                width: '100%',
                                                fontSize: 8,
                                                color: '#C7D300',
                                                marginTop: 2,
                                                marginBottom: 0,
                                                textAlign: 'center',
                                                flexGrow: 1
                                            }}>Deine Daten bleiben anonym.</p> : null}
                                        </div>
                                        <div style={{
                                            display: 'inline-block',
                                            position: 'relative',
                                            marginLeft: 5,
                                            width: '100.85px'
                                        }}>
                                            <div style={{
                                                display: 'inline-flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                height: '100%',
                                                position: 'absolute',
                                                top: 4,
                                                left: 0
                                            }}>
                                                <CustomButton
                                                    buttontype={'yellowWhite2'}
                                                    style={{ padding: '1.7px 15px' }}
                                                    onClick={() => {
                                                        sendPhonenumber();
                                                        // confirmSMS();
                                                    }}
                                                    disabled={counter > 0}
                                                >Bestätigen</CustomButton>
                                            </div>
                                            {counter > 0 ? <p style={{
                                                color: 'white',
                                                fontSize: 10,
                                                marginBottom: 5,
                                                position: 'absolute',
                                                bottom: '-42px',
                                                left: '15px',
                                            }}>verfügbar in {counter}s</p> : null}

                                        </div>


                                        <p className={'disable-selecting'}
                                           style={{
                                               color: 'white',
                                               textDecoration: 'underline',
                                               marginTop: 50,
                                               cursor: 'pointer',
                                               wordBreak: 'break-word',
                                               whiteSpace: 'normal',
                                               textAlign: 'center'
                                           }}
                                           onClick={() => setMailOnly(!mailOnly)}>Du möchtest nicht per SMS
                                            benachrichtigt werden?</p>


                                        {mailOnly ? <>

                                            <FormControlLabel className={'useMailOnly disable-selecting'}
                                                              onClick={(e) => {
                                                                  e.preventDefault()
                                                                  let t = !useMailOnly;
                                                                  setUseMailOnly(t);

                                                                  if (t) {
                                                                      setFinished(true);
                                                                  }


                                                              }} control={<Radio checked={useMailOnly}/>}
                                                              label="Ich möchte nur per E-Mail benachrichtigt werden"/>
                                        </> : null}

                                    </div>
                                </> : null}

                                {(requestSent && !finished && ReactSession.get('temp-auth-key')) ? <>
                                    <h2 style={{
                                        textAlign: 'center',
                                        color: 'white',
                                        fontFamily: 'VagRoundedBold'
                                    }}>Code eingeben</h2>

                                    <p style={{ textAlign: 'center', color: 'white', marginBottom: 60 }}>Wir haben dir
                                        einen 6-stelligen Code per SMS
                                        an {vorwahl}{mobilnummer} gesendet.</p>

                                    <div style={{ width: 232, margin: 'auto', position: 'relative' }}>


                                        <p style={{
                                            fontSize: 12,
                                            color: 'white',
                                            marginBottom: 0,
                                            textAlign: 'left'
                                        }}>Code eingeben</p>

                                        <div style={{ display: 'inline-block', width: '100%', height: 54 }}>
                                            <CodeInput value={code} setValue={setCode}/>
                                        </div>

                                        <p className={'disable-selecting'} style={{
                                            color: 'white',
                                            textDecoration: 'underline',
                                            marginTop: 50,
                                            cursor: 'pointer'
                                        }} onClick={() => setResendCode(!resendCode)}>Du hast keinen Code erhalten?</p>

                                        <div style={{ height: 54 }}>
                                            {resendCode ? <>
                                                <CustomButton
                                                    disabled={counter > 0}
                                                    // disabled={requestSent}
                                                    buttontype={'yellowWhite2'}
                                                    style={{ padding: '1.7px 15px' }}
                                                    onClick={() => {
                                                        sendPhonenumber();
                                                        // confirmSMS();
                                                    }}
                                                >Code erneut senden</CustomButton>
                                                {counter > 0 ? <p className={'disable-selecting'} style={{
                                                    color: 'white',
                                                    fontSize: 10,
                                                    marginBottom: 5
                                                }}>verfügbar in {counter}s</p> : null}
                                            </> : null}
                                        </div>


                                    </div>
                                </> : null}
                            </div>


                            {(requestSent && !finished && ReactSession.get('temp-auth-key')) ?
                                <div id={'test'} style={{ textAlign: 'left', zIndex: 2 }}>
                                    <IconButton
                                        style={{
                                            textAlign: 'left',
                                            height: 40,
                                            width: 40,
                                            fontSize: 40,
                                            padding: 15,
                                            marginLeft: isMobile ? 10 : '5%',
                                            transition: 'all 0.05s ease-in-out',
                                            color: '#C7D300',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => {
                                            setRequestSent(false);
                                        }}
                                        // disabled={requestSent === false}
                                    >
                                        <WestIcon fontSize={'100px'}/>
                                    </IconButton>

                                </div> : null}


                            {(finished || !ReactSession.get('temp-auth-key')) ? <>
                                {showLoadingspinner && ReactSession.get('temp-auth-key') ? <div ref={loadingSpinnerRef}
                                                                                                style={{
                                                                                                    color: 'white',
                                                                                                    paddingTop: 100,
                                                                                                    paddingLeft: isMobile ? 100 : 0,
                                                                                                    paddingRight: 100,
                                                                                                    paddingBottom: 100,
                                                                                                    width: '100%',
                                                                                                    display: 'flex',
                                                                                                    justifyContent: 'center',
                                                                                                    flexDirection: 'column'
                                                                                                }}>
                                    <div id={'testLogo'} style={{
                                        borderRadius: 50,
                                        width: 60,
                                        height: 60,
                                        minWidth: 60,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        display: 'flex',
                                        border: '4px solid transparent',
                                        transitionDuration: '0.2s',
                                        transitionProperty: 'transform',
                                        margin: '0 auto'
                                    }}
                                    >
                                        <div style={{
                                            padding: 7.5,
                                            background: 'rgba(0, 55, 78, 1)',
                                            borderRadius: 50,
                                            width: 57,
                                            minWidth: 27,
                                            height: 35,
                                            minHeight: 35,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            display: 'flex',
                                            border: '5px solid rgba(199, 211, 0, 1)'
                                        }}>
                                            <TestLogo
                                                style={{
                                                    width: 30,
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div> : <>
                                    {ReactSession.get('temp-auth-key') ? <>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    gap: '50px',
                                                    height: '70vh',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <div>
                                                    <CheckIcon
                                                        style={{
                                                            backgroundColor: '#adb800',
                                                            padding: '15px',
                                                            borderRadius: '50%',
                                                            height: '50px',
                                                            width: '50px',
                                                            fill: 'rgb(0,55,78)',
                                                            margin: 'auto',
                                                            display: 'block',
                                                            marginBottom: '50px'
                                                        }}
                                                    />

                                                    <p style={{
                                                        color: '#adb800',
                                                        fontFamily: 'VagRoundedBold',
                                                        margin: 0
                                                        ,
                                                        textAlign: 'center',
                                                        fontSize: '1.3rem',
                                                        marginBottom: '20px'
                                                    }}>Bestätige deine E-Mail-Adresse.</p>
                                                    <p style={{ margin: 0, textAlign: 'center' }}>Dazu haben wir dir eine
                                                        E-Mail gesendet. </p>
                                                        <div style={{ position: 'fixed', width: '100dvw', top: 0, left: 0, display: 'flex', justifyContent: 'center'}}>
                                                            <Alert style={{backgroundColor: 'rgb(211, 211, 211)'}} severity="warning">
                                                                <AlertTitle>Achtung</AlertTitle>
                                                                Checke ggf.
                                                                auch deinen Spam- oder Junk-Ordner.
                                                            </Alert>
                                                        </div>

                                                </div>
                                                {!isNativeMobile && <div style={{
                                                    display: 'flex',
                                                    gap: '20px',
                                                    flexDirection: 'column',
                                                    alignItems: 'center'
                                                }}>
                                                    <p
                                                        style={{
                                                            fontFamily: 'VagRoundedBold',
                                                            margin: 0
                                                            ,
                                                            textAlign: 'center',
                                                            fontSize: '1.3rem',
                                                            marginBottom: '20px',
                                                            color: 'white'
                                                        }}
                                                    >
                                                        Lad dir jetzt die App herunter und finde deinen Traumjob!
                                                    </p>
                                                    <div style={{
                                                        display: 'flex',
                                                        gap: '20px',
                                                    }}>
                                                        <button
                                                            onClick={() => {
                                                                window.open('https://apps.apple.com/de/app/swiitcher-anonyme-jobsuche/id6477400482', '_blank')
                                                            }}
                                                            style={{
                                                                backgroundColor: 'inherit',
                                                                border: 'none',
                                                                cursor: 'pointer'
                                                            }}
                                                        >
                                                            <AppStore

                                                            />
                                                        </button>

                                                        <button
                                                            onClick={() => {
                                                                window.open('https://play.google.com/store/apps/details?id=de.swiitcher.app', '_blank')
                                                            }}
                                                            style={{
                                                                backgroundColor: 'inherit',
                                                                border: 'none',
                                                                cursor: 'pointer'
                                                            }}
                                                        >
                                                            <GooglePlay/>
                                                        </button>
                                                    </div>
                                                </div>}
                                                <button style={{
                                                    fontFamily: 'VagRoundedBold',
                                                    background: 'rgb(199, 211, 0)',
                                                    cursor: 'pointer',
                                                    padding: '10px',
                                                    borderRadius: '50px',
                                                    color: 'rgb( 0 ,55,78)',
                                                    border: '2px solid rgb(199,211, 0 )',
                                                    fontSize: '1rem',
                                                }}
                                                        onClick={() => {
                                                            if (isFromMobileApp) window.parent.postMessage('redirect', '*');
                                                            else history.replace('/Login')
                                                        }}
                                                >
                                                    Weiter zum Login
                                                </button>
                                            </div>

                                        </> :
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                gap: '50px',
                                                height: '70vh',
                                                justifyContent: 'center',
                                                alignItems: 'center'
                                            }}
                                        >
                                            <div>
                                                <CheckIcon
                                                    style={{
                                                        backgroundColor: '#adb800',
                                                        padding: '15px',
                                                        borderRadius: '50%',
                                                        height: '50px',
                                                        width: '50px',
                                                        fill: 'rgb(0,55,78)',
                                                        margin: 'auto',
                                                        display: 'block',
                                                        marginBottom: '50px'
                                                    }}
                                                />

                                                <p style={{
                                                    color: '#adb800',
                                                    fontFamily: 'VagRoundedBold',
                                                    margin: 0,
                                                    textAlign: 'center',
                                                    fontSize: '1.3rem',
                                                    marginBottom: '20px'
                                                }}>Gespeichert!</p>

                                                <p style={{ margin: 0, textAlign: 'center' }}>Du hast dein anonymes
                                                    Profil erfolgreich aktualisiert</p>

                                            </div>
                                            <button style={{
                                                fontFamily: 'VagRoundedBold',
                                                background: 'rgb(199, 211, 0)',
                                                cursor: 'pointer',
                                                padding: '10px',
                                                borderRadius: '50px',
                                                color: 'rgb( 0 ,55,78)',
                                                border: '2px solid rgb(199,211, 0 )',
                                                fontSize: '1rem',
                                            }}
                                                    onClick={() => {
                                                        if (isFromMobileApp) window.parent.postMessage('redirect', '*');
                                                        else history.replace('/übersicht')
                                                    }}
                                            >
                                                Zur Übersicht
                                            </button>
                                        </div>
                                    }


                                </>}
                            </> : null}
                        </div>
                    </> : null)}
                </Grid>
                <Grid
                    item
                    xs={12}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                    }}
                >
                    {questions && questions.map((item, index) => {
                        if (item.answers && item.answers.filter(i => i.type == 'logos').length > 0) {
                            let subquestions = [...showSubQuestion];
                            return (
                                <div key={index} style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'flex-start',
                                    overflow: 'hidden',
                                    transition: 'all 0.3s ease-in-out',
                                    opacity: (activeQuestion === (index + 1)) ? 1 : 0,
                                    width: (activeQuestion === (index + 1)) ? 'auto' : 0,
                                    minHeight: 250,
                                    margin: (activeQuestion === (index + 1)) ? '20px auto 0' : 0,
                                    position: 'relative'
                                }}>
                                    {(questions.length >= activeQuestion) && questions[index].answers.map((childItem, childIndex) => {
                                        let Icon1 = item.logos[(childItem.answer + 'K')];
                                        let Icon2 = item.logos[(childItem.answer + 'F')];

                                        return (
                                            <div key={childIndex} style={{
                                                position: 'relative',
                                                zIndex: 3,
                                                float: 'left',
                                                display: 'block',
                                                width: isMobile ? width / 4 - 20 : 200,
                                                marginTop: 20,
                                                marginBottom: 20,
                                                transition: 'all 0.2s ease-in-out',
                                                minHeight: 320
                                            }} onMouseLeave={() => {
                                                if (onMouseOver == childItem.answer) {
                                                    setOnMouseOver(false)
                                                }
                                            }} onMouseOver={() => {
                                                if (onMouseOver != childItem.answer) {
                                                    setOnMouseOver(childItem.answer)
                                                }
                                            }}>
                                                <div style={{ position: 'relative', height: isMobile ? 100 : 200 }}>
                                                    <Icon1
                                                        height={isMobile ? 95 : 200}
                                                        style={{
                                                            position: 'absolute',
                                                            zIndex: 4,
                                                            transition: 'all 0.2s ease-in-out',
                                                            cursor: 'pointer',
                                                            opacity: questions[index].selected.answer.includes(childItem.answer) || (onMouseOver == childItem.answer && !isMobile) ? 0 : 1,
                                                            visibility: questions[index].selected.answer.includes(childItem.answer) || (onMouseOver == childItem.answer && !isMobile) ? 'hidden' : 'visible',
                                                            display: questions[index].selected.answer.includes(childItem.answer) || (onMouseOver == childItem.answer && !isMobile) ? 'none' : 'block',
                                                            marginLeft: 'auto',
                                                            marginRight: 'auto',
                                                            left: 0,
                                                            right: 0,
                                                            textAlign: 'center',
                                                        }}
                                                        onClick={() => {
                                                            let tq = [...questions];
                                                            let t = tq[activeQuestion - 1];
                                                            let temp = activeQuestion + 1;

                                                            if (!t.selected.answer.includes(childItem.answer)) {
                                                                let asd = t.selected;
                                                                asd.answer = [childItem.answer]
                                                                t.selected = asd;

                                                                if (t.selected.answer.length > 0) {
                                                                    setOnChangeQuestion(true);
                                                                    setActiveQuestion(temp)
                                                                }
                                                            } else if (t.selected.answer.includes(childItem.answer)) {
                                                                let asd = t.selected;
                                                                asd.answer = [];
                                                                t.selected = asd;
                                                            }

                                                            tq[activeQuestion - 1] = t;
                                                            setQuestions(tq);
                                                        }}
                                                    />
                                                    <Icon2
                                                        height={isMobile ? 95 : 200}
                                                        style={{
                                                            position: 'absolute',
                                                            zIndex: 2,
                                                            margin: 'auto',
                                                            transition: 'all 0.1s ease-in-out',
                                                            cursor: 'pointer',
                                                            opacity: questions[index].selected.answer.includes(childItem.answer) || (onMouseOver == childItem.answer && !isMobile) ? 1 : 0,
                                                            visibility: questions[index].selected.answer.includes(childItem.answer) || (onMouseOver == childItem.answer && !isMobile) ? 'visible' : 'hidden',
                                                            display: questions[index].selected.answer.includes(childItem.answer) || (onMouseOver == childItem.answer && !isMobile) ? 'block' : 'none',
                                                            marginLeft: 'auto',
                                                            marginRight: 'auto',
                                                            left: 0,
                                                            right: 0,
                                                            textAlign: 'center',
                                                        }}
                                                        onClick={() => {
                                                            let tq = [...questions];
                                                            let t = tq[activeQuestion - 1];
                                                            let temp = activeQuestion + 1;

                                                            if (!t.selected.answer.includes(childItem.answer)) {
                                                                let asd = t.selected;
                                                                asd.answer = [childItem.answer]
                                                                t.selected = asd;

                                                                if (t.selected.answer.length > 0) {
                                                                    setTimeout(() => {
                                                                        setOnChangeQuestion(true);
                                                                        setActiveQuestion(temp)
                                                                    }, 1000)
                                                                }
                                                            } else if (t.selected.answer.includes(childItem.answer)) {
                                                                let asd = t.selected;
                                                                asd.answer = [];
                                                                t.selected = asd;
                                                            }

                                                            tq[activeQuestion - 1] = t;
                                                            setQuestions(tq);
                                                        }}
                                                    />
                                                </div>
                                                <div style={{ margin: '10px auto', height: 35 }}>
                                                    <BpCheckbox
                                                        onClick={() => {
                                                            let tq = [...questions];
                                                            let t = tq[activeQuestion - 1];
                                                            let temp = activeQuestion + 1;

                                                            if (!t.selected.answer.includes(childItem.answer)) {
                                                                let asd = t.selected;
                                                                asd.answer = [childItem.answer]
                                                                t.selected = asd;

                                                                if (t.selected.length === t.answers.length) {
                                                                    setOnChangeQuestion(true);
                                                                    setActiveQuestion(temp)
                                                                }
                                                            } else if (t.selected.answer.includes(childItem.answer)) {
                                                                let asd = t.selected;
                                                                asd.answer = [];
                                                                t.selected = asd;
                                                            }

                                                            tq[activeQuestion - 1] = t;
                                                            setQuestions(tq);
                                                        }}
                                                        checked={questions[index].selected.answer && questions[index].selected.answer.includes(childItem.answer) ? true : false}
                                                    />
                                                </div>
                                                <span style={{ color: 'white' }}>{childItem.answer}</span>
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        }
                        if (item.answers && item.answers.filter(i => i.type == 'führerschein').length > 0) {
                            let subquestions = [...showSubQuestion];
                            return (
                                <>
                                    <div style={{ display: 'block' }}>
                                        <div key={index} style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'flex-start',
                                            overflow: 'hidden',
                                            transition: 'all 0.3s ease-in-out',
                                            opacity: (activeQuestion === (index + 1)) ? 1 : 0,
                                            width: (activeQuestion === (index + 1)) ? 'auto' : 0,
                                            minHeight: 200,
                                            margin: (activeQuestion === (index + 1)) ? '0 auto' : 0,
                                            maxWidth: 600
                                        }}>
                                            {(questions.length >= activeQuestion) && questions[index].answers.filter(i => i.type == 'führerschein' && i.label != 'Ich besitze keine gültige Fahrerlaubnis' && i.answer != 'Nein').map((childItem, childIndex) => {
                                                const Icon = (item.führerscheinLogos.hasOwnProperty(childItem.answer)) ? item.führerscheinLogos[childItem.answer] : null;
                                                if (Icon) return (
                                                    <div key={childIndex} style={{
                                                        float: 'left',
                                                        display: 'block',
                                                        width: isMobile ? width / 8 - 20 : 250,
                                                        margin: '20px 25px',
                                                        minHeight: 50
                                                    }}>
                                                        {
                                                            (childItem.answer === "FL" || childItem.answer === "L") ?
                                                                <Icon style={{
                                                                    fill: "#c7d300",
                                                                    margin: 'auto',
                                                                    transition: 'all 0.2s ease-in-out',
                                                                    cursor: 'pointer',
                                                                    width: 'auto',
                                                                    maxWidth: '100%',
                                                                    maxHeight: isMobile ? 14 : 29
                                                                }}
                                                                      onClick={() => {
                                                                          let tq = [...questions];
                                                                          let t = tq[index];

                                                                          if (t.selected.answer && Array.isArray(t.selected.answer) && t.selected.answer.includes('Eine gültige Fahrerlaubnis ist nicht erforderlich')) {
                                                                              t.selected.answer = [];
                                                                          }

                                                                          if (!t.selected.answer.includes(childItem.answer)) {
                                                                              let asd = t.selected;
                                                                              asd.answer.push(childItem.answer)
                                                                              // asd.answer = [childItem.answer]
                                                                              t.selected = asd;

                                                                              if (t.selected.length === t.answers.length) {
                                                                                  //setOnChangeQuestion(true);
                                                                                  //setActiveQuestion(temp)
                                                                              }
                                                                          } else if (t.selected.answer.includes(childItem.answer)) {
                                                                              let asd = t.selected;
                                                                              let selectedIndex = asd.answer.indexOf(childItem.answer);
                                                                              asd.answer.splice(selectedIndex, 1);
                                                                              // asd.answer = [];
                                                                              t.selected = asd;
                                                                          }

                                                                          tq[index] = t;
                                                                          setQuestions(tq);
                                                                      }}/> : ""
                                                        }
                                                        {
                                                            (childItem.answer !== "FL" && childItem.answer !== "L") ?
                                                                <Icon style={{
                                                                    fill: "#c7d300",
                                                                    margin: 'auto',
                                                                    transition: 'all 0.2s ease-in-out',
                                                                    cursor: 'pointer',
                                                                    width: 'auto',
                                                                    maxWidth: '100%'
                                                                }}
                                                                      onClick={() => {
                                                                          let tq = [...questions];
                                                                          let t = tq[index];

                                                                          if (t.selected.answer && Array.isArray(t.selected.answer) && t.selected.answer.includes('Eine gültige Fahrerlaubnis ist nicht erforderlich')) {
                                                                              t.selected.answer = [];
                                                                          }

                                                                          if (!t.selected.answer.includes(childItem.answer)) {
                                                                              let asd = t.selected;
                                                                              asd.answer.push(childItem.answer)
                                                                              // asd.answer = [childItem.answer]
                                                                              t.selected = asd;

                                                                              if (t.selected.length === t.answers.length) {
                                                                                  //setOnChangeQuestion(true);
                                                                                  //setActiveQuestion(temp)
                                                                              }
                                                                          } else if (t.selected.answer.includes(childItem.answer)) {
                                                                              let asd = t.selected;
                                                                              let selectedIndex = asd.answer.indexOf(childItem.answer);
                                                                              asd.answer.splice(selectedIndex, 1);
                                                                              // asd.answer = [];
                                                                              t.selected = asd;
                                                                          }

                                                                          tq[index] = t;
                                                                          setQuestions(tq);
                                                                      }}/> : ""
                                                        }
                                                        {/*<Icon*/}
                                                        {/*    height={100}*/}
                                                        {/*    style={{*/}
                                                        {/*        margin: 'auto',*/}
                                                        {/*        transition: 'all 0.2s ease-in-out',*/}
                                                        {/*        cursor: 'pointer',*/}
                                                        {/*        width: 'auto',*/}
                                                        {/*        maxWidth: '100%'*/}
                                                        {/*    }}*/}
                                                        {/*    onClick={() => {*/}
                                                        {/*        let tq = [...questions];*/}
                                                        {/*        let t = tq[activeQuestion - 1];*/}

                                                        {/*        let temp = activeQuestion + 1;*/}
                                                        {/*        console.log(t.selected)*/}

                                                        {/*        if (t.selected.answer && Array.isArray(t.selected.answer) && t.selected.answer.includes('Ich besitze keine gültige Fahrerlaubnis')) {*/}
                                                        {/*            console.log('ASDASD')*/}
                                                        {/*            t.selected.answer = [];*/}
                                                        {/*        }*/}

                                                        {/*        if (!t.selected.answer.includes(childItem.answer)) {*/}
                                                        {/*            let asd = t.selected;*/}
                                                        {/*            asd.answer.push(childItem.answer)*/}
                                                        {/*            t.selected = asd;*/}

                                                        {/*            if (t.selected.length === t.answers.length) {*/}
                                                        {/*                setOnChangeQuestion(true);*/}
                                                        {/*                setActiveQuestion(temp)*/}
                                                        {/*            }*/}
                                                        {/*        } else if (t.selected.answer.includes(childItem.answer)) {*/}
                                                        {/*            let asd = t.selected;*/}
                                                        {/*            let selectedIndex = asd.answer.indexOf(childItem.answer);*/}
                                                        {/*            asd.answer.splice(selectedIndex, 1);*/}
                                                        {/*            t.selected = asd;*/}
                                                        {/*        }*/}


                                                        {/*        tq[activeQuestion - 1] = t;*/}
                                                        {/*        setQuestions(tq);*/}
                                                        {/*    }}*/}
                                                        {/*/>*/}
                                                        <div style={{ margin: '0 auto 10px' }}>
                                                            <BpCheckbox
                                                                onClick={() => {
                                                                    let tq = [...questions];
                                                                    let t = tq[activeQuestion - 1];

                                                                    let temp = activeQuestion + 1;

                                                                    if (t.selected.answer && Array.isArray(t.selected.answer) && t.selected.answer.includes('Ich besitze keine gültige Fahrerlaubnis')) {
                                                                        t.selected.answer = [];
                                                                    }

                                                                    if (!t.selected.answer.includes(childItem.answer)) {
                                                                        let asd = t.selected;
                                                                        asd.answer.push(childItem.answer)
                                                                        t.selected = asd;

                                                                        if (t.selected.length === t.answers.length) {
                                                                            setOnChangeQuestion(true);
                                                                            setActiveQuestion(temp)
                                                                        }
                                                                    } else if (t.selected.answer.includes(childItem.answer)) {
                                                                        let asd = t.selected;
                                                                        let selectedIndex = asd.answer.indexOf(childItem.answer);
                                                                        asd.answer.splice(selectedIndex, 1);
                                                                        t.selected = asd;
                                                                    }


                                                                    tq[activeQuestion - 1] = t;
                                                                    setQuestions(tq);
                                                                }}
                                                                checked={questions[index].selected.answer && questions[index].selected.answer.includes(childItem.answer) ? true : false}
                                                            />
                                                        </div>
                                                        <span style={{ color: 'white' }}>{childItem.answer}
                                                            <Tooltip enterTouchDelay={0} title={childItem.helperText}>
                                                    <InfoIcon
                                                        style={isMobile ? { width: '100%' } : { marginLeft: 4 }}
                                                        fontSize={'14px'}
                                                        id={'infoIcon'}
                                                    />
                                                </Tooltip>
                                            </span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        {(questions.length >= activeQuestion) && questions[index].answers.filter(i => i.type == 'führerschein' && i.label == 'Ich besitze keine gültige Fahrerlaubnis').map((item, i) => {
                                            return (
                                                <div style={{
                                                    display: 'block',
                                                    opacity: (activeQuestion === (index + 1)) ? 1 : 0,
                                                    width: (activeQuestion === (index + 1)) ? 'auto' : 0,
                                                    margin: (activeQuestion === (index + 1)) ? '20px auto 0' : 0,
                                                }}>
                                                    <a style={{
                                                        color: questions[index].selected.answer.includes(item.label) ? '#C7D300' : 'white',
                                                        textDecoration: 'underline',
                                                        cursor: 'pointer'
                                                    }} onClick={() => {
                                                        let tq = [...questions];
                                                        let t = tq[activeQuestion - 1];

                                                        t.selected.answer = [item.label]

                                                        tq[activeQuestion - 1] = t;
                                                        setQuestions(tq);
                                                    }}>{item.label}</a>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </>
                            );
                        } else {
                            return (
                                <>
                                    <div key={index}
                                         style={{
                                             display: 'block',
                                             justifyContent: 'center',
                                             alignItems: 'flex-start',
                                             overflow: 'hidden',
                                             transition: 'all 0.3s ease-in-out',
                                             opacity: (activeQuestion === (index + 1)) ? 1 : 0,
                                             width: (activeQuestion === (index + 1)) ? 'calc(100vw)' : 0,
                                             height: (activeQuestion === (index + 1)) ? 'auto' : 0,
                                             minHeight: 250,
                                             marginTop: 20,
                                             marginBottom: 20
                                         }}
                                    >
                                        {questions[index].answers && questions[index].answers.filter(i => i.type === 'multipleDropdown').length > 0 &&
                                            <div
                                                style={{
                                                    width: '100%',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <FormControl
                                                    sx={{
                                                        width: isMobile ? ((width * 75 / 100).toString() + 'px') : '30%',
                                                    }}
                                                    style={{
                                                        marginTop: 5,
                                                        transition: 'all 0.15s ease',
                                                        height: 46,
                                                    }}
                                                    className={'testClassSelect whiteLabel'}>
                                                    <Select2
                                                        id={'questionTest'}
                                                        value={questions[index].selected.answer}
                                                        onChange={(e) => {
                                                            let tq = [...questions];
                                                            let t = tq[index].selected.answer;

                                                            t = e.target.value;
                                                            tq[index].selected.answer = t;
                                                            setQuestions(tq);
                                                        }}
                                                        defaultValue={false}
                                                        fullWidth
                                                        multiple
                                                        style={{
                                                            width: '100%',
                                                            marginBottom: 10,
                                                            color: 'white',
                                                        }}
                                                        MenuProps={MenuProps}
                                                        input={<Select2/>}
                                                    >
                                                        {(questions.length >= activeQuestion) && questions[index].answers.map((childItem, childIndex) => {
                                                            if (childItem.type == 'multipleDropdown') {
                                                                return (
                                                                    <MenuItem key={childIndex}
                                                                              value={childItem.answer}>{childItem.answer}{(childItem.helperText) &&
                                                                        <span className={'color-yellow'}>
                                                                                <Tooltip
                                                                                    enterTouchDelay={0}
                                                                                    title={childItem.helperText}>
                                                                                    <InfoIcon style={{ marginLeft: 4 }}
                                                                                              fontSize={'14px'}
                                                                                              id={'infoIcon'}/>
                                                                                </Tooltip></span>}</MenuItem>
                                                                );
                                                            }
                                                        })}
                                                    </Select2>
                                                </FormControl>
                                            </div>
                                        }
                                        {questions[index].answers && questions[index].answers.filter(i => i.type === 'singleDropdown').length > 0 &&
                                            <div
                                                style={{
                                                    width: '100%',
                                                    display: 'block',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}>
                                                <FormControl
                                                    sx={{
                                                        width: isMobile ? ((width * 75 / 100).toString() + 'px') : '30%',
                                                    }} style={{
                                                    display: 'block',
                                                    margin: '5px auto 0',
                                                    transition: 'all 0.15s ease',
                                                    height: 46,
                                                }}
                                                    className={'testClassSelect whiteLabel'}>
                                                    <Select2
                                                        id={'questionTest'}
                                                        value={questions[index].selected.answer}
                                                        onChange={(e) => {
                                                            let tq = [...questions];
                                                            let t = tq[index].selected.answer;

                                                            if (e.target.value == 'Studium') {
                                                                tq[index].selected.dropdown = 'Bachelor';
                                                            } else {
                                                                tq[index].selected.dropdown = null;
                                                            }


                                                            t = e.target.value;
                                                            if (tq[index].selected.answer != t) tq[index].selected.autocomplete = [];
                                                            tq[index].selected.answer = t;
                                                            setQuestions(tq);
                                                            if (questions[index].answers[questions[index].answers.indexOf(questions[index].answers.filter(i => i.answer == t)[0])].autoContinue == true) {
                                                                let t = activeQuestion + 1;
                                                                setActiveQuestion(t)
                                                            }
                                                        }}
                                                        defaultValue={false}
                                                        fullWidth
                                                        style={{
                                                            width: '100%',
                                                            marginBottom: 10,
                                                            color: 'white',
                                                        }}
                                                        MenuProps={MenuProps}
                                                        input={<Select2/>}
                                                    >
                                                        {(questions.length >= activeQuestion) && questions[index].answers.map((childItem, childIndex) => {
                                                            if (childItem.type == 'singleDropdown') {
                                                                return (
                                                                    <MenuItem key={childIndex}
                                                                              value={childItem.answer}>{childItem.answer}{(item.helperText && item.helperText[childItem.answer]) &&
                                                                        <span className={'color-yellow'}>
                                                                        <Tooltip
                                                                            enterTouchDelay={0}
                                                                            title={item.helperText[childItem.answer]}>
                                                                            <InfoIcon
                                                                                style={{ marginLeft: 4 }}
                                                                                fontSize={'14px'}
                                                                                id={'infoIcon'}
                                                                            />
                                                                                </Tooltip>
                                                                    </span>
                                                                    }
                                                                    </MenuItem>
                                                                );
                                                            }
                                                        })}
                                                    </Select2>
                                                </FormControl>
                                                {questions[index].selected.answer == 'Studium' ? <>
                                                    <FormControl
                                                        sx={{
                                                            width: isMobile ? ((width * 75 / 100).toString() + 'px') : '25%',
                                                        }}
                                                        style={{
                                                            marginTop: 5,
                                                            transition: 'all 0.15s ease',
                                                            minHeight: 46,
                                                        }}
                                                        className={'testClassSelect whiteLabel'}>
                                                        <Select2
                                                            id={'studiumDropdown'}
                                                            value={questions[index].selected.dropdown ?? ''}
                                                            onChange={(e) => {
                                                                let tq = [...questions];
                                                                tq[index].selected.dropdown = e.target.value;
                                                                setMultiSelectOpen(false);
                                                                setQuestions(tq);
                                                            }}
                                                            onClick={() => {
                                                                if (!multiSelectOpen) setMultiSelectOpen(index)
                                                            }}
                                                            onClose={() => {
                                                                setMultiSelectOpen(false);
                                                            }}
                                                            open={multiSelectOpen == index}
                                                            defaultValue={false}
                                                            fullWidth
                                                            on
                                                            style={{
                                                                width: '100%',
                                                                marginBottom: 10,
                                                                color: 'white',
                                                            }}
                                                            MenuProps={MenuProps}
                                                            input={<Select2/>}
                                                        >
                                                            <MenuItem disabled value="">
                                                            </MenuItem>
                                                            <MenuItem value={'Bachelor'}>Bachelor</MenuItem>
                                                            <MenuItem value={'Master'}>Master</MenuItem>
                                                            <MenuItem value={'Diplom - Diplomgrad'}>Diplom -
                                                                Diplomgrad</MenuItem>
                                                            <MenuItem value={'Staatsexamen'}>Staatsexamen</MenuItem>
                                                            <MenuItem
                                                                value={'Magister-Magistergrad'}>Magister-Magistergrad</MenuItem>
                                                        </Select2>
                                                    </FormControl>
                                                    <Autocomplete key={index}
                                                                  options={questions[index].autocomplete}
                                                                  getOptionLabel={(option) => {
                                                                      return (option.title) ? option.title : (Array.isArray(option) && option[0] && option[0].title) ? option[0].title : '';
                                                                  }}
                                                                  dataSourceConfig={{
                                                                      text: props.title,
                                                                      value: props.input
                                                                  }}
                                                                  ref={autocompleteRef}
                                                                  name={''}
                                                                  freeSolo
                                                                  multiple
                                                                  groupBy={(option) => 'Vorschläge'}
                                                                  value={questions[index].selected.autocomplete}
                                                                  onChange={(event, newValue) => {
                                                                      let temp = [...questions];
                                                                      let t = [];
                                                                      if (newValue && !Array.isArray(newValue)) {
                                                                          if (typeof newValue === 'string') {
                                                                              t = [{
                                                                                  input: newValue,
                                                                                  title: newValue,
                                                                              }];
                                                                          } else if (newValue && newValue.title) {
                                                                              t = [{
                                                                                  input: newValue.title,
                                                                                  title: newValue.title,
                                                                              }];
                                                                          } else {
                                                                              t = [newValue];
                                                                          }
                                                                      } else if (newValue) {
                                                                          newValue.map((item, index) => {
                                                                              if (typeof item === 'string') {
                                                                                  t.push({
                                                                                      input: item,
                                                                                      title: item,
                                                                                  });
                                                                              } else if (item && item.title) {
                                                                                  t.push({
                                                                                      input: item.title,
                                                                                      title: item.title,
                                                                                  });
                                                                              } else {
                                                                                  t.push(item);
                                                                              }
                                                                          })
                                                                      }
                                                                      setIsFocused(null)
                                                                      temp[index].selected.autocomplete = t;
                                                                      temp[index].selected.autocompleteInput = '';
                                                                      temp[index].selected.autocompleteInputBak = '';
                                                                      setQuestions(temp);
                                                                  }}
                                                                  loading={autocompleteLoading == (index)}
                                                                  inputValue={questions[index].selected.autocompleteInput}
                                                                  onInputChange={async (event, newInputValue) => {
                                                                      setIsFocused((index))
                                                                      let temp = [...questions];
                                                                      let t = [];
                                                                      if (newInputValue) {
                                                                          if (newInputValue && newInputValue.length > 0) {
                                                                              let url = ENV.REACT_APP_API_URL + '/autocompletes?filter%5BautocompleteType%5D%5Beq%5D=Studium&filter%5BautocompleteData%5D%5Blike%5D=' + newInputValue;
                                                                              setAutocompleteLoading(index);
                                                                              debouncedChangeHandler(url, 1, temp, index);
                                                                          }
                                                                      }
                                                                      temp[index].selected.autocompleteInput = newInputValue;
                                                                      if (newInputValue.length > 0 || keyDown && newInputValue.length == 0) temp[index].selected.autocompleteInputBak = newInputValue;
                                                                      if (!temp[index].autocomplete) temp[index].autocomplete = [];
                                                                      setQuestions(temp);
                                                                  }}
                                                        // open={questions[index].selected.autocompleteInput.length > 2 && questions[index].answers[childIndex].sub[subIndex].autocomplete && (!questions[index].selected.autocomplete || questions[index].selected.autocomplete != questions[index].selected.autocompleteInput)}
                                                                  open={(questions[index].selected.autocompleteInput.length > 0 && isFocused == (index))}
                                                                  className={'text-input-label-white'}
                                                                  onFocus={() => {
                                                                      setIsFocused((index))
                                                                  }}
                                                                  onKeyDown={(e) => {
                                                                      if (e.keyCode == 8 || e.keyCode == 46) setKeyDown(true)
                                                                      else setKeyDown(false)
                                                                  }}
                                                                  onBlur={() => {
                                                                      setIsFocused(null)
                                                                      if (questions[index].selected.autocompleteInputBak) {
                                                                          let temp = [...questions];
                                                                          temp[index].selected.autocomplete.push({
                                                                              input: questions[index].selected.autocompleteInputBak,
                                                                              title: questions[index].selected.autocompleteInputBak
                                                                          });
                                                                          temp[index].selected.autocompleteInput = '';
                                                                          temp[index].selected.autocompleteInputBak = '';
                                                                          setQuestions(temp);
                                                                      }
                                                                      setKeyDown(false);
                                                                  }}
                                                                  selectOnFocus
                                                                  clearOnBlur
                                                                  handleHomeEndKeys
                                                                  renderInput={(params) =>
                                                                      <>
                                                                          <CssTextField2 {...params}
                                                                                         sx={{ input: { color: 'white' } }}
                                                                                         label={'Studiengang'}
                                                                                         InputLabelProps={{
                                                                                             shrink: true
                                                                                         }}
                                                                                         variant="outlined"
                                                                                         style={{
                                                                                             width: isMobile ? ((width * 75 / 100).toString() + 'px') : '25%',
                                                                                             margin: '5px 0 0 0',
                                                                                             padding: 0,
                                                                                             transition: 'all 1s ease',
                                                                                         }}
                                                                          /></>
                                                                  }
                                                    />
                                                </> : null}
                                                {(questions[index].selected.answer == 'Staatlich geprüfter Techniker' || questions[index].selected.answer == 'Meister') ? <>
                                                    <Autocomplete key={index}
                                                                  options={questions[index].autocomplete}
                                                                  getOptionLabel={(option) => {
                                                                      return (option.title) ? option.title : (Array.isArray(option) && option[0] && option[0].title) ? option[0].title : '';
                                                                  }}
                                                                  dataSourceConfig={{
                                                                      text: props.title,
                                                                      value: props.input
                                                                  }}
                                                                  ref={autocompleteRef}
                                                                  name={''}
                                                                  freeSolo
                                                                  multiple
                                                                  groupBy={(option) => 'Vorschläge'}
                                                                  value={questions[index].selected.autocomplete}
                                                                  onChange={(event, newValue) => {
                                                                      let temp = [...questions];
                                                                      let t = [];
                                                                      if (newValue && !Array.isArray(newValue)) {
                                                                          if (typeof newValue === 'string') {
                                                                              t = [{
                                                                                  input: newValue,
                                                                                  title: newValue,
                                                                              }];
                                                                          } else if (newValue && newValue.title) {
                                                                              t = [{
                                                                                  input: newValue.title,
                                                                                  title: newValue.title,
                                                                              }];
                                                                          } else {
                                                                              t = [newValue];
                                                                          }
                                                                      } else if (newValue) {
                                                                          newValue.map((item, index) => {
                                                                              if (typeof item === 'string') {
                                                                                  t.push({
                                                                                      input: item,
                                                                                      title: item,
                                                                                  });
                                                                              } else if (item && item.title) {
                                                                                  t.push({
                                                                                      input: item.title,
                                                                                      title: item.title,
                                                                                  });
                                                                              } else {
                                                                                  t.push(item);
                                                                              }
                                                                          })
                                                                      }
                                                                      setIsFocused(null)
                                                                      temp[index].selected.autocomplete = t;
                                                                      temp[index].selected.autocompleteInput = '';
                                                                      temp[index].selected.autocompleteInputBak = '';
                                                                      setQuestions(temp);
                                                                  }}
                                                                  loading={autocompleteLoading == (index)}
                                                                  inputValue={questions[index].selected.autocompleteInput}
                                                                  onInputChange={async (event, newInputValue) => {
                                                                      setIsFocused((index))
                                                                      let temp = [...questions];
                                                                      let t = [];
                                                                      if (newInputValue) {
                                                                          if (newInputValue && newInputValue.length > 0) {
                                                                              let url = ENV.REACT_APP_API_URL + '/autocompletes?filter%5BautocompleteType%5D%5Beq%5D=Studium&filter%5BautocompleteData%5D%5Blike%5D=' + newInputValue;
                                                                              setAutocompleteLoading(index);
                                                                              debouncedChangeHandler(url, 1, temp, index);
                                                                          }
                                                                      }
                                                                      temp[index].selected.autocompleteInput = newInputValue;
                                                                      if (newInputValue.length > 0 || keyDown && newInputValue.length == 0) temp[index].selected.autocompleteInputBak = newInputValue;
                                                                      if (!temp[index].autocomplete) temp[index].autocomplete = [];
                                                                      setQuestions(temp);
                                                                  }}
                                                        // open={questions[index].selected.autocompleteInput.length > 2 && questions[index].answers[childIndex].sub[subIndex].autocomplete && (!questions[index].selected.autocomplete || questions[index].selected.autocomplete != questions[index].selected.autocompleteInput)}
                                                                  open={(questions[index].selected.autocompleteInput.length > 0 && isFocused == (index))}
                                                                  className={'text-input-label-white'}
                                                                  onFocus={() => {
                                                                      setIsFocused((index))
                                                                  }}
                                                                  onKeyDown={(e) => {
                                                                      if (e.keyCode == 8 || e.keyCode == 46) setKeyDown(true)
                                                                      else setKeyDown(false)
                                                                  }}
                                                                  onBlur={() => {
                                                                      setIsFocused(null)
                                                                      if (questions[index].selected.autocompleteInputBak) {
                                                                          let temp = [...questions];
                                                                          temp[index].selected.autocomplete.push({
                                                                              input: questions[index].selected.autocompleteInputBak,
                                                                              title: questions[index].selected.autocompleteInputBak
                                                                          });
                                                                          temp[index].selected.autocompleteInput = '';
                                                                          temp[index].selected.autocompleteInputBak = '';
                                                                          setQuestions(temp);
                                                                      }
                                                                      setKeyDown(false);
                                                                  }}
                                                                  selectOnFocus
                                                                  clearOnBlur
                                                                  handleHomeEndKeys
                                                                  renderInput={(params) =>
                                                                      <>
                                                                          <CssTextField2 {...params}
                                                                                         sx={{ input: { color: 'white' } }}
                                                                                         label={'Fachrichtung'}
                                                                                         InputLabelProps={{
                                                                                             shrink: true
                                                                                         }}
                                                                                         variant="outlined"
                                                                                         style={{
                                                                                             width: isMobile ? ((width * 75 / 100).toString() + 'px') : '30%',
                                                                                             margin: '5px 0 0 0',
                                                                                             padding: 0,
                                                                                             transition: 'all 1s ease',
                                                                                         }}
                                                                          /></>
                                                                  }
                                                    />
                                                </> : null}
                                            </div>
                                        }
                                        {(questions.length >= activeQuestion && questions[index].answers) && questions[index].answers.map((childItem, childIndex) => {
                                            return (
                                                <>
                                                    {childItem.type == 'multiple' && childItem.question_id != '62' &&
                                                        <div key={childIndex}
                                                             style={{
                                                                 height: 46,
                                                                 transition: 'all 0.15s ease',
                                                             }}
                                                        >
                                                            <div
                                                                style={{
                                                                    width: '100%',
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                }}
                                                            >
                                                                <button
                                                                    key={index}
                                                                    onMouseOver={() => handleMouseIn('answer-' + index + '-' + childIndex)}
                                                                    onMouseOut={() => handleMouseOut()}
                                                                    style={{
                                                                        borderRadius: 20,
                                                                        cursor: 'pointer',
                                                                        background: 'transparent',
                                                                        width: isMobile ? ((width * 75 / 100).toString() + 'px') : '30%',
                                                                        border: '2px solid',
                                                                        padding: '10px 15px 8px 15px',
                                                                        margin: 5,
                                                                        borderColor: (buttonHover === ('answer-' + index + '-' + childIndex) || questions[index].selected.answer.includes(childItem.answer)) ? '#C7D300' : 'white',
                                                                        color: 'white'
                                                                    }}
                                                                    onClick={() => {
                                                                        let tq = [...questions];
                                                                        let t = tq[index].selected.answer;
                                                                        let temp = activeQuestion + 1;

                                                                        if (tq[index].question === 'Was bist du derzeit?') {

                                                                            let x = [...t];
                                                                            if (!x.includes(childItem.answer)) {
                                                                                x.push(childItem.answer);
                                                                            } else {
                                                                                x.splice(x.indexOf(childItem.answer), 1);
                                                                            }

                                                                            setCategory(x);

                                                                        }


                                                                        if (!t.includes(childItem.answer)) {
                                                                            questions[index].answers.filter(i => (i.type === 'single' || i.type === 'singleDropdown')).map((i, ind) => {
                                                                                if (t.includes(i.answer)) t = [];
                                                                            })
                                                                            t.push(childItem.answer)
                                                                            if (childItem.autoContinue == true) {
                                                                                let t = activeQuestion + 1;
                                                                                setActiveQuestion(t)
                                                                            }
                                                                        } else if (t.includes(childItem.answer)) {
                                                                            let selectedIndex = t.indexOf(childItem.answer);
                                                                            t.splice(selectedIndex, 1);
                                                                        }

                                                                        tq[index].selected.answer = t;
                                                                        setQuestions(tq);
                                                                    }}
                                                                >{(childItem.answer == 'Arbeitnehmer*in') ? 'Arbeitnehmer*in / Absolvent*in / Arbeitsuchend' : childItem.answer}{(childItem.helperText) &&
                                                                    <span style={{ color: 'white' }}>
                                                                    <Tooltip enterTouchDelay={0}
                                                                             title={childItem.helperText}>
                                                                        <InfoIcon
                                                                            style={{ marginLeft: 4 }}
                                                                            fontSize={'14px'}
                                                                            id={'infoIcon'}
                                                                        />
                                                                    </Tooltip>
                                                                </span>
                                                                }</button>
                                                            </div>
                                                        </div>
                                                    }
                                                </>
                                            );
                                        })}
                                        {(questions[index] && (questions[index].id == 13 || questions[index].id == 26 || questions[index].id == 37 || questions[index].id == 49) && !login) &&
                                            <>
                                                {(onLogin) && <div className={'loginTest'} style={{
                                                    margin: '10px auto 105px',
                                                    display: 'block',
                                                    maxWidth: 280,
                                                    color: 'white',
                                                    padding: '15px 40px 70px',
                                                    border: !isMobile ? '3px solid white' : 'none',
                                                    borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                                }}>

                                                    <React.Fragment>
                                                        <p style={{
                                                            fontFamily: 'VagRoundedBold',
                                                        }}>Login</p>
                                                        <CssTextField3
                                                            fieldtype={'ASDF'}
                                                            label="E-Mail"
                                                            style={{
                                                                transition: 'all 0.2s ease',
                                                                marginTop: (isShrink && isShrink.includes('E-Mail') || mail) ? 20 : 5,
                                                                width: '100%'
                                                            }}
                                                            onFocus={() => {
                                                                let t = [...isShrink];
                                                                t.push('E-Mail');
                                                                setIsShrink(t);
                                                            }}
                                                            onBlur={() => {
                                                                setIsShrink([]);
                                                            }}
                                                            autoComplete={'off'}
                                                            onChange={e => {
                                                                if (e.target.value.trim().length === 0) {
                                                                    setMailError(true);
                                                                } else {
                                                                    setMailError(false);
                                                                }
                                                                setMail(e.target.value);
                                                            }}
                                                            onKeyPress={(e) => handleKeypress(e, 'login')}
                                                            value={mail}
                                                            error={!!mailError}
                                                            helperText={mailError === true ? 'Benutzername darf nicht leer sein!' : mailError}
                                                        />
                                                        <CssTextField3
                                                            label="Passwort"
                                                            type={showPassword1 ? "text" : "password"}
                                                            InputProps={{ // <-- This is where the toggle button is added.
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <IconButton
                                                                            aria-label="toggle password visibility"
                                                                            onClick={handleClickShowPassword}
                                                                            tabIndex={-1}
                                                                        >
                                                                            {showPassword1 ? <Visibility
                                                                                    style={{ color: '#C7D300' }}/> :
                                                                                <VisibilityOff
                                                                                    style={{ color: '#C7D300' }}/>}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                ),
                                                                autocomplete: 'new-password',
                                                                form: {
                                                                    autocomplete: 'off',
                                                                },
                                                            }}
                                                            style={{
                                                                transition: 'all 0.2s ease',
                                                                marginTop: (isShrink && isShrink.includes('Passwort') || password) ? 25 : 5,
                                                                marginBottom: 10,
                                                                width: '100%'
                                                            }}
                                                            onFocus={() => {
                                                                setIsShrink(['Passwort']);
                                                            }}
                                                            onBlur={() => {
                                                                setIsShrink([]);
                                                            }}
                                                            onChange={e => {
                                                                if (e.target.value.trim().length === 0) {
                                                                    setPasswordError(true);
                                                                } else {
                                                                    setPasswordError(false);
                                                                }
                                                                setPassword(e.target.value);
                                                            }}
                                                            onKeyPress={(e) => handleKeypress(e, 'login')}
                                                            value={password}
                                                            error={!!passwordError}
                                                            helperText={passwordError === true ? 'Passwort darf nicht leer sein!' : passwordError}
                                                        />

                                                        <button className={'fadeInOut'}
                                                                onMouseOver={() => setButtonHover('loginButton')}
                                                                onMouseOut={() => setButtonHover('')} style={{
                                                            fontFamily: 'VagRoundedBold',
                                                            background: buttonHover === 'loginButton' ? '#00374e' : '#C7D300',
                                                            cursor: 'pointer',
                                                            margin: 'auto',
                                                            textAlign: 'center',
                                                            padding: '10px 20px',
                                                            borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                                            color: buttonHover === 'loginButton' ? '#C7D300' : '#00374e',
                                                            border: buttonHover === 'loginButton' ? '2px solid #C7D300' : '2px solid #00374e',
                                                            fontSize: 12,
                                                            width: '100%'
                                                        }} onClick={() => {
                                                            loginRef.current.logIn(mail, password).then((e) => {
                                                                setMailError(e.mError);
                                                                setPasswordError(e.pError);
                                                                // console.log('ALARM')
                                                                setActiveStep(2);

                                                                setCompanyName(e.user?.companyName);
                                                                setStraße(e.user?.straße);
                                                                setHausnummer(e.user?.hausnummer);
                                                                setPostleitzahl(e.user?.postleitzahl);
                                                                setOrt(e.user?.ort);

                                                                if (e.success) {
                                                                    let t = activeQuestion;
                                                                    t++;
                                                                    createQuestion();
                                                                    history.push('/übersicht')
                                                                }


                                                            });
                                                        }}>LOGIN
                                                        </button>
                                                        <div style={{
                                                            marginTop: 15,
                                                            lineHeight: 0.8,
                                                            fontFamily: 'VagRoundedRegular',
                                                            marginLeft: 0,
                                                            textAlign: 'left',
                                                            float: 'left'
                                                        }}>
                                                            <a onClick={() => {
                                                                loginRef.current.passForget(mail);
                                                            }}
                                                               style={{
                                                                   color: 'white',
                                                                   cursor: 'pointer',
                                                                   fontSize: 10,
                                                                   textAlign: 'left',
                                                                   textDecoration: 'none'
                                                               }}>Passwort
                                                                vergessen?</a>
                                                        </div>
                                                        <div style={{
                                                            textAlign: 'right',
                                                            float: 'right',
                                                            marginTop: 10
                                                        }}>
                                                            <CustomButton
                                                                buttontype={'gray'}
                                                                onClick={() => {
                                                                    setOnLogin(false)
                                                                }}
                                                                style={{
                                                                    fontSize: 10,
                                                                    color: 'black',
                                                                }}
                                                            >Registrieren</CustomButton>
                                                        </div>
                                                    </React.Fragment>


                                                </div>}

                                                {(!onLogin) &&
                                                    <div className={'registerTest'} style={{
                                                        margin: '10px auto 115px',
                                                        display: 'block',
                                                        maxWidth: 600,
                                                        color: 'white',
                                                        width: 280,
                                                        padding: '15px 40px 70px',
                                                        border: !isMobile ? '3px solid white' : 'none',
                                                        borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                                    }}>
                                                        {/* Todo: Registrierung */}
                                                        <React.Fragment>
                                                            <p style={{
                                                                fontFamily: 'VagRoundedBold',
                                                                color: 'white',
                                                                paddingBottom: 10
                                                            }}>Registrierung</p>


                                                            <CssTextField3
                                                                label="E-Mail"
                                                                style={{
                                                                    transition: 'all 0.2s ease',
                                                                    marginTop: (isShrink && isShrink.includes('E-Mail') || mail) ? 20 : 0,
                                                                    width: '100%'
                                                                }}
                                                                onFocus={() => {
                                                                    let t = [...isShrink];
                                                                    t.push('E-Mail');
                                                                    setIsShrink(t);
                                                                }}
                                                                onBlur={() => {
                                                                    setIsShrink([]);
                                                                }}
                                                                autoComplete={'off'}
                                                                onChange={e => {
                                                                    if (e.target.value.trim().length === 0) {
                                                                        setMailError(true);
                                                                    } else {
                                                                        setMailError(false);
                                                                    }
                                                                    setMail(e.target.value);
                                                                }}
                                                                onKeyPress={(e) => handleKeypress(e, 'register')}
                                                                value={mail}
                                                                error={!!mailError}
                                                                helperText={mailError === true ? 'Benutzername darf nicht leer sein!' : mailError}
                                                            />
                                                            <CssTextField3
                                                                label="Passwort*"
                                                                type={showPassword1 ? "text" : "password"}
                                                                InputProps={{ // <-- This is where the toggle button is added.
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <IconButton
                                                                                aria-label="toggle password visibility"
                                                                                onClick={handleClickShowPassword}
                                                                                tabIndex={-1}
                                                                            >
                                                                                {showPassword1 ? <Visibility
                                                                                        style={{ color: '#C7D300' }}/> :
                                                                                    <VisibilityOff
                                                                                        style={{ color: '#C7D300' }}/>}
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                    ),
                                                                    autocomplete: 'new-password',
                                                                    form: {
                                                                        autocomplete: 'off',
                                                                    },
                                                                }}
                                                                style={{
                                                                    transition: 'all 0.2s ease',
                                                                    marginTop: (isShrink && isShrink.includes('Passwort') || password) ? 30 : 10,
                                                                    marginBottom: 10,
                                                                    width: '100%'
                                                                }}
                                                                onFocus={() => {
                                                                    setIsShrink(['Passwort']);
                                                                }}
                                                                onBlur={() => {
                                                                    setIsShrink([]);
                                                                }}
                                                                onChange={e => {
                                                                    if (e.target.value.trim().length === 0) {
                                                                        setPasswordError(true);
                                                                    } else {
                                                                        setPasswordError(false);
                                                                    }
                                                                    setPassword(e.target.value);
                                                                }}
                                                                onKeyPress={(e) => handleKeypress(e, 'register')}
                                                                value={password}
                                                                error={!!passwordError}
                                                                helperText={passwordError === true ? 'Passwort darf nicht leer sein!' : passwordError}
                                                            />
                                                            <CssTextField3
                                                                label="Passwort wiederholen*"
                                                                type={showPassword1 ? "text" : "password"}
                                                                InputProps={{ // <-- This is where the toggle button is added.
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <IconButton
                                                                                aria-label="toggle password visibility"
                                                                                onClick={handleClickShowPassword}
                                                                                tabIndex={-1}
                                                                            >
                                                                                {showPassword1 ? <Visibility
                                                                                        style={{ color: '#C7D300' }}/> :
                                                                                    <VisibilityOff
                                                                                        style={{ color: '#C7D300' }}/>}
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                    ),
                                                                    autocomplete: 'new-password',
                                                                    form: {
                                                                        autocomplete: 'off',
                                                                    },
                                                                }}
                                                                style={{
                                                                    transition: 'all 0.2s ease',
                                                                    marginTop: (isShrink && isShrink.includes('Passwort wiederholen') || passwordVerify) ? 20 : 0,
                                                                    marginBottom: 10,
                                                                    width: '100%'
                                                                }}
                                                                onFocus={() => {
                                                                    setIsShrink(['Passwort wiederholen']);
                                                                }}
                                                                onBlur={() => {
                                                                    setIsShrink([]);
                                                                }}
                                                                onChange={e => {
                                                                    if (e.target.value.trim().length === 0) {
                                                                        setPasswordVerifyError(true);
                                                                    } else {
                                                                        setPasswordVerifyError(false);
                                                                    }
                                                                    setPasswordVerify(e.target.value);
                                                                }}
                                                                onKeyPress={(e) => handleKeypress(e, 'register')}
                                                                value={passwordVerify}
                                                                error={!!passwordVerifyError}
                                                                helperText={passwordVerifyError === true ? 'Passwort darf nicht leer sein!' : passwordVerifyError}
                                                            />


                                                            <button className={'fadeInOut'}
                                                                    onMouseOver={() => setButtonHover('loginButton')}
                                                                    onMouseOut={() => setButtonHover('')} style={{
                                                                fontFamily: 'VagRoundedBold',
                                                                background: buttonHover === 'loginButton' ? '#00374e' : '#C7D300',
                                                                cursor: 'pointer',
                                                                margin: 'auto',
                                                                textAlign: 'center',
                                                                padding: '10px 20px',
                                                                borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                                                color: buttonHover === 'loginButton' ? '#C7D300' : '#00374e',
                                                                border: buttonHover === 'loginButton' ? '2px solid #C7D300' : '2px solid #00374e',
                                                                fontSize: 12,
                                                                width: '100%'
                                                            }} onClick={() => {
                                                                setMailError(false);
                                                                setCompanyError(false);
                                                                setPasswordError(false);
                                                                setPasswordVerifyError(false);
                                                                loginRef.current.reg(mail, accountType, accountType !== 'Arbeitgeber' ? null : company, password, passwordVerify, false).then(async (e) => {

                                                                    if (e.success) {
                                                                        await createQuestion(ReactSession.get('temp-auth-key'));
                                                                    } else {
                                                                        setMailError(e.mError);
                                                                        setCompanyError(e.coError);
                                                                        setPasswordError(e.pError);
                                                                        setPasswordVerifyError(e.pVError);
                                                                    }
                                                                })
                                                            }}>REGISTRIEREN
                                                            </button>
                                                            <div style={{
                                                                color: 'white',
                                                                fontSize: 14,
                                                                float: 'left',
                                                                marginTop: 15
                                                            }}>* Pflichtfelder
                                                            </div>
                                                            <div style={{
                                                                textAlign: 'right',
                                                                float: 'right',
                                                                marginTop: 10
                                                            }}>
                                                                {/*<Link onClick={() => {*/}
                                                                {/*    setOnLogin(true)*/}
                                                                {/*}} style={{*/}
                                                                {/*    cursor: 'pointer',*/}
                                                                {/*    fontSize: 14,*/}
                                                                {/*    color: 'white'*/}
                                                                {/*}}>Login</Link>*/}
                                                                <CustomButton
                                                                    buttontype={'gray'}
                                                                    onClick={() => {
                                                                        setOnLogin(true)
                                                                    }}
                                                                    style={{
                                                                        fontSize: 10,
                                                                        color: 'black',
                                                                    }}
                                                                >Login</CustomButton>
                                                            </div>
                                                        </React.Fragment>


                                                    </div>}
                                            </>

                                        }

                                        {questions[index].answers && questions[index].id != 62 && questions[index].answers.filter(i => i.type === 'single').length > 0 && questions[index].answers.map((childItem, childIndex) => {
                                            let subquestions = [...showSubQuestion];
                                            return (
                                                <>
                                                    {(childItem.type == 'single') &&
                                                        <div key={childIndex}
                                                             style={{
                                                                 height: 'auto',
                                                                 transition: 'all 0.15s ease',
                                                             }}
                                                        >
                                                            <div
                                                                style={{
                                                                    width: '100%',
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                }}
                                                            >
                                                                <button
                                                                    key={index}
                                                                    onMouseOver={() => handleMouseIn('answer-' + index + '-' + childIndex)}
                                                                    onMouseOut={() => handleMouseOut()}
                                                                    style={{
                                                                        borderRadius: 20,
                                                                        cursor: 'pointer',
                                                                        background: 'transparent',
                                                                        width: isMobile ? ((width * 75 / 100).toString() + 'px') : '30%',
                                                                        border: '2px solid',
                                                                        padding: '10px 15px 8px 15px',
                                                                        margin: 5,
                                                                        borderColor: (buttonHover === ('answer-' + index + '-' + childIndex) || Array.isArray(questions[index].selected.answer) && questions[index].selected.answer.includes(childItem.answer)) ? '#C7D300' : 'white',
                                                                        color: 'white',
                                                                        position: 'relative'
                                                                    }}
                                                                    onClick={() => {
                                                                        let tq = [...questions];
                                                                        let subquestions = [...showSubQuestion];
                                                                        let t = tq[index].selected.answer;
                                                                        let temp = activeQuestion + 1;

                                                                        if (childItem.sub && (childItem.sub.length > 0) && !subquestions.includes(('answer-' + index + '-' + childIndex))) {
                                                                            subquestions.push('answer-' + index + '-' + childIndex);
                                                                        } else if (childItem.sub && (childItem.sub && childItem.sub.length > 0) && subquestions.includes(('answer-' + index + '-' + childIndex))) {
                                                                            subquestions.splice(subquestions.indexOf('answer-' + index + '-' + childIndex), 1);
                                                                            questions[index].selected.subAnswer = [];
                                                                        }

                                                                        if (!Array.isArray(t) || !t.includes(childItem.answer)) {
                                                                            subquestions.map((item, index2) => {
                                                                                if (item.startsWith('answer-' + index + '-') && item !== ('answer-' + index + '-' + childIndex)) {
                                                                                    subquestions.splice(index2, 1);
                                                                                    tq[index].selected.subAnswer = [];
                                                                                    // tq[index].selected.autocomplete = [];
                                                                                }
                                                                            })

                                                                            t = [childItem.answer];
                                                                            if (childItem.autoContinue == true) {
                                                                                let t = activeQuestion + 1;
                                                                                setActiveQuestion(t)
                                                                            }
                                                                        } else if (t.includes(childItem.answer)) {
                                                                            t = [];
                                                                        }
                                                                        setShowSubQuestion(subquestions);

                                                                        tq[index].selected.answer = t;
                                                                        tq[index].selected.autocomplete = [];
                                                                        tq[index].selected.subAutocomplete = [];
                                                                        setQuestions(tq);
                                                                    }}
                                                                >{childItem.answer}{(childItem.helperText) &&
                                                                    <span style={{ color: 'white' }}>
                                                                    <Tooltip enterTouchDelay={0}
                                                                             title={childItem.helperText}>
                                                                        <InfoIcon
                                                                            style={{ marginLeft: 4 }}
                                                                            fontSize={'14px'}
                                                                            id={'infoIcon'}
                                                                        />
                                                                    </Tooltip>
                                                                </span>
                                                                }</button>
                                                            </div>
                                                            {(childItem.sub && (childItem.sub && childItem.sub.length > 0) || (childItem.sub && childItem.sub.url) || (childItem.sub && childItem.sub.autocomplete)) && subquestions.includes(('answer-' + index + '-' + childIndex)) &&
                                                                <div style={{ margin: '5px calc(100% - 95%) 10px' }}>
                                                                    {/* Type multipleDropdown START */}
                                                                    {(childItem.sub && childItem.sub.length > 0 && childItem.sub.filter(i => i.type === 'multipleDropdown').length > 1) &&
                                                                        <div
                                                                            style={{
                                                                                width: '100%',
                                                                                display: 'flex',
                                                                                justifyContent: 'center',
                                                                                alignItems: 'center'
                                                                            }}
                                                                        >
                                                                            <FormControl
                                                                                sx={{
                                                                                    width: isMobile ? ((width * 75 / 100).toString() + 'px') : '30%',
                                                                                }} style={{
                                                                                marginTop: 5,
                                                                                transition: 'all 0.15s ease',
                                                                                height: 46,
                                                                            }}
                                                                                className={'testClassSelect whiteLabel'}>
                                                                                <Select2
                                                                                    id={'questionTest'}
                                                                                    value={questions[index].selected.answer}
                                                                                    onChange={(e) => {
                                                                                        let tq = [...questions];
                                                                                        let t = tq[index].selected.answer;
                                                                                        let temp = activeQuestion + 1;


                                                                                        questions[index].answers.filter(i => (i.type === 'single' || i.type === 'singleDropdown')).map((i, ind) => {
                                                                                            if (e.target.value.includes(i.answer)) {
                                                                                                let selectedIndex = e.target.value.indexOf(i.answer);
                                                                                                e.target.value.splice(selectedIndex, 1);
                                                                                            }
                                                                                        })


                                                                                        t = e.target.value;
                                                                                        tq[index].selected.answer = t;
                                                                                        setQuestions(tq);
                                                                                    }}
                                                                                    defaultValue={false}
                                                                                    fullWidth
                                                                                    multiple
                                                                                    style={{
                                                                                        width: '100%',
                                                                                        marginBottom: 10,
                                                                                        color: 'white',
                                                                                    }}
                                                                                    MenuProps={MenuProps}
                                                                                    input={<Select2/>}
                                                                                >
                                                                                    {childItem.sub.map((subItem, subIndex) => {
                                                                                        if (subItem.type == 'multipleDropdown') {
                                                                                            return (
                                                                                                <MenuItem key={subIndex}
                                                                                                          value={subItem.answer}>{subItem.answer}</MenuItem>
                                                                                            );
                                                                                        }
                                                                                    })}
                                                                                </Select2>
                                                                            </FormControl>
                                                                        </div>
                                                                    }

                                                                    {/* Type multiple START */}
                                                                    {(childItem.sub && childItem.sub.length > 0) && childItem.sub.map((subItem, subIndex) => {

                                                                        return (
                                                                            <>
                                                                                {subItem.type == 'multiple' &&
                                                                                    <div key={subIndex} style={{
                                                                                        height: 46,
                                                                                        transition: 'all 0.15s ease',
                                                                                    }}>
                                                                                        <div style={{
                                                                                            width: '100%',
                                                                                            display: 'flex',
                                                                                            justifyContent: 'center',
                                                                                            alignItems: 'center',
                                                                                        }}>
                                                                                            <button
                                                                                                key={index}
                                                                                                onMouseOver={() => handleMouseIn('answer-' + index + '-' + childIndex + '-' + subIndex)}
                                                                                                onMouseOut={() => handleMouseOut()}
                                                                                                style={{
                                                                                                    borderRadius: 20,
                                                                                                    cursor: 'pointer',
                                                                                                    background: 'transparent',
                                                                                                    width: isMobile ? ((width * 75 / 100).toString() + 'px') : '30%',
                                                                                                    border: '2px solid',
                                                                                                    padding: '10px 15px 8px 15px',
                                                                                                    margin: 5,
                                                                                                    borderColor: (buttonHover === ('answer-' + index + '-' + childIndex + '-' + subIndex) || questions[index].selected.subAnswer.includes(subItem.answer)) ? '#C7D300' : 'white',
                                                                                                    color: 'white'
                                                                                                }}
                                                                                                onClick={() => {
                                                                                                    let tq = [...questions];
                                                                                                    let t = tq[index].selected.subAnswer;
                                                                                                    let temp = activeQuestion + 1;

                                                                                                    if (!t.includes(subItem.answer)) {
                                                                                                        //t = [subItem];
                                                                                                        t.push(subItem.answer)
                                                                                                    } else if (t.includes(subItem.answer)) {
                                                                                                        //t.antwort = [];
                                                                                                        let selectedIndex = t.indexOf(subItem.answer);
                                                                                                        t.splice(selectedIndex, 1);
                                                                                                    }

                                                                                                    tq[index].selected.subAnswer = t;
                                                                                                    setQuestions(tq);
                                                                                                }}
                                                                                            >{subItem.answer}</button>
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                            </>
                                                                        );
                                                                    })}
                                                                    {/* Type multiple END */}

                                                                    {/* Type single START */}
                                                                    {(childItem.sub && childItem.sub.length > 0) && childItem.sub.map((subItem, subIndex) => {
                                                                        return (
                                                                            <>
                                                                                {(subItem.type == 'single') &&
                                                                                    <div key={subIndex} style={{
                                                                                        transition: 'all 0.15s ease',
                                                                                    }}>
                                                                                        <div style={{
                                                                                            width: '100%',
                                                                                            display: childItem.sub.subType == 'autocomplete' ? 'block' : 'flex',
                                                                                            justifyContent: 'center',
                                                                                            alignItems: 'center',
                                                                                        }}>
                                                                                            {(childItem.sub.answer.length > 1) && <>
                                                                                                <button
                                                                                                    key={index}
                                                                                                    onMouseOver={() => handleMouseIn('answer-' + index + '-' + childIndex + '-' + subIndex)}
                                                                                                    onMouseOut={() => handleMouseOut()}
                                                                                                    style={{
                                                                                                        borderRadius: 20,
                                                                                                        cursor: 'pointer',
                                                                                                        background: 'transparent',
                                                                                                        width: isMobile ? ((width * 75 / 100).toString() + 'px') : '30%',
                                                                                                        border: '2px solid',
                                                                                                        padding: '10px 15px 8px 15px',
                                                                                                        margin: 5,
                                                                                                        borderColor: (buttonHover === ('answer-' + index + '-' + childIndex + '-' + subIndex) || questions[index].selected.subAnswer.includes(childItem.answer)) ? '#C7D300' : 'white',
                                                                                                        color: 'white'
                                                                                                    }}
                                                                                                    onClick={() => {
                                                                                                        let tq = [...questions];
                                                                                                        let subquestions = [...showSubQuestion];
                                                                                                        let t = tq[index].selected.subAnswer;
                                                                                                        let temp = activeQuestion + 1;

                                                                                                        if (!t.includes(subItem.answer)) {
                                                                                                            t = [subItem.answer];
                                                                                                        } else if (t.includes(subItem.answer)) {
                                                                                                            t = [];
                                                                                                        }
                                                                                                        setShowSubQuestion(subquestions);

                                                                                                        tq[index].selected.answer = t;
                                                                                                        setQuestions(tq);
                                                                                                    }}
                                                                                                >{subItem.answer}</button>
                                                                                            </>
                                                                                            }
                                                                                            {(childItem.sub.answer.length == 1) &&
                                                                                                <CssTextField2
                                                                                                    label={subItem.answer}
                                                                                                    InputLabelProps={{
                                                                                                        shrink: true
                                                                                                    }}
                                                                                                    value={questions[index].selected.subAnswer[0]}
                                                                                                    onChange={(e) => {
                                                                                                        let t = [...questions];
                                                                                                        questions[index].selected.subAnswer = [e.target.value];
                                                                                                        setQuestions(t);
                                                                                                    }}
                                                                                                    sx={{
                                                                                                        input: { color: 'white' },
                                                                                                        label: { color: 'white' },
                                                                                                        fieldset: {
                                                                                                            transition: 'all 1s ease',
                                                                                                            transform: showSubQuestion.includes('answer-' + index + '-' + childIndex) ? 'scaleY(1)' : 'scaleY(0)'
                                                                                                        }
                                                                                                    }}
                                                                                                    style={{
                                                                                                        marginTop: 10,
                                                                                                        marginBottom: 5,
                                                                                                        width: isMobile ? ((width * 75 / 100).toString() + 'px') : '30%',
                                                                                                        transformOrigin: 'top',
                                                                                                        transition: 'all 0.1s ease-in',
                                                                                                        transform: showSubQuestion.includes('answer-' + index + '-' + childIndex) ? 'scaleY(1)' : 'scaleY(0)'
                                                                                                    }}
                                                                                                    autoComplete={'off'}
                                                                                                />
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                            </>
                                                                        );
                                                                    })}
                                                                    {(childItem.sub && childItem.sub.length > 0) && childItem.sub.map((subItem, subIndex) => {
                                                                        if (subItem.type == 'autocomplete') {
                                                                            if (childItem.question_id != 6 && childItem.question_id != 36 && childItem.question_id != 48) {
                                                                                return (
                                                                                    <>
                                                                                        {subItem.autocompleteType == 'Studium' ? <>

                                                                                            <FormControl
                                                                                                sx={{
                                                                                                    width: '30%'
                                                                                                }}
                                                                                                style={{
                                                                                                    marginTop: 5,
                                                                                                    transition: 'all 0.15s ease',
                                                                                                    minHeight: 46,
                                                                                                }}
                                                                                                className={'testClassSelect whiteLabel'}>
                                                                                                <Select2
                                                                                                    id={'studiumDropdown'}
                                                                                                    value={questions[index].selected.dropdown ?? ''}
                                                                                                    onChange={(e) => {
                                                                                                        let tq = [...questions];
                                                                                                        tq[index].selected.dropdown = e.target.value;
                                                                                                        // tq[index].selected.autocomplete = e.target.value + tq[index].selected.autocomplete;
                                                                                                        setMultiSelectOpen(false);
                                                                                                        setQuestions(tq);
                                                                                                    }}
                                                                                                    onClick={() => {
                                                                                                        if (!multiSelectOpen) setMultiSelectOpen(index)
                                                                                                    }}
                                                                                                    onClose={() => {
                                                                                                        setMultiSelectOpen(false);
                                                                                                    }}
                                                                                                    open={multiSelectOpen == index}
                                                                                                    defaultValue={false}
                                                                                                    fullWidth
                                                                                                    on
                                                                                                    style={{
                                                                                                        width: '100%',
                                                                                                        marginBottom: 10,
                                                                                                        color: 'white',
                                                                                                    }}
                                                                                                    MenuProps={MenuProps}
                                                                                                    input={<Select2/>}
                                                                                                >
                                                                                                    <MenuItem disabled
                                                                                                              value="">
                                                                                                    </MenuItem>
                                                                                                    <MenuItem
                                                                                                        value={'Bachelor'}>Bachelor</MenuItem>
                                                                                                    <MenuItem
                                                                                                        value={'Master'}>Master</MenuItem>
                                                                                                    <MenuItem
                                                                                                        value={'Diplom - Diplomgrad'}>Diplom
                                                                                                        -
                                                                                                        Diplomgrad</MenuItem>
                                                                                                    <MenuItem
                                                                                                        value={'Staatsexamen'}>Staatsexamen</MenuItem>
                                                                                                    <MenuItem
                                                                                                        value={'Magister-Magistergrad'}>Magister-Magistergrad</MenuItem>
                                                                                                </Select2>
                                                                                            </FormControl>
                                                                                        </> : null}
                                                                                        {subItem.id == 514 ? <>

                                                                                            <p style={{
                                                                                                color: '#C7D300',
                                                                                                maxWidth: '90%',
                                                                                                margin: '15px auto'
                                                                                            }}>Für welche Berufe oder
                                                                                                Berufsbilder
                                                                                                interessiert du
                                                                                                dich?</p>


                                                                                            <p style={{
                                                                                                color: 'white',
                                                                                                maxWidth: 600,
                                                                                                textAlign: 'left',
                                                                                                fontSize: 10,
                                                                                                margin: '0 auto'
                                                                                            }}>Beliebte Berufe /
                                                                                                Berufsfelder</p>
                                                                                            <div style={{
                                                                                                display: 'flex',
                                                                                                flexFlow: 'row wrap',
                                                                                                marginBottom: 15,
                                                                                                maxWidth: '600px',
                                                                                                margin: '5px auto 25px',
                                                                                            }}>
                                                                                                {questions[index].autocompleteFromDB && questions[index].autocompleteFromDB.length > 0 && questions[index].autocompleteFromDB.map((subSubItem, subSubIndex) => {

                                                                                                    return <CustomButton
                                                                                                        key={subSubIndex}
                                                                                                        buttontype={'yellowWhite3'}
                                                                                                        style={{
                                                                                                            display: questions[index].selected.autocomplete.filter(i => i.title == subSubItem.title).length > 0 && questions[index].selected.autocomplete.filter(i => i.title == subSubItem.title)[0].title == subSubItem.title ? 'none' : 'block',
                                                                                                            padding: '1.7px 15px',
                                                                                                            margin: subSubIndex != questions[index].autocompleteFromDB.length ? '2px 2px 2px 0' : '2px 0',
                                                                                                        }}
                                                                                                        onClick={() => {
                                                                                                            console.log(subSubItem);
                                                                                                            let temp = [...questions];
                                                                                                            if (temp[index].selected.autocomplete.includes(subSubItem)) {
                                                                                                                console.log(temp[index].selected.autocomplete);
                                                                                                                let itemIndex = temp[index].selected.autocomplete.indexOf(subSubItem);
                                                                                                                console.log(itemIndex)
                                                                                                                console.log(temp[index].selected.autocomplete[itemIndex])
                                                                                                                temp[index].selected.autocomplete.splice(itemIndex, 1);
                                                                                                            } else {
                                                                                                                temp[index].selected.autocomplete.push(subSubItem);
                                                                                                            }
                                                                                                            console.log(temp[index])
                                                                                                            setQuestions(temp);
                                                                                                        }}
                                                                                                        disabled={counter > 0}
                                                                                                    >{subSubItem.title}</CustomButton>
                                                                                                })}
                                                                                            </div>


                                                                                        </> : null}
                                                                                        <div
                                                                                            key={childIndex}
                                                                                            style={{
                                                                                                transition: 'all 0.15s ease',
                                                                                                width: '100%',
                                                                                                display: 'flex',
                                                                                                justifyContent: 'center',
                                                                                                alignItems: 'center',
                                                                                                paddingTop: 5,
                                                                                                paddingBottom: 5
                                                                                            }}
                                                                                        >
                                                                                            <Autocomplete key={subIndex}
                                                                                                          sx={{
                                                                                                              width: isMobile ? ((width * 75 / 100).toString() + 'px') : '30%',
                                                                                                          }}
                                                                                                          options={questions[index].answers[childIndex].sub[subIndex].autocomplete}
                                                                                                          getOptionLabel={(option) => {
                                                                                                              return (subItem.autocompleteType != 'Studium' || option.title) ? option.title : (Array.isArray(option) && option[0] && option[0].title) ? option[0].title : '';
                                                                                                          }}
                                                                                                          dataSourceConfig={{
                                                                                                              text: props.title,
                                                                                                              value: props.input
                                                                                                          }}
                                                                                                          ref={autocompleteRef}
                                                                                                          name={''}
                                                                                                          freeSolo
                                                                                                          multiple
                                                                                                          openOnFocus={false}
                                                                                                          value={questions[index].selected.autocomplete}
                                                                                                          onChange={(event, newValue) => {
                                                                                                              let temp = [...questions];
                                                                                                              let t = [];
                                                                                                              if (newValue && !Array.isArray(newValue)) {
                                                                                                                  if (typeof newValue === 'string') {
                                                                                                                      t = [{
                                                                                                                          input: newValue,
                                                                                                                          title: newValue,
                                                                                                                      }];
                                                                                                                  } else if (newValue && newValue.title) {
                                                                                                                      // Create a new value from the user input
                                                                                                                      t = [{
                                                                                                                          input: newValue.title,
                                                                                                                          title: newValue.title,
                                                                                                                      }];
                                                                                                                  } else {
                                                                                                                      t = [newValue];
                                                                                                                  }
                                                                                                              } else if (newValue) {
                                                                                                                  newValue.map((item, index) => {
                                                                                                                      // let tem = item;
                                                                                                                      // if (subItem.autocompleteType == 'Studium') tem = ' ' + tem;
                                                                                                                      if (typeof item === 'string') {
                                                                                                                          t.push({
                                                                                                                              input: item,
                                                                                                                              title: item,
                                                                                                                          });
                                                                                                                      } else if (item && item.title) {
                                                                                                                          // Create a new value from the user input
                                                                                                                          t.push({
                                                                                                                              input: item.title,
                                                                                                                              title: item.title,
                                                                                                                          });
                                                                                                                      } else {
                                                                                                                          t.push(item);
                                                                                                                      }
                                                                                                                  })
                                                                                                              }

                                                                                                              temp[index].selected.autocomplete = t;
                                                                                                              temp[index].selected.autocompleteInput = '';
                                                                                                              temp[index].selected.autocompleteInputBak = '';
                                                                                                              setQuestions(temp);
                                                                                                          }}
                                                                                                          loading={autocompleteLoading == (index + '-' + childIndex + '-' + subIndex)}
                                                                                                          inputValue={questions[index].selected.autocompleteInput}
                                                                                                          onInputChange={async (event, newInputValue) => {
                                                                                                              setIsFocused((index + '-' + childIndex + '-' + subIndex))
                                                                                                              let temp = [...questions];
                                                                                                              let t = [];
                                                                                                              if (newInputValue) {
                                                                                                                  if (subItem.autocompleteType && newInputValue && newInputValue.length > 0) {
                                                                                                                      let url = ENV.REACT_APP_API_URL + '/autocompletes?filter%5BautocompleteType%5D%5Beq%5D=' + subItem.autocompleteType + '&filter%5BautocompleteData%5D%5Blike%5D=' + newInputValue;
                                                                                                                      setAutocompleteLoading(index + '-' + childIndex + '-' + subIndex);
                                                                                                                      debouncedChangeHandler(url, 2, temp, index, childIndex, subIndex);
                                                                                                                  }
                                                                                                              }
                                                                                                              temp[index].selected.autocompleteInput = newInputValue;
                                                                                                              if (newInputValue.length > 0 || keyDown && newInputValue.length == 0) temp[index].selected.autocompleteInputBak = newInputValue;
                                                                                                              if (!temp[index].answers[childIndex].sub[subIndex].autocomplete) temp[index].answers[childIndex].sub[subIndex].autocomplete = [];
                                                                                                              setQuestions(temp);
                                                                                                          }}
                                                                                                          open={(questions[index].selected.autocompleteInput.length > 0 && isFocused == (index + '-' + childIndex + '-' + subIndex))}
                                                                                                          groupBy={(option) => 'Vorschläge'}
                                                                                                          className={'text-input-label-white'}
                                                                                                          onFocus={() => {
                                                                                                              setIsFocused((index + '-' + childIndex + '-' + subIndex))
                                                                                                          }}
                                                                                                          onKeyDown={(e) => {
                                                                                                              if (e.keyCode == 8 || e.keyCode == 46) setKeyDown(true)
                                                                                                              else setKeyDown(false)
                                                                                                          }}
                                                                                                          onBlur={() => {
                                                                                                              setIsFocused(null)
                                                                                                              if (questions[index].selected.autocompleteInputBak) {
                                                                                                                  let temp = [...questions];
                                                                                                                  temp[index].selected.autocomplete.push({
                                                                                                                      input: questions[index].selected.autocompleteInputBak,
                                                                                                                      title: questions[index].selected.autocompleteInputBak
                                                                                                                  });
                                                                                                                  temp[index].selected.autocompleteInput = '';
                                                                                                                  temp[index].selected.autocompleteInputBak = '';
                                                                                                                  setQuestions(temp);
                                                                                                              }
                                                                                                              setKeyDown(false);
                                                                                                          }}
                                                                                                          selectOnFocus
                                                                                                          clearOnBlur
                                                                                                          handleHomeEndKeys
                                                                                                          renderInput={(params) =>
                                                                                                              <>
                                                                                                                  <CssTextField2 {...params}
                                                                                                                                 sx={{ input: { color: 'white' } }}
                                                                                                                                 label={childItem.sub[subIndex].label}
                                                                                                                                 InputLabelProps={{
                                                                                                                                     shrink: true
                                                                                                                                 }}
                                                                                                                                 variant="outlined"
                                                                                                                                 style={{
                                                                                                                                     width: '100%',
                                                                                                                                     margin: '5px 0 0 0',
                                                                                                                                     padding: 0,
                                                                                                                                     transition: 'all 1s ease',
                                                                                                                                     transform: showSubQuestion.includes('answer-' + index + '-' + childIndex) ? 'scaleY(1)' : 'scaleY(0)'
                                                                                                                                 }}
                                                                                                                                 helperText={'Nutze wenn möglich unsere Vorschläge!'}
                                                                                                                  /></>
                                                                                                          }
                                                                                            />
                                                                                        </div>
                                                                                        {subItem.id == 514 ? <>

                                                                                            <p style={{ minHeight: 60 }}></p>

                                                                                        </> : null}
                                                                                    </>
                                                                                );
                                                                            } else {
                                                                                return (
                                                                                    <>
                                                                                        <Autocomplete
                                                                                            key={index}
                                                                                            options={questions[index].autocomplete}
                                                                                            getOptionLabel={(option) => {
                                                                                                return option.title;
                                                                                            }}
                                                                                            dataSourceConfig={{
                                                                                                text: props.title,
                                                                                                value: props.input
                                                                                            }}
                                                                                            ref={autocompleteRef}
                                                                                            name={''}
                                                                                            freeSolo
                                                                                            multiple
                                                                                            openOnFocus={false}
                                                                                            value={questions[index].selected.autocomplete}
                                                                                            onChange={async (event, newValue) => {
                                                                                                console.log("17testtt")
                                                                                                let temp = [...questions];
                                                                                                let t = [];

                                                                                                if (newValue.length) {
                                                                                                    newValue.map((item, index) => {
                                                                                                        if (typeof item === 'string') {
                                                                                                            t.push({
                                                                                                                input: item,
                                                                                                                title: item,
                                                                                                            });
                                                                                                        } else if (item && item.title) {
                                                                                                            t.push({
                                                                                                                input: item.title,
                                                                                                                title: item.title,
                                                                                                            });
                                                                                                        } else {
                                                                                                            t.push(item);
                                                                                                        }
                                                                                                    })
                                                                                                    setIsFocused(null)

                                                                                                    temp[index].selected.autocomplete = t;
                                                                                                    temp[index].selected.autocompleteInput = '';
                                                                                                    temp[index].selected.autocompleteInputBak = '';

                                                                                                    console.log("QUESTIONS INDEX", questions[index])
                                                                                                    //TODO newValue[0].title/value?? on edit
                                                                                                    let url = ENV.REACT_APP_API_URL + '/jobTitles?target=title&filter[title][like]=' + newValue[0].title;
                                                                                                    const data = await api(url)
                                                                                                    // if (data.data?.data[0]?.attributes?.sector) {
                                                                                                    // setSectorData(data.data.data[0].attributes)

                                                                                                    let answerId = temp[index].answers.find(item => item.label == 'Branche')?.id ?? null
                                                                                                    const subAuto = [{
                                                                                                        id: temp[index].id,
                                                                                                        answer: data.data?.data[0]?.attributes?.sector ?? 'Alle Branchen',
                                                                                                        answerId: answerId
                                                                                                    }]

                                                                                                    // if (data.data.data[0]?.attributes?.topCategory) {
                                                                                                    answerId = temp[index].answers.find(item => item.label == 'Oberkategorie')?.id ?? null
                                                                                                    const subAutoTopCat = {
                                                                                                        id: temp[index].id,
                                                                                                        answer: (data.data?.data[0]?.attributes?.topCategory?.length > 0) ? data.data?.data[0]?.attributes?.topCategory : 'Alle Oberkategorien',
                                                                                                        answerId: answerId
                                                                                                    }
                                                                                                    subAuto.push(subAutoTopCat);
                                                                                                    // }
                                                                                                    // if (data.data.data[0]?.attributes?.category) {
                                                                                                    answerId = temp[index].answers.find(item => item.label == 'Kategorie')?.id ?? null
                                                                                                    const subAutoCat = {
                                                                                                        id: temp[index].id,
                                                                                                        answer: data.data?.data[0]?.attributes?.category ?? 'Alle Kategorien',
                                                                                                        answerId: answerId
                                                                                                    }
                                                                                                    subAuto.push(subAutoCat)
                                                                                                    // }
                                                                                                    temp[index].selected.autocomplete[0].subAutocomplete = subAuto;
                                                                                                    // } else {
                                                                                                    //     // fetchSectorData()
                                                                                                    //     // alert("fetchSectorData")
                                                                                                    // }

                                                                                                    setQuestions(temp);
                                                                                                    console.log("17test temp")
                                                                                                    fetchTopCategoryData(data.data?.data[0]?.attributes?.sector, temp[index].id)
                                                                                                    fetchCategoryData(data.data?.data[0]?.attributes?.sector, temp[index].id)
                                                                                                } else {
                                                                                                    setIsFocused(null)

                                                                                                    temp[index].selected.autocomplete = t;
                                                                                                    temp[index].selected.autocompleteInput = '';
                                                                                                    temp[index].selected.autocompleteInputBak = '';
                                                                                                }
                                                                                            }}
                                                                                            loading={autocompleteLoading == index}
                                                                                            inputValue={questions[index].selected.autocompleteInput}
                                                                                            onInputChange={async (event, newInputValue) => {
                                                                                                setIsFocused(('answer-' + index + '-' + childIndex))
                                                                                                let temp = [...questions];
                                                                                                console.log("17testt", newInputValue)
                                                                                                if (newInputValue) {
                                                                                                    let t = [];
                                                                                                    console.log(subItem.autocompleteType && newInputValue && newInputValue.length > 0, subItem.autocompleteType)
                                                                                                    if (subItem.autocompleteType && newInputValue && newInputValue.length > 0) {
                                                                                                        let url = ENV.REACT_APP_API_URL + '/autocompletes?filter%5BautocompleteType%5D%5Beq%5D=' + subItem.autocompleteType + '&filter%5BautocompleteData%5D%5Blike%5D=' + newInputValue;
                                                                                                        setAutocompleteLoading(index);
                                                                                                        debouncedChangeHandler(url, 1, temp, index);
                                                                                                    }
                                                                                                }
                                                                                                temp[index].selected.autocompleteInput = newInputValue;
                                                                                                if (newInputValue.length > 0 || keyDown && newInputValue.length == 0) temp[index].selected.autocompleteInputBak = newInputValue;
                                                                                                if (!temp[index].autocomplete) temp[index].autocomplete = [];
                                                                                                setQuestions(temp);
                                                                                            }}
                                                                                            open={(questions[index].selected.autocompleteInput.length > 0 && isFocused === ('answer-' + index + '-' + childIndex))}
                                                                                            groupBy={(option) => 'Vorschläge'}
                                                                                            className={'text-input-label-white ' + (isFocused === ('answer-' + index + '-' + childIndex) || (questions[index].selected.autocomplete && questions[index].selected.autocomplete.length > 0) ? '' : 'borderWhite')}
                                                                                            onFocus={() => {
                                                                                                setIsFocused(('answer-' + index + '-' + childIndex))
                                                                                            }}
                                                                                            onKeyDown={(e) => {
                                                                                                if (e.keyCode == 8 || e.keyCode == 46) setKeyDown(true)
                                                                                                else setKeyDown(false)
                                                                                            }}
                                                                                            onBlur={async () => {
                                                                                                setIsFocused(null)
                                                                                                const newValue = questions[index].selected.autocompleteInputBak
                                                                                                if (newValue) {
                                                                                                    let temp = [...questions];
                                                                                                    temp[index].selected.autocomplete.push({
                                                                                                        input: questions[index].selected.autocompleteInputBak,
                                                                                                        title: questions[index].selected.autocompleteInputBak
                                                                                                    });
                                                                                                    temp[index].selected.autocompleteInput = '';
                                                                                                    temp[index].selected.autocompleteInputBak = '';
                                                                                                    // setQuestions(temp);
                                                                                                    let url = ENV.REACT_APP_API_URL + '/jobTitles?target=title&filter[title][like]=' + questions[index].selected?.autocomplete[0]?.title;
                                                                                                    const data = await api(url)
                                                                                                    // if (data.data?.data[0]?.attributes?.sector) {
                                                                                                    // setSectorData(data.data.data[0].attributes)

                                                                                                    let answerId = temp[index].answers.find(item => item.label == 'Branche')?.id ?? null
                                                                                                    const subAuto = [{
                                                                                                        id: temp[index].id,
                                                                                                        answer: data.data?.data[0]?.attributes?.sector ?? 'Alle Branchen',
                                                                                                        answerId: answerId
                                                                                                    }]

                                                                                                    // if (data.data.data[0]?.attributes?.topCategory) {
                                                                                                    answerId = temp[index].answers.find(item => item.label == 'Oberkategorie')?.id ?? null
                                                                                                    const subAutoTopCat = {
                                                                                                        id: temp[index].id,
                                                                                                        answer: (data.data?.data[0]?.attributes?.topCategory?.length > 0) ? data.data?.data[0]?.attributes?.topCategory : 'Alle Oberkategorien',
                                                                                                        answerId: answerId
                                                                                                    }
                                                                                                    subAuto.push(subAutoTopCat);
                                                                                                    // }
                                                                                                    // if (data.data.data[0]?.attributes?.category) {
                                                                                                    answerId = temp[index].answers.find(item => item.label == 'Kategorie')?.id ?? null
                                                                                                    const subAutoCat = {
                                                                                                        id: temp[index].id,
                                                                                                        answer: data.data?.data[0]?.attributes?.category ?? 'Alle Kategorien',
                                                                                                        answerId: answerId
                                                                                                    }
                                                                                                    subAuto.push(subAutoCat)
                                                                                                    // }
                                                                                                    temp[index].selected.autocomplete[0].subAutocomplete = subAuto;
                                                                                                    // } else {
                                                                                                    //     // fetchSectorData()
                                                                                                    //     // alert("fetchSectorData")
                                                                                                    // }

                                                                                                    setQuestions(temp);
                                                                                                    console.log("17test temp")
                                                                                                    fetchTopCategoryData(data.data?.data[0]?.attributes?.sector, temp[index].id)
                                                                                                    fetchCategoryData(data.data?.data[0]?.attributes?.sector, temp[index].id)
                                                                                                }
                                                                                                setKeyDown(false);
                                                                                            }}
                                                                                            selectOnFocus
                                                                                            clearOnBlur
                                                                                            handleHomeEndKeys
                                                                                            renderInput={(params) =>
                                                                                                <>
                                                                                                    <CssTextField2
                                                                                                        {...params}
                                                                                                        sx={{ input: { color: 'white' } }}
                                                                                                        label={subItem.label}
                                                                                                        InputLabelProps={{
                                                                                                            shrink: true
                                                                                                        }}
                                                                                                        variant="outlined"
                                                                                                        style={{
                                                                                                            width: isMobile ? ((width * 75 / 100).toString() + 'px') : '30%',
                                                                                                            margin: '5px 0 0 0',
                                                                                                            padding: 0,
                                                                                                        }}
                                                                                                        helperText={'Nutze wenn möglich unsere Vorschläge!'}
                                                                                                    />
                                                                                                </>
                                                                                            }
                                                                                        />
                                                                                    </>
                                                                                );
                                                                            }
                                                                        }
                                                                        if (subItem.type == 'autocompleteDropdown') {
                                                                            return (
                                                                                <>
                                                                                    {/*{'6'}*/}
                                                                                    <div
                                                                                        key={childIndex}
                                                                                        style={{
                                                                                            transition: 'all 0.15s ease',
                                                                                            width: '100%',
                                                                                            display: 'flex',
                                                                                            justifyContent: 'center',
                                                                                            alignItems: 'center',
                                                                                        }}
                                                                                    >
                                                                                        {subItem.autocompleteType == 'Branchen' ?
                                                                                            <>
                                                                                                {(questions[index].selected.autocomplete[0]?.subAutocomplete) &&
                                                                                                    <FormControl
                                                                                                        sx={{
                                                                                                            width: isMobile ? ((width * 75 / 100).toString() + 'px') : '30%',
                                                                                                        }}
                                                                                                        style={{
                                                                                                            marginTop: 20,
                                                                                                            transition: 'all 0.15s ease',
                                                                                                            height: 46,
                                                                                                        }}
                                                                                                        className={'testClassSelect greyLabel'}>
                                                                                                        <InputLabel
                                                                                                            className={'whiteLabel'}>{subItem.label}</InputLabel>
                                                                                                        <Select2
                                                                                                            id={'questionTest'}
                                                                                                            value={questions[index].selected.autocomplete[0]?.subAutocomplete?.find(item => item.answerId == subItem.id)?.answer ?? ''}//questions[index].selected.answer}
                                                                                                            onChange={(e) => {
                                                                                                                // const tempData = sectorData
                                                                                                                // tempData.sector = e.target.value
                                                                                                                // setSectorData(tempData)
                                                                                                                let tq = [...questions];
                                                                                                                let t = tq[index].selected.answer;

                                                                                                                t = e.target.value;
                                                                                                                const subAuto = [{
                                                                                                                    id: tq[index].id,
                                                                                                                    answer: t,
                                                                                                                    answerId: subItem.id
                                                                                                                }]
                                                                                                                tq[index].selected.autocomplete[0].subAutocomplete = subAuto;
                                                                                                                setQuestions(tq);
                                                                                                                fetchTopCategoryData(e.target.value, questions[index].id, true)
                                                                                                                fetchCategoryData(e.target.value, questions[index].id)
                                                                                                            }}
                                                                                                            defaultValue={questions[index].selected.autocomplete[0]?.subAutocomplete?.find(item => item.answerId == subItem.id)?.answer ?? ''}
                                                                                                            fullWidth
                                                                                                            style={{
                                                                                                                width: '100%',
                                                                                                                marginBottom: 15,
                                                                                                            }}
                                                                                                            MenuProps={MenuProps}
                                                                                                            label={subItem.label}
                                                                                                            input={
                                                                                                                <Select2/>}
                                                                                                        >
                                                                                                            {(questions.length >= activeQuestion && sectors.length) && sectors.map((childItem, childIndex) => {
                                                                                                                // if (sectorData?.sector) {
                                                                                                                return (
                                                                                                                    <MenuItem
                                                                                                                        key={childIndex}
                                                                                                                        value={childItem}>{childItem}{(childItem.helperText) &&
                                                                                                                        <span
                                                                                                                            className={'color-yellow'}>
                                                                                <Tooltip
                                                                                    enterTouchDelay={0}
                                                                                    title={childItem.helperText}>
                                                                                    <InfoIcon style={{ marginLeft: 4 }}
                                                                                              fontSize={'14px'}
                                                                                              id={'infoIcon'}/>
                                                                                </Tooltip></span>}</MenuItem>
                                                                                                                );
                                                                                                                // }
                                                                                                            })}
                                                                                                        </Select2>
                                                                                                    </FormControl>
                                                                                                }
                                                                                            </> : null}
                                                                                        {subItem.autocompleteType == 'Oberkategorien' ?
                                                                                            <>
                                                                                                {(topCategories[questions[index]?.id]?.length > 0 && questions[index].selected.autocomplete[0]) &&
                                                                                                    <FormControl
                                                                                                        sx={{
                                                                                                            width: isMobile ? ((width * 75 / 100).toString() + 'px') : '30%',
                                                                                                        }}
                                                                                                        style={{
                                                                                                            marginTop: 20,
                                                                                                            transition: 'all 0.15s ease',
                                                                                                            height: 46,
                                                                                                        }}
                                                                                                        className={'testClassSelect greyLabel'}>
                                                                                                        <InputLabel
                                                                                                            className={'whiteLabel'}>{subItem.label}</InputLabel>
                                                                                                        <Select2
                                                                                                            id={'questionTest'}
                                                                                                            value={questions[index].selected.autocomplete[0]?.subAutocomplete?.find(itemm => itemm.answerId == subItem.id)?.answer ?? ''}
                                                                                                            onChange={(e) => {
                                                                                                                //TODO ADD SECTORANSWER INSTEAD OF SECTORDATA
                                                                                                                const topCatAnswerIndex = questions[index].selected.autocomplete[0]?.subAutocomplete?.findIndex(itemm => itemm.answerId == subItem.id) ?? 1

                                                                                                                // fetchCategoryData(sectorData?.sector, questions[index].id, e.target.value)
                                                                                                                // const tempData = sectorData
                                                                                                                // tempData.topCategory = e.target.value
                                                                                                                // setSectorData(tempData)
                                                                                                                let tq = [...questions];
                                                                                                                let t = tq[index].selected.answer;

                                                                                                                t = e.target.value;
                                                                                                                const indexUsed = tq[index].selected.autocomplete[0].subAutocomplete.findIndex(item => item.answerId == subItem.id)
                                                                                                                if (indexUsed > -1) {
                                                                                                                    tq[index].selected.autocomplete[0].subAutocomplete[indexUsed].answer = t;
                                                                                                                } else {
                                                                                                                    tq[index].selected.autocomplete[0].subAutocomplete.push({
                                                                                                                        id: questions[index]?.id,
                                                                                                                        answer: t,
                                                                                                                        answerId: subItem.id
                                                                                                                    })
                                                                                                                }
                                                                                                                const answerId = tq[index].answers.find(item => item.label == 'Kategorie')?.id ?? null
                                                                                                                const indexDelete = tq[index].selected.autocomplete[0].subAutocomplete.findIndex(item => item.answerId == answerId)
                                                                                                                if (indexDelete > -1) tq[index].selected.autocomplete[0].subAutocomplete.splice(indexDelete, 1)
                                                                                                                // tq[index].selected.answer = t;
                                                                                                                setQuestions(tq);
                                                                                                                fetchCategoryData(questions[index].selected.autocomplete[0]?.subAutocomplete[topCatAnswerIndex - 1]?.answer, questions[index].id, e.target.value)
                                                                                                            }}
                                                                                                            defaultValue={questions[index].selected.autocomplete[0]?.subAutocomplete?.find(item => item.answerId == subItem.id)?.answer ?? ''}
                                                                                                            fullWidth
                                                                                                            style={{
                                                                                                                width: '100%',
                                                                                                                marginBottom: 15,
                                                                                                                color: 'white',
                                                                                                            }}
                                                                                                            MenuProps={MenuProps}
                                                                                                            label={childItem.label}
                                                                                                            input={
                                                                                                                <Select2/>}
                                                                                                        >
                                                                                                            {(questions.length >= activeQuestion && topCategories[questions[index]?.id]?.length) && topCategories[questions[index]?.id].map((childItem, childIndex) => {
                                                                                                                // if (sectorData?.topCategory) {
                                                                                                                return (
                                                                                                                    <MenuItem
                                                                                                                        key={childIndex}
                                                                                                                        value={childItem}>{childItem}{(childItem.helperText) &&
                                                                                                                        <span
                                                                                                                            className={'color-yellow'}>
                                                                                <Tooltip
                                                                                    enterTouchDelay={0}
                                                                                    title={childItem.helperText}>
                                                                                    <InfoIcon style={{ marginLeft: 4 }}
                                                                                              fontSize={'14px'}
                                                                                              id={'infoIcon'}/>
                                                                                </Tooltip></span>}</MenuItem>
                                                                                                                );
                                                                                                                // }
                                                                                                            })}
                                                                                                        </Select2>
                                                                                                    </FormControl>
                                                                                                }
                                                                                            </> : null}
                                                                                        {subItem.autocompleteType == 'Kategorien' ?
                                                                                            <>
                                                                                                {(categories[questions[index]?.id]?.length > 0 && questions[index].selected.autocomplete[0]) &&
                                                                                                    <FormControl
                                                                                                        label={subItem.label}
                                                                                                        sx={{
                                                                                                            width: isMobile ? ((width * 75 / 100).toString() + 'px') : '30%',
                                                                                                        }}
                                                                                                        style={{
                                                                                                            marginTop: 20,
                                                                                                            transition: 'all 0.15s ease',
                                                                                                            height: 46,
                                                                                                        }}
                                                                                                        className={'testClassSelect greyLabel'}>
                                                                                                        <InputLabel
                                                                                                            className={'whiteLabel'}>{subItem.label}</InputLabel>
                                                                                                        <Select2
                                                                                                            id={'questionTest'}
                                                                                                            value={questions[index].selected.autocomplete[0]?.subAutocomplete?.find(item => item.answerId == subItem.id)?.answer ?? ''}
                                                                                                            onChange={(e) => {
                                                                                                                // const tempData = sectorData
                                                                                                                // tempData.category = e.target.value
                                                                                                                // setSectorData(tempData)
                                                                                                                let tq = [...questions];
                                                                                                                let t = tq[index].selected.answer;

                                                                                                                t = e.target.value;
                                                                                                                const indexUsed = tq[index].selected.autocomplete[0].subAutocomplete.findIndex(item => item.answerId == subItem.id)
                                                                                                                console.log(indexUsed)
                                                                                                                if (indexUsed > -1) {
                                                                                                                    tq[index].selected.autocomplete[0].subAutocomplete[indexUsed].answer = t;
                                                                                                                } else {
                                                                                                                    const subAutoCat = {
                                                                                                                        id: tq[index].id,
                                                                                                                        answer: t,
                                                                                                                        answerId: subItem.id
                                                                                                                    }
                                                                                                                    tq[index].selected.autocomplete[0].subAutocomplete.push(subAutoCat)
                                                                                                                }
                                                                                                                setQuestions(tq);
                                                                                                            }}
                                                                                                            defaultValue={questions[index].selected.autocomplete[0]?.subAutocomplete?.find(item => item.answerId == subItem.id)?.answer ?? ''}
                                                                                                            fullWidth
                                                                                                            style={{
                                                                                                                width: '100%',
                                                                                                                marginBottom: 10,
                                                                                                                color: 'lightgrey !important',
                                                                                                            }}
                                                                                                            MenuProps={MenuProps}
                                                                                                            label={subItem.label}
                                                                                                            input={
                                                                                                                <Select2/>}
                                                                                                        >
                                                                                                            {(questions.length >= activeQuestion && categories[questions[index]?.id]?.length) && categories[questions[index]?.id].map((childItem, childIndex) => {
                                                                                                                // if (sectorData?.category) {
                                                                                                                return (
                                                                                                                    <MenuItem
                                                                                                                        key={childIndex}
                                                                                                                        value={childItem}>{childItem}{(childItem.helperText) &&
                                                                                                                        <span
                                                                                                                            className={'color-yellow'}>
                                                                                <Tooltip
                                                                                    enterTouchDelay={0}
                                                                                    title={childItem.helperText}>
                                                                                    <InfoIcon style={{ marginLeft: 4 }}
                                                                                              fontSize={'14px'}
                                                                                              id={'infoIcon'}/>
                                                                                </Tooltip></span>}</MenuItem>
                                                                                                                );
                                                                                                                // }
                                                                                                            })}
                                                                                                        </Select2>
                                                                                                    </FormControl>}
                                                                                            </> : null}
                                                                                    </div>
                                                                                </>
                                                                            );
                                                                        }
                                                                    })}
                                                                    {/* Type single END */}
                                                                </div>}
                                                        </div>}
                                                </>
                                            );
                                        })}
                                        {(questions[index].answers && questions[index].answers.filter(i => i.type == 'autocomplete' && i.parent_id == null).length > 0) && questions[index].answers.map((childItem, childIndex) => {
                                            if (childItem.type == 'autocomplete') {

                                            } else {
                                                return;
                                            }
                                            return (
                                                <>
                                                    <div key={childIndex}
                                                         style={{
                                                             transition: 'all 0.15s ease',
                                                         }}
                                                    >
                                                        <div
                                                            style={{
                                                                width: '100%',
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                flexDirection: 'column',
                                                            }}
                                                        >
                                                            {childItem.autocompleteType == 'Zipcode' ?
                                                                <>
                                                                    <div style={{
                                                                        position: 'relative',
                                                                        paddingTop: 3,
                                                                        minWidth: '200px',
                                                                        width: isMobile ? ((width * 75 / 100).toString() + 'px') : '30%',
                                                                        opacity: (questions[index]?.selected?.answer[0] && questions[index]?.selected?.answer[0] == 'Ich möchte von Zuhause aus arbeiten') ? 0.5 : 1
                                                                    }}>
                                                                        <Autocomplete
                                                                            options={autocomplete}
                                                                            getOptionLabel={(option) => {
                                                                                if (option && option.attributes) {
                                                                                    return option.attributes.zipcode + ' ' + option.attributes.name;
                                                                                } else {
                                                                                    return '';
                                                                                }
                                                                            }}
                                                                            // dataSourceConfig={{text: props.title, value: props.input}}
                                                                            name={''}
                                                                            loading={autocompleteLoading === 'zipcode'}
                                                                            freeSolo
                                                                            openOnFocus={false}
                                                                            value={selectedAutocomplete}
                                                                            onChange={(event, newValue) => {
                                                                                console.log(newValue);
                                                                                if (newValue && newValue.attributes || newValue == null) {
                                                                                    setSelectedAutocomplete(newValue);
                                                                                    if (newValue) {
                                                                                        const newMapProps = {
                                                                                            center: {
                                                                                                lat: parseFloat(newValue.attributes.lat),
                                                                                                lng: parseFloat(newValue.attributes.long)
                                                                                            },
                                                                                            zoom: 8
                                                                                        }
                                                                                        setDefaultProps(newMapProps)
                                                                                        console.log(defaultProps)
                                                                                    }
                                                                                }
                                                                                if (newValue && newValue.length > 0 || autocompleteInput && autocompleteInput.length > 0) setTest(true);
                                                                                else setTest(false)

                                                                                let tq = [...questions];
                                                                                let t = [childItem];
                                                                                console.log("ANSWER2222", tq[index].selected.answer);
                                                                                //set Range also
                                                                                let tDistance = tq[index].selected.answer.filter((ans) => ans === "Ich möchte meinen Standort teilen")


                                                                                console.log("ANSWER2222", tDistance)
                                                                                if (newValue) {
                                                                                    tDistance.push(tq[index].answers.find((ans) => ans.answer == currentRange))
                                                                                    if (shareLocation) tDistance.push(shareLocation)
                                                                                    console.log("ANSWER2222", tDistance)
                                                                                    tq[index].selected.answer = tDistance;
                                                                                    console.log("t found", t)
                                                                                    if (t) t[0].answer = newValue.id
                                                                                    tq[index].selected.autocomplete = t
                                                                                } else {
                                                                                    tq[index].selected.answer = [];
                                                                                    tq[index].selected.autocomplete = [];
                                                                                }
                                                                                console.log("answer2222", tq[index]?.selected.answer)
                                                                                // tq[index].selected.subAutocomplete = [];
                                                                                setQuestions(tq);
                                                                            }}
                                                                            inputValue={autocompleteInput}
                                                                            onInputChange={async (event, newInputValue) => {
                                                                                if (newInputValue == undefined || newInputValue == 'undefined') {
                                                                                    newInputValue = '';
                                                                                }
                                                                                setAutocompleteInput(newInputValue);
                                                                                let t = [];
                                                                                if (newInputValue && newInputValue.length > 0 || selectedAutocomplete && selectedAutocomplete.length > 0) setTest(true);
                                                                                else setTest(false);

                                                                                if (newInputValue && newInputValue.length > 1) {
                                                                                    let url = ENV.REACT_APP_API_URL + '/zipcodes';
                                                                                    setAutocompleteLoading('zipcode');
                                                                                    debouncedChangeHandlerZip(url, newInputValue);

                                                                                }
                                                                            }}
                                                                            // open={(autocompleteInput.length > 2 && autocomplete.length > 0)}
                                                                            open={(autocomplete.length > 0 && (!selectedAutocomplete && autocompleteInput || selectedAutocomplete && (selectedAutocomplete.attributes.zipcode + ' ' + selectedAutocomplete.attributes.name) != autocompleteInput))}
                                                                            className={'autocompleteSearchJobsuche'}
                                                                            onFocus={() => {
                                                                                setTest(true);
                                                                            }}
                                                                            onBlur={() => {
                                                                                if (!selectedAutocomplete || selectedAutocomplete.length === 0) {
                                                                                    setTest(false);
                                                                                    setAutocomplete([]);
                                                                                }
                                                                            }}
                                                                            onKeyPress={handleKeypressEnter}
                                                                            selectOnFocus
                                                                            clearOnBlur
                                                                            handleHomeEndKeys
                                                                            renderInput={(params) =>
                                                                                <>
                                                                                    <CssTextField3
                                                                                        {...params}
                                                                                        autoComplete="new-password"
                                                                                        label="PLZ oder Ort"
                                                                                        style={{
                                                                                            transition: 'all 0.2s ease',
                                                                                            margin: '0 0 8px 0',
                                                                                            width: '100%',
                                                                                            maxHeight: 41.13,
                                                                                        }}
                                                                                        className={'bg-transparent ASDF'}
                                                                                    />
                                                                                    <div
                                                                                        style={{
                                                                                            color: 'white',
                                                                                            fontSize: 12,
                                                                                            width: '100%',
                                                                                            textAlign: 'start',
                                                                                            paddingLeft: 20,
                                                                                            display: (shareLocation) ? 'none' : 'block'
                                                                                        }}
                                                                                    >
                                                                                        Dein Wohnort bleibt anonym!
                                                                                    </div>
                                                                                </>
                                                                            }
                                                                        />
                                                                        <div style={{
                                                                            position: 'absolute',
                                                                            top: 3,
                                                                            right: 0,
                                                                            width: 40.13,
                                                                            height: 40.13,
                                                                            borderRadius: 25,
                                                                            background: '#C7D300',
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'center',
                                                                            lineHeight: '12px'
                                                                        }}>
                                                                            <p style={{
                                                                                position: 'absolute',
                                                                                marginTop: 10,
                                                                                color: '#00374e',
                                                                                fontWeight: 'bold'
                                                                            }}>{currentRange}{(currentRange == 100) ? '+' : ''}</p>
                                                                            <p style={{
                                                                                position: 'absolute',
                                                                                fontSize: 10,
                                                                                marginTop: 25,
                                                                                color: '#00374e',
                                                                                fontWeight: 'bold'
                                                                            }}>km</p>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{
                                                                        position: 'relative',
                                                                        minWidth: '186px',
                                                                        width: isMobile ? ((width * 75 / 100 - 14).toString() + 'px') : 'calc(30% - 14px)',
                                                                        opacity: (questions[index]?.selected?.answer[0] && questions[index]?.selected?.answer[0] == 'Ich möchte von Zuhause aus arbeiten') ? 0.5 : 1
                                                                    }}>
                                                                        <p style={{
                                                                            transition: 'all 0.2s ease',
                                                                            textAlign: 'left',
                                                                            color: '#C7D300',
                                                                            marginLeft: 14,
                                                                            marginBottom: 0,
                                                                            fontSize: 12
                                                                        }}>Umkreis</p>
                                                                        <PrettoSlider
                                                                            size={'small'}
                                                                            defaultValue={30}
                                                                            step={10}
                                                                            min={10}
                                                                            value={currentRange}
                                                                            onChange={(event, newValue) => {

                                                                                if (newValue !== currentRange) {
                                                                                    setCurrentRange(newValue);
                                                                                    getResultsZip()
                                                                                    let t = ['Egal']
                                                                                    //save answer
                                                                                    let tq = [...questions];
                                                                                    if (newValue != 100) t = tq[index].answers.find((ans) => ans.answer == newValue);
                                                                                    console.log("ANSWER2", t)
                                                                                    if (questions[index]?.selected?.autocomplete.length > 0) {
                                                                                        let tDistance = tq[index].selected.answer.filter((ans) => ans === "Ich möchte meinen Standort teilen")
                                                                                        tDistance.push(t)
                                                                                        console.log("t distance", tDistance)
                                                                                        // if(t) t.answer = newValue


                                                                                        console.log("TQ autocomplete", JSON.stringify(tq[0].selected))
                                                                                        console.log("T", t)
                                                                                        tq[index].selected.answer = tDistance;
                                                                                        // tq[index].selected.autocomplete = [t];
                                                                                        // tq[index].selected.subAutocomplete = [];
                                                                                        setQuestions(tq);
                                                                                    }
                                                                                    //debouncedChangeHandler(jobs, plz, newValue);
                                                                                }

                                                                            }}
                                                                            onKeyPress={handleKeypressEnter}
                                                                        />
                                                                    </div>
                                                                    <div

                                                                        style={{
                                                                            height: isMobile ? '60vw' : 350,
                                                                            width: isMobile ? '80vw' : 500,
                                                                            borderRadius: 15,
                                                                            marginTop: isMobile ? 5 : 10,
                                                                            marginBottom: isMobile ? 5 : 5,
                                                                            overflow: 'hidden',
                                                                            position: 'relative',
                                                                            opacity: (questions[index]?.selected?.answer[0] && questions[index]?.selected?.answer[0] == 'Ich möchte von Zuhause aus arbeiten') ? 0.5 : 1
                                                                        }}
                                                                    >
                                                                        <div style={{
                                                                            height: '100%',
                                                                            width: '100%',
                                                                            background: 'transparent',
                                                                            position: 'absolute',
                                                                            top: 0,
                                                                            left: 0,
                                                                            zIndex: 999
                                                                        }}></div>
                                                                        {defaultProps.center.lat && <GoogleMapReact
                                                                            bootstrapURLKeys={{ key: ENV.GOOGLE_API_KEY }}
                                                                            defaultCenter={defaultProps.center}
                                                                            defaultZoom={defaultProps.zoom}
                                                                            yesIWantToUseGoogleMapApiInternals
                                                                            onGoogleApiLoaded={({
                                                                                                    map,
                                                                                                    maps
                                                                                                }) => apiIsLoaded(map, maps)
                                                                            }
                                                                        >
                                                                            {/*<div style={{background: 'red', height: 10, width: 10}}></div>*/}
                                                                            {/*<MapRadius*/}
                                                                            {/*    lat={defaultProps.center.lat}*/}
                                                                            {/*    lng={defaultProps.center.lng}*/}
                                                                            {/*    km={currentRange}*/}
                                                                            {/*/>*/}
                                                                            {/*<Circle*/}
                                                                            {/*    defaultCenter={{*/}
                                                                            {/*        lat: 55.555,*/}
                                                                            {/*        lng: 70.555*/}
                                                                            {/*    }}*/}
                                                                            {/*    radius={3000}*/}
                                                                            {/*    options={{ strokeColor: "#ff0000" }}*/}
                                                                            {/*/>*/}
                                                                        </GoogleMapReact>}
                                                                    </div>
                                                                </> : null}
                                                            {childItem.autocompleteType == 'Berufe' ?
                                                                <>
                                                                    <Autocomplete
                                                                        key={index}
                                                                        options={questions[index].autocomplete}
                                                                        getOptionLabel={(option) => {
                                                                            return option.title;
                                                                        }}
                                                                        dataSourceConfig={{
                                                                            text: props.title,
                                                                            value: props.input
                                                                        }}
                                                                        ref={autocompleteRef}
                                                                        name={''}
                                                                        freeSolo
                                                                        multiple
                                                                        openOnFocus={false}
                                                                        value={questions[index].selected.autocomplete}
                                                                        onChange={async (event, newValue) => {
                                                                            console.log("17test", newValue)
                                                                            let temp = [...questions];
                                                                            let t = [];

                                                                            if (newValue.length) {
                                                                                console.log("NEWVALUE17", newValue)
                                                                                newValue.map((item, index) => {
                                                                                    if (typeof item === 'string') {
                                                                                        t.push({
                                                                                            input: item,
                                                                                            title: item,
                                                                                        });
                                                                                    } else if (item && item.title) {
                                                                                        t.push({
                                                                                            input: item.title,
                                                                                            title: item.title,
                                                                                        });
                                                                                    } else {
                                                                                        t.push(item);
                                                                                    }
                                                                                })
                                                                                setIsFocused(null)

                                                                                temp[index].selected.autocomplete = t;
                                                                                temp[index].selected.autocompleteInput = '';
                                                                                temp[index].selected.autocompleteInputBak = '';

                                                                                console.log("QUESTIONS INDEX", questions[index])
                                                                                let url = ENV.REACT_APP_API_URL + '/jobTitles?target=title&filter[title][like]=' + newValue[0].title;
                                                                                const data = await api(url)
                                                                                // if (data.data?.data[0]?.attributes?.sector) {
                                                                                // setSectorData(data.data.data[0].attributes)

                                                                                let answerId = temp[index].answers.find(item => item.label == 'Branche')?.id ?? null
                                                                                const subAuto = [{
                                                                                    id: temp[index].id,
                                                                                    answer: data.data?.data[0]?.attributes?.sector ?? 'Alle Branchen',
                                                                                    answerId: answerId
                                                                                }]

                                                                                // if (data.data.data[0]?.attributes?.topCategory) {
                                                                                answerId = temp[index].answers.find(item => item.label == 'Oberkategorie')?.id ?? null
                                                                                const subAutoTopCat = {
                                                                                    id: temp[index].id,
                                                                                    answer: (data.data?.data[0]?.attributes?.topCategory?.length > 0) ? data.data?.data[0]?.attributes?.topCategory : 'Alle Oberkategorien',
                                                                                    answerId: answerId
                                                                                }
                                                                                subAuto.push(subAutoTopCat);
                                                                                // }
                                                                                // if (data.data.data[0]?.attributes?.category) {
                                                                                answerId = temp[index].answers.find(item => item.label == 'Kategorie')?.id ?? null
                                                                                const subAutoCat = {
                                                                                    id: temp[index].id,
                                                                                    answer: data.data?.data[0]?.attributes?.category ?? 'Alle Kategorien',
                                                                                    answerId: answerId
                                                                                }

                                                                                subAuto.push(subAutoCat)
                                                                                // }
                                                                                temp[index].selected.autocomplete[0].subAutocomplete = subAuto;
                                                                                // } else {
                                                                                //     fetchSectorData()
                                                                                //     // alert("fetchSectorData")
                                                                                // }
                                                                                console.log("17test temp")
                                                                                setQuestions(temp);
                                                                                fetchTopCategoryData(data.data?.data[0]?.attributes?.sector, temp[index].id)
                                                                                fetchCategoryData(data.data?.data[0]?.attributes?.sector, temp[index].id)
                                                                            } else {
                                                                                setIsFocused(null)

                                                                                temp[index].selected.autocomplete = t;
                                                                                temp[index].selected.autocompleteInput = '';
                                                                                temp[index].selected.autocompleteInputBak = '';
                                                                            }
                                                                        }}
                                                                        loading={autocompleteLoading == index}
                                                                        inputValue={questions[index].selected.autocompleteInput}
                                                                        onInputChange={async (event, newInputValue) => {
                                                                            setIsFocused(('answer-' + index + '-' + childIndex))
                                                                            let temp = [...questions];

                                                                            if (newInputValue) {
                                                                                let t = [];
                                                                                if (childItem.autocompleteType && newInputValue && newInputValue.length > 0) {
                                                                                    let url = ENV.REACT_APP_API_URL + '/autocompletes?filter%5BautocompleteType%5D%5Beq%5D=' + childItem.autocompleteType + '&filter%5BautocompleteData%5D%5Blike%5D=' + newInputValue;
                                                                                    setAutocompleteLoading(index);
                                                                                    debouncedChangeHandler(url, 1, temp, index);
                                                                                }
                                                                            }
                                                                            console.log("tempINDEX", temp[index])
                                                                            temp[index].selected.autocompleteInput = newInputValue;
                                                                            if (newInputValue.length > 0 || keyDown && newInputValue.length == 0) temp[index].selected.autocompleteInputBak = newInputValue;
                                                                            if (!temp[index].autocomplete) temp[index].autocomplete = [];
                                                                            setQuestions(temp);
                                                                        }}
                                                                        open={(questions[index].selected.autocompleteInput.length > 0 && isFocused === ('answer-' + index + '-' + childIndex))}
                                                                        groupBy={(option) => 'Vorschläge'}
                                                                        className={'text-input-label-white ' + (isFocused === ('answer-' + index + '-' + childIndex) || (questions[index].selected.autocomplete && questions[index].selected.autocomplete.length > 0) ? '' : 'borderWhite')}
                                                                        onFocus={() => {
                                                                            setIsFocused(('answer-' + index + '-' + childIndex))
                                                                        }}
                                                                        onKeyDown={(e) => {
                                                                            if (e.keyCode == 8 || e.keyCode == 46) setKeyDown(true)
                                                                            else setKeyDown(false)
                                                                        }}
                                                                        onBlur={async () => {
                                                                            setIsFocused(null)
                                                                            const newValue = questions[index].selected.autocompleteInputBak
                                                                            if (newValue) {
                                                                                let temp = [...questions];
                                                                                temp[index].selected.autocomplete.push({
                                                                                    input: questions[index].selected.autocompleteInputBak,
                                                                                    title: questions[index].selected.autocompleteInputBak
                                                                                });
                                                                                temp[index].selected.autocompleteInput = '';
                                                                                temp[index].selected.autocompleteInputBak = '';
                                                                                // setQuestions(temp);
                                                                                let url = ENV.REACT_APP_API_URL + '/jobTitles?target=title&filter[title][like]=' + questions[index].selected?.autocomplete[0]?.title;
                                                                                const data = await api(url)
                                                                                // if (data.data?.data[0]?.attributes?.sector) {
                                                                                // setSectorData(data.data.data[0].attributes)

                                                                                let answerId = temp[index].answers.find(item => item.label == 'Branche')?.id ?? null
                                                                                const subAuto = [{
                                                                                    id: temp[index].id,
                                                                                    answer: data.data?.data[0]?.attributes?.sector ?? 'Alle Branchen',
                                                                                    answerId: answerId
                                                                                }]

                                                                                // if (data.data.data[0]?.attributes?.topCategory) {
                                                                                answerId = temp[index].answers.find(item => item.label == 'Oberkategorie')?.id ?? null
                                                                                const subAutoTopCat = {
                                                                                    id: temp[index].id,
                                                                                    answer: (data.data?.data[0]?.attributes?.topCategory?.length > 0) ? data.data?.data[0]?.attributes?.topCategory : 'Alle Oberkategorien',
                                                                                    answerId: answerId
                                                                                }
                                                                                subAuto.push(subAutoTopCat);
                                                                                // }
                                                                                // if (data.data.data[0]?.attributes?.category) {
                                                                                answerId = temp[index].answers.find(item => item.label == 'Kategorie')?.id ?? null
                                                                                const subAutoCat = {
                                                                                    id: temp[index].id,
                                                                                    answer: data.data?.data[0]?.attributes?.category ?? 'Alle Kategorien',
                                                                                    answerId: answerId
                                                                                }
                                                                                subAuto.push(subAutoCat)
                                                                                // }
                                                                                temp[index].selected.autocomplete[0].subAutocomplete = subAuto;
                                                                                // } else {
                                                                                //     // fetchSectorData()
                                                                                //     // alert("fetchSectorData")
                                                                                // }

                                                                                setQuestions(temp);
                                                                                console.log("17test temp")
                                                                                fetchTopCategoryData(data.data?.data[0]?.attributes?.sector, temp[index].id)
                                                                                fetchCategoryData(data.data?.data[0]?.attributes?.sector, temp[index].id)
                                                                            }
                                                                            setKeyDown(false);
                                                                        }}
                                                                        selectOnFocus
                                                                        clearOnBlur
                                                                        handleHomeEndKeys
                                                                        renderInput={(params) =>
                                                                            <>
                                                                                <CssTextField2
                                                                                    {...params}
                                                                                    sx={{ input: { color: 'white' } }}
                                                                                    label={childItem.label}
                                                                                    InputLabelProps={{
                                                                                        shrink: true
                                                                                    }}
                                                                                    variant="outlined"
                                                                                    style={{
                                                                                        width: isMobile ? ((width * 75 / 100).toString() + 'px') : '30vw',
                                                                                        margin: '5px 0 0 0',
                                                                                        padding: 0,
                                                                                    }}
                                                                                    helperText={'Nutze wenn möglich unsere Vorschläge!'}
                                                                                />
                                                                            </>
                                                                        }
                                                                    />
                                                                </> : null}
                                                            {childItem.autocompleteType == 'Studium' ? <>
                                                                <FormControl
                                                                    sx={{
                                                                        width: isMobile ? '80%' : '30%'
                                                                    }}
                                                                    style={{
                                                                        marginTop: 5,
                                                                        transition: 'all 0.15s ease',
                                                                        minHeight: 46,
                                                                    }}
                                                                    className={'testClassSelect whiteLabel'}>
                                                                    <Select2
                                                                        id={'studiumDropdown'}
                                                                        value={questions[index].selected.dropdown ?? 'Bachelor'}
                                                                        onChange={(e) => {
                                                                            let tq = [...questions];
                                                                            tq[index].selected.dropdown = e.target.value;
                                                                            // tq[index].selected.autocomplete = e.target.value + tq[index].selected.autocomplete;
                                                                            setMultiSelectOpen(false);
                                                                            setQuestions(tq);
                                                                        }}
                                                                        onClick={() => {
                                                                            if (!multiSelectOpen) setMultiSelectOpen(index)
                                                                        }}
                                                                        onClose={() => {
                                                                            setMultiSelectOpen(false);
                                                                        }}
                                                                        open={multiSelectOpen == index}
                                                                        defaultValue={false}
                                                                        fullWidth
                                                                        on
                                                                        style={{
                                                                            width: '100%',
                                                                            marginBottom: 10,
                                                                            color: 'white',
                                                                        }}
                                                                        MenuProps={MenuProps}
                                                                        input={<Select2/>}
                                                                    >
                                                                        <MenuItem disabled value="">
                                                                        </MenuItem>
                                                                        <MenuItem value={'Bachelor'}>Bachelor</MenuItem>
                                                                        <MenuItem value={'Master'}>Master</MenuItem>
                                                                        <MenuItem value={'Diplom - Diplomgrad'}>Diplom -
                                                                            Diplomgrad</MenuItem>
                                                                        <MenuItem
                                                                            value={'Staatsexamen'}>Staatsexamen</MenuItem>
                                                                        <MenuItem
                                                                            value={'Magister-Magistergrad'}>Magister-Magistergrad</MenuItem>
                                                                    </Select2>
                                                                </FormControl>
                                                            </> : null}
                                                            {(childItem.autocompleteType != 'subAutocomplete' && childItem.autocompleteType != 'Zipcode' && childItem.autocompleteType != 'Berufe') ?
                                                                <Autocomplete
                                                                    options={questions[index].autocomplete}
                                                                    getOptionLabel={(option) => {
                                                                        return option.title;
                                                                    }}
                                                                    dataSourceConfig={{
                                                                        text: props.title,
                                                                        value: props.input
                                                                    }}
                                                                    ref={autocompleteRef}
                                                                    name={''}
                                                                    freeSolo
                                                                    multiple
                                                                    openOnFocus={false}
                                                                    value={questions[index].selected.autocomplete}
                                                                    onChange={(event, newValue) => {
                                                                        let temp = [...questions];
                                                                        let t = [];

                                                                        if (newValue) {
                                                                            newValue.map((item, index) => {
                                                                                if (typeof item === 'string') {
                                                                                    t.push({
                                                                                        input: item,
                                                                                        title: item,
                                                                                    });
                                                                                } else if (item && item.title) {
                                                                                    t.push({
                                                                                        input: item.title,
                                                                                        title: item.title,
                                                                                    });
                                                                                } else {
                                                                                    t.push(item);
                                                                                }
                                                                            })
                                                                            setIsFocused(null)
                                                                            temp[index].selected.autocomplete = t;
                                                                            temp[index].selected.autocompleteInput = '';
                                                                            temp[index].selected.autocompleteInputBak = '';
                                                                            setQuestions(temp);
                                                                        }
                                                                    }}
                                                                    loading={autocompleteLoading == index}
                                                                    inputValue={questions[index].selected.autocompleteInput}
                                                                    onInputChange={async (event, newInputValue) => {
                                                                        setIsFocused(('answer-' + index + '-' + childIndex))
                                                                        let temp = [...questions];

                                                                        if (newInputValue) {
                                                                            let t = [];
                                                                            if (childItem.autocompleteType && newInputValue && newInputValue.length > 0) {
                                                                                let url = ENV.REACT_APP_API_URL + '/autocompletes?filter%5BautocompleteType%5D%5Beq%5D=' + childItem.autocompleteType + '&filter%5BautocompleteData%5D%5Blike%5D=' + newInputValue;
                                                                                setAutocompleteLoading(index);
                                                                                debouncedChangeHandler(url, 1, temp, index);
                                                                            }
                                                                        }
                                                                        temp[index].selected.autocompleteInput = newInputValue;
                                                                        if (newInputValue.length > 0 || keyDown && newInputValue.length == 0) temp[index].selected.autocompleteInputBak = newInputValue;
                                                                        if (!temp[index].autocomplete) temp[index].autocomplete = [];
                                                                        setQuestions(temp);
                                                                    }}
                                                                    open={(questions[index].selected.autocompleteInput.length > 0 && isFocused === ('answer-' + index + '-' + childIndex))}
                                                                    groupBy={(option) => 'Vorschläge'}
                                                                    className={'text-input-label-white ' + (isFocused === ('answer-' + index + '-' + childIndex) || (questions[index].selected.autocomplete && questions[index].selected.autocomplete.length > 0) ? '' : 'borderWhite')}
                                                                    onFocus={() => {
                                                                        setIsFocused(('answer-' + index + '-' + childIndex))
                                                                    }}
                                                                    onKeyDown={(e) => {
                                                                        if (e.keyCode == 8 || e.keyCode == 46) setKeyDown(true)
                                                                        else setKeyDown(false)
                                                                    }}
                                                                    onBlur={() => {
                                                                        setIsFocused(null)
                                                                        if (questions[index].selected.autocompleteInputBak) {
                                                                            let temp = [...questions];
                                                                            temp[index].selected.autocomplete.push({
                                                                                input: questions[index].selected.autocompleteInputBak,
                                                                                title: questions[index].selected.autocompleteInputBak
                                                                            });
                                                                            temp[index].selected.autocompleteInput = '';
                                                                            temp[index].selected.autocompleteInputBak = '';
                                                                            setQuestions(temp);
                                                                        }
                                                                        setKeyDown(false);
                                                                    }}
                                                                    selectOnFocus
                                                                    clearOnBlur
                                                                    handleHomeEndKeys
                                                                    renderInput={(params) =>
                                                                        <>
                                                                            <CssTextField2
                                                                                {...params}
                                                                                sx={{ input: { color: 'white' } }}
                                                                                label={childItem.label}
                                                                                InputLabelProps={{
                                                                                    shrink: true
                                                                                }}
                                                                                variant="outlined"
                                                                                style={{
                                                                                    width: isMobile ? ((width * 75 / 100).toString() + 'px') : '30vw',
                                                                                    margin: '5px 0 0 0',
                                                                                    padding: 0,
                                                                                }}
                                                                                helperText={'Nutze wenn möglich unsere Vorschläge!'}
                                                                            />
                                                                        </>
                                                                    }
                                                                /> : null}
                                                            {(childItem.autocompleteType == 'subAutocomplete' && questions[index].selected.autocomplete.length > 0) ? <>

                                                                <div id={'subAutocomplete'}
                                                                     style={{ position: 'relative' }}>
                                                                    <Autocomplete key={index}
                                                                                  options={[]}
                                                                                  getOptionLabel={(option) => {
                                                                                      return (option.title) ? option.title : (Array.isArray(option) && option[0] && option[0].title) ? option[0].title : '';
                                                                                  }}
                                                                                  dataSourceConfig={{
                                                                                      text: props.title,
                                                                                      value: props.input
                                                                                  }}
                                                                                  name={''}
                                                                                  freeSolo
                                                                                  multiple
                                                                                  groupBy={(option) => 'Vorschläge'}
                                                                                  value={questions[index].selected.subAutocomplete}
                                                                                  onChange={(event, newValue) => {
                                                                                      let temp = [...questions];
                                                                                      let t = [];
                                                                                      if (newValue && !Array.isArray(newValue)) {
                                                                                          if (typeof newValue === 'string') {
                                                                                              t = [{
                                                                                                  input: newValue,
                                                                                                  title: newValue,
                                                                                              }];
                                                                                          } else if (newValue && newValue.title) {
                                                                                              t = [{
                                                                                                  input: newValue.title,
                                                                                                  title: newValue.title,
                                                                                              }];
                                                                                          } else {
                                                                                              t = [newValue];
                                                                                          }
                                                                                      } else if (newValue) {
                                                                                          newValue.map((item, index) => {
                                                                                              if (typeof item === 'string') {
                                                                                                  t.push({
                                                                                                      input: item,
                                                                                                      title: item,
                                                                                                  });
                                                                                              } else if (item && item.title) {
                                                                                                  t.push({
                                                                                                      input: item.title,
                                                                                                      title: item.title,
                                                                                                  });
                                                                                              } else {
                                                                                                  t.push(item);
                                                                                              }
                                                                                          })
                                                                                      }
                                                                                      setIsFocused(null)
                                                                                      temp[index].selected.subAutocomplete = t;
                                                                                      temp[index].selected.subAutocompleteInput = '';
                                                                                      temp[index].selected.subAutocompleteInputBak = '';
                                                                                      setQuestions(temp);
                                                                                  }}
                                                                                  loading={autocompleteLoading == (index)}
                                                                                  inputValue={questions[index].selected.subAutocompleteInput}
                                                                                  onInputChange={async (event, newInputValue) => {
                                                                                      setIsFocused((index))
                                                                                      let temp = [...questions];
                                                                                      let t = [];
                                                                                      if (newInputValue) {
                                                                                          if (newInputValue && newInputValue.length > 0) {
                                                                                              let url = ENV.REACT_APP_API_URL + '/autocompletes?filter%5BautocompleteType%5D%5Beq%5D=Studium&filter%5BautocompleteData%5D%5Blike%5D=' + newInputValue;
                                                                                              setAutocompleteLoading(index);
                                                                                              debouncedChangeHandler(url, 1, temp, index);
                                                                                          }
                                                                                      }
                                                                                      temp[index].selected.subAutocompleteInput = newInputValue;
                                                                                      if (newInputValue.length > 0 || keyDown && newInputValue.length == 0) temp[index].selected.subAutocompleteInputBak = newInputValue;
                                                                                      if (!temp[index].subAutocomplete) temp[index].subAutocomplete = [];
                                                                                      setQuestions(temp);
                                                                                  }}
                                                                        // open={questions[index].selected.dropdownInput.length > 2 && questions[index].answers[childIndex].sub[subIndex].dropdown && (!questions[index].selected.dropdown || questions[index].selected.dropdown != questions[index].selected.dropdownInput)}
                                                                                  open={false}
                                                                                  className={'text-input-label-white ' + (isFocused === ('answer-' + index + '-' + childIndex) || (questions[index].selected.subAutocomplete && questions[index].selected.subAutocomplete.length > 0) || questions[index].selected.subAutocompleteInput ? '' : 'borderWhite')}
                                                                                  onFocus={() => {
                                                                                      setIsFocused(('answer-' + index + '-' + childIndex))
                                                                                  }}
                                                                                  onKeyDown={(e) => {
                                                                                      if (e.keyCode == 8 || e.keyCode == 46) setKeyDown(true)
                                                                                      else setKeyDown(false)
                                                                                  }}
                                                                                  onBlur={() => {
                                                                                      setIsFocused(null)
                                                                                      if (questions[index].selected.subAutocompleteInputBak) {
                                                                                          let temp = [...questions];
                                                                                          temp[index].selected.subAutocomplete.push({
                                                                                              input: questions[index].selected.subAutocompleteInputBak,
                                                                                              title: questions[index].selected.subAutocompleteInputBak
                                                                                          });
                                                                                          temp[index].selected.subAutocompleteInput = '';
                                                                                          temp[index].selected.subAutocompleteInputBak = '';
                                                                                          setQuestions(temp);
                                                                                      }
                                                                                      setKeyDown(false);
                                                                                  }}
                                                                                  selectOnFocus
                                                                                  clearOnBlur
                                                                                  handleHomeEndKeys
                                                                                  renderInput={(params) =>
                                                                                      <>
                                                                                          <CssTextField2 {...params}
                                                                                                         sx={{
                                                                                                             input: {
                                                                                                                 color: 'white',
                                                                                                                 "&::placeholder": {
                                                                                                                     color: "white",
                                                                                                                     fontSize: 10,
                                                                                                                     opacity: 1,
                                                                                                                 },
                                                                                                             }
                                                                                                         }}
                                                                                                         label={'In welcher Branche oder welchem Bereich?'}
                                                                                                         InputLabelProps={{
                                                                                                             shrink: true
                                                                                                         }}
                                                                                                         variant="outlined"
                                                                                                         placeholder={questions[index].selected.subAutocomplete.length > 0 ? '' : 'z.b. Koch*in = Gastronomie / Hotellerie, Verkäufer*in = Lebensmittel'}
                                                                                                         style={{
                                                                                                             width: isMobile ? ((width * 75 / 100).toString() + 'px') : '30vw',
                                                                                                             margin: '15px 0 10px 0',
                                                                                                             padding: 0,
                                                                                                             transition: 'all 1s ease',
                                                                                                         }}
                                                                                          />
                                                                                          <p style={{
                                                                                              margin: '0 auto',
                                                                                              transition: 'all 0.2s ease',
                                                                                              position: 'absolute',
                                                                                              width: 45,
                                                                                              right: isMobile ? '20%' : '38%',
                                                                                              bottom: 3,
                                                                                              background: '#00374a',
                                                                                              color: questions[index].selected.subAutocomplete.length > 0 || isFocused == ('answer-' + index + '-' + childIndex) || questions[index].selected.subAutocompleteInput ? '#C7D300' : 'white',
                                                                                              padding: '0 6px',
                                                                                              fontSize: 11,
                                                                                              fontFamily: '"Roboto","Helvetica","Arial",sans-serif'
                                                                                          }}>(optional)</p>

                                                                                      </>
                                                                                  }
                                                                    />
                                                                </div>
                                                            </> : null}

                                                        </div>
                                                    </div>
                                                </>
                                            );
                                        })}
                                        {(questions.length >= activeQuestion && questions[index].answers) && questions[index].answers.map((childItem, childIndex) => {
                                            return (
                                                <>
                                                    {childItem.type == 'multiple' && childItem.question_id == '62' &&
                                                        <div key={childIndex}
                                                             style={{
                                                                 height: 46,
                                                                 transition: 'all 0.15s ease',
                                                                 marginBottom: 10
                                                             }}
                                                        >
                                                            <div
                                                                style={{
                                                                    width: '100%',
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                }}
                                                            >
                                                                <FormControlLabel
                                                                    style={{ color: 'white' }}
                                                                    control={<Checkbox
                                                                        disabled={(questions[index]?.selected?.answer[0] && questions[index]?.selected?.answer[0] == 'Ich möchte von Zuhause aus arbeiten')}
                                                                        color={'secondary'}
                                                                        sx={{
                                                                            color: "white",
                                                                        }}
                                                                        checked={shareLocation ?? false}
                                                                        onClick={() => {
                                                                            if (shareLocation) {
                                                                                setShareLocation(null)
                                                                            } else {
                                                                                setShareLocation(childItem.answer)
                                                                            }
                                                                            let tq = [...questions];
                                                                            let t = tq[index].selected.answer;
                                                                            let temp = activeQuestion + 1;

                                                                            if (tq[index].question === 'Was bist du derzeit?') {

                                                                                let x = [...t];
                                                                                if (!x.includes(childItem.answer)) {
                                                                                    x.push(childItem.answer);
                                                                                } else {
                                                                                    x.splice(x.indexOf(childItem.answer), 1);
                                                                                }

                                                                                setCategory(x);

                                                                            }

                                                                            if (questions[index]?.selected?.autocomplete.length > 0) {
                                                                                if (!t.includes(childItem.answer)) {
                                                                                    questions[index].answers.filter(i => (i.type === 'single' || i.type === 'singleDropdown')).map((i, ind) => {
                                                                                        if (t.includes(i.answer)) t = [];
                                                                                    })
                                                                                    t.push(childItem.answer)
                                                                                    if (childItem.autoContinue == true) {
                                                                                        let t = activeQuestion + 1;
                                                                                        setActiveQuestion(t)
                                                                                    }
                                                                                } else if (t.includes(childItem.answer)) {
                                                                                    let selectedIndex = t.indexOf(childItem.answer);
                                                                                    t.splice(selectedIndex, 1);
                                                                                }

                                                                                console.log("AUTOCOMP345", questions[index]?.selected?.autocomplete)
                                                                                tq[index].selected.answer = t;
                                                                                setQuestions(tq);
                                                                            }

                                                                            // tq[index].selected.answer = t;
                                                                            // setQuestions(tq);

                                                                        }
                                                                        }
                                                                    />} label={childItem.answer}/>
                                                                {(childItem.helperText) &&
                                                                    <span style={{ color: 'white' }}>
                                                                    <Tooltip enterTouchDelay={0}
                                                                             title={childItem.helperText}>
                                                                        <InfoIcon
                                                                            style={{ marginLeft: 4 }}
                                                                            fontSize={'14px'}
                                                                            id={'infoIcon'}
                                                                        />
                                                                    </Tooltip>
                                                                </span>}
                                                            </div>
                                                        </div>
                                                    }
                                                </>
                                            );
                                        })}
                                        {questions[index].answers && questions[index].answers.filter(i => i.type === 'autocompleteDropdown').length > 0 && questions[index].answers.map((childItem, childIndex) => {
                                            if (childItem.type == 'autocompleteDropdown' && questions[index].id != 6 && questions[index].id != 36 && questions[index].id != 48 && questions[index].id != 61) {

                                            } else {
                                                return;
                                            }
                                            return (<div
                                                    key={childIndex}
                                                    style={{
                                                        transition: 'all 0.15s ease',
                                                        width: '100%',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    {childItem.autocompleteType == 'Branchen' ?
                                                        <>
                                                            {/*{'3,16'}*/}
                                                            {(questions[index].selected.autocomplete[0]?.subAutocomplete) &&
                                                                <FormControl
                                                                    sx={{
                                                                        width: isMobile ? ((width * 75 / 100).toString() + 'px') : '30%',
                                                                    }}
                                                                    style={{
                                                                        marginTop: 5,
                                                                        transition: 'all 0.15s ease',
                                                                        height: 46,
                                                                    }}
                                                                    className={'testClassSelect greyLabel'}>
                                                                    <InputLabel
                                                                        className={'whiteLabel'}>{childItem.label}</InputLabel>
                                                                    <Select2
                                                                        id={'questionTest'}
                                                                        value={questions[index].selected.autocomplete[0]?.subAutocomplete?.find(item => item.answerId == childItem.id)?.answer ?? ''}//questions[index].selected.answer}
                                                                        onChange={(e) => {
                                                                            // const tempData = sectorData
                                                                            // tempData.sector = e.target.value
                                                                            // setSectorData(tempData)
                                                                            let tq = [...questions];
                                                                            let t = tq[index].selected.answer;

                                                                            t = e.target.value;

                                                                            const subAuto = [{
                                                                                id: tq[index].id,
                                                                                answer: t,
                                                                                answerId: childItem.id
                                                                            }]
                                                                            tq[index].selected.autocomplete[0].subAutocomplete = subAuto;
                                                                            setQuestions(tq);
                                                                            fetchTopCategoryData(e.target.value, questions[index].id, true)
                                                                            fetchCategoryData(e.target.value, questions[index].id)
                                                                        }}
                                                                        defaultValue={questions[index].selected.autocomplete[0]?.subAutocomplete?.find(item => item.answerId == childItem.id)?.answer ?? ''}
                                                                        fullWidth
                                                                        style={{
                                                                            width: '100%',
                                                                            marginBottom: 15,
                                                                        }}
                                                                        MenuProps={MenuProps}
                                                                        label={childItem.label}
                                                                        input={<Select2/>}
                                                                    >
                                                                        {(questions.length >= activeQuestion && sectors.length) && sectors.map((childItem, childIndex) => {
                                                                            // if (sectorData?.sector) {
                                                                            return (
                                                                                <MenuItem key={childIndex}
                                                                                          value={childItem}>{childItem}{(childItem.helperText) &&
                                                                                    <span
                                                                                        className={'color-yellow'}>
                                                                                <Tooltip
                                                                                    enterTouchDelay={0}
                                                                                    title={childItem.helperText}>
                                                                                    <InfoIcon style={{ marginLeft: 4 }}
                                                                                              fontSize={'14px'}
                                                                                              id={'infoIcon'}/>
                                                                                </Tooltip></span>}</MenuItem>
                                                                            );
                                                                            // }
                                                                        })}
                                                                    </Select2>
                                                                </FormControl>
                                                            }
                                                        </> : null}
                                                    {childItem.autocompleteType == 'Oberkategorien' ?
                                                        <>
                                                            {(topCategories[questions[index]?.id]?.length > 0 && questions[index].selected.autocomplete[0]) &&
                                                                <FormControl
                                                                    sx={{
                                                                        width: isMobile ? ((width * 75 / 100).toString() + 'px') : '30%',
                                                                    }}
                                                                    style={{
                                                                        marginTop: 15,
                                                                        transition: 'all 0.15s ease',
                                                                        height: 46,
                                                                    }}
                                                                    className={'testClassSelect greyLabel'}>
                                                                    <InputLabel
                                                                        className={'whiteLabel'}>{childItem.label}</InputLabel>
                                                                    <Select2
                                                                        id={'questionTest'}
                                                                        value={questions[index].selected.autocomplete[0]?.subAutocomplete?.find(item => item.answerId == childItem.id)?.answer ?? ''}
                                                                        onChange={(e) => {
                                                                            const topCatAnswerIndex = questions[index].selected.autocomplete[0]?.subAutocomplete?.findIndex(item => item.answerId == childItem.id) ?? 1


                                                                            // const tempData = sectorData
                                                                            // tempData.topCategory = e.target.value
                                                                            // setSectorData(tempData)
                                                                            let tq = [...questions];
                                                                            let t = tq[index].selected.answer;

                                                                            t = e.target.value;
                                                                            const indexUsed = tq[index].selected.autocomplete[0]?.subAutocomplete?.findIndex(item => item.answerId == childItem.id)
                                                                            if (indexUsed > -1) {
                                                                                tq[index].selected.autocomplete[0].subAutocomplete[indexUsed].answer = t;
                                                                            } else if (tq[index].selected.autocomplete[0]?.subAutocomplete) {
                                                                                tq[index].selected.autocomplete[0].subAutocomplete.push({
                                                                                    id: questions[index]?.id,
                                                                                    answer: t,
                                                                                    answerId: childItem.id
                                                                                })
                                                                            } else {
                                                                                tq[index].selected.autocomplete[0].subAutocomplete = [{
                                                                                    id: questions[index]?.id,
                                                                                    answer: t,
                                                                                    answerId: childItem.id
                                                                                }]
                                                                            }
                                                                            const answerId = tq[index].answers.find(item => item.label == 'Kategorie')?.id ?? null
                                                                            const indexDelete = tq[index].selected.autocomplete[0].subAutocomplete.findIndex(item => item.answerId == answerId)
                                                                            if (indexDelete > -1) tq[index].selected.autocomplete[0].subAutocomplete.splice(indexDelete, 1)
                                                                            // tq[index].selected.answer = t;
                                                                            setQuestions(tq);
                                                                            fetchCategoryData(questions[index].selected.autocomplete[0]?.subAutocomplete[topCatAnswerIndex - 1]?.answer, questions[index].id, e.target.value)
                                                                        }}
                                                                        defaultValue={questions[index].selected.autocomplete[0]?.subAutocomplete?.find(item => item.answerId == childItem.id)?.answer ?? ''}
                                                                        fullWidth
                                                                        style={{
                                                                            width: '100%',
                                                                            marginBottom: 15,
                                                                            color: 'white',
                                                                        }}
                                                                        MenuProps={MenuProps}
                                                                        label={childItem.label}
                                                                        input={<Select2/>}
                                                                    >
                                                                        {(questions.length >= activeQuestion && topCategories[questions[index]?.id]?.length) && topCategories[questions[index]?.id].map((childItem, childIndex) => {
                                                                            // if (sectorData?.topCategory) {
                                                                            return (
                                                                                <MenuItem key={childIndex}
                                                                                          value={childItem}>{childItem}{(childItem.helperText) &&
                                                                                    <span
                                                                                        className={'color-yellow'}>
                                                                                <Tooltip
                                                                                    enterTouchDelay={0}
                                                                                    title={childItem.helperText}>
                                                                                    <InfoIcon style={{ marginLeft: 4 }}
                                                                                              fontSize={'14px'}
                                                                                              id={'infoIcon'}/>
                                                                                </Tooltip></span>}</MenuItem>
                                                                            );
                                                                            // }
                                                                        })}
                                                                    </Select2>
                                                                </FormControl>
                                                            }
                                                        </> : null}
                                                    {childItem.autocompleteType == 'Kategorien' ?
                                                        <>
                                                            {(categories[questions[index]?.id]?.length > 0 && questions[index].selected.autocomplete[0]) &&
                                                                <FormControl
                                                                    label={childItem.label}
                                                                    sx={{
                                                                        width: isMobile ? ((width * 75 / 100).toString() + 'px') : '30%',
                                                                    }}
                                                                    style={{
                                                                        marginTop: 15,
                                                                        transition: 'all 0.15s ease',
                                                                        height: 46,
                                                                    }}
                                                                    className={'testClassSelect greyLabel'}>
                                                                    <InputLabel
                                                                        className={'whiteLabel'}>{childItem.label}</InputLabel>
                                                                    <Select2
                                                                        id={'questionTest'}
                                                                        value={questions[index].selected.autocomplete[0]?.subAutocomplete?.find(item => item.answerId == childItem.id)?.answer ?? ''}
                                                                        onChange={(e) => {
                                                                            // const tempData = sectorData
                                                                            // tempData.category = e.target.value
                                                                            // setSectorData(tempData)
                                                                            let tq = [...questions];
                                                                            let t = tq[index].selected.answer;

                                                                            t = e.target.value;
                                                                            const indexUsed = tq[index].selected.autocomplete[0]?.subAutocomplete?.findIndex(item => item.answerId == childItem.id)
                                                                            console.log(indexUsed)
                                                                            if (indexUsed > -1) {
                                                                                tq[index].selected.autocomplete[0].subAutocomplete[indexUsed].answer = t;
                                                                            } else if (tq[index].selected.autocomplete[0]?.subAutocomplete) {
                                                                                const subAutoCat = {
                                                                                    id: tq[index].id,
                                                                                    answer: t,
                                                                                    answerId: childItem.id
                                                                                }
                                                                                tq[index].selected.autocomplete[0].subAutocomplete.push(subAutoCat)
                                                                            } else {
                                                                                tq[index].selected.autocomplete[0].subAutocomplete = [{
                                                                                    id: tq[index].id,
                                                                                    answer: t,
                                                                                    answerId: childItem.id
                                                                                }]
                                                                            }
                                                                            setQuestions(tq);
                                                                        }}
                                                                        defaultValue={questions[index].selected.autocomplete[0]?.subAutocomplete?.find(item => item.answerId == childItem.id)?.answer ?? ''}
                                                                        fullWidth
                                                                        style={{
                                                                            width: '100%',
                                                                            marginBottom: 10,
                                                                            color: 'lightgrey !important',
                                                                        }}
                                                                        MenuProps={MenuProps}
                                                                        label={childItem.label}
                                                                        input={<Select2/>}
                                                                    >
                                                                        {(questions.length >= activeQuestion && categories[questions[index]?.id]?.length) && categories[questions[index]?.id].map((childItem, childIndex) => {
                                                                            // if (sectorData?.category) {
                                                                            return (
                                                                                <MenuItem key={childIndex}
                                                                                          value={childItem}>{childItem}{(childItem.helperText) &&
                                                                                    <span
                                                                                        className={'color-yellow'}>
                                                                                <Tooltip
                                                                                    enterTouchDelay={0}
                                                                                    title={childItem.helperText}>
                                                                                    <InfoIcon style={{ marginLeft: 4 }}
                                                                                              fontSize={'14px'}
                                                                                              id={'infoIcon'}/>
                                                                                </Tooltip></span>}</MenuItem>
                                                                            );
                                                                            // }
                                                                        })}
                                                                    </Select2>
                                                                </FormControl>}
                                                        </> : null}
                                                </div>
                                            )
                                        })}
                                        {(questions[index].id == 61) &&
                                            <>
                                                <Grid container spacing={3} style={{
                                                    width: isMobile ? '100vw' : '60%',
                                                    marginLeft: isMobile ? '0px' : '20vw',
                                                    marginBottom: isMobile ? '80px' : '0px'
                                                }}>
                                                    {(questions[index].selected.autocomplete.length < 2) &&
                                                        <Grid item xs={4}></Grid>}
                                                    {(questions[index].selected.autocomplete.length == 2) &&
                                                        <Grid item xs={2}></Grid>}
                                                    {(questions[index].selected.autocomplete.map((childItem, childIndex) => {
                                                        return (<Grid key={childIndex} item xs={12} lg={4}
                                                                      style={{ paddingRight: '24px' }}>

                                                                <div
                                                                    style={{
                                                                        transition: 'all 0.15s ease',
                                                                        width: '100%',
                                                                        display: 'flex',
                                                                        justifyContent: 'center',
                                                                        alignItems: 'center',
                                                                        border: '2px solid rgb(199, 211, 0)',
                                                                        borderRadius: '10px',
                                                                        paddingTop: '15px',
                                                                        paddingBottom: '15px', position: 'relative'
                                                                    }}
                                                                >
                                                                    {(childIndex > 0) && <div style={{
                                                                        height: '20px',
                                                                        width: '20px',
                                                                        color: 'white',
                                                                        position: 'absolute',
                                                                        top: '5px',
                                                                        right: '5px',
                                                                        display: 'flex',
                                                                        justifyContent: 'center',
                                                                        alignItems: 'center',
                                                                        cursor: 'pointer'
                                                                    }}
                                                                                              onClick={() => {
                                                                                                  const tq = [...questions]
                                                                                                  tq[index].selected.autocomplete.splice(childIndex, 1)
                                                                                                  setQuestions(tq)
                                                                                              }}

                                                                    >
                                                                        <Clear>
                                                                        </Clear>
                                                                    </div>}
                                                                    <Grid container spacing={2}
                                                                          style={{ width: '90%' }}>
                                                                        {/*{questions[index].answers && questions[index].answers.filter(i => i.type === 'autocompleteDropdown' || i.type === 'multipleAutoDropdown').length > 0 && questions[index].answers.map((childItem, childIndex) => {*/}
                                                                        {/*    return (*/}
                                                                        {/*        <>*/}
                                                                        <Grid item xs={12}>
                                                                            <div
                                                                                key={'00' + String(childIndex)}
                                                                                style={{
                                                                                    transition: 'all 0.15s ease',
                                                                                    width: '100%',
                                                                                }}
                                                                            >
                                                                                {/*{childItem.autocompleteType == 'Branchen' ?*/}
                                                                                {/*    <>*/}
                                                                                {/*{'61'}*/}
                                                                                {(sixtyOne.sectors.length) &&
                                                                                    <FormControl
                                                                                        sx={{
                                                                                            width: isMobile ? ((width * 75 / 100).toString() + 'px') : '100%',
                                                                                        }}
                                                                                        style={{
                                                                                            marginTop: 15,
                                                                                            transition: 'all 0.15s ease',
                                                                                            height: 46,
                                                                                        }}
                                                                                        className={'testClassSelect whiteLabel'}>
                                                                                        <InputLabel
                                                                                            className={'whiteLabel'}>{questions[index].answers[0]?.label}</InputLabel>
                                                                                        <Select2
                                                                                            id={'questionTest'}
                                                                                            value={questions[index].selected.autocomplete[childIndex]?.answer ?? ''}//questions[index].selected.answer}
                                                                                            onChange={(e) => {
                                                                                                // const tempData = sectorData
                                                                                                // tempData.sector = e.target.value
                                                                                                // setSectorData(tempData)
                                                                                                let tq = [...questions];
                                                                                                let t = tq[index].selected.answer;

                                                                                                t = e.target.value;
                                                                                                const subAuto = {
                                                                                                    id: tq[index].id,
                                                                                                    answer: t,
                                                                                                    answerId: questions[index].answers[0]?.id
                                                                                                }

                                                                                                if (tq[index].selected.autocomplete[childIndex]) {
                                                                                                    tq[index].selected.autocomplete[childIndex] = subAuto;
                                                                                                } else {
                                                                                                    tq[index].selected.autocomplete.push(subAuto)
                                                                                                }

                                                                                                setQuestions(tq);
                                                                                                fetchSOTopCategoryData(e.target.value, childIndex, true)
                                                                                                fetchSOCategoryData(e.target.value, childIndex)
                                                                                            }}
                                                                                            defaultValue={questions[index].selected.autocomplete[childIndex]?.answer ?? ''}
                                                                                            fullWidth
                                                                                            style={{
                                                                                                width: '100%',
                                                                                                marginBottom: 15,
                                                                                            }}
                                                                                            MenuProps={MenuProps}
                                                                                            label={questions[index].answers[0]?.label}
                                                                                            input={<Select2/>}
                                                                                        >
                                                                                            {(questions.length >= activeQuestion && sixtyOne.sectors.length) && sixtyOne.sectors.map((childItem, childIndex) => {
                                                                                                if (sixtyOne.sectors.length) {
                                                                                                    return (
                                                                                                        <MenuItem
                                                                                                            key={childIndex}
                                                                                                            value={childItem}>{childItem}{(childItem.helperText) &&
                                                                                                            <span
                                                                                                                className={'color-yellow'}>
                                                                                <Tooltip
                                                                                    enterTouchDelay={0}
                                                                                    title={childItem.helperText}>
                                                                                    <InfoIcon style={{ marginLeft: 4 }}
                                                                                              fontSize={'14px'}
                                                                                              id={'infoIcon'}/>
                                                                                </Tooltip></span>}</MenuItem>
                                                                                                    );
                                                                                                }
                                                                                            })}
                                                                                        </Select2>
                                                                                    </FormControl>
                                                                                }
                                                                                {/*</> : null}*/}
                                                                            </div>
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <div
                                                                                key={'01' + String(childIndex)}
                                                                                style={{
                                                                                    transition: 'all 0.15s ease',
                                                                                    width: '100%',
                                                                                    display: 'flex',
                                                                                    justifyContent: 'center',
                                                                                    alignItems: 'center'
                                                                                }}
                                                                            >
                                                                                {/*{childItem.autocompleteType == 'Oberkategorien' ?*/}
                                                                                {/*    <>*/}
                                                                                {(sixtyOne.topCategories[childIndex]?.length > 0) &&
                                                                                    <FormControl
                                                                                        sx={{
                                                                                            width: isMobile ? ((width * 75 / 100).toString() + 'px') : '100%',
                                                                                        }}
                                                                                        style={{
                                                                                            marginTop: 15,
                                                                                            transition: 'all 0.15s ease',
                                                                                            height: 46,
                                                                                        }}
                                                                                        className={'testClassSelect whiteLabel'}>
                                                                                        <InputLabel
                                                                                            className={'whiteLabel'}>{questions[index].answers[1]?.label}</InputLabel>
                                                                                        <Select2
                                                                                            id={'questionTest'}
                                                                                            value={questions[index].selected.autocomplete[childIndex]?.subAutocomplete?.find(item => item.answerId == questions[index].answers[1]?.id)?.answer ?? ''}
                                                                                            onChange={(e) => {
                                                                                                // fetchCategoryData(sectorData?.sector, questions[index].id, e.target.value)

                                                                                                // if (sectorData) {
                                                                                                //     const tempData = sectorData
                                                                                                //     tempData.topCategory = e.target.value
                                                                                                //     setSectorData(tempData)
                                                                                                // }
                                                                                                let tq = [...questions];
                                                                                                let t = tq[index].selected.answer;

                                                                                                t = e.target.value;
                                                                                                const indexUsed = tq[index].selected.autocomplete[childIndex]?.subAutocomplete?.findIndex(item => item.answerId == questions[index].answers[1]?.id)
                                                                                                if (indexUsed > -1) {
                                                                                                    tq[index].selected.autocomplete[childIndex].subAutocomplete[indexUsed].answer = t;
                                                                                                } else if (tq[index].selected.autocomplete[childIndex].subAutocomplete) {
                                                                                                    tq[index].selected.autocomplete[childIndex].subAutocomplete.push({
                                                                                                        id: questions[index]?.id,
                                                                                                        answer: t,
                                                                                                        answerId: questions[index].answers[1]?.id
                                                                                                    })
                                                                                                } else {
                                                                                                    tq[index].selected.autocomplete[childIndex].subAutocomplete = [{
                                                                                                        id: questions[index]?.id,
                                                                                                        answer: t,
                                                                                                        answerId: questions[index].answers[1]?.id
                                                                                                    }]
                                                                                                }
                                                                                                const answerId = tq[index].answers.find(item => item.label == 'Kategorie')?.id ?? null
                                                                                                const indexDelete = tq[index].selected.autocomplete[childIndex].subAutocomplete.findIndex(item => item.answerId == answerId)
                                                                                                if (indexDelete > -1) tq[index].selected.autocomplete[childIndex].subAutocomplete.splice(indexDelete, 1)
                                                                                                // tq[index].selected.answer = t;
                                                                                                setQuestions(tq);
                                                                                                fetchSOCategoryData(questions[index].selected.autocomplete[childIndex].answer, childIndex, e.target.value)
                                                                                            }}
                                                                                            defaultValue={questions[index].selected.autocomplete[childIndex]?.subAutocomplete?.find(item => item.answerId == questions[index].answers[1]?.id)?.answer ?? ''}
                                                                                            fullWidth
                                                                                            style={{
                                                                                                width: '100%',
                                                                                                marginBottom: 15,
                                                                                                color: 'white',
                                                                                            }}
                                                                                            MenuProps={MenuProps}
                                                                                            label={questions[index].answers[1]?.label}
                                                                                            input={<Select2/>}
                                                                                        >
                                                                                            {(questions.length >= activeQuestion && sixtyOne.topCategories[childIndex]?.length) && sixtyOne.topCategories[childIndex].map((childItem, childIndex) => {
                                                                                                // if (sectorData?.topCategory) {
                                                                                                return (
                                                                                                    <MenuItem
                                                                                                        key={childIndex}
                                                                                                        value={childItem}>{childItem}{(childItem.helperText) &&
                                                                                                        <span
                                                                                                            className={'color-yellow'}>
                                                                                <Tooltip
                                                                                    enterTouchDelay={0}
                                                                                    title={childItem.helperText}>
                                                                                    <InfoIcon style={{ marginLeft: 4 }}
                                                                                              fontSize={'14px'}
                                                                                              id={'infoIcon'}/>
                                                                                </Tooltip></span>}</MenuItem>
                                                                                                );
                                                                                                // }
                                                                                            })}
                                                                                        </Select2>
                                                                                    </FormControl>
                                                                                }
                                                                                {/*</> : null}*/}
                                                                            </div>
                                                                        </Grid>

                                                                        <Grid item xs={12}>
                                                                            <div
                                                                                key={'02' + String(childIndex)}
                                                                                style={{
                                                                                    transition: 'all 0.15s ease',
                                                                                    width: '100%',
                                                                                    display: 'flex',
                                                                                    justifyContent: 'center',
                                                                                    alignItems: 'center'
                                                                                }}
                                                                            >
                                                                                {/*{childItem.autocompleteType == 'Kategorien' ?*/}
                                                                                {/*    <>*/}
                                                                                {(sixtyOne.categories[childIndex]?.length > 0) &&
                                                                                    <FormControl
                                                                                        label={questions[index].answers[2]?.label}
                                                                                        sx={{
                                                                                            width: isMobile ? ((width * 75 / 100).toString() + 'px') : '100%',
                                                                                        }}
                                                                                        style={{
                                                                                            marginTop: 15,
                                                                                            transition: 'all 0.15s ease',
                                                                                            height: 46,
                                                                                        }}
                                                                                        className={'testClassSelect whiteLabel'}>
                                                                                        <InputLabel
                                                                                            className={'whiteLabel'}>{questions[index].answers[2]?.label}</InputLabel>
                                                                                        <Select2
                                                                                            id={'questionTest'}
                                                                                            value={questions[index].selected.autocomplete[childIndex]?.subAutocomplete?.find(item => item.answerId == questions[index].answers[2]?.id)?.answer ?? ''}
                                                                                            onChange={(e) => {
                                                                                                // if (sectorData) {
                                                                                                //     const tempData = sectorData
                                                                                                //     tempData.category = e.target.value
                                                                                                //     setSectorData(tempData)
                                                                                                // }

                                                                                                let tq = [...questions];
                                                                                                let t = tq[index].selected.answer;

                                                                                                t = e.target.value;
                                                                                                const indexUsed = tq[index].selected.autocomplete[childIndex]?.subAutocomplete?.findIndex(item => item.answerId == questions[index].answers[2]?.id)
                                                                                                console.log(indexUsed)
                                                                                                if (indexUsed > -1) {
                                                                                                    tq[index].selected.autocomplete[childIndex].subAutocomplete[indexUsed].answer = t;
                                                                                                } else if (tq[index].selected.autocomplete[childIndex]?.subAutocomplete) {
                                                                                                    const subAutoCat = {
                                                                                                        id: tq[index].id,
                                                                                                        answer: t,
                                                                                                        answerId: questions[index].answers[2]?.id
                                                                                                    }
                                                                                                    tq[index].selected.autocomplete[childIndex].subAutocomplete.push(subAutoCat)
                                                                                                } else {
                                                                                                    tq[index].selected.autocomplete[childIndex].subAutocomplete = [{
                                                                                                        id: tq[index].id,
                                                                                                        answer: t,
                                                                                                        answerId: questions[index].answers[2]?.id
                                                                                                    }]
                                                                                                }
                                                                                                setQuestions(tq);
                                                                                            }}
                                                                                            defaultValue={questions[index].selected.autocomplete[childIndex]?.subAutocomplete?.find(item => item.answerId == questions[index].answers[2]?.id)?.answer ?? ''}
                                                                                            fullWidth
                                                                                            style={{
                                                                                                width: '100%',
                                                                                                marginBottom: 10,
                                                                                                color: 'lightgrey !important',
                                                                                            }}
                                                                                            MenuProps={MenuProps}
                                                                                            label={questions[index].answers[2]?.label}
                                                                                            input={<Select2/>}
                                                                                        >
                                                                                            {(questions.length >= activeQuestion && sixtyOne.categories[childIndex]?.length) && sixtyOne.categories[childIndex].map((childItem, childIndex) => {
                                                                                                // if (sectorData?.category) {
                                                                                                return (
                                                                                                    <MenuItem
                                                                                                        key={childIndex}
                                                                                                        value={childItem}>{childItem}{(childItem.helperText) &&
                                                                                                        <span
                                                                                                            className={'color-yellow'}>
                                                                                <Tooltip
                                                                                    enterTouchDelay={0}
                                                                                    title={childItem.helperText}>
                                                                                    <InfoIcon style={{ marginLeft: 4 }}
                                                                                              fontSize={'14px'}
                                                                                              id={'infoIcon'}/>
                                                                                </Tooltip></span>}</MenuItem>
                                                                                                );
                                                                                                // }
                                                                                            })}
                                                                                        </Select2>
                                                                                    </FormControl>}
                                                                                {/*</> : null}*/}
                                                                            </div>
                                                                        </Grid>
                                                                        {/*</>*/}
                                                                        {/*)*/}
                                                                        {/*})*/}
                                                                        {/*}*/}
                                                                    </Grid>
                                                                </div>
                                                            </Grid>
                                                        )
                                                    }))
                                                    }
                                                    {(questions[index].selected.autocomplete.length < 3) &&
                                                        <Grid item xs={12} style={{
                                                            paddingRight: '24px',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center'
                                                        }}>
                                                            <div
                                                                style={{
                                                                    transition: 'all 0.15s ease',
                                                                    width: '60px',
                                                                    height: '60px',
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                    border: '2px solid rgb(199, 211, 0)',
                                                                    color: 'rgb(199, 211, 0)',
                                                                    borderRadius: '10px',
                                                                    cursor: 'pointer'
                                                                }}
                                                                onClick={() => {
                                                                    const tq = [...questions]
                                                                    // tq[index].selected.autocomplete[autoIndex] = {}
                                                                    console.log('18test', tq)
                                                                    const autoIndex = tq[index].selected.autocomplete.length
                                                                    const subAuto = {
                                                                        id: tq[index].id,
                                                                        answer: 'Alle Branchen',
                                                                        answerId: questions[index].answers[0]?.id
                                                                    }

                                                                    if (tq[index].selected.autocomplete[autoIndex]) {
                                                                        tq[index].selected.autocomplete[autoIndex] = subAuto;
                                                                    } else {
                                                                        tq[index].selected.autocomplete.push(subAuto)
                                                                    }

                                                                    setQuestions(tq);
                                                                    fetchSOTopCategoryData('Alle Branchen', autoIndex, true)
                                                                    fetchSOCategoryData('Alle Branchen', autoIndex)
                                                                }}
                                                            >
                                                                <Add fontSize='large'></Add>
                                                            </div>
                                                        </Grid>}
                                                </Grid></>}

                                        {questions[index].answers && questions[index].answers.filter(i => i.type === 'autocompleteDropdown' || i.type === 'multipleAutoDropdown').length > 0 && questions[index].answers.map((childItem, childIndex) => {
                                            if ((childItem.type == 'autocompleteDropdown' || childItem.type == 'multipleAutoDropdown') && questions[index].id == 61) {

                                            } else {
                                                return;
                                            }
                                            return (
                                                <div
                                                    key={childIndex}
                                                    style={{
                                                        transition: 'all 0.15s ease',
                                                        width: '100%',
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    <Grid container spacing={2}
                                                          style={{ width: isMobile ? '100%' : '60%' }}>

                                                    </Grid>
                                                </div>
                                            )
                                        })}
                                        {questions[index].answers && questions[index].answers.filter(i => i.type === 'textfield').length > 0 && questions[index].answers.map((childItem, childIndex) => {
                                            return (
                                                <>
                                                    {(childItem.type == 'textfield') &&
                                                        <div
                                                            key={childIndex}
                                                            style={{
                                                                transition: 'all 0.15s ease',
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    width: '100%',
                                                                    display: 'block',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                }}
                                                            >
                                                                {childItem.rows == 5 ?
                                                                    <p style={{ color: 'white' }}>(optional)</p> : null}
                                                                <CssTextField2
                                                                    label={item.label}
                                                                    InputLabelProps={{
                                                                        shrink: true
                                                                    }}
                                                                    value={Array.isArray(questions[index].selected.answer) ? questions[index].selected.answer[0] : questions[index].selected.answer}
                                                                    onChange={(e) => {
                                                                        let tq = [...questions];
                                                                        let t = tq[index].selected.answer;
                                                                        t = e.target.value;
                                                                        tq[index].selected.answer = t;
                                                                        setQuestions(tq);
                                                                    }}
                                                                    className={'multiLineInputColor ' + (isFocused === ('answer-' + index + '-' + childIndex) || (questions[index].selected.subAnswer && questions[index].selected.subAnswer[0]) ? '' : 'borderWhite')}
                                                                    onFocus={() => {
                                                                        setIsFocused(('answer-' + index + '-' + childIndex))
                                                                    }}
                                                                    onBlur={() => {
                                                                        setIsFocused(null)
                                                                    }}
                                                                    multiline={childItem.rows ? true : false}
                                                                    rows={childItem.rows ?? 1}
                                                                    sx={{
                                                                        input: { color: 'white' },
                                                                        multilineColor: { color: 'white' },
                                                                        label: { color: 'white' },
                                                                        fieldset: { transition: 'all 1s ease', }
                                                                    }}
                                                                    style={{
                                                                        marginTop: 10,
                                                                        marginBottom: 5,
                                                                        width: isMobile ? ((width * 75 / 100).toString() + 'px') : '30%',
                                                                        transformOrigin: 'top',
                                                                        transition: 'all 0.1s ease-in',
                                                                    }}
                                                                    autoComplete={'off'}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                </>
                                            );
                                        })}
                                        {questions[index].answers && questions[index].id == 62 && questions[index].answers.filter(i => i.type === 'single').length > 0 && questions[index].answers.map((childItem, childIndex) => {
                                            return (
                                                <>
                                                    {(childItem.type == 'single') &&
                                                        <div key={childIndex}
                                                             style={{
                                                                 height: 'auto',
                                                                 transition: 'all 0.15s ease',
                                                             }}
                                                        >
                                                            <div
                                                                style={{
                                                                    width: '100%',
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'center',
                                                                }}
                                                            >
                                                                <button
                                                                    key={index}
                                                                    onMouseOver={() => handleMouseIn('answer-' + index + '-' + childIndex)}
                                                                    onMouseOut={() => handleMouseOut()}
                                                                    style={{
                                                                        borderRadius: 20,
                                                                        cursor: 'pointer',
                                                                        background: 'transparent',
                                                                        width: isMobile ? ((width * 75 / 100).toString() + 'px') : '30%',
                                                                        border: '2px solid',
                                                                        padding: '10px 15px 8px 15px',
                                                                        margin: 5,
                                                                        borderColor: (buttonHover === ('answer-' + index + '-' + childIndex) || Array.isArray(questions[index].selected.answer) && questions[index].selected.answer.includes(childItem.answer)) ? '#C7D300' : 'white',
                                                                        color: 'white',
                                                                        position: 'relative'
                                                                    }}
                                                                    onClick={() => {
                                                                        let tq = [...questions];
                                                                        let subquestions = [...showSubQuestion];
                                                                        let t = tq[index].selected.answer;
                                                                        let temp = activeQuestion + 1;

                                                                        if (childItem.sub && (childItem.sub.length > 0) && !subquestions.includes(('answer-' + index + '-' + childIndex))) {
                                                                            subquestions.push('answer-' + index + '-' + childIndex);
                                                                        } else if (childItem.sub && (childItem.sub && childItem.sub.length > 0) && subquestions.includes(('answer-' + index + '-' + childIndex))) {
                                                                            subquestions.splice(subquestions.indexOf('answer-' + index + '-' + childIndex), 1);
                                                                            questions[index].selected.subAnswer = [];
                                                                        }

                                                                        if (!Array.isArray(t) || !t.includes(childItem.answer)) {
                                                                            subquestions.map((item, index2) => {
                                                                                if (item.startsWith('answer-' + index + '-') && item !== ('answer-' + index + '-' + childIndex)) {
                                                                                    subquestions.splice(index2, 1);
                                                                                    tq[index].selected.subAnswer = [];
                                                                                    // tq[index].selected.autocomplete = [];
                                                                                }
                                                                            })

                                                                            t = [childItem.answer];
                                                                            if (childItem.autoContinue == true) {
                                                                                let t = activeQuestion + 1;
                                                                                setActiveQuestion(t)
                                                                            }
                                                                        } else if (t.includes(childItem.answer)) {
                                                                            t = [];
                                                                        }
                                                                        setShowSubQuestion(subquestions);

                                                                        tq[index].selected.answer = t;
                                                                        tq[index].selected.autocomplete = [];
                                                                        tq[index].selected.subAutocomplete = [];
                                                                        setAutocompleteInput('');
                                                                        setQuestions(tq);
                                                                    }}
                                                                >{childItem.answer}{(childItem.helperText) &&
                                                                    <span style={{ color: 'white' }}>
                                                                    <Tooltip enterTouchDelay={0}
                                                                             title={childItem.helperText}>
                                                                        <InfoIcon
                                                                            style={{ marginLeft: 4 }}
                                                                            fontSize={'14px'}
                                                                            id={'infoIcon'}
                                                                        />
                                                                    </Tooltip>
                                                                </span>
                                                                }</button>
                                                            </div>
                                                        </div>}
                                                </>
                                            );
                                        })}
                                        {questions[index].answers && questions[index].answers.filter(i => i.type === 'datetimepicker').length > 0 && questions[index].answers.map((childItem, childIndex) => {
                                            return (
                                                <>
                                                    {(childItem.type == 'datetimepicker') &&
                                                        <div key={childIndex}
                                                             style={{
                                                                 transition: 'all 0.15s ease',
                                                             }}
                                                        >
                                                            <div
                                                                style={{
                                                                    width: isMobile ? ((width * 75 / 100).toString() + 'px') : '30%',
                                                                    display: 'flex',
                                                                    justifyContent: 'center',
                                                                    alignItems: 'flex-start',
                                                                    margin: 'auto',
                                                                    height: 200,
                                                                }}
                                                            >
                                                                <Grid container>
                                                                    <Grid item xs={7} style={{ paddingRight: 5 }}>
                                                                        <FormControl
                                                                            sx={{
                                                                                width: '100%'
                                                                            }}
                                                                            style={{
                                                                                marginTop: 15,
                                                                                transition: 'all 0.15s ease',
                                                                                height: 46,
                                                                            }}
                                                                            className={'testClassSelect whiteLabel'}>
                                                                            <InputLabel
                                                                                className={'whiteLabel'}>Monat</InputLabel>
                                                                            <Select2
                                                                                id={'questionTest'}
                                                                                onChange={(e) => {
                                                                                    let tq = [...questions];
                                                                                    let t = tq[index].selected.answer;
                                                                                    let temp = activeQuestion + 1;


                                                                                    questions[index].answers.filter(i => (i.type === 'single' || i.type === 'singleDropdown')).map((i, ind) => {
                                                                                        if (e.target.value.includes(i.answer)) {
                                                                                            let selectedIndex = e.target.value.indexOf(i.answer);
                                                                                            e.target.value.splice(selectedIndex, 1);
                                                                                        }
                                                                                    })

                                                                                    setMonth(e.target.value);
                                                                                    t = e.target.value + ' ' + year;
                                                                                    tq[index].selected.answer = t;
                                                                                    setQuestions(tq);
                                                                                }}
                                                                                value={month}
                                                                                fullWidth
                                                                                style={{
                                                                                    width: '100%',
                                                                                    marginBottom: 10,
                                                                                    color: 'white',
                                                                                }}
                                                                                MenuProps={MenuProps}
                                                                                input={<Select2/>}
                                                                            >
                                                                                <MenuItem
                                                                                    value={'Januar'}>Januar</MenuItem>
                                                                                <MenuItem
                                                                                    value={'Februar'}>Februar</MenuItem>
                                                                                <MenuItem value={'März'}>März</MenuItem>
                                                                                <MenuItem
                                                                                    value={'April'}>April</MenuItem>
                                                                                <MenuItem value={'Mai'}>Mai</MenuItem>
                                                                                <MenuItem value={'Juni'}>Juni</MenuItem>
                                                                                <MenuItem value={'Juli'}>Juli</MenuItem>
                                                                                <MenuItem
                                                                                    value={'August'}>August</MenuItem>
                                                                                <MenuItem
                                                                                    value={'September'}>September</MenuItem>
                                                                                <MenuItem
                                                                                    value={'Oktober'}>Oktober</MenuItem>
                                                                                <MenuItem
                                                                                    value={'November'}>November</MenuItem>
                                                                                <MenuItem
                                                                                    value={'Dezember'}>Dezember</MenuItem>
                                                                            </Select2>
                                                                        </FormControl>
                                                                    </Grid>
                                                                    <Grid item xs={5}>
                                                                        <FormControl
                                                                            sx={{
                                                                                width: '100%'
                                                                            }}
                                                                            style={{
                                                                                marginTop: 15,
                                                                                transition: 'all 0.15s ease',
                                                                                height: 46,
                                                                            }}
                                                                            className={'testClassSelect whiteLabel'}>
                                                                            <InputLabel shrink
                                                                                        className={'whiteLabel'}>Jahr</InputLabel>
                                                                            <Select2
                                                                                id={'questionTest'}
                                                                                onChange={(e) => {
                                                                                    let tq = [...questions];
                                                                                    let t = tq[index].selected.answer;
                                                                                    let temp = activeQuestion + 1;


                                                                                    questions[index].answers.filter(i => (i.type === 'single' || i.type === 'singleDropdown')).map((i, ind) => {
                                                                                        if (e.target.value.includes(i.answer)) {
                                                                                            let selectedIndex = e.target.value.indexOf(i.answer);
                                                                                            e.target.value.splice(selectedIndex, 1);
                                                                                        }
                                                                                    })


                                                                                    setYear(e.target.value);
                                                                                    t = month + ' ' + e.target.value;
                                                                                    tq[index].selected.answer = t;
                                                                                    setQuestions(tq);
                                                                                }}
                                                                                value={year}
                                                                                fullWidth
                                                                                style={{
                                                                                    width: '100%',
                                                                                    marginBottom: 10,
                                                                                    color: 'white',
                                                                                }}
                                                                                MenuProps={MenuProps}
                                                                                input={<Select2/>}
                                                                            >
                                                                                <MenuItem
                                                                                    value={currentYear.toString()}>{currentYear}</MenuItem>
                                                                                <MenuItem
                                                                                    value={(currentYear + 1).toString()}>{currentYear + 1}</MenuItem>
                                                                                <MenuItem
                                                                                    value={(currentYear + 2).toString()}>{currentYear + 2}</MenuItem>
                                                                                <MenuItem
                                                                                    value={(currentYear + 3).toString()}>{currentYear + 3}</MenuItem>
                                                                                <MenuItem
                                                                                    value={(currentYear + 4).toString()}>{currentYear + 4}</MenuItem>
                                                                                <MenuItem
                                                                                    value={(currentYear + 5).toString()}>{currentYear + 5}</MenuItem>
                                                                                <MenuItem
                                                                                    value={(currentYear + 6).toString()}>{currentYear + 6}</MenuItem>
                                                                                <MenuItem
                                                                                    value={(currentYear + 7).toString()}>{currentYear + 7}</MenuItem>
                                                                                <MenuItem
                                                                                    value={(currentYear + 8).toString()}>{currentYear + 8}</MenuItem>
                                                                            </Select2>
                                                                        </FormControl>
                                                                    </Grid>
                                                                </Grid>


                                                            </div>
                                                        </div>
                                                    }
                                                </>
                                            );
                                        })}

                                    </div>
                                </>
                            );
                        }
                    })}
                </Grid>
                {(questions && questions.length >= activeQuestion) ?
                    <>
                        <div style={{
                            width: '100%',
                            position: 'absolute',
                            paddingBottom: isMobile ? 20 : 45,
                            zIndex: '4',
                            bottom: 0
                        }}>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                                <div style={{
                                    position: 'fixed',
                                    bottom: 65,
                                    left: '5%',
                                    height: 15,
                                    width: 15,
                                    borderRadius: '50%',
                                    fontSize: 40,
                                    padding: 15,
                                    marginRight: 5,
                                    transition: 'all 0.05s ease-in-out',
                                    background: 'rgba(106, 106, 106, 0.5)',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                    <IconButton
                                        style={{
                                            height: 10,
                                            width: 10,
                                            fontSize: 40,
                                            padding: 15,
                                            transition: 'all 0.05s ease-in-out',
                                            color: (activeQuestion === 1 || onChangeQuestion === true) ? 'rgba(146,146,146,0.6)' : '#C7D300',
                                        }}
                                        onClick={() => {
                                            let temp = 0;
                                            temp = activeQuestion - 1;
                                            setOnChangeQuestion(true);
                                            setActiveQuestion(temp)
                                        }}
                                        disabled={activeQuestion === 1 || onChangeQuestion === true}
                                    >
                                        <WestIcon style={{ height: 30, width: 30 }}/>
                                    </IconButton>
                                </div>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>

                                </div>
                                <div style={{
                                    position: 'fixed',
                                    bottom: 65,
                                    right: '5%',
                                    height: 15,
                                    width: 15,
                                    borderRadius: '50%',
                                    fontSize: 40,
                                    padding: 15,
                                    marginRight: 5,
                                    transition: 'all 0.05s ease-in-out',
                                    background: 'rgba(106, 106, 106, 0.5)',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                                >
                                    <IconButton
                                        style={{
                                            height: 10,
                                            width: 10,
                                            fontSize: 40,
                                            padding: 15,
                                            transition: 'all 0.05s ease-in-out',
                                            color: (questions && activeQuestion >= questions.length || onChangeQuestion) ? 'rgba(146,146,146,0.6)' : '#C7D300',
                                        }}
                                        onClick={() => {
                                            let temp = activeQuestion + 1;
                                            if (questions && questions.length && questions.length > 2 && questions.length == activeQuestion + 1 && validateQuestions()) {
                                                setOnChangeQuestion(true);
                                                setActiveQuestion(temp)
                                            } else if (questions && questions.length && (questions.length == 2 || questions.length > 2 && questions.length != activeQuestion + 1)) {
                                                setOnChangeQuestion(true);
                                                setActiveQuestion(temp)
                                            }
                                        }}
                                        disabled={questions && activeQuestion >= questions.length || onChangeQuestion}
                                    >
                                        <EastIcon style={{ height: 30, width: 30 }}/>
                                    </IconButton>
                                </div>
                            </div>
                            <div style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                marginTop: isMobile ? 50 : 0
                            }}>
                                {questions && questions.length > 0 && Object.keys(questions).map((key, index) => {
                                    if (questions.length == 3 && index == 2) {
                                        return;
                                    }
                                    return (
                                        <>{(errors && errors.length && errors.includes(questions[index].id)) ?
                                            <Tooltip enterTouchDelay={0}
                                                     title={'Pflichtfeld'}>
                                                <div key={index} onClick={() => {
                                                    console.log(index + 1);
                                                    console.log(questions.length);
                                                    if (questions && questions.length && questions.length > 2 && questions.length == index + 1 && validateQuestions()) {
                                                        setOnChangeQuestion(true);
                                                        setActiveQuestion(index + 1)
                                                    } else if (questions && questions.length && (questions.length == 2 || questions.length > 2 && questions.length != index + 1)) {
                                                        setOnChangeQuestion(true);
                                                        setActiveQuestion(index + 1)
                                                    }
                                                }} style={{
                                                    cursor: 'pointer',
                                                    width: 13,
                                                    height: 13,
                                                    margin: '0 3px',
                                                    background: (errors && errors.length && errors.includes(questions[index].id) && questions[index].optional !== '1' && activeQuestion !== (index + 1)) ? 'red' : activeQuestion === (index + 1) ? 'white' : ((questions[key].selected?.answer?.length || questions[key].selected?.autocomplete?.length) > 0 || questions[key].optional == '1') ? 'yellow' : 'rgba(146,146,146,0.6)',
                                                    borderRadius: 10
                                                }}/>
                                            </Tooltip> : <div key={index} onClick={() => {
                                                console.log(index + 1);
                                                console.log(questions.length);
                                                if (questions && questions.length && questions.length > 2 && questions.length == index + 1 && validateQuestions()) {
                                                    setOnChangeQuestion(true);
                                                    setActiveQuestion(index + 1)
                                                } else if (questions && questions.length && (questions.length == 2 || questions.length > 2 && questions.length != index + 1)) {
                                                    setOnChangeQuestion(true);
                                                    setActiveQuestion(index + 1)
                                                }
                                            }} style={{
                                                cursor: 'pointer',
                                                width: 13,
                                                height: 13,
                                                margin: '0 3px',
                                                background: (errors && errors.length && errors.includes(questions[index].id) && questions[index].optional !== '1' && activeQuestion !== (index + 1)) ? 'red' : activeQuestion === (index + 1) ? 'white' : ((questions[key].selected?.answer?.length || questions[key].selected?.autocomplete?.length) > 0 || questions[key].optional == '1') ? 'yellow' : 'rgba(146,146,146,0.6)',
                                                borderRadius: 10
                                            }}/>}</>

                                    )
                                })}
                            </div>
                        </div>
                    </>
                    : null}
            </Grid>
        </>
    );
}
