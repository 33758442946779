import React from "react";
import PropTypes from "prop-types";

const ToggleSwitch = ({ id, name, checked, onChange, optionLabels, small, disabled }) => {

    return (
        <div className={"toggle-switch" + (small ? " small-switch" : "")}>
            <input
                type="checkbox"
                name={name}
                className={"toggle-switch-checkbox" + (checked ? '-checked' : '')}
                checked={checked}
                id={id}
                onChange={e => {
                    console.log('checked1')
                    onChange(e.target.checked)
                }}
            />
            {id ? (
                <label className="toggle-switch-label" htmlFor={id}>
            <span
                className={
                    disabled
                        ? "toggle-switch-inner toggle-switch-disabled"
                        : "toggle-switch-inner"
                }
                data-yes={optionLabels[0]}
                data-no={optionLabels[1]}
            />
                    <span
                        className={
                            disabled
                                ? "toggle-switch-switch toggle-switch-disabled"
                                : "toggle-switch-switch"
                        }
                    />
                </label>
            ) : null}
        </div>
    );
}

ToggleSwitch.defaultProps = {
    optionLabels: ["Matches", "Anonymes Profil"],
};

ToggleSwitch.propTypes = {
    id: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    name: PropTypes.string,
    optionLabels: PropTypes.array,
    small: PropTypes.bool,
    disabled: PropTypes.bool
};

export default ToggleSwitch;
