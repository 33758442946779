import React, {useEffect, useRef, useState} from 'react';
import 'react-toastify/dist/ReactToastify.css';
// import startseite from '../images/png/Startseite.png';
import startseite from '../images/jpg/titelbild_as.jpg';
import {Grid, Link} from "@mui/material";
import {useLocation, useParams} from "react-router";
import {BackgroundImage} from "react-image-and-background-image-fade";
import {useHistory} from "react-router-dom";
import {ReactComponent as LogoSvgIconBlauWeiß} from "../images/svg/swiitcher_icon_blau_weiß.svg";
import {ReactComponent as LogoSvgSuchen} from "../images/svg/suchen_neu.svg";
import {ReactComponent as LogoSvgStarFull} from "../images/svg/star_full.svg";
import {ReactComponent as LogoSvgStarOut} from "../images/svg/star_out.svg";
import {ReactComponent as LogoSvgQouselduselmark} from "../images/svg/qouselduselmark.svg";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { Link as Link2, animateScroll as scroll } from "react-scroll";
import {isMobile} from "react-device-detect";
import { ReactSession } from 'react-client-session';

export default function Home(props) {

    const {locationChange} = props;
    const location = useLocation();
    const history = useHistory();
    const params = useParams();
    const [buttonHover, setButtonHover] = useState(null);
    const jetztSwiitchenRef = useRef(null);


    useEffect(() => {
        document.title = 'swiitcher';
        locationChange(location, params.token);
    }, [])

    const handleMouseIn = (type) => {
        setButtonHover(type)
    };

    const handleMouseOut = () => {
        setButtonHover(null)
    };

    // const [checkLoggedIn, setCheckLoggedIn] = React.useState()
    //
    // useEffect(() => {
    //     if (ReactSession.get('user') !== "Arbeitgeber" && ReactSession.get('user') !== 'Arbeitnehmer'){
    //         history.push('/login')
    //     }
    // }, [])


    return (
        <React.Fragment>
            <BackgroundImage
                src={startseite}
                style={{
                    background: 'rgba(0,55,78,0.5)',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    width: '100% +1px',
                    minHeight: '100vh',
                    height: 'auto',
                    marginTop: 64,
                    marginLeft: '-1px',
                    position: 'relative',
                }}
                lazyLoad
            >
                <div style={{
                    // position: 'absolute',
                    // top: 0,
                    // left: 0,
                    width: '100%',
                    height: '100%',
                    minHeight: '100vh',
                    // background: 'rgba(0,55,78,0.5)',
                    boxShadow: '0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)'
                }}>
                    <Grid container style={{
                        height: '100%',
                        padding: isMobile ? 20 : 70
                    }}>
                        <Grid item xs={12} style={{
                            display: 'flex',
                            alignContent: 'start',
                            justifyContent: 'start'
                        }}>
                            {/*<div style={{position: 'absolute', color: 'white', textAlign: 'left', top: '35%', left: '10%'}}>*/}
                            <div style={{
                                color: 'white',
                                textAlign: 'left',
                                display: 'block',
                                height: 'auto',
                                margin: isMobile ? '10% 0px 0px 5%' : '5% 0px 0px 5%',
                            }}>
                                <p style={{fontSize: (isMobile ? 38 : 60), margin: 0, lineHeight: 1}}>
                                <span style={{
                                    fontFamily: 'VagRoundedBold',
                                    fontWeight: 'bold'
                                }}>Die anonyme Jobsuche</span><br/>
                                    <span>in unter 3 Minuten!</span>
                                </p>
                                <p style={{fontSize: 18, margin: '20px 0 25px 0px', maxWidth: 555}}>
                                    <span style={{display: 'block'}}>Mit wenigen Fragen erstellst du dir hier ein Profil zu deiner Qualifikation und wir matchen dich mit passenden Jobangeboten. An dir interessierte Unternehmen stellen dir anschließend eine Anfrage, um dich als Mitarbeiter*in zu gewinnen.</span><span style={{display: 'block'}}>&nbsp;</span> Bestätige die Anfrage und trete mit dem Unternehmen via Chat in Kontakt. <span style={{fontFamily: 'VagRoundedBold'}}>100 % anonym und kostenfrei.</span>
                                </p>
                                <button className={'fadeInOut'} onMouseOver={() => handleMouseIn('jobFindenButton')}
                                        onMouseOut={() => handleMouseOut()} style={{
                                    fontFamily: 'VagRoundedBold',
                                    background: buttonHover === 'jobFindenButton' ? 'rgba(0, 55, 78, 0.5)' : '#C7D300',
                                    cursor: 'pointer',
                                    margin: 'auto',
                                    textAlign: 'center',
                                    padding: '7px 15px 6px',
                                    borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                    color: buttonHover === 'jobFindenButton' ? '#C7D300' : '#00374e',
                                    border: '2px solid ' + (buttonHover === 'jobFindenButton' ? '#C7D300' : '#C7D300'),
                                    fontSize: 18,
                                    minWidth: 100
                                }} onClick={() => {
                                    // const yOffset = 60;
                                    // const element = jetztSwiitchenRef.current;
                                    // const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
                                    // window.scrollTo({top: y, behavior: 'smooth'});
                                    // // jetztSwiitchenRef.current.scrollIntoView({behavior: "smooth", block: "end", inline: "nearest"});
                                    history.push('/anonym-swiitchen')
                                }}>
                                    ANONYMES PROFIL ERSTELLEN
                                </button>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </BackgroundImage>
        </React.Fragment>
    );
}

