import React, {useEffect} from 'react';
import Container from "@material-ui/core/Container";
import {useLocation} from "react-router-dom";
import ueberUnsBild from '../images/jpg/Tänzel-0283.jpg';
import {isMobile} from "react-device-detect";
import useWindowDimensions from "./useWindowDimensions";

export default function UeberUns() {

    const location = useLocation();
    const {width} = useWindowDimensions();

    useEffect(() => {
        document.title = 'Über uns';
    }, []);

    return (
        <Container maxWidth="lg" style={{marginTop: 100, background: 'white'}}>

            <div style={{textAlign: 'left'}}>
                <h1>UNSERE STORY</h1>
                <h2>UNSER ZIEL</h2>
                <p>Wir glauben, dass Stellensuche schnell, einfach und smart sein sollte. Deshalb haben wir mit „swiitcher“ eine einzigartige Plattform entwickelt, die Arbeitnehmer und Arbeitgeber verlässlich und komfortabel vernetzt. Indem wir das selbsterklärende Design mit künstlicher Intelligenz verbinden, ermöglichen wir Wechselwilligen die berufliche Neuorientierung, schaffen neue Möglichkeiten für Berufseinsteiger, erzielen optimale Matches auf dem Arbeitsmarkt und bringen viel Dynamik in träge Strukturen.</p>
                <div style={{
                    width: '100%',
                    textAlign: 'center'
                }}>
                    <img style={{
                        maxWidth: width < 640 ? '100%' : 600,
                        marginTop: 20
                    }}
                         src={ueberUnsBild} />
                </div>
                <h2>WER WIR SIND</h2>
                <p>Auch swiitcher begann mit einem perfekten Match: Thomas und Dominik – Vater und Sohn – haben ihre gemeinsame Vision in die Tat umgesetzt. 2020 begannen die beiden gemeinsam, die Weichen für das Projekt swiitcher zu stellen. Und, anstatt eine fertige Lösung zu übernehmen und eine weitere Stellenplattform zu starten, wollen wir den Austausch der Menschen auf Augenhöhe fördern und ein lebendiges Netzwerk für eine effiziente Jobsuche starten: passende Talente für freie Stellen.</p>
                <p>Dominik, der als Leiter des Projekts für Konzeption, Design und Entwicklung zuständig war, ist heute stolzer Geschäftsführer von swiitcher. Mit einem erfahrenen Team aus Webdesignern und Programmierern aus dem Allgäu trugen wir die besten Ansätze zusammen und entwickelten swiitcher als innovative Plattform für den Arbeitsmarkt. Nicht nur einmal wurde das Konzept angepasst, mussten viele Hürden überwunden und aus zahlreichen Gesprächen die optimale Lösung herausgearbeitet werden – swiitcher nahm immer mehr Gestalt an. Es war eine weite und anspruchsvolle Reise vom ursprünglichen Gedanken bis hin zu swiitcher – einer innovativen Plattform für smarte Mitarbeiterfindung!</p>

            </div>
        </Container>
    );
}
