import React, {useEffect, useRef} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {Grid, IconButton} from "@mui/material";
import {useState} from "react";
import {useHistory, useLocation} from "react-router";
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import {ReactComponent as LogoSvgArbeitgeber1} from "../images/svg/swiitcher_icon.svg";
import {ReactComponent as LogoSvgGefunden} from "../images/svg/gefunden.svg";
import {ReactComponent as LogoSvgHeart} from "../images/svg/heart.svg";
import {ReactComponent as LogoSvgHeartOutline} from "../images/svg/heart_outline.svg";
import {ReactComponent as LogoSvgPuzzle} from "../images/svg/puzzle.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import SearchIcon from "@mui/icons-material/Search";
import HorizontalLinearStepper from "./HorizontalLinearStepper";
import {ReactSession} from "react-client-session";
import useFetch from "./UseFetch";
import {toast} from "react-toastify";
import {CssTextField2} from "./CssTextField2";
import {useParams} from "react-router-dom";
import htmlToDraft from "html-to-draftjs";
import ContentState from "draft-js/lib/ContentState";
import {EditorState} from "draft-js";
import {stateToHTML} from "draft-js-export-html";
import moment from "moment";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import {CustomButton} from "./CustomButton";
import Box from "@mui/material/Box";
import {PriorityHigh} from "@mui/icons-material";

const antworten = ['Arbeitnehmer', 'Azubi', 'Schüler', 'Student'];

export default function NeueStellenanzeige(props) {

    const {locationChange, menuAppBarRef} = props;
    const location = useLocation();
    const params = useParams();
    const api = useFetch();
    const history = useHistory();
    const [canCreateAdvertisement, setCanCreateAdvertisement] = React.useState(null);
    const [canUpdateAdvertisement, setCanUpdateAdvertisement] = React.useState(false)
    const [onUpdate, setOnUpdate] = React.useState(true);

    useEffect(() => {
        if (ReactSession.get('user') != 'Arbeitgeber')  {
            menuAppBarRef.current.logNeeded('Arbeitgeber');
            history.push('/');
        }
        else {
            document.title = 'Neue Stellenanzeige';
            locationChange(location);
            if (params.id) {
                // getJobAdvertisement();
            }
            getContingent();
        }
    }, [])

    const getContingent = async () => {

        // let url = ENV.REACT_APP_API_URL + '/job-advertisements?page%5Bsize%5D=20&page%5Bnumber%5D=' + i;
        let url = ENV.REACT_APP_API_URL + '/user/' + ReactSession.get('userId') + '?include=jobAdvertisementContingent';
        console.log(url);
        //if (this.state.sort) url += '&sort=' + this.state.sort;

        let {response, data} = await api(url)


        if (!response || response.status == 401) {
            ReactSession.set('token', undefined);
            ReactSession.set('refreshToken', undefined);
            history.push('/login');
            return;
        }

        if (data && data.included && data.included[0] && (data.included[0].attributes.availableSingleJobAdvertisements > 0 || moment(data.included[0].attributes.flatDuration).isValid() && moment(data.included[0].attributes.flatDuration) > moment())) {
            console.log('ACCESS GRANTED');
            setCanCreateAdvertisement(true);
            setForbidden(false);
            setOnUpdate(false);
        } else if (data && data.included && data.included[0] && (data.included[0].attributes.availableSingleJobAdvertisements == 0 && (!moment(data.included[0].attributes.flatDuration).isValid() || moment(data.included[0].attributes.flatDuration) < moment()))) {
            console.log('ACCESS DENIED');
            setCanCreateAdvertisement(false);
            setForbidden(false);
            setOnUpdate(false);
            setCanUpdateAdvertisement(true)
        } else if (response.error) {
            notify('API Fehler: ' + response.status, true);
        }
    }

    const notify = (message, error) => {
        console.log('NOTIFY')
        if (error) {
            toast.error(message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                style: {marginTop: 30}
            });
        } else {
            toast.success(message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                style: {marginTop: 30}
            });
        }
    }


    // const passwordStyle = {
    //     position: 'absolute',
    //     top: '35%',
    //     left: '50%',
    //     transform: 'translate(-50%, -50%)',
    //     width: '20%',
    //     minWidth: 250,
    //     maxHeight: '70%',
    //     overflow: 'auto',
    //     backgroundColor: 'rgba(218, 227, 243, 0.95)',
    //     border: '2px solid transparent !important',
    //     borderRadius: 5,
    //     padding: '5px 16px 20px',
    //     boxShadow: 24,
    //     zIndex: 4,
    //     lineHeight: '13px'
    // };

    const passwordStyle = {
        position: 'absolute',
        textAlign: 'left',
        top: '35%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '20%',
        minWidth: 200,
        maxHeight: '70%',
        overflow: 'auto',
        backgroundColor: 'rgba(218, 227, 243, 0.95)',
        border: '2px solid transparent !important',
        borderRadius: 5,
        padding: '5px 16px 20px',
        boxShadow: 24,
        zIndex: 4,
        lineHeight: '13px'
    };



    const [buttonHover, setButtonHover] = useState(null);
    const [activeMatchItem, setActiveMatchItem] = useState(null);
    // const [selected, setSelected] = useState(props.type === 'update' ? temp : []);
    // const [answered, setAnswered] = useState(props.type === 'update');
    const [selected, setSelected] = useState([]);
    const [answered, setAnswered] = useState(false);
    const [forbidden, setForbidden] = useState(true);

    // const getJobAdvertisement = async () => {
    //     let url = ENV.REACT_APP_API_URL + '/job-advertisement/' + params.id + '?include=contacts,companyAddresses,answeredQuestions';
    //
    //     let {response, data} = await api(url)
    //
    //
    //     if (!response || response.status == 401) {
    //         ReactSession.set('token', undefined);
    //         ReactSession.set('refreshToken', undefined);
    //         return;
    //     }
    //
    //     let sel = [];
    //     if (data && data.data && response.status == '200') {
    //         if (data.data.attributes.creator_id != ReactSession.get('userId')) {
    //             setForbidden(true);
    //             notify('Du hast nicht die nötigen Berechtigungen dafür', true);
    //             return;
    //         }
    //         setOnUpdate(false);
    //         if (data.included && data.included.filter(i => i.attributes.answerText == 'Arbeitnehmer').length > 0) {
    //             sel.push('Arbeitnehmer');
    //         }
    //         if (data.included && data.included.filter(i => i.attributes.answerText == 'Azubi').length > 0) {
    //             sel.push('Azubi');
    //         }
    //         if (data.included && data.included.filter(i => (i.attributes.answerText == 'Schüler' || i.attributes.answerText == 'Schüler')).length > 0) {
    //             sel.push('Schüler');
    //         }
    //         if (data.included && data.included.filter(i => i.attributes.answerText == 'Student').length > 0) {
    //             sel.push('Student');
    //         }
    //         setSelected(sel);
    //     }
    // }

    return (
        <Grid className="mainApp" container style={{
            padding: 0,
            minHeight: '70vh'
        }}>
            <Grid id={'stepper'} item xs={12} style={{
                display: 'block',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 64,
                marginBottom: activeMatchItem !== null ? 50 : 0,
                padding: '30px 50px 50px',
                width: '100%',
                minHeight: '800px',
            }}>
                <Grid container>
                    <Grid item xs={12}>
                        <p className={'headerName buttonLinkYellow'} onClick={() => {
                            setForbidden(false);
                            history.push('/stellenanzeigen')
                        }} style={{
                            textAlign: 'left',
                            position: 'relative',
                            lineHeight: '40px',
                            color: '#00374e',
                            cursor: 'pointer',
                            transition: 'all 0.1s ease-in-out'
                        }}>Zurück zur Übersicht</p>
                    </Grid>
                </Grid>

                {(!canCreateAdvertisement && onUpdate === false && forbidden) && <>
                    <Box sx={passwordStyle}>
                        <h3 style={{marginTop: 0, marginBottom: 30, fontFamily: 'VagRoundedBold'}}>Fehlendes Kontingent<PriorityHigh
                            style={{
                                color: '#92D050',
                                marginBottom: -5,
                                width: 40,
                                height: 40,
                            }}
                        /></h3>
                        <p style={{fontSize: 12}}>Damit du eine Stellenanzeige erstellen kannst, buche eine Einzelanzeige oder Flat.</p>
                        <CustomButton
                            buttontype={'yellowBlue'}
                            onClick={() => {
                                // login();
                                history.push('/arbeitgeber#produkte-und-preise');
                            }}
                            style={{
                                marginTop: 20,
                                width: '100%'
                            }}
                        >Einzelanzeige oder Flat buchen</CustomButton>
                    </Box>
                </>}

                {((canCreateAdvertisement && onUpdate === false && !forbidden) || (canUpdateAdvertisement)) && <Grid container style={{padding: 15}}>
                    <Grid item xs={12} style={answered ? {padding: '40px', borderTop: '2px solid #00374e'} : {}}>
                        <HorizontalLinearStepper id={params.id} type={props.type} selected={selected} />
                    </Grid>
                </Grid>}
            </Grid>
        </Grid>
    );
}

