import React, {useEffect} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {Autocomplete, Grid, Link, Slider} from "@mui/material";
import {useHistory, useLocation} from "react-router";
import {CssTextField} from "./CssTextField";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import platzhalter from '../images/png/logo black-250px-transparent.png';
import {ReactComponent as LogoSvgHeart} from "../images/svg/heart.svg";
import {ReactComponent as LogoSvgHeartOutline} from "../images/svg/heart_outline.svg";
import {useState} from "react";
import {CssTextField3} from "./CssTextField3";
import {useMemo} from "react";
import debounce from "lodash.debounce";
import {styled} from "@mui/styles";
import {useParams} from 'react-router-dom';
import {ReactSession} from "react-client-session";
import ShareIcon from '@mui/icons-material/Share';
import PrintIcon from '@mui/icons-material/Print';
import placeholder from '../images/png/2022-08-19_21-43.png';
import edelweiss from '../images/jpg/49dbad3-edelweiss-gmbh-co-kg-logo.jpg';
import LoremIpsum, {Avatar} from "react-lorem-ipsum";
import PhoneIcon from '@mui/icons-material/Phone';
import useFetch from "./UseFetch";
import htmlToDraft from "html-to-draftjs";
import ContentState from "draft-js/lib/ContentState";
import {EditorState} from "draft-js";
import {toast} from "react-toastify";
import renderHTML from "react-render-html";
import Button from "@mui/material/Button";
import {CustomButton} from "./CustomButton";
import {CssTextField2} from "./CssTextField2";
import {Mail} from "@mui/icons-material";
import LanguageIcon from "@mui/icons-material/Language";
import {isMobile} from "react-device-detect";

const PrettoSlider = styled(Slider)({
    color: '#00374e',
    height: 3,
    width: 'calc(100% - 30px)',
    padding: 0,
    marginTop: 5,
    marginBottom: 5,
    '& .MuiSlider-track': {
        border: 'none',
    },
    '& .MuiSlider-thumb': {
        height: 14,
        width: 14,
        backgroundColor: '#C7D300',
        border: '2px solid #C7D300',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&:before': {
            display: 'none',
        },
        '&:after': {
            height: 16,
        },
    },
    '& .MuiSlider-valueLabel': {
        lineHeight: 1.2,
        fontSize: 12,
        background: 'unset',
        padding: 0,
        width: 32,
        height: 32,
        borderRadius: '50% 50% 50% 0',
        backgroundColor: '#52af77',
        transformOrigin: 'bottom left',
        transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
        '&:before': {display: 'none'},
        '&.MuiSlider-valueLabelOpen': {
            transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
        },
        '& > *': {
            transform: 'rotate(45deg)',
        },
    },
});

export default function Vorschau(props) {

    const [title, setTitle] = useState(props.title.title ?? '');
    const [subTitle, setSubTitle] = useState(props.subTitle ?? '');
    const [slogan, setSlogan] = useState(props.slogan ?? '');
    const [m_w_d, setM_w_d] = useState(props.m_w_d ?? '');
    const [employmentTypes, setEmploymentTypes] = useState(props.employmentTypes ?? '');
    const [description, setDescription] = useState(props.description ?? '');
    const [imageDescription, setImageDescription] = useState(props.imageDescription ?? '');
    const [editorText, setEditorText] = useState(props.editorText ?? '');
    const [companyImage, setCompanyImage] = useState(props.companyImage ? props.companyImage.id : false);
    const [teaserImage, setTeaserImage] = useState(props.teaserImage ? props.teaserImage.data_url ? { data_url: props.teaserImage.data_url } : props.teaserImage.id : false);
    const [selectedCompanyAddresses, setSelectedCompanyAddresses] = React.useState(props.selectedCompanyAddresses ?? []);
    const [selectedContacts, setSelectedContacts] = React.useState(props.selectedContacts ?? []);
    const [badges, setBadges] = useState(props.badges ?? []);

    return (
        <Grid
            item
            xs={12}
            style={{
                position: 'relative',
                margin: '10px 120px 10px',
                padding: '20px 0',
                transition: 'all 0.2s ease',
            }}
        >
            <div onClick={props.closeModal} style={{ position: "absolute", top: -30, right: -120,fontFamily: 'VagRoundedBold',
                fontSize: isMobile ? 22 : 32, cursor: 'pointer',
                margin: 0 }}>x</div>
            {/*<Grid container>*/}
            {/*    <Grid item xs={6}>*/}
            {/*        <p style={{*/}
            {/*            textAlign: 'left',*/}
            {/*            fontFamily: 'VagRoundedBold',*/}
            {/*            fontSize: 32,*/}
            {/*            margin: 0,*/}
            {/*        }} className={'color-blue'}>*/}
            {/*            {title}*/}
            {/*        </p>*/}
            {/*        <p style={{*/}
            {/*            textAlign: 'left',*/}
            {/*            fontFamily: 'VagRoundedRegular',*/}
            {/*            fontSize: 16,*/}
            {/*            margin: '15px 0 0',*/}
            {/*        }} className={'color-blue'}>*/}
            {/*            {subTitle}*/}
            {/*        </p>*/}
            {/*    </Grid>*/}
            {/*    <Grid item xs={6}>*/}
            {/*        <div style={{display: 'flex', justifyContent: 'flex-end'}}>*/}
            {/*        </div>*/}
            {/*    </Grid>*/}
            {/*</Grid>*/}


            <Grid container>
                <Grid item xs={8}>
                    <div style={{
                        display: 'flex',
                        textAlign: 'left',
                        fontFamily: 'VagRoundedBold',
                        fontSize: isMobile ? 22 : 32,
                        margin: 0
                    }} className={'color-blue'}>
                        <p style={{
                            flexGrow: 1,
                            width: '70%',
                            margin: '0 10px 0 0',
                            lineHeight: '32px'
                        }}>{title} {m_w_d ? <span style={{
                            fontFamily: 'VagRoundedRegular',
                            color: '#333',
                            fontSize: 16
                        }}>(m/w/d)</span> : ''}{subTitle ? <span style={{
                            fontFamily: 'VagRoundedRegular',
                            fontSize: 16
                        }}><br/>{subTitle}</span> : null}</p>{(isMobile ? <br/> : ' ')}
                        {employmentTypes ? <p style={{
                            textAlign: 'left',
                            width: isMobile ? 'unset' : 275,
                            margin: 0,
                            fontFamily: 'VagRoundedBold',
                            color: 'black',
                            fontSize: 16,
                            paddingLeft: 10
                        }}>{employmentTypes}</p> : ''}
                    </div>
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={8} style={{
                    paddingRight: 40,
                    borderRight: '1px solid #ddd',
                    marginTop: 20,
                }}>
                    <p style={{ color: '#00374e', textAlign: 'left' }}>
                        {badges.map((item, index) => {
                            if (item.value) {
                                return (
                                    <span style={{
                                        fontSize: 14,
                                        background: '#ddd',
                                        padding: '7px 40px 5px',
                                        borderRadius: 25,
                                        marginRight: 15
                                    }}>{item.value}</span>
                                );
                            }
                        })}
                    </p>
                    <p style={{ color: '#00374e', textAlign: 'left', fontSize: 13, marginTop: 20 }}>
                        {description}
                    </p>


                    <div style={{ position: 'relative', textAlign: 'center' }}>
                        {/*<img src={placeholder} style={{width: '100%'}}/>*/}
                        {teaserImage &&
                            <img
                                src={teaserImage.data_url ? teaserImage.data_url : ENV.REACT_APP_API_URL + '/file/' + teaserImage + '/unlogged/content'}
                                style={{ maxWidth: '100%', maxHeight: 350 }}/>}
                        {(teaserImage && imageDescription) && <div style={{
                            position: 'absolute',
                            bottom: -10,
                            textAlign: 'center',
                            width: '100%',
                        }}>
                            <div style={{
                                background: '#00374e',
                                borderRadius: 50,
                                padding: '7px 20px 10px',
                                fontFamily: 'VagRoundedBold',
                                color: 'white',
                                fontSize: 28,
                                margin: 'auto',
                                width: 'fit-content'
                            }}>
                                {imageDescription}
                            </div>

                        </div>}
                    </div>

                    <div className="text-wrapper" style={{ textAlign: 'left', marginTop: 50, fontFamily: 'Arial' }}>
                        {renderHTML(editorText)}
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: 40 }}>
                    </div>
                </Grid>
                <Grid item xs={4} style={{ textAlign: 'left', width: 'calc(100% - 80px)', padding: 40 }}
                      className={'color-blue'}>
                    {companyImage &&
                        <img src={ENV.REACT_APP_API_URL + '/file/' + companyImage + '/unlogged/content'}
                             style={{ maxWidth: '100%', maxHeight: 200 }}/>}

                    {slogan ?
                        <p style={{
                            fontFamily: 'VagRoundedBold'
                        }}>{slogan}</p>
                        : null}

                    <p style={{
                        marginTop: 40,
                        marginBottom: 10,
                        fontFamily: 'VagRoundedBold',
                        fontSize: 20
                    }}>ARBEITGEBER</p>
                    {selectedCompanyAddresses && selectedCompanyAddresses.map((item, index) => {
                        console.log(item);
                        return (
                            <p style={{ marginTop: 0 }}>
                                {item.attributes.companyName}<br/>
                                {item.attributes.street}&#8202; {item.attributes.houseNumber}<br/>
                                {item.attributes.zipcode}&#8202; {item.attributes.city}<br/><br/>
                                {item.attributes.telephoneNumber && <div
                                    style={{ display: 'flex', alignItems: 'center', lineHeight: '16px' }}>
                                    <PhoneIcon style={{ fontSize: 16 }}/>
                                    <p style={{
                                        margin: '2px 0 0 2px',
                                        paddingTop: 2,
                                        marginTop: 2
                                    }}><a className={'oldLinkStyle'}
                                          href={'telto:' + item.attributes.telephoneNumber}>{item.attributes.telephoneNumber}</a>
                                    </p>
                                </div>}
                                {item.attributes.email && <div
                                    style={{ display: 'flex', alignItems: 'center', lineHeight: '16px' }}>
                                    <Mail style={{ fontSize: 16 }}/>
                                    <p style={{
                                        margin: '2px 0 0 2px',
                                        paddingTop: 2,
                                        marginTop: 2
                                    }}><a className={'oldLinkStyle'}
                                          href={'mailto:' + item.attributes.email}>{item.attributes.email}</a>
                                    </p>
                                </div>}
                                {item.attributes.website && <div
                                    style={{ display: 'flex', alignItems: 'center', lineHeight: '16px' }}>
                                    <LanguageIcon style={{ fontSize: 16 }}/>
                                    <p style={{
                                        margin: '2px 0 0 2px',
                                        paddingTop: 2,
                                        marginTop: 2
                                    }}><a className={'oldLinkStyle'}
                                          href={item.attributes.website}
                                          target={'_blank'}>{item.attributes.website?.replace('https://', '')}</a>
                                    </p>
                                </div>}
                            </p>
                        );
                    })}

                    <p style={{
                        marginTop: 10,
                        marginBottom: 10,
                        fontFamily: 'VagRoundedBold',
                        fontSize: 20
                    }}>Ansprechpartner</p>
                    {selectedContacts && selectedContacts.map((item, index) => {
                        console.log(item);
                        return (
                            <div style={{ marginBottom: 10 }}>
                                {(item.attributes.file_id) &&
                                    <img
                                        src={ENV.REACT_APP_API_URL + '/file/' + item.attributes.file_id + '/unlogged/content'}
                                        style={{
                                            borderRadius: 10,
                                            objectFit: 'cover',
                                            padding: '10px 0',
                                            maxWidth: '60%',
                                            // aspectRatio: '4 / 3',
                                        }}
                                        className="avatar"
                                        width="100%"
                                        height="auto"
                                        alt="Avatar"
                                    />}
                                <p style={{ margin: 0 }}>
                                    {item.attributes.anrede} {item.attributes.vorname} {item.attributes.nachname}
                                </p>
                                <br/>
                                {item.attributes.telefon &&
                                    <div style={{ display: 'flex', alignItems: 'center', lineHeight: '16px' }}>
                                        <PhoneIcon style={{ fontSize: 16 }}/>
                                        <p style={{
                                            margin: '2px 0 0 2px',
                                            marginTop: 2
                                        }}><a className={'oldLinkStyle'}
                                              href={'telto:' + item.attributes.telefon}>{item.attributes.telefon}</a>
                                        </p>
                                    </div>}
                                {item.attributes.email &&
                                    <div style={{ display: 'flex', alignItems: 'center', lineHeight: '16px' }}>
                                        <Mail style={{ fontSize: 16 }}/>
                                        <p style={{
                                            margin: '2px 0 0 2px',
                                            marginTop: 2
                                        }}><a className={'oldLinkStyle'}
                                              href={'mailto:' + item.attributes.email}>{item.attributes.email}</a></p>
                                    </div>}
                            </div>

                        );
                    })}
                </Grid>
            </Grid>
        </Grid>
    );
}

