import React, {useEffect, useRef} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {Checkbox, FormControl, FormControlLabel, Grid, IconButton, Radio, RadioGroup} from "@mui/material";
import {useState} from "react";
import {useHistory, useLocation} from "react-router";
import {ReactSession} from "react-client-session";
import useFetch from "./UseFetch";
import {toast} from "react-toastify";
import {DataGrid} from '@mui/x-data-grid';
import ImageUploading from "react-images-uploading";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {ReactComponent as LogoSvgHeartOutline} from "../images/svg/heart_outline.svg";
import {ReactComponent as LogoSvgHeart} from "../images/svg/heart.svg";
import {ReactComponent as LogoUser} from "../images/svg/Login.svg";
import AddIcon from "@mui/icons-material/Add";
import {ReactComponent as TrashIcon} from "../images/svg/mulleimer.svg";
import ClearIcon from "@mui/icons-material/Clear";
import {CssTextField3} from "./CssTextField3";
import {CssTextField4} from "./CssTextField4";
import {CustomButton} from "./CustomButton";
import {confirmAlert} from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import moment from 'moment';
import {ReactComponent as TestLogo} from "../images/svg/swiitcher_icon_blau.svg";
import {isMobile} from "react-device-detect";

const EditAddress = React.forwardRef((props, ref) => {
    const {
        type,
        index,
        id,
        updateUser,
        companyName,
        setCompanyName,
        companyNameError,
        setCompanyNameError,
        firstname,
        setFirstname,
        firstnameError,
        setFirstnameError,
        surname,
        setSurname,
        surnameError,
        setSurnameError,
        street,
        setStreet,
        streetError,
        setStreetError,
        nr,
        setNr,
        nrError,
        setNrError,
        postleitzahl,
        setPostleitzahl,
        postleitzahlError,
        setPostleitzahlError,
        ort,
        setOrt,
        ortError,
        setOrtError,
        telefonnummer,
        setTelefonnummer,
        telefonnummerError,
        setTelefonnummerError,
        email,
        setEmail,
        emailError,
        setEmailError,
        website,
        setWebsite,
        websiteError,
        setWebsiteError,
        newAddress,
        setNewAddress,
        setEditAddress
    } = props;

    return (
        <div ref={ref} key={id} style={{
            border: '2px solid #C7D300',
            borderRadius: 25,
            // height: 200,
            // padding: '10px 15px 15px 15px',
            padding: '10px 15px 20px 15px',
            textAlign: 'left'
        }}>
            <Grid container>
                <Grid item xs={8} style={{paddingTop: 5}}>
                    <p style={{
                        margin: 0,
                        padding: 0,
                        textAlign: 'left',
                        fontFamily: 'VagRoundedBold',
                        fontSize: 14
                    }}
                       className={'color-blue'}>Profil bearbeiten</p>
                </Grid>
                <Grid item xs={4} style={{textAlign: 'right'}}>
                    <ClearIcon
                        className={'not-yellow'}
                        onClick={() => {
                            setCompanyName('');
                            setCompanyNameError(false);
                            setStreet('');
                            setStreetError(false);
                            setNr('');
                            setNrError(false);
                            setPostleitzahl('');
                            setPostleitzahlError(false);
                            setOrt('');
                            setOrtError(false);
                            setTelefonnummer('');
                            setTelefonnummerError(false);
                            setEmail('');
                            setEmailError(false);
                            setWebsite('');
                            setWebsiteError(false);
                            if (type === 'new') {
                                setNewAddress(false);
                                window.scrollTo(0, 0);
                            } else if (type === 'edit') {
                                setEditAddress(false);
                            }
                        }}
                        style={{
                            height: 30,
                            width: 30,
                            marginRight: 2,
                            transition: 'all 0.2s ease-in-out',
                            textAlign: 'center',
                            fill: '#00374e',
                            cursor: 'pointer',
                            borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                        }}
                    />
                </Grid>
                {ReactSession.get('user') === 'Arbeitnehmer' ? <>
                    <Grid item xs={6}>
                        <CssTextField4
                            label="Vorname*"
                            fieldtype={'GrayTransparent'}
                            // InputLabelProps={{
                            //     shrink: true
                            // }}
                            // className={'text-input-label-blue'}
                            style={{
                                transition: 'all 0.2s ease',

                                width: 'calc(100% - 10px)'
                            }}
                            autoComplete={'off'}
                            onChange={e => {
                                if (e.target.value.trim().length < 5) {
                                    setFirstnameError(true);
                                } else {
                                    setFirstnameError(false);
                                }
                                setFirstname(e.target.value);
                            }}
                            // onKeyPress={handleKeypress}
                            value={firstname}
                            error={!!firstnameError}
                            // helperText={firstnameError ? (Array.isArray(firstnameError) && firstnameError[0]) ? firstnameError[0] : 'Vorname muss mindestens 5 Zeichen lang sein!' : ''}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <CssTextField4
                            label="Nachname*"
                            fieldtype={'GrayTransparent'}
                            // InputLabelProps={{
                            //     shrink: true
                            // }}
                            // className={'text-input-label-blue'}
                            style={{
                                transition: 'all 0.2s ease',

                                width: 'calc(100% - 10px)'
                            }}
                            autoComplete={'off'}
                            onChange={e => {
                                if (e.target.value.trim().length < 5) {
                                    setSurnameError(true);
                                } else {
                                    setSurnameError(false);
                                }
                                setSurname(e.target.value);
                            }}
                            // onKeyPress={handleKeypress}
                            value={surname}
                            error={!!surnameError}
                            // helperText={surnameError ? (Array.isArray(surnameError) && surnameError[0]) ? surnameError[0] : 'Nachname muss mindestens 5 Zeichen lang sein!' : ''}
                        />
                    </Grid>
                </> : <Grid item xs={12}>
                    <CssTextField4
                        label="Firmenname*"
                        fieldtype={'GrayTransparent'}
                        // InputLabelProps={{
                        //     shrink: true
                        // }}
                        // className={'text-input-label-blue'}
                        style={{
                            transition: 'all 0.2s ease',

                            width: 'calc(100% - 10px)'
                        }}
                        autoComplete={'off'}
                        onChange={e => {
                            if (e.target.value.trim().length < 5) {
                                setCompanyNameError(true);
                            } else {
                                setCompanyNameError(false);
                            }
                            setCompanyName(e.target.value);
                        }}
                        // onKeyPress={handleKeypress}
                        value={companyName}
                        error={!!companyNameError}
                        helperText={companyNameError ? (Array.isArray(companyNameError) && companyNameError[0]) ? companyNameError[0] : 'Firmenname muss mindestens 5 Zeichen lang sein!' : ''}
                    />
                </Grid>}
                <Grid item xs={10}>
                    <CssTextField4
                        label="Straße*"
                        fieldtype={'GrayTransparent'}
                        // InputLabelProps={{
                        //     shrink: true
                        // }}
                        // className={'text-input-label-blue'}
                        style={{
                            transition: 'all 0.2s ease',

                            width: 'calc(100% - 10px)'
                        }}
                        autoComplete={'off'}
                        onChange={e => {
                            setStreet(e.target.value);
                        }}
                        // onKeyPress={handleKeypress}
                        value={street}
                        error={!!streetError}
                    />
                </Grid>
                <Grid item xs={2}>
                    <CssTextField4
                        label="Nr*"
                        fieldtype={'GrayTransparent'}
                        // InputLabelProps={{
                        //     shrink: true
                        // }}
                        // className={'text-input-label-blue'}
                        style={{
                            transition: 'all 0.2s ease',

                            width: 'calc(100% - 10px)'
                        }}
                        autoComplete={'off'}
                        onChange={e => {
                            setNr(e.target.value);
                        }}
                        // onKeyPress={handleKeypress}
                        value={nr}
                        error={!!nrError}
                    />
                </Grid>
                <Grid item xs={6}>
                    <CssTextField4
                        label="PLZ*"
                        fieldtype={'GrayTransparent'}
                        // InputLabelProps={{
                        //     shrink: true
                        // }}
                        // className={'text-input-label-blue'}
                        style={{
                            transition: 'all 0.2s ease',

                            width: 'calc(100% - 10px)'
                        }}
                        autoComplete={'off'}
                        onChange={e => {
                            setPostleitzahl(e.target.value);
                        }}
                        // onKeyPress={handleKeypress}
                        value={postleitzahl}
                        error={!!postleitzahlError}
                    />
                </Grid>
                <Grid item xs={6}>
                    <CssTextField4
                        label="Ort / Sitz*"
                        fieldtype={'GrayTransparent'}
                        // InputLabelProps={{
                        //     shrink: true
                        // }}
                        // className={'text-input-label-blue'}
                        style={{
                            transition: 'all 0.2s ease',

                            width: 'calc(100% - 10px)'
                        }}
                        autoComplete={'off'}
                        onChange={e => {
                            setOrt(e.target.value);
                        }}
                        // onKeyPress={handleKeypress}
                        value={ort}
                        error={!!ortError}
                    />
                </Grid>
                <Grid item xs={6}>
                    <CssTextField4
                        label="Telefonnummer*"
                        fieldtype={'GrayTransparent'}
                        // InputLabelProps={{
                        //     shrink: true
                        // }}
                        // className={'text-input-label-blue'}
                        style={{
                            transition: 'all 0.2s ease',

                            width: 'calc(100% - 10px)'
                        }}
                        autoComplete={'off'}
                        onChange={e => {
                            setTelefonnummer(e.target.value);
                        }}
                        // onKeyPress={handleKeypress}
                        value={telefonnummer}
                        error={!!telefonnummerError}
                    />
                </Grid>
                <Grid item xs={6}>
                    <CssTextField4
                        label="E-Mail"
                        fieldtype={'GrayTransparent'}
                        // InputLabelProps={{
                        //     shrink: true
                        // }}
                        // className={'text-input-label-blue'}
                        disabled
                        style={{
                            transition: 'all 0.2s ease',

                            width: 'calc(100% - 10px)'
                        }}
                        autoComplete={'off'}
                        // onKeyPress={handleKeypress}
                        value={email}
                    />
                </Grid>
                {ReactSession.get('user') === 'Arbeitnehmer' ? <Grid item xs={6}></Grid> : <Grid item xs={6}>
                    <CssTextField4
                        label="Website*"
                        fieldtype={'GrayTransparent'}
                        // InputLabelProps={{
                        //     shrink: true
                        // }}
                        // className={'text-input-label-blue'}
                        style={{
                            transition: 'all 0.2s ease',

                            width: 'calc(100% - 10px)'
                        }}
                        autoComplete={'off'}
                        onChange={e => {
                            setWebsite(e.target.value);
                        }}
                        // onKeyPress={handleKeypress}
                        value={website}
                        error={!!websiteError}
                    />
                </Grid>}
                <Grid item xs={6} style={{textAlign: 'right', marginTop: 7}}>
                    <CustomButton
                        buttontype={'yellowBlue'}
                        style={{padding: '1.7px 15px', marginRight: 10}}
                        onClick={() => {
                            updateUser(id);
                        }}
                    >Speichern</CustomButton>
                </Grid>
            </Grid>
        </div>
    );
});

export default function Einstellungen(props) {

    const {locationChange, menuAppBarRef} = props;
    const location = useLocation();
    const history = useHistory();
    const api = useFetch();

    useEffect(() => {
        if (!ReactSession.get('user')) {
            menuAppBarRef.current.logNeeded();
            history.push('/');
        } else {
            document.title = 'Einstellungen';
            locationChange(location);
            getUser();
        }
    }, [])

    const [buttonHover, setButtonHover] = useState(null);
    const [activeMatchItem, setActiveMatchItem] = useState(null);
    const [favorite, setFavorite] = useState(null);
    const [user, setUser] = React.useState(null);

    const [password, setPassword] = React.useState('');
    const [passwordError, setPasswordError] = React.useState(false);
    const [passwordVerify, setPasswordVerify] = React.useState('');
    const [passwordVerifyError, setPasswordVerifyError] = React.useState(false);
    const [companyName, setCompanyName] = React.useState('');
    const [companyNameError, setCompanyNameError] = React.useState(false);
    const [firstname, setFirstname] = React.useState('');
    const [firstnameError, setFirstnameError] = React.useState(false);
    const [surname, setSurname] = React.useState('');
    const [surnameError, setSurnameError] = React.useState(false);
    const [street, setStreet] = React.useState('');
    const [streetError, setStreetError] = React.useState(false);
    const [nr, setNr] = React.useState('');
    const [nrError, setNrError] = React.useState(false);
    const [postleitzahl, setPostleitzahl] = React.useState('');
    const [postleitzahlError, setPostleitzahlError] = React.useState(false);
    const [ort, setOrt] = React.useState('');
    const [ortError, setOrtError] = React.useState(false);
    const [telefonnummer, setTelefonnummer] = React.useState('');
    const [telefonnummerError, setTelefonnummerError] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [emailError, setEmailError] = React.useState(false);
    const [website, setWebsite] = React.useState('');
    const [websiteError, setWebsiteError] = React.useState(false);


    const [progress, setProgress] = React.useState(false);
    const [editAddress, setEditAddress] = React.useState(false);
    const [newAddress, setNewAddress] = React.useState(false);
    const [getRef, setGetRef] = React.useState(false);
    const [activeProduct, setActiveProduct] = React.useState(false);
    const [activeProductValue, setActiveProductValue] = React.useState(false);
    const newAddressRef = React.createRef();
    const loadingSpinnerRef = useRef(null)


    const getUser = async () => {

        let rows = [];

        let url = ENV.REACT_APP_API_URL + '/user/' + ReactSession.get('userId') + '?include=jobAdvertisementContingent';

        let {response, data} = await api(url)


        if (!response || response.status == 401) {
            ReactSession.set('token', undefined);
            ReactSession.set('refreshToken', undefined);
            history.push('/login');
            return;
        }
        console.log(data);
        if (data && data.data) {

            if (data.included && data.included[0] && data.included[0].attributes) {
                let contingent = data.included[0];

                if (moment(contingent.attributes.flatDuration) > moment()) {
                    setActiveProduct('Flat');
                    // setActiveProductValue(moment.utc(contingent.attributes.flatDuration).local().format('DD.MM.YYYY'))
                    setActiveProductValue(moment(contingent.attributes.flatDuration).format('DD.MM.YYYY'))
                } else if (contingent.attributes.availableSingleJobAdvertisements > 0) {
                    setActiveProduct('Einzelanzeige o. Flat');
                    setActiveProductValue(contingent.attributes.availableSingleJobAdvertisements)
                } else {

                }

            }
            getProgress(data.data);

            setUser(data.data);
        } else if (response.error) {
            notify('API Fehler: ' + response.status, true);
        }
    }

    const getProgress = (user) => {
        console.log(user);
        let count = 0;
        if (ReactSession.get('user') === 'Arbeitgeber') {
            Object.keys(user.attributes).map(function(key) {
                if ((key == 'email' ||
                        key == 'companyName' ||
                        key == 'street' ||
                        key == 'houseNumber' ||
                        key == 'zipcode' ||
                        key == 'city' ||
                        key == 'telephoneNumber' ||
                        key == 'website')
                    && user.attributes[key] !== '') {
                    count++;
                    console.log(user.attributes[key])
                }
            });
        } else if (ReactSession.get('user') === 'Arbeitnehmer') {
            Object.keys(user.attributes).map(function(key) {
                if ((key == 'email' ||
                        key == 'firstname' ||
                        key == 'surname' ||
                        key == 'street' ||
                        key == 'houseNumber' ||
                        key == 'zipcode' ||
                        key == 'city' ||
                        key == 'telephoneNumber')
                    && user.attributes[key] !== '') {
                    count++;
                    console.log(user.attributes[key])
                }
            });
        }

        if (count > 0) {
            setProgress(count * 100 / 8);
        } else {
            setProgress(0);
        }
    }

    useEffect(() => {
        if (newAddressRef.current && getRef && user) {
            setGetRef(false);
            const yOffset = 0;
            const y = (newAddressRef.current.getBoundingClientRect().bottom - newAddressRef.current.getBoundingClientRect().y) + yOffset;

            window.scrollTo({top: y, behavior: 'smooth'});
        }
    }, [newAddressRef])

    const notify = (message, error) => {
        console.log('NOTIFY')
        if (error) {
            toast.error(message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                style: {marginTop: 30}
            });
        } else {
            toast.success(message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                style: {marginTop: 30}
            });
        }
    }

    const updateUser = async (id) => {
        if (validateUser()) {
            let updatedAttributes = {
                data: {
                    attributes: {
                        companyName: companyName,
                        street: street,
                        houseNumber: nr,
                        zipcode: postleitzahl,
                        city: ort,
                        telephoneNumber: telefonnummer,
                        website: website ? (!website.includes('https://') && !website.includes('http://')) ? 'https://' + website : website : null
                    },
                    relationships: {}
                }
            }

            if (ReactSession.get('user') === 'Arbeitnehmer') {
                updatedAttributes = {
                    data: {
                        attributes: {
                            firstname: firstname,
                            surname: surname,
                            street: street,
                            houseNumber: nr,
                            zipcode: postleitzahl,
                            city: ort,
                            telephoneNumber: telefonnummer
                        },
                        relationships: {}
                    }
                }
            }

            let url = ENV.REACT_APP_API_URL + "/user/" + ReactSession.get('userId');
            const {response, data} = await api(url, 'PATCH', JSON.stringify(updatedAttributes));
            console.log(data);

            if (response && response.status != '404' && data && response.status != '422') {
                setCompanyName('');
                setCompanyNameError(false);
                setStreet('');
                setStreetError(false);
                setNr('');
                setNrError(false);
                setPostleitzahl('');
                setPostleitzahlError(false);
                setOrt('');
                setOrtError(false);
                setTelefonnummer('');
                setTelefonnummerError(false);
                setEmail('');
                setEmailError(false);
                setWebsite('');
                setWebsiteError(false);
                setNewAddress(false);
                setEditAddress(false);
                getUser();
                notify('Dein Profil wurde erfolgreich aktualisiert');
            } else if (data && data.errors && data.errors[0] && data.errors[0].meta && data.errors[0].meta.companyName) {
                setCompanyNameError(data.errors[0].meta.companyName);
            } else if (response.status == '404') {

            }
        }
    }

    const validateUser = () => {
        if (ReactSession.get('user') === 'Arbeitgeber' && !companyName) {
            return false;
        } else if (ReactSession.get('user') === 'Arbeitgeber' && companyName.length < 5) {
            return false;
        } else {
            return true;
        }
    }

    const updatePassword = async () => {
        console.log('updatePassword');
        if (validate()) {

            let updatedAttributes = {
                password
            };

            let url = ENV.REACT_APP_API_URL + '/user/' + ReactSession.get('userId');

            const {response, data} = await api(url, 'PATCH', JSON.stringify(updatedAttributes));

            if (data.data) {
                setPassword('');
                setPasswordError('');
                setPasswordVerify('');
                setPasswordVerifyError('');
                notify('Dein Passwort wurde erfolgreich aktualisiert');
            } else if ((data.errors && data.errors[0]) || response.status == '500') {
                notify('API Fehler: ' + response.status, true);
            }
        }
    };

    const handleKeypress = e => {
        //it triggers by pressing the enter key
        if (e.charCode === 13) {
            updatePassword();
        }
    };

    const validate = () => {
        let error = false;


        let lowerCaseLetters = /[a-z]/g;
        if (!password.match(lowerCaseLetters)) {
            error = 'Passwort enthält keinen Kleinbuchstaben';
        }

        // Validate capital letters
        let upperCaseLetters = /[A-Z]/g;
        if (!password.match(upperCaseLetters)) {
            error = 'Passwort enthält keinen Großbuchstaben';
        }

        // Validate numbers
        let numbers = /[0-9]/g;
        if (!password.match(numbers)) {
            error = 'Passwort enthält keine Zahl';
        }

        // Validate special chars
        let chars = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        if (!password.match(chars)) {
            error = 'Passwort enthält kein Sonderzeichen';
        }

        // Validate length
        if (password.length < 8) {
            console.log('to short')
            error = 'Passwort muss mindestens 8 Zeichen lang sein'
        }

        // Validate length
        if (password.length === '') {
            error = 'Passwort darf nicht leer sein!'
        }

        if (password.trim() !== passwordVerify.trim()) {
            error = true;
            setPasswordError('Passwörter stimmen nicht überein!');
            setPasswordVerifyError('Passwörter stimmen nicht überein!');
        } else {
            setPasswordError(error)
            setPasswordVerifyError(undefined)
        }

        if (!error) {
            setPasswordError(undefined);
            setPasswordVerifyError(undefined);
        }

        return !error;

    };

    return (
        <Grid className="mainApp" container style={{
            padding: 0,
        }}>
            <Grid id={'unternehmensprofil'} item xs={12} style={{
                display: 'block',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 64,
                marginBottom: activeMatchItem !== null ? 50 : 0,
                padding: isMobile ? '30px 20px 50px' : '30px 50px 50px',
                width: '100%',
                minHeight: '800px',
            }}>
                <Grid id={'step4'} container style={{padding: isMobile ? 0 : 15}}>
                    <Grid item xs={12} style={{padding: '0'}}>
                        <p style={{margin: '20px 0', textAlign: 'left', fontFamily: 'VagRoundedBold', fontSize: 32}}
                           className={'color-blue'}>Einstellungen</p>
                    </Grid>
                    <Grid item xs={12} style={{padding: 5, maxWidth: 600, margin: 'auto'}}>
                        {!user ? <><div ref={loadingSpinnerRef}
                                                                        style={{
                                                                            //display: 'flex',
                                                                            //justifyContent: 'center',
                                                                            //alignItems: 'center',
                                                                            color: 'white',
                                                                            paddingTop: 0,
                                                                            paddingLeft: 0,
                                                                            paddingRight: 0,
                                                                            paddingBottom: 50,
                                                                            width: '100%',
                                                                            display: 'flex',
                                                                            justifyContent: 'center',
                                                                            flexDirection: 'column'
                                                                        }}>
                                <div id={'testLogo'} style={{
                                    borderRadius: 50,
                                    width: 60,
                                    height: 60,
                                    minWidth: 60,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    display: 'flex',
                                    border: '4px solid transparent',
                                    // cursor: 'pointer',
                                    transitionDuration: '0.2s',
                                    transitionProperty: 'transform',
                                    margin: '0 auto'
                                }}
                                >
                                    <div style={{
                                        padding: 7.5,
                                        background: 'rgba(0, 55, 78, 1)',
                                        borderRadius: 50,
                                        width: 57,
                                        minWidth: 27,
                                        height: 35,
                                        minHeight: 35,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        display: 'flex',
                                        border: '5px solid rgba(199, 211, 0, 1)'
                                    }}>
                                        <TestLogo
                                            style={{
                                                width: 30,
                                            }}
                                        />
                                    </div>
                                </div>
                            </div></> : null }
                        {user && <>
                            {editAddress && <>
                                <EditAddress
                                    type={'edit'}
                                    index={0}
                                    id={user.id}
                                    updateUser={updateUser}
                                    companyName={companyName}
                                    setCompanyName={setCompanyName}
                                    companyNameError={companyNameError}
                                    setCompanyNameError={setCompanyNameError}
                                    firstname={firstname}
                                    setFirstname={setFirstname}
                                    firstnameError={firstnameError}
                                    setFirstnameError={setFirstnameError}
                                    surname={surname}
                                    setSurname={setSurname}
                                    surnameError={surnameError}
                                    setSurnameError={setSurnameError}
                                    street={street}
                                    setStreet={setStreet}
                                    streetError={streetError}
                                    setStreetError={setStreetError}
                                    nr={nr}
                                    setNr={setNr}
                                    nrError={nrError}
                                    setNrError={setNrError}
                                    postleitzahl={postleitzahl}
                                    setPostleitzahl={setPostleitzahl}
                                    postleitzahlError={postleitzahlError}
                                    setPostleitzahlError={setPostleitzahlError}
                                    ort={ort}
                                    setOrt={setOrt}
                                    ortError={ortError}
                                    setOrtError={setOrtError}
                                    telefonnummer={telefonnummer}
                                    setTelefonnummer={setTelefonnummer}
                                    telefonnummerError={telefonnummerError}
                                    setTelefonnummerError={setTelefonnummerError}
                                    email={email}
                                    setEmail={setEmail}
                                    emailError={emailError}
                                    setEmailError={setEmailError}
                                    website={website}
                                    setWebsite={setWebsite}
                                    websiteError={websiteError}
                                    setWebsiteError={setWebsiteError}
                                    newAddress={newAddress}
                                    setNewAddress={setNewAddress}
                                    setEditAddress={setEditAddress}
                                />
                            </>}
                            {!editAddress && <>
                                <div style={{
                                    height: 'auto',
                                    position: 'relative',
                                    background: 'rgba(221, 221, 221, 0.5)',
                                    padding: 20,
                                    paddingBottom: 70,
                                    borderRadius: 40,
                                }}>
                                    <p className={'headerName'}
                                       style={{position: 'relative', width: 'calc(100% - 10px)', margin: ' 0 15px 15px 10px'}}>
                                        {ReactSession.get('user') === 'Arbeitnehmer' ? 'Profildaten' : 'Unternehmensdaten'}
                                        <span style={{float: 'right'}}>
                                            <EditIcon
                                                className={'not-yellow'}
                                                style={{
                                                    height: 30,
                                                    width: 30,
                                                    marginRight: 2,
                                                    transition: 'all 0.2s ease-in-out',
                                                    textAlign: 'right',
                                                    fill: '#00374e',
                                                    cursor: 'pointer',
                                                    borderRadius: ENV.REACT_APP_BORDER_RADIUS,
                                                }}
                                                onClick={() => {
                                                    console.log('EDIT')
                                                    setCompanyName(user.attributes.companyName);
                                                    setCompanyNameError(false);
                                                    setFirstname(user.attributes.firstname);
                                                    setFirstnameError(false);
                                                    setSurname(user.attributes.surname);
                                                    setSurnameError(false);
                                                    setStreet(user.attributes.street);
                                                    setStreetError(false);
                                                    setNr(user.attributes.houseNumber);
                                                    setNrError(false);
                                                    setPostleitzahl(user.attributes.zipcode);
                                                    setPostleitzahlError(false);
                                                    setOrt(user.attributes.city);
                                                    setOrtError(false);
                                                    setTelefonnummer(user.attributes.telephoneNumber);
                                                    setTelefonnummerError(false);
                                                    setEmail(user.attributes.email.replace(/ /g, ''));
                                                    setEmailError(false);
                                                    setWebsite(user.attributes.website ? user.attributes.website.replace('https://', '').replace('http://', '') : '');
                                                    setWebsiteError(false);
                                                    setNewAddress(false);
                                                    setEditAddress(true);
                                                }}
                                            />
                                        </span>
                                    </p>
                                    {user && <>
                                        <Grid container>
                                            <Grid item xs={isMobile ? 12 : 7}>
                                                {ReactSession.get('user') === 'Arbeitnehmer' ? <Grid container style={{paddingLeft: 10, marginTop: 10}}>
                                                    <Grid item xs={4}>
                                                        <p style={{textAlign: 'left', lineHeight: '26px', margin: 0}}
                                                           className={'color-blue'}>Name</p>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <p style={{textAlign: 'left', margin: 0, fontFamily: 'VagRoundedBold'}}
                                                           className={'color-blue'}>{user.attributes.firstname} {user.attributes.surname}</p>
                                                    </Grid>
                                                </Grid> : <Grid container style={{paddingLeft: 10, marginTop: 10}}>
                                                    <Grid item xs={4}>
                                                        <p style={{textAlign: 'left', lineHeight: '26px', margin: 0}}
                                                           className={'color-blue'}>Firmenname</p>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <p style={{textAlign: 'left', margin: 0, fontFamily: 'VagRoundedBold'}}
                                                           className={'color-blue'}>{user.attributes.companyName}</p>
                                                    </Grid>
                                                </Grid>}
                                                <Grid container style={{paddingLeft: 10}}>
                                                    <Grid item xs={4}>
                                                        <p style={{textAlign: 'left', lineHeight: '26px', margin: 0}}
                                                           className={'color-blue'}>Anschrift</p>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <p style={{textAlign: 'left', margin: 0, fontFamily: 'VagRoundedBold'}}
                                                           className={'color-blue'}>{user.attributes.street} {user.attributes.houseNumber}</p>
                                                    </Grid>
                                                </Grid>
                                                <Grid container style={{paddingLeft: 10}}>
                                                    <Grid item xs={4}>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <p style={{textAlign: 'left', margin: 0, fontFamily: 'VagRoundedBold'}}
                                                           className={'color-blue'}>{user.attributes.zipcode} {user.attributes.city}</p>
                                                    </Grid>
                                                </Grid>
                                                <Grid container style={{paddingLeft: 10}}>
                                                    <Grid item xs={4}>
                                                        <p style={{textAlign: 'left', lineHeight: '26px', margin: 0}}
                                                           className={'color-blue'}>Telefon</p>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <p style={{textAlign: 'left', margin: 0, fontFamily: 'VagRoundedBold'}}
                                                           className={'color-blue'}>{user.attributes.telephoneNumber}</p>
                                                    </Grid>
                                                </Grid>
                                                <Grid container style={{paddingLeft: 10}}>
                                                    <Grid item xs={4}>
                                                        <p style={{textAlign: 'left', lineHeight: '26px', margin: 0}}
                                                           className={'color-blue'}>E-Mail</p>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <p style={{textAlign: 'left', margin: 0, fontFamily: 'VagRoundedBold'}}
                                                           className={'color-blue'}>{user.attributes.email}</p>
                                                    </Grid>
                                                </Grid>
                                                {ReactSession.get('user') === 'Arbeitgeber' && <Grid container style={{paddingLeft: 10}}>
                                                    <Grid item xs={4}>
                                                        <p style={{textAlign: 'left', lineHeight: '26px', margin: 0}}
                                                           className={'color-blue'}>Website</p>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <p style={{textAlign: 'left', margin: 0, fontFamily: 'VagRoundedBold'}}
                                                           className={'color-blue'}>{user.attributes.website ? user.attributes.website.replace('https://', '').replace('http://') : ''}</p>
                                                    </Grid>
                                                </Grid>}
                                            </Grid>
                                            <Grid item xs={isMobile ? 12 : 5} style={{textAlign: 'right'}}>
                                                <div style={{
                                                    background: 'white',
                                                    width: isMobile ? 'calc(100% - 20px)' : '100%',
                                                    height: isMobile ? 'unset' : 'calc(100% - 33px)',
                                                    margin: isMobile ? '10px 10px 10px 0' : '0 10px 10px 0',
                                                    padding: isMobile ? 10 : 0,
                                                    borderRadius: 30,
                                                    textAlign: 'left',
                                                    fontSize: 10,
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}>
                                                    {ReactSession.get('user') == 'Arbeitnehmer' ?
                                                        <div style={{display: 'block'}}>
                                                            <p style={{fontFamily: 'VagRoundedBold', margin: 0}}>Aktiv
                                                                seit</p>
                                                            <p style={{margin: 0}}>{ReactSession.get('userCreatedAt') ? moment.unix(ReactSession.get('userCreatedAt')).format('DD.MM.YYYY') : ''}</p>
                                                        </div> :
                                                        <div style={{display: 'block'}}>
                                                            <p style={{fontFamily: 'VagRoundedBold', margin: 0}}>Aktives
                                                                Produkt</p>
                                                            <p style={{margin: '0 0 10px 0'}}>{activeProduct ? activeProduct : 'Keins'}</p>

                                                            {activeProduct && <>
                                                                {activeProduct === 'Flat' ?
                                                                    <>
                                                                        <p style={{
                                                                            fontFamily: 'VagRoundedBold',
                                                                            margin: 0
                                                                        }}>Auslauf</p>
                                                                        <p style={{margin: 0}}>zum {activeProductValue}</p>
                                                                    </> :
                                                                    <>
                                                                        <p style={{
                                                                            fontFamily: 'VagRoundedBold',
                                                                            margin: 0
                                                                        }}>Anzahl</p>
                                                                        <p style={{margin: 0}}>{activeProductValue}</p>
                                                                    </>}
                                                            </>}
                                                        </div>}
                                                </div>
                                            </Grid>
                                            <p style={{
                                                position: 'absolute',
                                                textAlign: 'left',
                                                bottom: 25,
                                                marginLeft: 10,
                                                fontSize: 12,
                                                color: '#C7D300'
                                            }}>Dein {ReactSession.get('user') == 'Arbeitnehmer' ? 'Profil' : 'Unternehmensprofil'} ist zu {progress}% fertiggestellt</p>
                                            <div style={{
                                                position: 'absolute',
                                                bottom: 15,
                                                width: 'calc(100% - 65px)',
                                                height: 10,
                                                marginLeft: 10,
                                                fontSize: 12,
                                                textDecoration: 'underline',
                                                border: '3px solid #C7D300',
                                                borderRadius: 10,
                                            }}>
                                                <div style={{
                                                    marginTop: -1,
                                                    background: '#C7D300',
                                                    width: (progress.toString() + '%'),
                                                    height: 'calc(100% + 2px)',
                                                    borderBottomLeftRadius: 10,
                                                    borderTopLeftRadius: 10
                                                }}></div>
                                            </div>
                                        </Grid>
                                    </>}
                                </div>
                            </>}

                            <div style={{
                                border: '2px solid #C7D300',
                                borderRadius: 25,
                                // height: 200,
                                // padding: '10px 15px 15px 15px',
                                marginTop: 15,
                                padding: '10px 15px 20px 15px',
                                textAlign: 'left'
                            }}>
                                <Grid container>
                                    <Grid item xs={12} style={{paddingTop: 5}}>
                                        <p style={{
                                            margin: '0 0 10px',
                                            padding: 0,
                                            textAlign: 'left',
                                            fontFamily: 'VagRoundedBold',
                                            fontSize: 14
                                        }}
                                           className={'color-blue'}>Passwort ändern</p>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CssTextField3
                                            label="Neues Passwort*"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            type="password"
                                            style={{
                                                transition: 'all 0.2s ease',
                                                margin: '20px 5px 10px 0',
                                                minHeight: 60,
                                                width: 'calc(100% - 5px)'
                                            }}
                                            onChange={e => {
                                                if (e.target.value.trim().length === 0) {
                                                    setPasswordError(true);
                                                } else {
                                                    setPasswordError(false);
                                                }
                                                setPassword(e.target.value);
                                            }}
                                            className={'text-input-label-blue'}
                                            onKeyPress={handleKeypress}
                                            value={password}
                                            error={!!passwordError}
                                            helperText={passwordError === true ? 'Passwort darf nicht leer sein!' : passwordError}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CssTextField3
                                            label="Neues Passwort wiederholen*"
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            type="password"
                                            style={{
                                                transition: 'all 0.2s ease',
                                                margin: '10px 5px 10px 0',
                                                minHeight: 60,
                                                width: 'calc(100% - 5px)'
                                            }}
                                            onChange={e => {
                                                if (e.target.value.trim().length === 0) {
                                                    setPasswordVerifyError(true);
                                                } else {
                                                    setPasswordVerifyError(false);
                                                }
                                                setPasswordVerify(e.target.value);
                                            }}
                                            className={'text-input-label-blue'}
                                            onKeyPress={handleKeypress}
                                            value={passwordVerify}
                                            error={!!passwordVerifyError}
                                            helperText={passwordVerifyError === true ? 'Passwort darf nicht leer sein!' : passwordVerifyError}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                    </Grid>
                                    <Grid item xs={6} style={{textAlign: 'right', marginTop: 0}}>
                                        <CustomButton
                                            buttontype={'yellowBlue'}
                                            style={{padding: '1.7px 15px', marginRight: 5}}
                                            onClick={() => {
                                                updatePassword();
                                            }}
                                        >Speichern</CustomButton>
                                    </Grid>
                                </Grid>
                            </div>
                        </>}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
