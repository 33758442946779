import React, {Component} from 'react';
import {EditorState} from 'draft-js';
import {Editor} from 'react-draft-wysiwyg';
import '../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import parse from "html-react-parser";
import renderHTML from 'react-render-html';
import {stateToHTML} from "draft-js-export-html";
import {convertToHTML, convertFromHTML} from 'draft-convert';

import {convertToRaw, convertFromRaw} from 'draft-js';

let options = {
    blockStyleFn: block => {
        let data = block.getData();
        if (data.size == 0) return;

        let style = {};
        if (data.get('text-align')) style = {...style, textAlign: data.get('text-align')}
        return {style};
    }
};

export default class Editor3 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editorState: props.editorTextFromHtml ?? EditorState.createEmpty(),
        };

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.editorTextFromHtml !== this.props.editorTextFromHtml) {
            this.props.setEditorText(stateToHTML(this.props.editorTextFromHtml.getCurrentContent(), options))
            this.setState({
                editorState: this.props.editorTextFromHtml
            })
        }
    }

    onEditorStateChange = (editorState) => {
        this.props.setEditorText(stateToHTML(editorState.getCurrentContent(), options))
        this.setState({
            editorState,
        });
    };

    getHtml = () => {
        return renderHTML(stateToHTML(this.state.editorState.getCurrentContent(), options));
    }

    render() {
        const {editorState} = this.state;
        return (
            <>
                <div id={'editor'}>
                    <Editor
                        editorState={editorState}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        onEditorStateChange={this.onEditorStateChange}
                        handlePastedText={() => false}
                        toolbar = {
                            { options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'embedded', 'image', 'remove', 'history'] }
                        }
                    />
                </div>

            </>
        )
    }
}
