import React, {useEffect} from 'react';
import Container from "@material-ui/core/Container";
import {useLocation} from "react-router-dom";

export default function AGB() {

    const location = useLocation();

    useEffect(() => {
        document.title = 'AGB';
    }, []);

    return (
        <Container maxWidth="lg" style={{marginTop: 100, background: 'white'}}>
            <div style={{textAlign: 'left'}}>
                <h1>AGBs</h1>
                <h2>§ 1 Geltungsbereich</h2>
                <p>1. Die nachfolgenden Allgemeinen Geschäftsbedingungen (AGB) gelten für alle Rechtsbeziehungen
                    zwischen
                    der TT Verlag GmbH und allen privaten Nutzern.</p>
                <p>1.1 Die nachfolgenden Allgemeinen Geschäftsbedingungen gelten für alle privaten Nutzer der Plattform
                    swiitcher.de, welche von der TT Verlag GmbH („Anbieter“) betrieben wird, und dessen angebotenen
                    Service,
                    Funktionen und sonstigen Leistungen.</p>
                <p>2. Abweichungen von diesen Nutzungsbedingungen gelten nur dann als vereinbart, wenn sie vom Anbieter
                    ausdrücklich schriftlich bestätigt wurden.</p>
                <h2>§ 2 Inhalte</h2>
                <p>2.1 Die Plattform swiitcher.de bietet Privatpersonen ein Angebot zur Entwicklung individueller
                    Karrierechancen und Unternehmen Produkte und Dienstleistungen zur zielorientierten
                    Personalrekrutierung
                    an. Privatpersonen können sich durch zielgerichtete Fragen mit Angabe ihrer Qualifikation anonym auf
                    swiitcher.de platzieren. Anschließend werden die Qualifikations-Profile mit vakanten
                    Online-Stellenanzeigen verknüpft, sodass Nutzer mit den Arbeitgebern in Kontakt treten kann. Erst
                    auf
                    Anfrage des Arbeitgebers und Bestätigung des privaten Nutzers kommt ein Kontakt zustande. Für die
                    Nutzung dieser Funktion ist eine Registrierung erforderlich mit Verifizierung der vom Nutzer
                    angegebenen
                    E-Mail Adresse. Die Nutzung der Funktion ist für private Nutzer kostenfrei. Auf dieser Plattform
                    werden
                    kostenpflichtig für das Portal beauftragte Online-Stellenanzeigen veröffentlicht wie auch
                    Stellenausschreibungen, die anderweitig veröffentlicht wurden und vom Anbieter unter Hinweis auf die
                    Quelle in die Datenbank des Portals eingestellt werden.</p>
                <p>2.2 Alle Inhalte auf dem Portal sind urheberrechtlich geschützt. Die Registrierung beinhaltet keine
                    Übertragung von Eigentums- oder Nutzungsrechten, Lizenzen oder sonstigen Rechten an der Software auf
                    den
                    Nutzer. Alle Rechte an der genutzten Software, an Kennzeichen, Titeln, Marken und Urheber- und
                    sonstigen
                    Rechten verbleiben uneingeschränkt beim Anbieter. Gewerbsmäßige Adressbeschaffung sowie das Kopieren
                    von
                    Inhalten, insbesondere auch von Profilen oder Kontaktdaten der Nutzer, und deren anderweitige
                    Veröffentlichung ohne schriftliche Genehmigung des Anbieters ist nicht gestattet.</p>
                <p>2.3 Der Anbieter ist frei in der Gestaltung der Inhalte und jederzeit berechtigt, die Angebote auf
                    dem
                    Portal zu ändern, einzuschränken, zu erweitern, oder ganz einzustellen. Der Anbieter wird hierbei
                    die
                    berechtigten Interessen der Nutzer angemessen berücksichtigen. Der Anbieter ist bei der Erbringung
                    seiner Leistung frei, diese auch durch Dritte nach eigener Wahl zu erbringen.</p>

                <h2>§ 3 Login / Registrierung</h2>
                <p>3.1 Die Recherche von Online-Anzeigen ist auch für nicht registrierte Nutzer gestattet.</p>
                <p>3.2 Für die Nutzung aller der für den privaten Nutzer vorhergesehen Funktionen ist eine einmalige
                    Registrierung unter Angabe von persönlichen Daten, wie die E-Mail Adresse, Passwort, etc. notwendig.
                    Das
                    Passwort kann dabei vom Nutzer frei festgelegt werden. Für jeden Nutzer ist lediglich eine einmalige
                    Registrierung möglich. Für den Zugriff auf das Benutzerprofil ist vorab ein Login notwendig. Die
                    Logindaten sind nicht auf Dritte übertragbar. Der Nutzer ist zur Geheimhaltung seiner Logindaten
                    verpflichtet. Sollte einem Nutzer die missbräuchliche Nutzung seines Logins bekannt werden, hat er
                    dies
                    unverzüglich dem Anbieter mitzuteilen und sein Passwort zu ändern, um sich vor einer missbräuchliche
                    Fremdnutzung durch Dritte zu schützen. Der Anbieter übernimmt keinerlei Haftung für Schäden, die aus
                    der
                    unberechtigten bzw. unsachgemäßen Verwendung passwortgeschützter Logins des Nutzers resultieren.</p>
                <p>3.3 Ein Vertrag kommt erst mit erfolgreichem Abschluss der Registrierung und durch die schriftliche
                    Bestätigung des Anbieters per Mail zustande. Dem Nutzer besteht jederzeit die Möglichkeit den
                    Vertragstext auszudrucken.</p>
                <h2>§ 4 Pflichten des Nutzers und Leistungsstörungen</h2>
                <p>4.1 Der Nutzer darf die Funktionen und Leistungen des Anbieters nur sachgerecht nutzen.
                    die Zugriffsmöglichkeiten auf den Service nicht rechtsmissbräuchlich, insbesondere nicht im
                    Widerspruch
                    zu den Nutzungsbedingungen nutzen und die Gesetze sowie die Rechte Dritter respektieren;
                    zum Schutz der Daten den anerkannten Grundsätzen der Datensicherheit Rechnung tragen und die
                    Verpflichtungen der Datenschutzvereinbarung beachten;
                    gegebenenfalls an den Anbieter beziehungsweise Dritte ausgehende E-Mails und Abfragen mit
                    größtmöglicher
                    Sorgfalt auf Viren überprüfen;
                    gesetzliche, behördliche und technische Vorschriften einhalten.</p>
                <p>4.2 Der Nutzer hat dem Anbieter in dem zuvor geschilderten Fall den aus solchen Pflichtverletzungen
                    resultierenden Schaden zu ersetzen.</p>
                <p>4.3 Der Nutzer ist rechtlich allein verantwortlich für die von ihm eingestellten Inhalte und hat
                    sicherzustellen, dass keine rechts- oder sittenwidrigen Inhalte aufgenommen werden oder die Aufnahme
                    solcher Inhalte ermöglicht wird, derartiger Inhalt gespeichert, verbreitet, zugänglich gemacht oder
                    auf
                    ein Angebot mit solchem Inhalt hingewiesen bzw. verlinkt wird.</p>
                <p>4.4 Ausdrücklich untersagt ist die Einstellung von rassistischen, pornographischen,
                    menschenverachtenden, beleidigenden, zu Straftaten anleitenden und gegen die guten Sitten
                    verstoßenden
                    Inhalten. Ausdrücklich verboten ist die Verbreitung von Inhalten, mit denen zum Hass gegen Teile der
                    Bevölkerung aufgerufen wird (Volksverhetzung) oder mit denen Propaganda für eine
                    verfassungsfeindliche
                    Organisation betrieben wird, sowie verleumderische, beleidigende oder ruf- oder geschäftsschädigende
                    bzw. persönlichkeitsrechtsverletzende Äußerungen sowie Junkmails, Spam, Kettenbriefe und andere
                    Inhalte
                    mit werbendem Charakter. Ebenfalls untersagt ist die Einstellung von Beiträgen, die gegen die
                    Grundsätze
                    der Datensicherheit verstoßen (z.B. mit Viren, Würmern, Trojanern u.ä. behaftete Beiträge und
                    E-Mails).</p>
                <p>4.5 Vom Nutzer eingestellte Inhalte sollten Folgendes nicht beinhalten: Informationen über
                    Krankheiten
                    oder eine bestehende Schwangerschaft, Informationen zu ethnischer Herkunft, politische, religiöse
                    oder
                    weltanschauliche Überzeugungen, Angaben zu Gewerkschaftszugehörigkeit, sexueller Ausrichtung,
                    diffamierende oder entwürdigende Informationen oder solche Informationen, die in keinem konkreten
                    Zusammenhang mit den Anforderungen eines Stellenangebotes oder der beruflichen Qualifikation
                    stehen.</p>
                <p>4.6 Der Nutzer ist allein und umfassend für die von ihm auf dem Portal eingestellten Inhalte
                    verantwortlich und verpflichtet, dem Anbieter sämtlichen Schaden, einschließlich aller Aufwendungen,
                    zu
                    ersetzen, welche diesem aufgrund schuldhafter Verstöße gegen diese Nutzungsbedingungen entstehen.
                    Der
                    Nutzer stellt den Anbieter insoweit ausdrücklich auf erstes Anfordern auch von allen Ansprüchen
                    Dritter
                    und den Kosten der damit verbundenen Rechtsverfolgung frei.</p>
                <p>4.7 Der Anbieter behält sich darüber hinaus vor, bei Verdacht einer missbräuchlichen Nutzung oder
                    wesentlicher Vertragsverletzungen diesen Vorgängen nachzugehen, entsprechende Vorkehrungen zu
                    treffen
                    und bei einem begründeten Verdacht gegebenenfalls den Zugang des Nutzers - mindestens bis zu einer
                    Verdachtsausräumung seitens des Nutzers - zu sperren und/oder vom Nutzer eingestellte Inhalte zu
                    löschen
                    und/oder gegebenenfalls bei besonders schwerwiegenden Verstößen auch das Vertragsverhältnis fristlos
                    zu
                    kündigen. Soweit der Nutzer den Verdacht ausräumt, wird die Sperrung aufgehoben.</p>
                <h2>§ 5 Sonstiges</h2>
                <p>5.1 Jegliche Nutzung für gewerbliche Zwecke ist den Nutzern untersagt. Bei Abweichungen hiervon
                    bedarf
                    es der vorherigen Zustimmung des Anbieters in Schriftform.</p>
                <p>5.2 Urheberrechtshinweise und/oder Markenbezeichnungen und/oder sonstige Rechtevorbehalte in den
                    Inhalten dürfen weder verändert noch beseitigt werden.</p>
                <p>5.3 Ein Recht der Nutzer auf Veröffentlichung ihrer Inhalte besteht nicht. Dem Anbieter obliegt das
                    Recht Inhalte von Nutzern teilweise oder ganz zu löschen.</p>
                <p>5.4 Der Nutzer räumt dem Anbieter mit Einstellung seiner Inhalte das Recht ein, diese auf dem Portal
                    im
                    Rahmen der jeweiligen Sichtbarkeitseinstellungen zu veröffentlichen und/oder veröffentlichen zu
                    lassen,
                    zu verbreiten und/oder verbreiten zu lassen, sowie auf sonstige Weise Dritten öffentlich zugänglich
                    zu
                    machen. Von der Rechteinräumung umfasst ist die Möglichkeit, Beiträge zum Abruf durch Dritte zur
                    Verfügung zu stellen und Beiträge zu archivieren. Mit Einstellung seiner Inhalte sichert der Nutzer
                    zu,
                    dass er über die eingeräumten Rechte verfügen kann und dass über diese Rechte nicht bereits
                    anderweitig
                    in einer Art verfügt wurde, welche die vorstehenden Nutzungsrechte des Anbieters beeinträchtigten
                    könnten. Beim Einstellen von Bildern oder Videos sichert der Nutzer zu, dass alle darauf erkennbaren
                    Personen der Veröffentlichung zugestimmt haben.</p>
                <h2>§ 6 Personenbezogene Daten</h2>
                <p>Die Verwendung, Verarbeitung und Nutzung personenbezogener Daten erfolgt gemäß den jeweils gültigen
                    gesetzlichen Bestimmungen und den Datenschutzregelungen des Anbieters.</p>
                <h2>§ 7 Gewährleistung</h2>
                <p>7.1 Der Anbieter übernimmt keine Gewähr – weder ausdrücklich noch stillschweigend –für Richtigkeit,
                    Vollständigkeit, Verlässlichkeit und Aktualität sowie für die Brauchbarkeit der abgerufenen Inhalte
                    für
                    den Nutzer, soweit diese von Nutzern veröffentlicht wurden. Der Anbieter wird versuchen, im Rahmen
                    der
                    technischen Möglichkeiten, mehr als unerhebliche Störungen und Fehler schnellstmöglich zu beseitigen
                    und
                    unerhebliche Beeinträchtigungen in angemessener Frist zu beseitigen. Der Anbieter wird sich bemühen,
                    die
                    angebotenen Leistungen auf dem Portal stets zugänglich zu halten. Der Nutzer hat jedoch keinen
                    Anspruch
                    auf die ständige Verfügbarkeit und Störungsfreiheit der Leistungen.</p>
                <p>7.2 Der Anbieter ist für den eventuellen Erfolg einer Stellenvermittlung in keiner Weise
                    verantwortlich
                    und haftet nicht, falls keine Stellenvermittlung zustande kommt.</p>
                <p>7.3 Dem Nutzer ist bekannt, dass es nach dem derzeitigen Stand der Technik nicht möglich ist, alle
                    Risiken aus der Nutzung des Internet auszuschließen. Daher übernimmt der Anbieter keine
                    Gewährleistung
                    für technische Mängel, insbesondere für die ständige und ununterbrochene Verfügbarkeit der Datenbank
                    und
                    ihrer Inhalte oder für die vollständige und fehlerfreie Wiedergabe der vom Nutzer eingestellten
                    Inhalte.</p>
                <p>7.4 Sämtliche Downloads – gleich ob unmittelbar von dem Portal oder von mit dem Portal verlinkten
                    Seiten
                    – erfolgen auf eigenes Risiko.</p>
                <h2>§ 8 Haftungsbeschränkung</h2>
                <p>8.1 Der Anbieter haftet bei Vorsatz oder grober Fahrlässigkeit für alle vom Anbieter im Zusammenhang
                    mit
                    der Erbringung der vertragsgemäßen Leistungen verursachten Schäden unbeschränkt.</p>
                <p>8.2 Bei leichter Fahrlässigkeit haftet der Anbieter im Fall der Verletzung des Lebens, des Körpers
                    oder
                    der Gesundheit unbeschränkt.</p>
                <p>8.3 Für unentgeltliche Dienste besteht einer über die in Absatz 1 und Absatz 2 hinausgehende Haftung
                    des
                    Anbieters nicht.</p>
                <p>8.4 Im Übrigen haftet der Anbieter im Zusammenhang mit der Erbringung entgeltlicher Leistungen nur,
                    soweit er eine wesentliche Vertragspflicht verletzt hat. Als wesentliche Vertragspflichten werden
                    dabei
                    abstrakt solche Pflichten bezeichnet, deren Erfüllung die ordnungsgemäße Durchführung des Vertrages
                    überhaupt erst ermöglicht und auf deren Einhaltung der Nutzer regelmäßig vertrauen darf. In diesen
                    Fällen ist die Haftung auf den Ersatz des vorhersehbaren, typischerweise eintretenden Schadens
                    beschränkt.</p>
                <p>8.5 Soweit die Haftung des Anbieters nach den vorgenannten Vorschriften ausgeschlossen oder
                    beschränkt
                    wird, gilt dies auch für Erfüllungsgehilfen des Anbieters.</p>
                <p>8.6 Die Haftung nach dem Produkthaftungsgesetz bleibt unberührt.</p>
                <h2>§ 9 Löschung</h2>
                <p>Der Anbieter ist berechtigt, den Zugang und sämtliche zugehörige Daten des Nutzers nach einjähriger
                    Inaktivität (kein Login über Webbrowser) zu löschen.</p>
                <h2>§ 10 Änderungen der Geschäftsbedingungen</h2>
                <p>Der Anbieter behält sich vor, diese AGB jederzeit mit Wirksamkeit auch innerhalb der bestehenden
                    Vertragsverhältnisse zu ändern. Über derartige Änderungen wird der Anbieter registrierte Nutzer
                    mindestens 6 Wochen vor dem geplanten Inkrafttreten der Änderungen in Kenntnis setzen. Sofern der
                    Nutzer
                    nicht innerhalb von 6 Wochen ab Zugang der Mitteilung widerspricht und die Inanspruchnahme der
                    Dienste
                    auch nach Ablauf der Widerspruchsfrist fortsetzt, gelten die Änderungen ab Fristablauf als wirksam
                    vereinbart. In der Änderungsmitteilung wird der Anbieter den Nutzer auf sein Widerspruchsrecht und
                    auf
                    die Folgen eines Widerspruchs hinweisen. Im Falle des Widerspruchs steht dem Anbieter das Recht zu,
                    das
                    Vertragsverhältnis mit dem Nutzer zum geplanten Inkrafttreten der Änderungen zu beenden.</p>
                <h2>§ 11 Hinweis zum Widerrufsrecht</h2>
                <p>(1) Widerrufsbelehrung</p>
                Widerrufsrecht
                <p>Sie können Ihre Vertragserklärung innerhalb von 14 Tagen ohne Angabe von Gründen in Textform (z. B.
                    Brief, Fax, E-Mail) widerrufen. Die Frist beginnt nach Erhalt dieser Belehrung in Textform, jedoch
                    nicht
                    vor Vertragsschluss und auch nicht vor Erfüllung unserer Informationspflichten gemäß Artikel 246 § 2
                    in
                    Verbindung mit § 1 Abs. 1 und 2 EGBGB sowie unserer Pflichten gemäß § 312e Abs. 1 Satz 1 BGB in
                    Verbindung mit Artikel 246 § 3 EGBGB. Zur Wahrung der Widerrufsfrist genügt die rechtzeitige
                    Absendung
                    des Widerrufs. Der Widerruf ist zu richten an:</p>
                <p>TT Verlag<br/>
                    St.-Mang-Platz 1<br/>
                    87435 Kempten<br/>
                    info@swiitcher.de</p>

                <p>Im Falle eines wirksamen Widerrufs sind die beiderseits empfangenen Leistungen zurückzugewähren und
                    ggf.
                    gezogene Nutzungen (z. B. Zinsen) herauszugeben. Können Sie uns die empfangene Leistung ganz oder
                    teilweise nicht oder nur in verschlechtertem Zustand zurückgewähren, müssen Sie uns insoweit ggf.
                    Wertersatz leisten. Dies kann dazu führen, dass Sie die vertraglichen Zahlungsverpflichtungen für
                    den
                    Zeitraum bis zum Widerruf gleichwohl erfüllen müssen. Verpflichtungen zur Erstattung von Zahlungen
                    müssen innerhalb von 30 Tagen erfüllt werden. Die Frist beginnt für Sie mit der Absendung Ihrer
                    Widerrufserklärung, für uns mit deren Empfang.</p>
                <p>Besondere Hinweise</p>
                <p>Ihr Widerrufsrecht erlischt vorzeitig, wenn der Vertrag von beiden Seiten auf Ihren ausdrücklichen
                    Wunsch vollständig erfüllt ist, bevor Sie Ihr Widerrufsrecht ausgeübt haben.<br/><br/>
                    • Ende der Widerrufsbelehrung -</p>
                <h2>§ 12 Schlussbestimmungen</h2>
                <p>12.1 Es gilt das Recht der Bundesrepublik Deutschland, wobei die Geltung des UN-Kaufrechts
                    ausgeschlossen wird.</p>
                <p>12.2 Ist der Nutzer Kaufmann, juristische Person des öffentlichen Rechts oder öffentlich-rechtliches
                    Sondervermögen, so ist der Sitz des Diensteanbieters ausschließlicher Gerichtsstand für alle
                    Streitigkeiten aus dem Vertragsverhältnis.</p>
                <p>12.3 Sollten einzelne Bestimmungen dieser Nutzungsbedingungen unwirksam sein oder werden, so berührt
                    dies die Wirksamkeit der übrigen Bestimmungen nicht.
                    AGBs für gewerbliche Kunden</p>
                <h2>§ 1 Geltungsbereich</h2>
                <p>1. Die nachfolgenden Allgemeinen Geschäftsbedingungen (AGB) gelten für alle Rechtsbeziehungen
                    zwischen
                    der TT Verlag GmbH und allen Unternehmer/Arbeitgebern („Kunden“).</p>
                <p>1.1 Die nachfolgenden Allgemeinen Geschäftsbedingungen gelten für alle gewerblichen Kunden der
                    Plattform
                    swiitcher.de, welche von der TT Verlag GmbH („Anbieter“) betrieben wird, und dessen angebotenen
                    Produkte
                    und Dienstleistungen.</p>
                <h2>§ 2 Inhalte</h2>
                <p>2.1 Abweichungen von diesen Nutzungsbedingungen gelten nur dann als vereinbart, wenn sie vom Anbieter
                    ausdrücklich schriftlich bestätigt wurden.</p>
                <p>2.2 Die Plattform swiitcher.de bietet Unternehmen Produkte und Dienstleistungen zur zielorientierten
                    Personalrekrutierung an. Privatpersonen können sich durch zielgerichtete Fragen mit Angabe ihrer
                    Qualifikation anonym auf swiitcher.de platzieren. Anschließend werden die Qualifikations-Profile mit
                    vakanten Online-Stellenanzeigen verknüpft, sodass Jobsuchende (private Nutzer) mit interessierten
                    Arbeitgebern in Kontakt treten können. Erst auf Anfrage des Arbeitgebers und Bestätigung durch den
                    privaten Nutzer, wird eine beidseitige Kommunikation ermöglicht. Für die Nutzung dieser Funktion ist
                    eine Registrierung des Kunden erforderlich mit Verifizierung durch die angegebenen E-Mail Adresse.
                    Die
                    Nutzung dieser Plattform ist für Jobsuchende (private Nutzer) kostenfrei. Auf dieser Plattform
                    werden
                    kostenpflichtig für das Portal beauftragte Online-Stellenanzeigen veröffentlicht wie auch
                    Stellenausschreibungen, die anderweitig veröffentlicht wurden und vom Anbieter unter Hinweis auf die
                    Quelle in die Datenbank des Portals eingestellt werden.</p>
                <p>2.3 Alle Inhalte auf dem Portal sind urheberrechtlich geschützt. Die Registrierung beinhaltet keine
                    Übertragung von Eigentums- oder Nutzungsrechten, Lizenzen oder sonstigen Rechten an der Software auf
                    den
                    Nutzer. Alle Rechte an der genutzten Software, an Kennzeichen, Titeln, Marken und Urheber- und
                    sonstigen
                    Rechten verbleiben uneingeschränkt beim Anbieter. Gewerbsmäßige Adressbeschaffung sowie das Kopieren
                    von
                    Inhalten, insbesondere auch von Profilen oder Kontaktdaten der Nutzer, und deren anderweitige
                    Veröffentlichung ohne schriftliche Genehmigung des Anbieters ist nicht gestattet.</p>
                <h2>§ 3 Login / Registrierung</h2>
                <p>3.1 Zugangsberechtigt sind Firmen und Unternehmen jeglicher Branche.</p>
                <p>3.2 Ein Unternehmen kann erst dann die Funktionen und Leistungen nutzen, nachdem eine Registrierung
                    erfolgt ist. Es ist sowohl für private Nutzer als auch für Unternehmen lediglich eine einmalige
                    Registrierung möglich. Die Registrierung erfolgt wie gewohnt durch unternehmensbezogene,
                    wahrheitsgemäße
                    Angaben des Unternehmens (Firmierung, Anzahl der Mitarbeiter, Kontaktdaten der Ansprechpartner) und
                    setzt das Akzeptieren dieser AGBs voraus. Der Benutzername entspricht der angegebenen E-Mail
                    Adresse,
                    das Passwort kann frei gewählt und im Nachgang jederzeit geändert werden. Um sich Zugriff zum
                    Unternehmensprofil und Nutzung aller Funktionen zu verschaffen, setzt es einen Login voraus. Hier
                    gelten
                    die Daten, die bei der Registrierung vom Kunde festgelegt wurden. Diese Daten gelten für das
                    Unternehmen, die Ansprechpartner und sonstigen Mitarbeiter und ist nur vom Kunden selbst intern
                    übertragbar. Der Kunde ist zur Geheimhaltung seiner Zugangsdaten verpflichtet; er haftet insoweit
                    uneingeschränkt für sämtliche Mitarbeiter. Sollte dem Kunden die missbräuchliche Nutzung seines
                    Logins
                    bekannt werden, hat er dies unverzüglich dem Anbieter mitzuteilen und sein Passwort zu ändern, um
                    sich
                    vor einer missbräuchliche Fremdnutzung durch Dritte zu schützen. Der Anbieter übernimmt keinerlei
                    Haftung für Schäden, die aus der unberechtigten bzw. unsachgemäßen Verwendung passwortgeschützter
                    Logins
                    des Nutzers resultieren.</p>
                <p>3.3 Der Kunde ist verpflichtet, bei der Registrierung ausschließlich wahrheitsgemäße und vollständige
                    Daten zu übermitteln und diese unverzüglich zu aktualisieren, sofern es zu Änderungen kommen
                    sollte.</p>
                <p>3.4 Der Kunde gewährleistet eine durchgängige Erreichbarkeit der im Rahmen der Registrierung
                    festgelegten Kontaktdaten. Das gilt besonders für die angegebene E-Mail</p>
                <p>3.5 Der Anbieter behält sich vor, die Registrierung einzelner Unternehmen ohne Angabe von Gründen
                    abzulehnen. Ferner behält sich der Anbieter vor, auch bereits aktive Kunden aus wichtigem Grund
                    (z.B.
                    wegen Verstoß gegen diese AGB) zu sperren.</p>
                <p>3.6 Die Registrierung von Unternehmen als Kunden ist kostenfrei. Alle weiteren Funktionen und
                    Leistungen
                    können nur auf Basis der Buchung / Bestellung einer Einzelanzeige oder Flat vom Kunden genutzt
                    werden.</p>
                <p>3.7 Ein Vertrag kommt erst mit erfolgreichem Abschluss der Registrierung und durch die schriftliche
                    Bestätigung des Anbieters per Mail zustande. Dem Nutzer besteht jederzeit die Möglichkeit den
                    Vertragstext auszudrucken.</p>
                <h2>§ 4 Pflichten des Kunden und Leistungsstörungen</h2>
                <p>4.1 Der Kunde darf den Service des Anbieters nur sachgerecht nutzen. Er wird insbesondere:
                    die Zugriffsmöglichkeiten auf den Service nicht rechtsmissbräuchlich, insbesondere nicht im
                    Widerspruch
                    zu den Allgemeinen Geschäftsbedingungen nutzen und die Gesetze sowie die Rechte Dritter
                    respektieren;
                    zum Schutz der Daten den anerkannten Grundsätzen der Datensicherheit Rechnung tragen und die
                    Verpflichtungen der Datenschutzvereinbarung beachten;
                    gegebenenfalls an den Anbieter beziehungsweise Dritte ausgehende E-Mails und Abfragen mit
                    größtmöglicher
                    Sorgfalt auf Viren überprüfen;
                    gesetzliche, behördliche und technische Vorschriften einhalten.</p>
                <p>4.2 Der Kunde hat dem Anbieter in dem zuvor geschilderten Fall den aus solchen schuldhaften
                    Pflichtverletzungen resultierenden Schaden zu ersetzen.</p>
                <p>4.3 Der Kunde ist rechtlich allein verantwortlich für die von ihm eingestellten Inhalte und hat
                    sicherzustellen, dass keine rechts- oder sittenwidrigen Inhalte aufgenommen werden oder die Aufnahme
                    solcher Inhalte ermöglicht wird, derartiger Inhalt gespeichert, verbreitet, zugänglich gemacht oder
                    auf
                    ein Angebot mit solchem Inhalt hingewiesen bzw. verlinkt wird. Der Kunde verpflichtet sich
                    insbesondere,
                    bei allen von ihm eingestellten Inhalten und bei jedweder Kommunikation über das Portal nach bestem
                    Wissen und Gewissen nur wahrheitsgemäße Angaben zu machen und die Vorgaben des AGG (Allgemeines
                    Gleichbehandlungsgesetz) zu beachten, insbesondere Stellenangebote nur geschlechtsneutral zu
                    formulieren.</p>
                <p>4.4 Ausdrücklich untersagt ist die Einstellung von rassistischen, pornographischen,
                    menschenverachtenden, beleidigenden, zu Straftaten anleitenden und gegen die guten Sitten
                    verstoßenden
                    Inhalten. Ausdrücklich verboten ist die Verbreitung von Inhalten, mit denen zum Hass gegen Teile der
                    Bevölkerung aufgerufen wird (Volksverhetzung) oder mit denen Propaganda für eine
                    verfassungsfeindliche
                    Organisation betrieben wird, sowie verleumderische, beleidigende oder ruf- oder geschäftsschädigende
                    bzw. persönlichkeitsrechtsverletzende Äußerungen sowie Junkmails, Spam, Kettenbriefe und andere
                    Inhalte
                    mit werbendem Charakter. Ebenfalls untersagt ist die Einstellung von Beiträgen, die gegen die
                    Grundsätze
                    der Datensicherheit verstoßen (z.B. mit Viren, Würmern, Trojanern u.ä. behaftete Beiträge und
                    E-Mails).</p>
                <p>4.5 Die Entscheidung über die Einstellung von Inhalten des Kunden in das Portal steht im Ermessen des
                    Anbieters. Dieser ist daher jederzeit berechtigt, die Veröffentlichung von Inhalten, insbesondere
                    von
                    Stellenangeboten, ohne Angabe von Gründen zu verweigern bzw. bereits eingestellte Inhalte wieder zu
                    entfernen.</p>
                <p>4.6 Der Kunde ist allein und umfassend für die von ihm auf dem Portal eingestellten Inhalte
                    verantwortlich und verpflichtet, dem Anbieter sämtlichen Schaden, einschließlich aller Aufwendungen,
                    zu
                    ersetzen, welche diesem aufgrund schuldhafter Verstöße gegen diese Nutzungsbedingungen entstehen.
                    Der
                    Kunde stellt den Anbieter insoweit ausdrücklich auf erstes Anfordern auch von allen berechtigten
                    Ansprüchen Dritter und den Kosten der damit verbundenen Rechtsverfolgung frei.</p>
                <p>4.7 Der Anbieter behält sich darüber hinaus vor, bei Verdacht einer missbräuchlichen Nutzung oder
                    wesentlicher Vertragsverletzungen diesen Vorgängen nachzugehen, entsprechende Vorkehrungen zu
                    treffen
                    und bei einem begründeten Verdacht gegebenenfalls den Zugang des Kunden - mindestens bis zu einer
                    Verdachtsausräumung seitens des Kunden - zu sperren und/oder vom Kunden eingestellte Inhalte zu
                    löschen
                    und/oder gegebenenfalls bei besonders schwerwiegenden Verstößen auch das Vertragsverhältnis fristlos
                    zu
                    kündigen. Soweit der Kunde den Verdacht ausräumt, wird die Sperrung aufgehoben.</p>
                <h2>§ 5 Sonstiges</h2>
                <p>5.1 Soweit Inhalte, insbesondere Informationen über Stellensuchende, dem Kunden über das Portal
                    zugänglich gemacht werden, dürfen diese vom Kunden nur für eigene interne Zwecke verwendet werden.
                    Jede
                    Nutzung von Inhalten für gewerbliche externe Zwecke ist untersagt. Eine Archivierung darf nur zu
                    eigenen
                    und internen Zwecken des Kunden dienen, also Dritten nicht zugänglich sein, nicht dazu dienen,
                    Vervielfältigungen für Dritte herzustellen und die Archivnutzung darf nicht zu einer zusätzlichen
                    Verwertung der Inhalte führen. Alle weiteren Nutzungen (z.B. Vervielfältigung zu gewerblichen
                    externen
                    Zwecken einschl. Archivierung, Überlassung an oder Verarbeitung durch Dritte für eigene oder fremde
                    Zwecke oder zur öffentlichen Wiedergabe, Übersetzung, Bearbeitungen usw.) bedürfen der vorherigen
                    schriftlichen Zustimmung des Anbieters.</p>
                <p>5.2 Urheberrechtshinweise und/oder Markenbezeichnungen und/oder sonstige Rechtevorbehalte in den
                    Inhalten dürfen weder verändert noch beseitigt werden.</p>
                <p>5.3 Der Kunde räumt dem Anbieter mit Einstellung seiner Inhalte das Recht ein, diese auf dem Portal
                    zu
                    veröffentlichen und/oder veröffentlichen zu lassen, zu verbreiten und/oder verbreiten zu lassen,
                    sowie
                    auf sonstige Weise Dritten öffentlich zugänglich zu machen. Von der Rechteinräumung umfasst ist die
                    Möglichkeit, Beiträge zum Abruf durch Dritte zur Verfügung zu stellen und Beiträge zu archivieren.
                    Mit
                    Einstellung seiner Inhalte sichert der Kunde zu, dass er über die eingeräumten Rechte verfügen kann
                    und
                    dass über diese Rechte nicht bereits anderweitig in einer Art verfügt wurde, welche die vorstehenden
                    Nutzungsrechte des Anbieters beeinträchtigten könnten. Beim Einstellen von Bildern oder Videos
                    sichert
                    der Kunde zu, dass alle darauf erkennbaren Personen der Veröffentlichung zugestimmt haben. Vom
                    Kunden
                    hochgeladene Dateien werden vom Anbieter ggf. in das für die Veröffentlichung auf dem Portal
                    erforderliche Format umformatiert. Bei dieser Umformatierung wird lediglich die Datenstruktur, nicht
                    aber der Dateninhalt verändert.</p>
                <p>5.4 Der Kunde überlässt dem Anbieter „swiitcher“ das Recht, den Kunden als Referenz zu bewerben. Dazu
                    zählen u. a. auch freie Nutzung (innerhalb des dafür vorhergesehenen Rahmens) von dessen Logo,
                    Schriftzug, Firmenbezeichnung für Bewerbungen des Portals aller Art. Darunter sind sämtliche
                    Marketingmaßnahmen wie z. B. der Verwendung auf social media, Plakatwänden, Flyer, etc. gemeint.</p>
                <p>5.5 Ebenfalls obliegt dem Anbieter das Recht, auf der Plattform veröffentlichte Stellenanzeigen auch
                    auf
                    anderen Kanälen, unter Angabe der Quelle zu bewerben. Der Kunde hat dabei jederzeit die Möglichkeit
                    dem
                    Anbieter mitzuteilen, dass das nicht erwünscht ist.</p>
                <h2>§ 6. Pflichten des Anbieters</h2>
                <p>6.1 Der Kunde kann nach kostenpflichtigem Bezug einer vom Anbieter angebotenen Leistungen und in
                    dessen
                    Rahmen, Stellenanzeigen mittels einem dafür vorhergesehenen Eingabeformular inklusive aller
                    dazugehörigen Funktionen auf der Plattform veröffentlichen. Dabei kann der Kunde in einem
                    angemessenen
                    Rahmen die Inhalte der Stellenanzeige frei bestimmen und gestalten. Die Inhalte vom Kunden werden
                    dann
                    vom Anbieter im Internet online zur Verfügung gestellt. Bei der Eingabe von Stellenanzeigen bestimmt
                    der
                    Kunde die Qualifikationskriterien seiner Wunschbewerber. Im Anschluss stellt „swiitcher“ im
                    Unternehmensprofil des Kunden automatisiert „Matches“ zur Verfügung, sofern ein Matching zwischen
                    Privatuser und Unternehmen / Kunde hergestellt werden konnte. Dieses „Matches“ können jederzeit vom
                    Kunden während der vertraglich vereinbarten Laufzeit des Unternehmensprofils eingesehen werden</p>
                <p>6.2 Kostenpflichtiger und verbindlicher Bezug von „Matches“. Die Einsicht der Bewerberprofile ist für
                    Kunden der Plattform kostenfrei und zu jederzeit, während der vertraglich vereinbarten
                    Vertragslaufzeit,
                    möglich - sofern Matches von „swiitcher“, aufgrund der Angaben der Qualifikation, hergestellt werden
                    konnten. Die jeweiligen „Matches“ (Qualifikationsprofile von Bewerbern) können vom Kunden jederzeit
                    angefragt werden. Dieser Vorgang ist für den Kunden verbindlich und kann zu weiteren Kosten führen.
                    Sollte der Bewerber / Jobsuchende mit der Anfrage des Kunden einverstanden sein und bestätigt dessen
                    Anfrage, werden die Kosten dafür fällig und dem Kunden automatisch in Rechnung gestellt. Eine
                    Rechnungsstellung erfolgt per Mail. Die Zahlung der Rechnung versteht sich ab dem Zeitpunkt der
                    Annahme
                    der Kontaktaufnahme des Kunden vom Jobsuchenden, innerhalb 14 Tage. Die Rechnungen können jederzeit
                    im
                    Kundenprofil eingesehen und ausgedruckt werden. Sollten mehrere Matches vom Kunden angefragt und vom
                    Bewerber / Jobsuchenden bestätigt worden sein, wird es zu Sammelrechnungen kommen. Ein oder mehrere
                    „Matches“ die vom Kunden verbindlich angefragt worden sind, können nur aufgrund einer Löschung der
                    Stellenanzeige aufgehoben werden. Siehe Punkt 6.3.</p>
                <p>6.3. Widerruf von Stellenanzeigen. Konnte der Kunden die vakante Stelle besetzen oder löscht diese
                    aufgrund seines freien Wunsches, werden alle dafür angefragten und verbindlichen „Matches“
                    automatisiert
                    aufgehoben und sind nicht weiter kostenpflichtig. Sollte es noch zu bestätigten Matches vor der
                    Aufhebung der Stellenanzeige kommen, werden diese dem Kunden kostenpflichtig in Rechnung gestellt.
                    Diese
                    Funktion ist jederzeit für den Kunden möglich.</p>
                <p>6.4 Die vom Kunden eingestellten Inhalte, insbesondere Stellenangebote, sind zeitlich begrenzt auf
                    dem
                    Portal abrufbar. Die Dauer der Verfügbarkeit wurde im Voraus durch den Kunden vertraglich
                    vereinbart.</p>
                <p>6.5 Der Anbieter beteiligt sich inhaltlich nicht an der Kommunikation, dem Informationsaustausch oder
                    der Stellenvermittlung zwischen Unternehmen und privaten Nutzern. Insofern übernimmt der Anbieter
                    keinerlei Gewähr für die Richtigkeit und Vollständigkeit der von den privaten Nutzern über die
                    Services
                    verbreiteten Informationen und überprüft diese auch nicht.</p>
                <p>6.6 Soweit Unternehmen und private Nutzer über das Portal untereinander Verträge abschließen,
                    entstehen
                    daraus keinerlei vertragliche Verpflichtungen für den Anbieter.</p>
                <p>6.7 Der Anbieter behält sich Änderungen seiner Leistungen vor, soweit solche Änderungen nicht die
                    Kernleistungen verändern und unter Berücksichtigung der Interessen des Kunden für diesen zumutbar
                    sind.</p>
                <p>6.8 Der Anbieter ist berechtigt, die ihm obliegenden Leistungen ganz oder teilweise durch Dritte
                    erbringen zu lassen.</p>
                <h2>§ 7 Personenbezogene Daten</h2>
                <p>Die Verwendung, Verarbeitung und Nutzung personenbezogener Daten erfolgt gemäß den jeweils gültigen
                    gesetzlichen Bestimmungen und den Datenschutzregelungen des Anbieters.</p>
                <h2>§ 8 Gewährleistung</h2>

                <p>8.1 Der Anbieter wird versuchen, im Rahmen der technischen Möglichkeiten, mehr als unerhebliche
                    Störungen und Fehler schnellstmöglich zu beseitigen und unerhebliche Beeinträchtigungen in
                    angemessener
                    Frist zu beseitigen. Der Anbieter wird sich bemühen, die angebotenen Leistungen auf dem Portal stets
                    zugänglich zu halten. Der Kunde hat jedoch keinen Anspruch auf die ständige Verfügbarkeit und
                    Störungsfreiheit der Leistungen.</p>
                <p>8.2 Der Anbieter ist für den eventuellen Erfolg einer Stellenvermittlung in keiner Weise
                    verantwortlich
                    und haftet nicht, falls keine Stellenvermittlung zustande kommt.</p>
                <p>8.3 Dem Kunden ist bekannt, dass es nach dem derzeitigen Stand der Technik nicht möglich ist, alle
                    Risiken aus der Nutzung des Internets auszuschließen. Daher übernimmt der Anbieter keine
                    Gewährleistung
                    für technische Mängel, insbesondere für die ständige und ununterbrochene Verfügbarkeit der Datenbank
                    und
                    ihrer Inhalte oder für die vollständige und fehlerfreie Wiedergabe der vom Kunden eingestellten
                    Inhalte.</p>
                <p>8.4 Sämtliche Downloads – gleich ob unmittelbar von dem Portal oder von mit dem Portal verlinkten
                    Seiten
                    – erfolgen auf eigenes Risiko.</p>
                <h2>§ 9 Haftungsbeschränkung des Anbieters</h2>
                <p>9.1 Der Anbieter haftet bei Vorsatz oder grober Fahrlässigkeit für alle vom Anbieter im Zusammenhang
                    mit
                    der Erbringung der vertragsgemäßen Leistungen verursachten Schäden unbeschränkt.</p>
                <p>9.2 Bei leichter Fahrlässigkeit haftet der Anbieter im Fall der Verletzung des Lebens, des Körpers
                    oder
                    der Gesundheit unbeschränkt.</p>
                <p>9.3 Im Übrigen haftet der Anbieter nur, soweit er eine wesentliche Vertragspflicht verletzt hat. Als
                    wesentliche Vertragspflichten werden dabei abstrakt solche Pflichten bezeichnet, deren Erfüllung die
                    ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglicht und auf deren Einhaltung der
                    Nutzer
                    regelmäßig vertrauen darf. In diesen Fällen ist die Haftung auf den Ersatz des vorhersehbaren,
                    typischerweise eintretenden Schadens beschränkt.</p>
                <p>9.4 Soweit die Haftung des Anbieters nach den vorgenannten Vorschriften ausgeschlossen oder
                    beschränkt
                    wird, gilt dies auch für Erfüllungsgehilfen des Anbieters.</p>
                <p>9.5. Schadensersatzansprüche verjähren innerhalb von 12 Monaten nach dem Schluss des Jahres, in dem
                    der
                    Anspruch entstanden ist und der Kunde von dem Anspruch begründenden Umständen und der Person des
                    Schädigers Kenntnis erlangt oder ohne grobe Fahrlässigkeit erlangen müsste.</p>
                <h2>§ 10 Laufzeit, Vergütung und Abrechnung</h2>
                <p>10.1 Die Laufzeit für die Nutzung der jeweiligen Leistung ist vertraglich vereinbart. Eine Kündigung
                    ist
                    nicht möglich. Im Falle einer Verlängerung der Laufzeit durch den Kunden, erfolgt diese ab dem
                    Zeitpunkt
                    der Beendigung des Vorvertrags unter der Zustimmung des Kunde und den geltenden Preislisten. Soweit
                    das
                    Lastschriftverfahren vom Kunden gewünscht ist, hat er dieses mit der Zustimmung des Anbieters im
                    Vorfeld
                    festzulegen. Ebenfalls kann diese Zahlungsart dazu verwendet werden, den wiederholten Einzug der
                    Verlängerung einer Leistung abzuwickeln. Das geschieht unter der Zustimmung des Kunden. Der Anbieter
                    behält sich vor, Preise und Produkte für die Zukunft zu ändern. Der Anbieter wird den Kunden über
                    Änderungen, die ihn betreffen, rechtzeitig informieren.</p>
                <p>10.2 Entscheidet sich der Kunde zu einer Vertragsänderung eines Produkts oder Laufzeit, kann einer
                    neuer
                    Vertrag, unter Berücksichtigung der Änderungen zustande kommen.</p>
                <h2>§ 11 Sonstige Regelungen</h2>
                <p>11.1 Es gilt das Recht der Bundesrepublik Deutschland, wobei die Geltung des UN-Kaufrechts
                    ausgeschlossen wird.</p>
                <p>11.2 Ist der Nutzer Kaufmann, juristische Person des öffentlichen Rechts oder öffentlich-rechtliches
                    Sondervermögen, so ist der Sitz des Diensteanbieters ausschließlicher Gerichtsstand für alle
                    Streitigkeiten aus dem Vertragsverhältnis.</p>
                <p>11.3 Sollten einzelne Bestimmungen dieser Nutzungsbedingungen unwirksam sein oder werden, so berührt
                    dies die Wirksamkeit der übrigen Bestimmungen nicht.</p>
            </div>
        </Container>
    );
}
